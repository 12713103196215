export const COORDINATE_SYSTEM_SETTING_LIST = [
  {coordinate_system:"CZM:OSGB36Cartographic", label: "OSGB36Cartographic / OSGB36 (with EPSG 1314 Helmert transform)"},
  {coordinate_system:"CZM:OSTN02Cartographic", label: "OSTN02Cartographic / OSGB36 with OSTN02 grid shift"},
  {coordinate_system:"EPSG:3819", label: "EPSG:3819 / HD1909"},
  {coordinate_system:"EPSG:3821", label: "EPSG:3821 / TWD67"},
  {coordinate_system:"EPSG:3822", label: "EPSG:3822 / TWD97"},
  {coordinate_system:"EPSG:3823", label: "EPSG:3823 / TWD97"},
  {coordinate_system:"EPSG:3824", label: "EPSG:3824 / TWD97"},
  {coordinate_system:"EPSG:3887", label: "EPSG:3887 / IGRS"},
  {coordinate_system:"EPSG:3888", label: "EPSG:3888 / IGRS"},
  {coordinate_system:"EPSG:3889", label: "EPSG:3889 / IGRS"},
  {coordinate_system:"EPSG:3906", label: "EPSG:3906 / MGI 1901"},
  {coordinate_system:"EPSG:4000", label: "EPSG:4000 / MOLDREF99"},
  {coordinate_system:"EPSG:4017", label: "EPSG:4017 / MOLDREF99"},
  {coordinate_system:"EPSG:4023", label: "EPSG:4023 / MOLDREF99"},
  {coordinate_system:"EPSG:4039", label: "EPSG:4039 / RGRDC 2005"},
  {coordinate_system:"EPSG:4040", label: "EPSG:4040 / RGRDC 2005"},
  {coordinate_system:"EPSG:4046", label: "EPSG:4046 / RGRDC 2005"},
  {coordinate_system:"EPSG:4073", label: "EPSG:4073 / SREF98"},
  {coordinate_system:"EPSG:4074", label: "EPSG:4074 / SREF98"},
  {coordinate_system:"EPSG:4075", label: "EPSG:4075 / SREF98"},
  {coordinate_system:"EPSG:4079", label: "EPSG:4079 / REGCAN95"},
  {coordinate_system:"EPSG:4080", label: "EPSG:4080 / REGCAN95"},
  {coordinate_system:"EPSG:4081", label: "EPSG:4081 / REGCAN95"},
  {coordinate_system:"EPSG:4120", label: "EPSG:4120 / Greek"},
  {coordinate_system:"EPSG:4121", label: "EPSG:4121 / GGRS87"},
  {coordinate_system:"EPSG:4122", label: "EPSG:4122 / ATS77"},
  {coordinate_system:"EPSG:4123", label: "EPSG:4123 / KKJ"},
  {coordinate_system:"EPSG:4124", label: "EPSG:4124 / RT90"},
  {coordinate_system:"EPSG:4127", label: "EPSG:4127 / Tete"},
  {coordinate_system:"EPSG:4128", label: "EPSG:4128 / Madzansua"},
  {coordinate_system:"EPSG:4129", label: "EPSG:4129 / Observatario"},
  {coordinate_system:"EPSG:4130", label: "EPSG:4130 / Moznet"},
  {coordinate_system:"EPSG:4131", label: "EPSG:4131 / Indian 1960"},
  {coordinate_system:"EPSG:4132", label: "EPSG:4132 / FD58"},
  {coordinate_system:"EPSG:4133", label: "EPSG:4133 / EST92"},
  {coordinate_system:"EPSG:4134", label: "EPSG:4134 / PSD93"},
  {coordinate_system:"EPSG:4135", label: "EPSG:4135 / Old Hawaiian"},
  {coordinate_system:"EPSG:4136", label: "EPSG:4136 / St. Lawrence Island"},
  {coordinate_system:"EPSG:4137", label: "EPSG:4137 / St. Paul Island"},
  {coordinate_system:"EPSG:4138", label: "EPSG:4138 / St. George Island"},
  {coordinate_system:"EPSG:4139", label: "EPSG:4139 / Puerto Rico"},
  {coordinate_system:"EPSG:4141", label: "EPSG:4141 / Israel 1993"},
  {coordinate_system:"EPSG:4142", label: "EPSG:4142 / Locodjo 1965"},
  {coordinate_system:"EPSG:4143", label: "EPSG:4143 / Abidjan 1987"},
  {coordinate_system:"EPSG:4144", label: "EPSG:4144 / Kalianpur 1937"},
  {coordinate_system:"EPSG:4145", label: "EPSG:4145 / Kalianpur 1962"},
  {coordinate_system:"EPSG:4146", label: "EPSG:4146 / Kalianpur 1975"},
  {coordinate_system:"EPSG:4147", label: "EPSG:4147 / Hanoi 1972"},
  {coordinate_system:"EPSG:4148", label: "EPSG:4148 / Hartebeesthoek94"},
  {coordinate_system:"EPSG:4149", label: "EPSG:4149 / CH1903"},
  {coordinate_system:"EPSG:4150", label: "EPSG:4150 / CH1903+"},
  {coordinate_system:"EPSG:4151", label: "EPSG:4151 / CHTRS95"},
  {coordinate_system:"EPSG:4152", label: "EPSG:4152 / NAD83(HARN)"},
  {coordinate_system:"EPSG:4153", label: "EPSG:4153 / Rassadiran"},
  {coordinate_system:"EPSG:4154", label: "EPSG:4154 / ED50(ED77)"},
  {coordinate_system:"EPSG:4155", label: "EPSG:4155 / Dabola 1981"},
  {coordinate_system:"EPSG:4156", label: "EPSG:4156 / S-JTSK"},
  {coordinate_system:"EPSG:4157", label: "EPSG:4157 / Mount Dillon"},
  {coordinate_system:"EPSG:4158", label: "EPSG:4158 / Naparima 1955"},
  {coordinate_system:"EPSG:4159", label: "EPSG:4159 / ELD79"},
  {coordinate_system:"EPSG:4160", label: "EPSG:4160 / Chos Malal 1914"},
  {coordinate_system:"EPSG:4161", label: "EPSG:4161 / Pampa del Castillo"},
  {coordinate_system:"EPSG:4162", label: "EPSG:4162 / Korean 1985"},
  {coordinate_system:"EPSG:4163", label: "EPSG:4163 / Yemen NGN96"},
  {coordinate_system:"EPSG:4164", label: "EPSG:4164 / South Yemen"},
  {coordinate_system:"EPSG:4165", label: "EPSG:4165 / Bissau"},
  {coordinate_system:"EPSG:4166", label: "EPSG:4166 / Korean 1995"},
  {coordinate_system:"EPSG:4167", label: "EPSG:4167 / NZGD2000"},
  {coordinate_system:"EPSG:4168", label: "EPSG:4168 / Accra"},
  {coordinate_system:"EPSG:4169", label: "EPSG:4169 / American Samoa 1962"},
  {coordinate_system:"EPSG:4170", label: "EPSG:4170 / SIRGAS 1995"},
  {coordinate_system:"EPSG:4171", label: "EPSG:4171 / RGF93 v1"},
  {coordinate_system:"EPSG:4173", label: "EPSG:4173 / IRENET95"},
  {coordinate_system:"EPSG:4174", label: "EPSG:4174 / Sierra Leone 1924"},
  {coordinate_system:"EPSG:4175", label: "EPSG:4175 / Sierra Leone 1968"},
  {coordinate_system:"EPSG:4176", label: "EPSG:4176 / Australian Antarctic"},
  {coordinate_system:"EPSG:4178", label: "EPSG:4178 / Pulkovo 1942(83)"},
  {coordinate_system:"EPSG:4179", label: "EPSG:4179 / Pulkovo 1942(58)"},
  {coordinate_system:"EPSG:4180", label: "EPSG:4180 / EST97"},
  {coordinate_system:"EPSG:4181", label: "EPSG:4181 / LUREF"},
  {coordinate_system:"EPSG:4182", label: "EPSG:4182 / Azores Occidental 1939"},
  {coordinate_system:"EPSG:4183", label: "EPSG:4183 / Azores Central 1948"},
  {coordinate_system:"EPSG:4184", label: "EPSG:4184 / Azores Oriental 1940"},
  {coordinate_system:"EPSG:4188", label: "EPSG:4188 / OSNI 1952"},
  {coordinate_system:"EPSG:4189", label: "EPSG:4189 / REGVEN"},
  {coordinate_system:"EPSG:4190", label: "EPSG:4190 / POSGAR 98"},
  {coordinate_system:"EPSG:4191", label: "EPSG:4191 / Albanian 1987"},
  {coordinate_system:"EPSG:4192", label: "EPSG:4192 / Douala 1948"},
  {coordinate_system:"EPSG:4193", label: "EPSG:4193 / Manoca 1962"},
  {coordinate_system:"EPSG:4194", label: "EPSG:4194 / Qornoq 1927"},
  {coordinate_system:"EPSG:4195", label: "EPSG:4195 / Scoresbysund 1952"},
  {coordinate_system:"EPSG:4196", label: "EPSG:4196 / Ammassalik 1958"},
  {coordinate_system:"EPSG:4197", label: "EPSG:4197 / Garoua"},
  {coordinate_system:"EPSG:4198", label: "EPSG:4198 / Kousseri"},
  {coordinate_system:"EPSG:4199", label: "EPSG:4199 / Egypt 1930"},
  {coordinate_system:"EPSG:4200", label: "EPSG:4200 / Pulkovo 1995"},
  {coordinate_system:"EPSG:4201", label: "EPSG:4201 / Adindan"},
  {coordinate_system:"EPSG:4202", label: "EPSG:4202 / AGD66"},
  {coordinate_system:"EPSG:4203", label: "EPSG:4203 / AGD84"},
  {coordinate_system:"EPSG:4204", label: "EPSG:4204 / Ain el Abd"},
  {coordinate_system:"EPSG:4205", label: "EPSG:4205 / Afgooye"},
  {coordinate_system:"EPSG:4206", label: "EPSG:4206 / Agadez"},
  {coordinate_system:"EPSG:4207", label: "EPSG:4207 / Lisbon"},
  {coordinate_system:"EPSG:4208", label: "EPSG:4208 / Aratu"},
  {coordinate_system:"EPSG:4209", label: "EPSG:4209 / Arc 1950"},
  {coordinate_system:"EPSG:4210", label: "EPSG:4210 / Arc 1960"},
  {coordinate_system:"EPSG:4211", label: "EPSG:4211 / Batavia"},
  {coordinate_system:"EPSG:4212", label: "EPSG:4212 / Barbados 1938"},
  {coordinate_system:"EPSG:4213", label: "EPSG:4213 / Beduaram"},
  {coordinate_system:"EPSG:4214", label: "EPSG:4214 / Beijing 1954"},
  {coordinate_system:"EPSG:4215", label: "EPSG:4215 / BD50"},
  {coordinate_system:"EPSG:4216", label: "EPSG:4216 / Bermuda 1957"},
  {coordinate_system:"EPSG:4218", label: "EPSG:4218 / Bogota 1975"},
  {coordinate_system:"EPSG:4219", label: "EPSG:4219 / Bukit Rimpah"},
  {coordinate_system:"EPSG:4220", label: "EPSG:4220 / Camacupa 1948"},
  {coordinate_system:"EPSG:4221", label: "EPSG:4221 / Campo Inchauspe"},
  {coordinate_system:"EPSG:4222", label: "EPSG:4222 / Cape"},
  {coordinate_system:"EPSG:4223", label: "EPSG:4223 / Carthage"},
  {coordinate_system:"EPSG:4224", label: "EPSG:4224 / Chua"},
  {coordinate_system:"EPSG:4225", label: "EPSG:4225 / Corrego Alegre 1970-72"},
  {coordinate_system:"EPSG:4227", label: "EPSG:4227 / Deir ez Zor"},
  {coordinate_system:"EPSG:4229", label: "EPSG:4229 / Egypt 1907"},
  {coordinate_system:"EPSG:4230", label: "EPSG:4230 / ED50"},
  {coordinate_system:"EPSG:4231", label: "EPSG:4231 / ED87"},
  {coordinate_system:"EPSG:4232", label: "EPSG:4232 / Fahud"},
  {coordinate_system:"EPSG:4236", label: "EPSG:4236 / Hu Tzu Shan 1950"},
  {coordinate_system:"EPSG:4237", label: "EPSG:4237 / HD72"},
  {coordinate_system:"EPSG:4238", label: "EPSG:4238 / ID74"},
  {coordinate_system:"EPSG:4239", label: "EPSG:4239 / Indian 1954"},
  {coordinate_system:"EPSG:4240", label: "EPSG:4240 / Indian 1975"},
  {coordinate_system:"EPSG:4241", label: "EPSG:4241 / Jamaica 1875"},
  {coordinate_system:"EPSG:4242", label: "EPSG:4242 / JAD69"},
  {coordinate_system:"EPSG:4243", label: "EPSG:4243 / Kalianpur 1880"},
  {coordinate_system:"EPSG:4244", label: "EPSG:4244 / Kandawala"},
  {coordinate_system:"EPSG:4245", label: "EPSG:4245 / Kertau 1968"},
  {coordinate_system:"EPSG:4246", label: "EPSG:4246 / KOC"},
  {coordinate_system:"EPSG:4247", label: "EPSG:4247 / La Canoa"},
  {coordinate_system:"EPSG:4248", label: "EPSG:4248 / PSAD56"},
  {coordinate_system:"EPSG:4249", label: "EPSG:4249 / Lake"},
  {coordinate_system:"EPSG:4250", label: "EPSG:4250 / Leigon"},
  {coordinate_system:"EPSG:4251", label: "EPSG:4251 / Liberia 1964"},
  {coordinate_system:"EPSG:4252", label: "EPSG:4252 / Lome"},
  {coordinate_system:"EPSG:4253", label: "EPSG:4253 / Luzon 1911"},
  {coordinate_system:"EPSG:4254", label: "EPSG:4254 / Hito XVIII 1963"},
  {coordinate_system:"EPSG:4255", label: "EPSG:4255 / Herat North"},
  {coordinate_system:"EPSG:4256", label: "EPSG:4256 / Mahe 1971"},
  {coordinate_system:"EPSG:4257", label: "EPSG:4257 / Makassar"},
  {coordinate_system:"EPSG:4258", label: "EPSG:4258 / ETRS89"},
  {coordinate_system:"EPSG:4259", label: "EPSG:4259 / Malongo 1987"},
  {coordinate_system:"EPSG:4261", label: "EPSG:4261 / Merchich"},
  {coordinate_system:"EPSG:4262", label: "EPSG:4262 / Massawa"},
  {coordinate_system:"EPSG:4263", label: "EPSG:4263 / Minna"},
  {coordinate_system:"EPSG:4265", label: "EPSG:4265 / Monte Mario"},
  {coordinate_system:"EPSG:4266", label: "EPSG:4266 / M'poraloko"},
  {coordinate_system:"EPSG:4267", label: "EPSG:4267 / NAD27"},
  {coordinate_system:"EPSG:4269", label: "EPSG:4269 / NAD83"},
  {coordinate_system:"EPSG:4270", label: "EPSG:4270 / Nahrwan 1967"},
  {coordinate_system:"EPSG:4271", label: "EPSG:4271 / Naparima 1972"},
  {coordinate_system:"EPSG:4272", label: "EPSG:4272 / NZGD49"},
  {coordinate_system:"EPSG:4273", label: "EPSG:4273 / NGO 1948"},
  {coordinate_system:"EPSG:4274", label: "EPSG:4274 / Datum 73"},
  {coordinate_system:"EPSG:4275", label: "EPSG:4275 / NTF"},
  {coordinate_system:"EPSG:4276", label: "EPSG:4276 / NSWC 9Z-2"},
  {coordinate_system:"EPSG:4277", label: "EPSG:4277 / OSGB36"},
  {coordinate_system:"EPSG:4278", label: "EPSG:4278 / OSGB70"},
  {coordinate_system:"EPSG:4279", label: "EPSG:4279 / OS(SN)80"},
  {coordinate_system:"EPSG:4281", label: "EPSG:4281 / Palestine 1923"},
  {coordinate_system:"EPSG:4282", label: "EPSG:4282 / Pointe Noire"},
  {coordinate_system:"EPSG:4283", label: "EPSG:4283 / GDA94"},
  {coordinate_system:"EPSG:4284", label: "EPSG:4284 / Pulkovo 1942"},
  {coordinate_system:"EPSG:4285", label: "EPSG:4285 / Qatar 1974"},
  {coordinate_system:"EPSG:4286", label: "EPSG:4286 / Qatar 1948"},
  {coordinate_system:"EPSG:4288", label: "EPSG:4288 / Loma Quintana"},
  {coordinate_system:"EPSG:4289", label: "EPSG:4289 / Amersfoort"},
  {coordinate_system:"EPSG:4292", label: "EPSG:4292 / Sapper Hill 1943"},
  {coordinate_system:"EPSG:4293", label: "EPSG:4293 / Schwarzeck"},
  {coordinate_system:"EPSG:4295", label: "EPSG:4295 / Serindung"},
  {coordinate_system:"EPSG:4297", label: "EPSG:4297 / Tananarive"},
  {coordinate_system:"EPSG:4298", label: "EPSG:4298 / Timbalai 1948"},
  {coordinate_system:"EPSG:4299", label: "EPSG:4299 / TM65"},
  {coordinate_system:"EPSG:4300", label: "EPSG:4300 / TM75"},
  {coordinate_system:"EPSG:4301", label: "EPSG:4301 / Tokyo"},
  {coordinate_system:"EPSG:4302", label: "EPSG:4302 / Trinidad 1903"},
  {coordinate_system:"EPSG:4303", label: "EPSG:4303 / TC(1948)"},
  {coordinate_system:"EPSG:4304", label: "EPSG:4304 / Voirol 1875"},
  {coordinate_system:"EPSG:4306", label: "EPSG:4306 / Bern 1938"},
  {coordinate_system:"EPSG:4307", label: "EPSG:4307 / Nord Sahara 1959"},
  {coordinate_system:"EPSG:4308", label: "EPSG:4308 / RT38"},
  {coordinate_system:"EPSG:4309", label: "EPSG:4309 / Yacare"},
  {coordinate_system:"EPSG:4310", label: "EPSG:4310 / Yoff"},
  {coordinate_system:"EPSG:4311", label: "EPSG:4311 / Zanderij"},
  {coordinate_system:"EPSG:4312", label: "EPSG:4312 / MGI"},
  {coordinate_system:"EPSG:4313", label: "EPSG:4313 / BD72"},
  {coordinate_system:"EPSG:4314", label: "EPSG:4314 / DHDN"},
  {coordinate_system:"EPSG:4315", label: "EPSG:4315 / Conakry 1905"},
  {coordinate_system:"EPSG:4316", label: "EPSG:4316 / Dealul Piscului 1930"},
  {coordinate_system:"EPSG:4318", label: "EPSG:4318 / NGN"},
  {coordinate_system:"EPSG:4319", label: "EPSG:4319 / KUDAMS"},
  {coordinate_system:"EPSG:4322", label: "EPSG:4322 / WGS 72"},
  {coordinate_system:"EPSG:4324", label: "EPSG:4324 / WGS 72BE"},
  {coordinate_system:"EPSG:4326", label: "EPSG:4326 / WGS 84"},
  {coordinate_system:"EPSG:4463", label: "EPSG:4463 / RGSPM06"},
  {coordinate_system:"EPSG:4465", label: "EPSG:4465 / RGSPM06"},
  {coordinate_system:"EPSG:4466", label: "EPSG:4466 / RGSPM06"},
  {coordinate_system:"EPSG:4468", label: "EPSG:4468 / RGM04"},
  {coordinate_system:"EPSG:4469", label: "EPSG:4469 / RGM04"},
  {coordinate_system:"EPSG:4470", label: "EPSG:4470 / RGM04"},
  {coordinate_system:"EPSG:4472", label: "EPSG:4472 / Cadastre 1997"},
  {coordinate_system:"EPSG:4473", label: "EPSG:4473 / Cadastre 1997"},
  {coordinate_system:"EPSG:4475", label: "EPSG:4475 / Cadastre 1997"},
  {coordinate_system:"EPSG:4479", label: "EPSG:4479 / China Geodetic Coordinate System 2000"},
  {coordinate_system:"EPSG:4480", label: "EPSG:4480 / China Geodetic Coordinate System 2000"},
  {coordinate_system:"EPSG:4481", label: "EPSG:4481 / Mexico ITRF92"},
  {coordinate_system:"EPSG:4482", label: "EPSG:4482 / Mexico ITRF92"},
  {coordinate_system:"EPSG:4483", label: "EPSG:4483 / Mexico ITRF92"},
  {coordinate_system:"EPSG:4490", label: "EPSG:4490 / China Geodetic Coordinate System 2000"},
  {coordinate_system:"EPSG:4555", label: "EPSG:4555 / New Beijing"},
  {coordinate_system:"EPSG:4556", label: "EPSG:4556 / RRAF 1991"},
  {coordinate_system:"EPSG:4557", label: "EPSG:4557 / RRAF 1991"},
  {coordinate_system:"EPSG:4558", label: "EPSG:4558 / RRAF 1991"},
  {coordinate_system:"EPSG:4600", label: "EPSG:4600 / Anguilla 1957"},
  {coordinate_system:"EPSG:4601", label: "EPSG:4601 / Antigua 1943"},
  {coordinate_system:"EPSG:4602", label: "EPSG:4602 / Dominica 1945"},
  {coordinate_system:"EPSG:4603", label: "EPSG:4603 / Grenada 1953"},
  {coordinate_system:"EPSG:4604", label: "EPSG:4604 / Montserrat 1958"},
  {coordinate_system:"EPSG:4605", label: "EPSG:4605 / St. Kitts 1955"},
  {coordinate_system:"EPSG:4606", label: "EPSG:4606 / St. Lucia 1955"},
  {coordinate_system:"EPSG:4607", label: "EPSG:4607 / St. Vincent 1945"},
  {coordinate_system:"EPSG:4608", label: "EPSG:4608 / NAD27(76)"},
  {coordinate_system:"EPSG:4609", label: "EPSG:4609 / NAD27(CGQ77)"},
  {coordinate_system:"EPSG:4610", label: "EPSG:4610 / Xian 1980"},
  {coordinate_system:"EPSG:4611", label: "EPSG:4611 / Hong Kong 1980"},
  {coordinate_system:"EPSG:4612", label: "EPSG:4612 / JGD2000"},
  {coordinate_system:"EPSG:4613", label: "EPSG:4613 / Segara"},
  {coordinate_system:"EPSG:4614", label: "EPSG:4614 / QND95"},
  {coordinate_system:"EPSG:4615", label: "EPSG:4615 / Porto Santo"},
  {coordinate_system:"EPSG:4616", label: "EPSG:4616 / Selvagem Grande"},
  {coordinate_system:"EPSG:4617", label: "EPSG:4617 / NAD83(CSRS)"},
  {coordinate_system:"EPSG:4618", label: "EPSG:4618 / SAD69"},
  {coordinate_system:"EPSG:4619", label: "EPSG:4619 / SWEREF99"},
  {coordinate_system:"EPSG:4620", label: "EPSG:4620 / Point 58"},
  {coordinate_system:"EPSG:4621", label: "EPSG:4621 / Fort Marigot"},
  {coordinate_system:"EPSG:4622", label: "EPSG:4622 / Guadeloupe 1948"},
  {coordinate_system:"EPSG:4623", label: "EPSG:4623 / CSG67"},
  {coordinate_system:"EPSG:4624", label: "EPSG:4624 / RGFG95"},
  {coordinate_system:"EPSG:4625", label: "EPSG:4625 / Martinique 1938"},
  {coordinate_system:"EPSG:4626", label: "EPSG:4626 / Reunion 1947"},
  {coordinate_system:"EPSG:4627", label: "EPSG:4627 / RGR92"},
  {coordinate_system:"EPSG:4628", label: "EPSG:4628 / Tahiti 52"},
  {coordinate_system:"EPSG:4629", label: "EPSG:4629 / Tahaa 54"},
  {coordinate_system:"EPSG:4630", label: "EPSG:4630 / IGN72 Nuku Hiva"},
  {coordinate_system:"EPSG:4632", label: "EPSG:4632 / Combani 1950"},
  {coordinate_system:"EPSG:4633", label: "EPSG:4633 / IGN56 Lifou"},
  {coordinate_system:"EPSG:4636", label: "EPSG:4636 / Petrels 1972"},
  {coordinate_system:"EPSG:4637", label: "EPSG:4637 / Perroud 1950"},
  {coordinate_system:"EPSG:4638", label: "EPSG:4638 / Saint Pierre et Miquelon 1950"},
  {coordinate_system:"EPSG:4639", label: "EPSG:4639 / MOP78"},
  {coordinate_system:"EPSG:4641", label: "EPSG:4641 / IGN53 Mare"},
  {coordinate_system:"EPSG:4642", label: "EPSG:4642 / ST84 Ile des Pins"},
  {coordinate_system:"EPSG:4643", label: "EPSG:4643 / ST71 Belep"},
  {coordinate_system:"EPSG:4644", label: "EPSG:4644 / NEA74 Noumea"},
  {coordinate_system:"EPSG:4646", label: "EPSG:4646 / Grand Comoros"},
  {coordinate_system:"EPSG:4657", label: "EPSG:4657 / Reykjavik 1900"},
  {coordinate_system:"EPSG:4658", label: "EPSG:4658 / Hjorsey 1955"},
  {coordinate_system:"EPSG:4659", label: "EPSG:4659 / ISN93"},
  {coordinate_system:"EPSG:4660", label: "EPSG:4660 / Helle 1954"},
  {coordinate_system:"EPSG:4661", label: "EPSG:4661 / LKS92"},
  {coordinate_system:"EPSG:4662", label: "EPSG:4662 / IGN72 Grande Terre"},
  {coordinate_system:"EPSG:4663", label: "EPSG:4663 / Porto Santo 1995"},
  {coordinate_system:"EPSG:4664", label: "EPSG:4664 / Azores Oriental 1995"},
  {coordinate_system:"EPSG:4665", label: "EPSG:4665 / Azores Central 1995"},
  {coordinate_system:"EPSG:4666", label: "EPSG:4666 / Lisbon 1890"},
  {coordinate_system:"EPSG:4667", label: "EPSG:4667 / IKBD-92"},
  {coordinate_system:"EPSG:4668", label: "EPSG:4668 / ED79"},
  {coordinate_system:"EPSG:4669", label: "EPSG:4669 / LKS94"},
  {coordinate_system:"EPSG:4670", label: "EPSG:4670 / IGM95"},
  {coordinate_system:"EPSG:4671", label: "EPSG:4671 / Voirol 1879"},
  {coordinate_system:"EPSG:4672", label: "EPSG:4672 / Chatham Islands 1971"},
  {coordinate_system:"EPSG:4673", label: "EPSG:4673 / Chatham Islands 1979"},
  {coordinate_system:"EPSG:4674", label: "EPSG:4674 / SIRGAS 2000"},
  {coordinate_system:"EPSG:4675", label: "EPSG:4675 / Guam 1963"},
  {coordinate_system:"EPSG:4676", label: "EPSG:4676 / Vientiane 1982"},
  {coordinate_system:"EPSG:4677", label: "EPSG:4677 / Lao 1993"},
  {coordinate_system:"EPSG:4678", label: "EPSG:4678 / Lao 1997"},
  {coordinate_system:"EPSG:4679", label: "EPSG:4679 / Jouik 1961"},
  {coordinate_system:"EPSG:4680", label: "EPSG:4680 / Nouakchott 1965"},
  {coordinate_system:"EPSG:4682", label: "EPSG:4682 / Gulshan 303"},
  {coordinate_system:"EPSG:4683", label: "EPSG:4683 / PRS92"},
  {coordinate_system:"EPSG:4684", label: "EPSG:4684 / Gan 1970"},
  {coordinate_system:"EPSG:4686", label: "EPSG:4686 / MAGNA-SIRGAS"},
  {coordinate_system:"EPSG:4687", label: "EPSG:4687 / RGPF"},
  {coordinate_system:"EPSG:4688", label: "EPSG:4688 / Fatu Iva 72"},
  {coordinate_system:"EPSG:4689", label: "EPSG:4689 / IGN63 Hiva Oa"},
  {coordinate_system:"EPSG:4690", label: "EPSG:4690 / Tahiti 79"},
  {coordinate_system:"EPSG:4691", label: "EPSG:4691 / Moorea 87"},
  {coordinate_system:"EPSG:4692", label: "EPSG:4692 / Maupiti 83"},
  {coordinate_system:"EPSG:4693", label: "EPSG:4693 / Nakhl-e Ghanem"},
  {coordinate_system:"EPSG:4694", label: "EPSG:4694 / POSGAR 94"},
  {coordinate_system:"EPSG:4695", label: "EPSG:4695 / Katanga 1955"},
  {coordinate_system:"EPSG:4696", label: "EPSG:4696 / Kasai 1953"},
  {coordinate_system:"EPSG:4697", label: "EPSG:4697 / IGC 1962 6th Parallel South"},
  {coordinate_system:"EPSG:4698", label: "EPSG:4698 / IGN 1962 Kerguelen"},
  {coordinate_system:"EPSG:4699", label: "EPSG:4699 / Le Pouce 1934"},
  {coordinate_system:"EPSG:4700", label: "EPSG:4700 / IGN Astro 1960"},
  {coordinate_system:"EPSG:4701", label: "EPSG:4701 / IGCB 1955"},
  {coordinate_system:"EPSG:4702", label: "EPSG:4702 / Mauritania 1999"},
  {coordinate_system:"EPSG:4703", label: "EPSG:4703 / Mhast 1951"},
  {coordinate_system:"EPSG:4704", label: "EPSG:4704 / Mhast (onshore)"},
  {coordinate_system:"EPSG:4705", label: "EPSG:4705 / Mhast (offshore)"},
  {coordinate_system:"EPSG:4706", label: "EPSG:4706 / Egypt Gulf of Suez S-650 TL"},
  {coordinate_system:"EPSG:4707", label: "EPSG:4707 / Tern Island 1961"},
  {coordinate_system:"EPSG:4708", label: "EPSG:4708 / Cocos Islands 1965"},
  {coordinate_system:"EPSG:4709", label: "EPSG:4709 / Iwo Jima 1945"},
  {coordinate_system:"EPSG:4710", label: "EPSG:4710 / Astro DOS 71"},
  {coordinate_system:"EPSG:4711", label: "EPSG:4711 / Marcus Island 1952"},
  {coordinate_system:"EPSG:4712", label: "EPSG:4712 / Ascension Island 1958"},
  {coordinate_system:"EPSG:4713", label: "EPSG:4713 / Ayabelle Lighthouse"},
  {coordinate_system:"EPSG:4714", label: "EPSG:4714 / Bellevue"},
  {coordinate_system:"EPSG:4715", label: "EPSG:4715 / Camp Area Astro"},
  {coordinate_system:"EPSG:4716", label: "EPSG:4716 / Phoenix Islands 1966"},
  {coordinate_system:"EPSG:4717", label: "EPSG:4717 / Cape Canaveral"},
  {coordinate_system:"EPSG:4718", label: "EPSG:4718 / Solomon 1968"},
  {coordinate_system:"EPSG:4719", label: "EPSG:4719 / Easter Island 1967"},
  {coordinate_system:"EPSG:4720", label: "EPSG:4720 / Fiji 1986"},
  {coordinate_system:"EPSG:4721", label: "EPSG:4721 / Fiji 1956"},
  {coordinate_system:"EPSG:4722", label: "EPSG:4722 / South Georgia 1968"},
  {coordinate_system:"EPSG:4723", label: "EPSG:4723 / GCGD59"},
  {coordinate_system:"EPSG:4724", label: "EPSG:4724 / Diego Garcia 1969"},
  {coordinate_system:"EPSG:4725", label: "EPSG:4725 / Johnston Island 1961"},
  {coordinate_system:"EPSG:4726", label: "EPSG:4726 / SIGD61"},
  {coordinate_system:"EPSG:4727", label: "EPSG:4727 / Midway 1961"},
  {coordinate_system:"EPSG:4728", label: "EPSG:4728 / PN84"},
  {coordinate_system:"EPSG:4729", label: "EPSG:4729 / Pitcairn 1967"},
  {coordinate_system:"EPSG:4730", label: "EPSG:4730 / Santo 1965"},
  {coordinate_system:"EPSG:4732", label: "EPSG:4732 / Marshall Islands 1960"},
  {coordinate_system:"EPSG:4733", label: "EPSG:4733 / Wake Island 1952"},
  {coordinate_system:"EPSG:4734", label: "EPSG:4734 / Tristan 1968"},
  {coordinate_system:"EPSG:4735", label: "EPSG:4735 / Kusaie 1951"},
  {coordinate_system:"EPSG:4736", label: "EPSG:4736 / Deception Island"},
  {coordinate_system:"EPSG:4737", label: "EPSG:4737 / Korea 2000"},
  {coordinate_system:"EPSG:4738", label: "EPSG:4738 / Hong Kong 1963"},
  {coordinate_system:"EPSG:4739", label: "EPSG:4739 / Hong Kong 1963(67)"},
  {coordinate_system:"EPSG:4740", label: "EPSG:4740 / PZ-90"},
  {coordinate_system:"EPSG:4741", label: "EPSG:4741 / FD54"},
  {coordinate_system:"EPSG:4742", label: "EPSG:4742 / GDM2000"},
  {coordinate_system:"EPSG:4743", label: "EPSG:4743 / Karbala 1979"},
  {coordinate_system:"EPSG:4744", label: "EPSG:4744 / Nahrwan 1934"},
  {coordinate_system:"EPSG:4745", label: "EPSG:4745 / RD/83"},
  {coordinate_system:"EPSG:4746", label: "EPSG:4746 / PD/83"},
  {coordinate_system:"EPSG:4747", label: "EPSG:4747 / GR96"},
  {coordinate_system:"EPSG:4748", label: "EPSG:4748 / Vanua Levu 1915"},
  {coordinate_system:"EPSG:4749", label: "EPSG:4749 / RGNC91-93"},
  {coordinate_system:"EPSG:4750", label: "EPSG:4750 / ST87 Ouvea"},
  {coordinate_system:"EPSG:4751", label: "EPSG:4751 / Kertau (RSO)"},
  {coordinate_system:"EPSG:4752", label: "EPSG:4752 / Viti Levu 1912"},
  {coordinate_system:"EPSG:4753", label: "EPSG:4753 / fk89"},
  {coordinate_system:"EPSG:4754", label: "EPSG:4754 / LGD2006"},
  {coordinate_system:"EPSG:4755", label: "EPSG:4755 / DGN95"},
  {coordinate_system:"EPSG:4756", label: "EPSG:4756 / VN-2000"},
  {coordinate_system:"EPSG:4757", label: "EPSG:4757 / SVY21"},
  {coordinate_system:"EPSG:4758", label: "EPSG:4758 / JAD2001"},
  {coordinate_system:"EPSG:4759", label: "EPSG:4759 / NAD83(NSRS2007)"},
  {coordinate_system:"EPSG:4760", label: "EPSG:4760 / WGS 66"},
  {coordinate_system:"EPSG:4761", label: "EPSG:4761 / HTRS96"},
  {coordinate_system:"EPSG:4762", label: "EPSG:4762 / BDA2000"},
  {coordinate_system:"EPSG:4763", label: "EPSG:4763 / Pitcairn 2006"},
  {coordinate_system:"EPSG:4764", label: "EPSG:4764 / RSRGD2000"},
  {coordinate_system:"EPSG:4765", label: "EPSG:4765 / Slovenia 1996"},
  {coordinate_system:"EPSG:4801", label: "EPSG:4801 / Bern 1898 (Bern)"},
  {coordinate_system:"EPSG:4802", label: "EPSG:4802 / Bogota 1975 (Bogota)"},
  {coordinate_system:"EPSG:4803", label: "EPSG:4803 / Lisbon (Lisbon)"},
  {coordinate_system:"EPSG:4804", label: "EPSG:4804 / Makassar (Jakarta)"},
  {coordinate_system:"EPSG:4805", label: "EPSG:4805 / MGI (Ferro)"},
  {coordinate_system:"EPSG:4806", label: "EPSG:4806 / Monte Mario (Rome)"},
  {coordinate_system:"EPSG:4807", label: "EPSG:4807 / NTF (Paris)"},
  {coordinate_system:"EPSG:4809", label: "EPSG:4809 / BD50 (Brussels)"},
  {coordinate_system:"EPSG:4810", label: "EPSG:4810 / Tananarive (Paris)"},
  {coordinate_system:"EPSG:4811", label: "EPSG:4811 / Voirol 1875 (Paris)"},
  {coordinate_system:"EPSG:4813", label: "EPSG:4813 / Batavia (Jakarta)"},
  {coordinate_system:"EPSG:4814", label: "EPSG:4814 / RT38 (Stockholm)"},
  {coordinate_system:"EPSG:4815", label: "EPSG:4815 / Greek (Athens)"},
  {coordinate_system:"EPSG:4816", label: "EPSG:4816 / Carthage (Paris)"},
  {coordinate_system:"EPSG:4817", label: "EPSG:4817 / NGO 1948 (Oslo)"},
  {coordinate_system:"EPSG:4818", label: "EPSG:4818 / S-JTSK (Ferro)"},
  {coordinate_system:"EPSG:4820", label: "EPSG:4820 / Segara (Jakarta)"},
  {coordinate_system:"EPSG:4821", label: "EPSG:4821 / Voirol 1879 (Paris)"},
  {coordinate_system:"EPSG:4823", label: "EPSG:4823 / Sao Tome"},
  {coordinate_system:"EPSG:4824", label: "EPSG:4824 / Principe"},
  {coordinate_system:"EPSG:4882", label: "EPSG:4882 / Slovenia 1996"},
  {coordinate_system:"EPSG:4883", label: "EPSG:4883 / Slovenia 1996"},
  {coordinate_system:"EPSG:4884", label: "EPSG:4884 / RSRGD2000"},
  {coordinate_system:"EPSG:4885", label: "EPSG:4885 / RSRGD2000"},
  {coordinate_system:"EPSG:4886", label: "EPSG:4886 / BDA2000"},
  {coordinate_system:"EPSG:4887", label: "EPSG:4887 / BDA2000"},
  {coordinate_system:"EPSG:4888", label: "EPSG:4888 / HTRS96"},
  {coordinate_system:"EPSG:4889", label: "EPSG:4889 / HTRS96"},
  {coordinate_system:"EPSG:4890", label: "EPSG:4890 / WGS 66"},
  {coordinate_system:"EPSG:4891", label: "EPSG:4891 / WGS 66"},
  {coordinate_system:"EPSG:4892", label: "EPSG:4892 / NAD83(NSRS2007)"},
  {coordinate_system:"EPSG:4893", label: "EPSG:4893 / NAD83(NSRS2007)"},
  {coordinate_system:"EPSG:4894", label: "EPSG:4894 / JAD2001"},
  {coordinate_system:"EPSG:4895", label: "EPSG:4895 / JAD2001"},
  {coordinate_system:"EPSG:4896", label: "EPSG:4896 / ITRF2005"},
  {coordinate_system:"EPSG:4897", label: "EPSG:4897 / DGN95"},
  {coordinate_system:"EPSG:4898", label: "EPSG:4898 / DGN95"},
  {coordinate_system:"EPSG:4899", label: "EPSG:4899 / LGD2006"},
  {coordinate_system:"EPSG:4900", label: "EPSG:4900 / LGD2006"},
  {coordinate_system:"EPSG:4901", label: "EPSG:4901 / ATF (Paris)"},
  {coordinate_system:"EPSG:4903", label: "EPSG:4903 / Madrid 1870 (Madrid)"},
  {coordinate_system:"EPSG:4904", label: "EPSG:4904 / Lisbon 1890 (Lisbon)"},
  {coordinate_system:"EPSG:4906", label: "EPSG:4906 / RGNC91-93"},
  {coordinate_system:"EPSG:4907", label: "EPSG:4907 / RGNC91-93"},
  {coordinate_system:"EPSG:4908", label: "EPSG:4908 / GR96"},
  {coordinate_system:"EPSG:4909", label: "EPSG:4909 / GR96"},
  {coordinate_system:"EPSG:4910", label: "EPSG:4910 / ITRF88"},
  {coordinate_system:"EPSG:4911", label: "EPSG:4911 / ITRF89"},
  {coordinate_system:"EPSG:4912", label: "EPSG:4912 / ITRF90"},
  {coordinate_system:"EPSG:4913", label: "EPSG:4913 / ITRF91"},
  {coordinate_system:"EPSG:4914", label: "EPSG:4914 / ITRF92"},
  {coordinate_system:"EPSG:4915", label: "EPSG:4915 / ITRF93"},
  {coordinate_system:"EPSG:4916", label: "EPSG:4916 / ITRF94"},
  {coordinate_system:"EPSG:4917", label: "EPSG:4917 / ITRF96"},
  {coordinate_system:"EPSG:4918", label: "EPSG:4918 / ITRF97"},
  {coordinate_system:"EPSG:4919", label: "EPSG:4919 / ITRF2000"},
  {coordinate_system:"EPSG:4920", label: "EPSG:4920 / GDM2000"},
  {coordinate_system:"EPSG:4921", label: "EPSG:4921 / GDM2000"},
  {coordinate_system:"EPSG:4922", label: "EPSG:4922 / PZ-90"},
  {coordinate_system:"EPSG:4923", label: "EPSG:4923 / PZ-90"},
  {coordinate_system:"EPSG:4924", label: "EPSG:4924 / Mauritania 1999"},
  {coordinate_system:"EPSG:4925", label: "EPSG:4925 / Mauritania 1999"},
  {coordinate_system:"EPSG:4926", label: "EPSG:4926 / Korea 2000"},
  {coordinate_system:"EPSG:4927", label: "EPSG:4927 / Korea 2000"},
  {coordinate_system:"EPSG:4928", label: "EPSG:4928 / POSGAR 94"},
  {coordinate_system:"EPSG:4929", label: "EPSG:4929 / POSGAR 94"},
  {coordinate_system:"EPSG:4930", label: "EPSG:4930 / Australian Antarctic"},
  {coordinate_system:"EPSG:4931", label: "EPSG:4931 / Australian Antarctic"},
  {coordinate_system:"EPSG:4932", label: "EPSG:4932 / CHTRS95"},
  {coordinate_system:"EPSG:4933", label: "EPSG:4933 / CHTRS95"},
  {coordinate_system:"EPSG:4934", label: "EPSG:4934 / EST97"},
  {coordinate_system:"EPSG:4935", label: "EPSG:4935 / EST97"},
  {coordinate_system:"EPSG:4936", label: "EPSG:4936 / ETRS89"},
  {coordinate_system:"EPSG:4937", label: "EPSG:4937 / ETRS89"},
  {coordinate_system:"EPSG:4938", label: "EPSG:4938 / GDA94"},
  {coordinate_system:"EPSG:4939", label: "EPSG:4939 / GDA94"},
  {coordinate_system:"EPSG:4940", label: "EPSG:4940 / Hartebeesthoek94"},
  {coordinate_system:"EPSG:4941", label: "EPSG:4941 / Hartebeesthoek94"},
  {coordinate_system:"EPSG:4942", label: "EPSG:4942 / IRENET95"},
  {coordinate_system:"EPSG:4943", label: "EPSG:4943 / IRENET95"},
  {coordinate_system:"EPSG:4944", label: "EPSG:4944 / ISN93"},
  {coordinate_system:"EPSG:4945", label: "EPSG:4945 / ISN93"},
  {coordinate_system:"EPSG:4946", label: "EPSG:4946 / JGD2000"},
  {coordinate_system:"EPSG:4947", label: "EPSG:4947 / JGD2000"},
  {coordinate_system:"EPSG:4948", label: "EPSG:4948 / LKS92"},
  {coordinate_system:"EPSG:4949", label: "EPSG:4949 / LKS92"},
  {coordinate_system:"EPSG:4950", label: "EPSG:4950 / LKS94"},
  {coordinate_system:"EPSG:4951", label: "EPSG:4951 / LKS94"},
  {coordinate_system:"EPSG:4952", label: "EPSG:4952 / Moznet"},
  {coordinate_system:"EPSG:4953", label: "EPSG:4953 / Moznet"},
  {coordinate_system:"EPSG:4954", label: "EPSG:4954 / NAD83(CSRS)"},
  {coordinate_system:"EPSG:4955", label: "EPSG:4955 / NAD83(CSRS)"},
  {coordinate_system:"EPSG:4956", label: "EPSG:4956 / NAD83(HARN)"},
  {coordinate_system:"EPSG:4957", label: "EPSG:4957 / NAD83(HARN)"},
  {coordinate_system:"EPSG:4958", label: "EPSG:4958 / NZGD2000"},
  {coordinate_system:"EPSG:4959", label: "EPSG:4959 / NZGD2000"},
  {coordinate_system:"EPSG:4960", label: "EPSG:4960 / POSGAR 98"},
  {coordinate_system:"EPSG:4961", label: "EPSG:4961 / POSGAR 98"},
  {coordinate_system:"EPSG:4962", label: "EPSG:4962 / REGVEN"},
  {coordinate_system:"EPSG:4963", label: "EPSG:4963 / REGVEN"},
  {coordinate_system:"EPSG:4964", label: "EPSG:4964 / RGF93 v1"},
  {coordinate_system:"EPSG:4965", label: "EPSG:4965 / RGF93 v1"},
  {coordinate_system:"EPSG:4966", label: "EPSG:4966 / RGFG95"},
  {coordinate_system:"EPSG:4967", label: "EPSG:4967 / RGFG95"},
  {coordinate_system:"EPSG:4970", label: "EPSG:4970 / RGR92"},
  {coordinate_system:"EPSG:4971", label: "EPSG:4971 / RGR92"},
  {coordinate_system:"EPSG:4974", label: "EPSG:4974 / SIRGAS 1995"},
  {coordinate_system:"EPSG:4975", label: "EPSG:4975 / SIRGAS 1995"},
  {coordinate_system:"EPSG:4976", label: "EPSG:4976 / SWEREF99"},
  {coordinate_system:"EPSG:4977", label: "EPSG:4977 / SWEREF99"},
  {coordinate_system:"EPSG:4978", label: "EPSG:4978 / WGS 84"},
  {coordinate_system:"EPSG:4979", label: "EPSG:4979 / WGS 84"},
  {coordinate_system:"EPSG:4980", label: "EPSG:4980 / Yemen NGN96"},
  {coordinate_system:"EPSG:4981", label: "EPSG:4981 / Yemen NGN96"},
  {coordinate_system:"EPSG:4982", label: "EPSG:4982 / IGM95"},
  {coordinate_system:"EPSG:4983", label: "EPSG:4983 / IGM95"},
  {coordinate_system:"EPSG:4984", label: "EPSG:4984 / WGS 72"},
  {coordinate_system:"EPSG:4985", label: "EPSG:4985 / WGS 72"},
  {coordinate_system:"EPSG:4986", label: "EPSG:4986 / WGS 72BE"},
  {coordinate_system:"EPSG:4987", label: "EPSG:4987 / WGS 72BE"},
  {coordinate_system:"EPSG:4988", label: "EPSG:4988 / SIRGAS 2000"},
  {coordinate_system:"EPSG:4989", label: "EPSG:4989 / SIRGAS 2000"},
  {coordinate_system:"EPSG:4990", label: "EPSG:4990 / Lao 1993"},
  {coordinate_system:"EPSG:4991", label: "EPSG:4991 / Lao 1993"},
  {coordinate_system:"EPSG:4992", label: "EPSG:4992 / Lao 1997"},
  {coordinate_system:"EPSG:4993", label: "EPSG:4993 / Lao 1997"},
  {coordinate_system:"EPSG:4994", label: "EPSG:4994 / PRS92"},
  {coordinate_system:"EPSG:4995", label: "EPSG:4995 / PRS92"},
  {coordinate_system:"EPSG:4996", label: "EPSG:4996 / MAGNA-SIRGAS"},
  {coordinate_system:"EPSG:4997", label: "EPSG:4997 / MAGNA-SIRGAS"},
  {coordinate_system:"EPSG:4998", label: "EPSG:4998 / RGPF"},
  {coordinate_system:"EPSG:4999", label: "EPSG:4999 / RGPF"},
  {coordinate_system:"EPSG:5011", label: "EPSG:5011 / PTRA08"},
  {coordinate_system:"EPSG:5012", label: "EPSG:5012 / PTRA08"},
  {coordinate_system:"EPSG:5013", label: "EPSG:5013 / PTRA08"},
  {coordinate_system:"EPSG:5132", label: "EPSG:5132 / Tokyo 1892"},
  {coordinate_system:"EPSG:5228", label: "EPSG:5228 / S-JTSK/05"},
  {coordinate_system:"EPSG:5229", label: "EPSG:5229 / S-JTSK/05 (Ferro)"},
  {coordinate_system:"EPSG:5233", label: "EPSG:5233 / SLD99"},
  {coordinate_system:"EPSG:5244", label: "EPSG:5244 / GDBD2009"},
  {coordinate_system:"EPSG:5245", label: "EPSG:5245 / GDBD2009"},
  {coordinate_system:"EPSG:5246", label: "EPSG:5246 / GDBD2009"},
  {coordinate_system:"EPSG:5250", label: "EPSG:5250 / TUREF"},
  {coordinate_system:"EPSG:5251", label: "EPSG:5251 / TUREF"},
  {coordinate_system:"EPSG:5252", label: "EPSG:5252 / TUREF"},
  {coordinate_system:"EPSG:5262", label: "EPSG:5262 / DRUKREF 03"},
  {coordinate_system:"EPSG:5263", label: "EPSG:5263 / DRUKREF 03"},
  {coordinate_system:"EPSG:5264", label: "EPSG:5264 / DRUKREF 03"},
  {coordinate_system:"EPSG:5322", label: "EPSG:5322 / ISN2004"},
  {coordinate_system:"EPSG:5323", label: "EPSG:5323 / ISN2004"},
  {coordinate_system:"EPSG:5324", label: "EPSG:5324 / ISN2004"},
  {coordinate_system:"EPSG:5332", label: "EPSG:5332 / ITRF2008"},
  {coordinate_system:"EPSG:5340", label: "EPSG:5340 / POSGAR 2007"},
  {coordinate_system:"EPSG:5341", label: "EPSG:5341 / POSGAR 2007"},
  {coordinate_system:"EPSG:5342", label: "EPSG:5342 / POSGAR 2007"},
  {coordinate_system:"EPSG:5352", label: "EPSG:5352 / MARGEN"},
  {coordinate_system:"EPSG:5353", label: "EPSG:5353 / MARGEN"},
  {coordinate_system:"EPSG:5354", label: "EPSG:5354 / MARGEN"},
  {coordinate_system:"EPSG:5358", label: "EPSG:5358 / SIRGAS-Chile 2002"},
  {coordinate_system:"EPSG:5359", label: "EPSG:5359 / SIRGAS-Chile 2002"},
  {coordinate_system:"EPSG:5360", label: "EPSG:5360 / SIRGAS-Chile 2002"},
  {coordinate_system:"EPSG:5363", label: "EPSG:5363 / CR05"},
  {coordinate_system:"EPSG:5364", label: "EPSG:5364 / CR05"},
  {coordinate_system:"EPSG:5365", label: "EPSG:5365 / CR05"},
  {coordinate_system:"EPSG:5368", label: "EPSG:5368 / MACARIO SOLIS"},
  {coordinate_system:"EPSG:5369", label: "EPSG:5369 / Peru96"},
  {coordinate_system:"EPSG:5370", label: "EPSG:5370 / MACARIO SOLIS"},
  {coordinate_system:"EPSG:5371", label: "EPSG:5371 / MACARIO SOLIS"},
  {coordinate_system:"EPSG:5372", label: "EPSG:5372 / Peru96"},
  {coordinate_system:"EPSG:5373", label: "EPSG:5373 / Peru96"},
  {coordinate_system:"EPSG:5379", label: "EPSG:5379 / SIRGAS-ROU98"},
  {coordinate_system:"EPSG:5380", label: "EPSG:5380 / SIRGAS-ROU98"},
  {coordinate_system:"EPSG:5381", label: "EPSG:5381 / SIRGAS-ROU98"},
  {coordinate_system:"EPSG:5391", label: "EPSG:5391 / SIRGAS_ES2007.8"},
  {coordinate_system:"EPSG:5392", label: "EPSG:5392 / SIRGAS_ES2007.8"},
  {coordinate_system:"EPSG:5393", label: "EPSG:5393 / SIRGAS_ES2007.8"},
  {coordinate_system:"EPSG:5451", label: "EPSG:5451 / Ocotepeque 1935"},
  {coordinate_system:"EPSG:5464", label: "EPSG:5464 / Sibun Gorge 1922"},
  {coordinate_system:"EPSG:5467", label: "EPSG:5467 / Panama-Colon 1911"},
  {coordinate_system:"EPSG:5487", label: "EPSG:5487 / RGAF09"},
  {coordinate_system:"EPSG:5488", label: "EPSG:5488 / RGAF09"},
  {coordinate_system:"EPSG:5489", label: "EPSG:5489 / RGAF09"},
  {coordinate_system:"EPSG:5524", label: "EPSG:5524 / Corrego Alegre 1961"},
  {coordinate_system:"EPSG:5527", label: "EPSG:5527 / SAD69(96)"},
  {coordinate_system:"EPSG:5544", label: "EPSG:5544 / PNG94"},
  {coordinate_system:"EPSG:5545", label: "EPSG:5545 / PNG94"},
  {coordinate_system:"EPSG:5546", label: "EPSG:5546 / PNG94"},
  {coordinate_system:"EPSG:5558", label: "EPSG:5558 / UCS-2000"},
  {coordinate_system:"EPSG:5560", label: "EPSG:5560 / UCS-2000"},
  {coordinate_system:"EPSG:5561", label: "EPSG:5561 / UCS-2000"},
  {coordinate_system:"EPSG:5591", label: "EPSG:5591 / FEH2010"},
  {coordinate_system:"EPSG:5592", label: "EPSG:5592 / FEH2010"},
  {coordinate_system:"EPSG:5593", label: "EPSG:5593 / FEH2010"},
  {coordinate_system:"EPSG:5681", label: "EPSG:5681 / DB_REF"},
  {coordinate_system:"EPSG:5828", label: "EPSG:5828 / DB_REF"},
  {coordinate_system:"EPSG:5830", label: "EPSG:5830 / DB_REF"},
  {coordinate_system:"EPSG:5884", label: "EPSG:5884 / TGD2005"},
  {coordinate_system:"EPSG:5885", label: "EPSG:5885 / TGD2005"},
  {coordinate_system:"EPSG:5886", label: "EPSG:5886 / TGD2005"},
  {coordinate_system:"EPSG:6133", label: "EPSG:6133 / CIGD11"},
  {coordinate_system:"EPSG:6134", label: "EPSG:6134 / CIGD11"},
  {coordinate_system:"EPSG:6135", label: "EPSG:6135 / CIGD11"},
  {coordinate_system:"EPSG:6207", label: "EPSG:6207 / Nepal 1981"},
  {coordinate_system:"EPSG:6309", label: "EPSG:6309 / CGRS93"},
  {coordinate_system:"EPSG:6310", label: "EPSG:6310 / CGRS93"},
  {coordinate_system:"EPSG:6311", label: "EPSG:6311 / CGRS93"},
  {coordinate_system:"EPSG:6317", label: "EPSG:6317 / NAD83(2011)"},
  {coordinate_system:"EPSG:6318", label: "EPSG:6318 / NAD83(2011)"},
  {coordinate_system:"EPSG:6319", label: "EPSG:6319 / NAD83(2011)"},
  {coordinate_system:"EPSG:6320", label: "EPSG:6320 / NAD83(PA11)"},
  {coordinate_system:"EPSG:6321", label: "EPSG:6321 / NAD83(PA11)"},
  {coordinate_system:"EPSG:6322", label: "EPSG:6322 / NAD83(PA11)"},
  {coordinate_system:"EPSG:6323", label: "EPSG:6323 / NAD83(MA11)"},
  {coordinate_system:"EPSG:6324", label: "EPSG:6324 / NAD83(MA11)"},
  {coordinate_system:"EPSG:6325", label: "EPSG:6325 / NAD83(MA11)"},
  {coordinate_system:"EPSG:6363", label: "EPSG:6363 / Mexico ITRF2008"},
  {coordinate_system:"EPSG:6364", label: "EPSG:6364 / Mexico ITRF2008"},
  {coordinate_system:"EPSG:6365", label: "EPSG:6365 / Mexico ITRF2008"},
  {coordinate_system:"EPSG:6666", label: "EPSG:6666 / JGD2011"},
  {coordinate_system:"EPSG:6667", label: "EPSG:6667 / JGD2011"},
  {coordinate_system:"EPSG:6668", label: "EPSG:6668 / JGD2011"},
  {coordinate_system:"EPSG:6704", label: "EPSG:6704 / RDN2008"},
  {coordinate_system:"EPSG:6705", label: "EPSG:6705 / RDN2008"},
  {coordinate_system:"EPSG:6706", label: "EPSG:6706 / RDN2008"},
  {coordinate_system:"EPSG:6781", label: "EPSG:6781 / NAD83(CORS96)"},
  {coordinate_system:"EPSG:6782", label: "EPSG:6782 / NAD83(CORS96)"},
  {coordinate_system:"EPSG:6783", label: "EPSG:6783 / NAD83(CORS96)"},
  {coordinate_system:"EPSG:6881", label: "EPSG:6881 / Aden 1925"},
  {coordinate_system:"EPSG:6882", label: "EPSG:6882 / Bekaa Valley 1920"},
  {coordinate_system:"EPSG:6883", label: "EPSG:6883 / Bioko"},
  {coordinate_system:"EPSG:6892", label: "EPSG:6892 / South East Island 1943"},
  {coordinate_system:"EPSG:6894", label: "EPSG:6894 / Gambia"},
  {coordinate_system:"EPSG:6934", label: "EPSG:6934 / IGS08"},
  {coordinate_system:"EPSG:6981", label: "EPSG:6981 / IG05 Intermediate CRS"},
  {coordinate_system:"EPSG:6982", label: "EPSG:6982 / IG05 Intermediate CRS"},
  {coordinate_system:"EPSG:6983", label: "EPSG:6983 / IG05 Intermediate CRS"},
  {coordinate_system:"EPSG:6988", label: "EPSG:6988 / IG05/12 Intermediate CRS"},
  {coordinate_system:"EPSG:6989", label: "EPSG:6989 / IG05/12 Intermediate CRS"},
  {coordinate_system:"EPSG:6990", label: "EPSG:6990 / IG05/12 Intermediate CRS"},
  {coordinate_system:"EPSG:7034", label: "EPSG:7034 / RGSPM06 (lon-lat)"},
  {coordinate_system:"EPSG:7035", label: "EPSG:7035 / RGSPM06 (lon-lat)"},
  {coordinate_system:"EPSG:7036", label: "EPSG:7036 / RGR92 (lon-lat)"},
  {coordinate_system:"EPSG:7037", label: "EPSG:7037 / RGR92 (lon-lat)"},
  {coordinate_system:"EPSG:7038", label: "EPSG:7038 / RGM04 (lon-lat)"},
  {coordinate_system:"EPSG:7039", label: "EPSG:7039 / RGM04 (lon-lat)"},
  {coordinate_system:"EPSG:7040", label: "EPSG:7040 / RGFG95 (lon-lat)"},
  {coordinate_system:"EPSG:7041", label: "EPSG:7041 / RGFG95 (lon-lat)"},
  {coordinate_system:"EPSG:7042", label: "EPSG:7042 / RGF93 v1 (lon-lat)"},
  {coordinate_system:"EPSG:7071", label: "EPSG:7071 / RGTAAF07"},
  {coordinate_system:"EPSG:7072", label: "EPSG:7072 / RGTAAF07"},
  {coordinate_system:"EPSG:7073", label: "EPSG:7073 / RGTAAF07"},
  {coordinate_system:"EPSG:7084", label: "EPSG:7084 / RGF93 v1 (lon-lat)"},
  {coordinate_system:"EPSG:7085", label: "EPSG:7085 / RGAF09 (lon-lat)"},
  {coordinate_system:"EPSG:7086", label: "EPSG:7086 / RGAF09 (lon-lat)"},
  {coordinate_system:"EPSG:7087", label: "EPSG:7087 / RGTAAF07 (lon-lat)"},
  {coordinate_system:"EPSG:7133", label: "EPSG:7133 / RGTAAF07 (lon-lat)"},
  {coordinate_system:"EPSG:7134", label: "EPSG:7134 / IGD05"},
  {coordinate_system:"EPSG:7135", label: "EPSG:7135 / IGD05"},
  {coordinate_system:"EPSG:7136", label: "EPSG:7136 / IGD05"},
  {coordinate_system:"EPSG:7137", label: "EPSG:7137 / IGD05/12"},
  {coordinate_system:"EPSG:7138", label: "EPSG:7138 / IGD05/12"},
  {coordinate_system:"EPSG:7139", label: "EPSG:7139 / IGD05/12"},
  {coordinate_system:"EPSG:7371", label: "EPSG:7371 / ONGD14"},
  {coordinate_system:"EPSG:7372", label: "EPSG:7372 / ONGD14"},
  {coordinate_system:"EPSG:7373", label: "EPSG:7373 / ONGD14"},
  {coordinate_system:"EPSG:7656", label: "EPSG:7656 / WGS 84 (G730)"},
  {coordinate_system:"EPSG:7657", label: "EPSG:7657 / WGS 84 (G730)"},
  {coordinate_system:"EPSG:7658", label: "EPSG:7658 / WGS 84 (G873)"},
  {coordinate_system:"EPSG:7659", label: "EPSG:7659 / WGS 84 (G873)"},
  {coordinate_system:"EPSG:7660", label: "EPSG:7660 / WGS 84 (G1150)"},
  {coordinate_system:"EPSG:7661", label: "EPSG:7661 / WGS 84 (G1150)"},
  {coordinate_system:"EPSG:7662", label: "EPSG:7662 / WGS 84 (G1674)"},
  {coordinate_system:"EPSG:7663", label: "EPSG:7663 / WGS 84 (G1674)"},
  {coordinate_system:"EPSG:7664", label: "EPSG:7664 / WGS 84 (G1762)"},
  {coordinate_system:"EPSG:7665", label: "EPSG:7665 / WGS 84 (G1762)"},
  {coordinate_system:"EPSG:7677", label: "EPSG:7677 / PZ-90.02"},
  {coordinate_system:"EPSG:7678", label: "EPSG:7678 / PZ-90.02"},
  {coordinate_system:"EPSG:7679", label: "EPSG:7679 / PZ-90.11"},
  {coordinate_system:"EPSG:7680", label: "EPSG:7680 / PZ-90.11"},
  {coordinate_system:"EPSG:7681", label: "EPSG:7681 / GSK-2011"},
  {coordinate_system:"EPSG:7682", label: "EPSG:7682 / GSK-2011"},
  {coordinate_system:"EPSG:7683", label: "EPSG:7683 / GSK-2011"},
  {coordinate_system:"EPSG:7684", label: "EPSG:7684 / Kyrg-06"},
  {coordinate_system:"EPSG:7685", label: "EPSG:7685 / Kyrg-06"},
  {coordinate_system:"EPSG:7686", label: "EPSG:7686 / Kyrg-06"},
  {coordinate_system:"EPSG:7789", label: "EPSG:7789 / ITRF2014"},
  {coordinate_system:"EPSG:7796", label: "EPSG:7796 / BGS2005"},
  {coordinate_system:"EPSG:7797", label: "EPSG:7797 / BGS2005"},
  {coordinate_system:"EPSG:7798", label: "EPSG:7798 / BGS2005"},
  {coordinate_system:"EPSG:7815", label: "EPSG:7815 / WGS 84 (Transit)"},
  {coordinate_system:"EPSG:7816", label: "EPSG:7816 / WGS 84 (Transit)"},
  {coordinate_system:"EPSG:7842", label: "EPSG:7842 / GDA2020"},
  {coordinate_system:"EPSG:7843", label: "EPSG:7843 / GDA2020"},
  {coordinate_system:"EPSG:7844", label: "EPSG:7844 / GDA2020"},
  {coordinate_system:"EPSG:7879", label: "EPSG:7879 / St. Helena Tritan"},
  {coordinate_system:"EPSG:7880", label: "EPSG:7880 / St. Helena Tritan"},
  {coordinate_system:"EPSG:7881", label: "EPSG:7881 / St. Helena Tritan"},
  {coordinate_system:"EPSG:7884", label: "EPSG:7884 / SHGD2015"},
  {coordinate_system:"EPSG:7885", label: "EPSG:7885 / SHGD2015"},
  {coordinate_system:"EPSG:7886", label: "EPSG:7886 / SHGD2015"},
  {coordinate_system:"EPSG:7900", label: "EPSG:7900 / ITRF88"},
  {coordinate_system:"EPSG:7901", label: "EPSG:7901 / ITRF89"},
  {coordinate_system:"EPSG:7902", label: "EPSG:7902 / ITRF90"},
  {coordinate_system:"EPSG:7903", label: "EPSG:7903 / ITRF91"},
  {coordinate_system:"EPSG:7904", label: "EPSG:7904 / ITRF92"},
  {coordinate_system:"EPSG:7905", label: "EPSG:7905 / ITRF93"},
  {coordinate_system:"EPSG:7906", label: "EPSG:7906 / ITRF94"},
  {coordinate_system:"EPSG:7907", label: "EPSG:7907 / ITRF96"},
  {coordinate_system:"EPSG:7908", label: "EPSG:7908 / ITRF97"},
  {coordinate_system:"EPSG:7909", label: "EPSG:7909 / ITRF2000"},
  {coordinate_system:"EPSG:7910", label: "EPSG:7910 / ITRF2005"},
  {coordinate_system:"EPSG:7911", label: "EPSG:7911 / ITRF2008"},
  {coordinate_system:"EPSG:7912", label: "EPSG:7912 / ITRF2014"},
  {coordinate_system:"EPSG:7914", label: "EPSG:7914 / ETRF89"},
  {coordinate_system:"EPSG:7915", label: "EPSG:7915 / ETRF89"},
  {coordinate_system:"EPSG:7916", label: "EPSG:7916 / ETRF90"},
  {coordinate_system:"EPSG:7917", label: "EPSG:7917 / ETRF90"},
  {coordinate_system:"EPSG:7918", label: "EPSG:7918 / ETRF91"},
  {coordinate_system:"EPSG:7919", label: "EPSG:7919 / ETRF91"},
  {coordinate_system:"EPSG:7920", label: "EPSG:7920 / ETRF92"},
  {coordinate_system:"EPSG:7921", label: "EPSG:7921 / ETRF92"},
  {coordinate_system:"EPSG:7922", label: "EPSG:7922 / ETRF93"},
  {coordinate_system:"EPSG:7923", label: "EPSG:7923 / ETRF93"},
  {coordinate_system:"EPSG:7924", label: "EPSG:7924 / ETRF94"},
  {coordinate_system:"EPSG:7925", label: "EPSG:7925 / ETRF94"},
  {coordinate_system:"EPSG:7926", label: "EPSG:7926 / ETRF96"},
  {coordinate_system:"EPSG:7927", label: "EPSG:7927 / ETRF96"},
  {coordinate_system:"EPSG:7928", label: "EPSG:7928 / ETRF97"},
  {coordinate_system:"EPSG:7929", label: "EPSG:7929 / ETRF97"},
  {coordinate_system:"EPSG:7930", label: "EPSG:7930 / ETRF2000"},
  {coordinate_system:"EPSG:7931", label: "EPSG:7931 / ETRF2000"},
  {coordinate_system:"EPSG:8042", label: "EPSG:8042 / Gusterberg (Ferro)"},
  {coordinate_system:"EPSG:8043", label: "EPSG:8043 / St. Stephen (Ferro)"},
  {coordinate_system:"EPSG:8084", label: "EPSG:8084 / ISN2016"},
  {coordinate_system:"EPSG:8085", label: "EPSG:8085 / ISN2016"},
  {coordinate_system:"EPSG:8086", label: "EPSG:8086 / ISN2016"},
  {coordinate_system:"EPSG:8227", label: "EPSG:8227 / IGS14"},
  {coordinate_system:"EPSG:8230", label: "EPSG:8230 / NAD83(CSRS96)"},
  {coordinate_system:"EPSG:8231", label: "EPSG:8231 / NAD83(CSRS96)"},
  {coordinate_system:"EPSG:8232", label: "EPSG:8232 / NAD83(CSRS96)"},
  {coordinate_system:"EPSG:8233", label: "EPSG:8233 / NAD83(CSRS)v2"},
  {coordinate_system:"EPSG:8235", label: "EPSG:8235 / NAD83(CSRS)v2"},
  {coordinate_system:"EPSG:8237", label: "EPSG:8237 / NAD83(CSRS)v2"},
  {coordinate_system:"EPSG:8238", label: "EPSG:8238 / NAD83(CSRS)v3"},
  {coordinate_system:"EPSG:8239", label: "EPSG:8239 / NAD83(CSRS)v3"},
  {coordinate_system:"EPSG:8240", label: "EPSG:8240 / NAD83(CSRS)v3"},
  {coordinate_system:"EPSG:8242", label: "EPSG:8242 / NAD83(CSRS)v4"},
  {coordinate_system:"EPSG:8244", label: "EPSG:8244 / NAD83(CSRS)v4"},
  {coordinate_system:"EPSG:8246", label: "EPSG:8246 / NAD83(CSRS)v4"},
  {coordinate_system:"EPSG:8247", label: "EPSG:8247 / NAD83(CSRS)v5"},
  {coordinate_system:"EPSG:8248", label: "EPSG:8248 / NAD83(CSRS)v5"},
  {coordinate_system:"EPSG:8249", label: "EPSG:8249 / NAD83(CSRS)v5"},
  {coordinate_system:"EPSG:8250", label: "EPSG:8250 / NAD83(CSRS)v6"},
  {coordinate_system:"EPSG:8251", label: "EPSG:8251 / NAD83(CSRS)v6"},
  {coordinate_system:"EPSG:8252", label: "EPSG:8252 / NAD83(CSRS)v6"},
  {coordinate_system:"EPSG:8253", label: "EPSG:8253 / NAD83(CSRS)v7"},
  {coordinate_system:"EPSG:8254", label: "EPSG:8254 / NAD83(CSRS)v7"},
  {coordinate_system:"EPSG:8255", label: "EPSG:8255 / NAD83(CSRS)v7"},
  {coordinate_system:"EPSG:8351", label: "EPSG:8351 / S-JTSK [JTSK03]"},
  {coordinate_system:"EPSG:8397", label: "EPSG:8397 / ETRF2005"},
  {coordinate_system:"EPSG:8399", label: "EPSG:8399 / ETRF2005"},
  {coordinate_system:"EPSG:8401", label: "EPSG:8401 / ETRF2014"},
  {coordinate_system:"EPSG:8403", label: "EPSG:8403 / ETRF2014"},
  {coordinate_system:"EPSG:8425", label: "EPSG:8425 / Hong Kong Geodetic CS"},
  {coordinate_system:"EPSG:8426", label: "EPSG:8426 / Hong Kong Geodetic CS"},
  {coordinate_system:"EPSG:8427", label: "EPSG:8427 / Hong Kong Geodetic CS"},
  {coordinate_system:"EPSG:8428", label: "EPSG:8428 / Macao 1920"},
  {coordinate_system:"EPSG:8429", label: "EPSG:8429 / Macao 2008"},
  {coordinate_system:"EPSG:8430", label: "EPSG:8430 / Macao 2008"},
  {coordinate_system:"EPSG:8431", label: "EPSG:8431 / Macao 2008"},
  {coordinate_system:"EPSG:8541", label: "EPSG:8541 / NAD83(FBN)"},
  {coordinate_system:"EPSG:8542", label: "EPSG:8542 / NAD83(FBN)"},
  {coordinate_system:"EPSG:8543", label: "EPSG:8543 / NAD83(HARN Corrected)"},
  {coordinate_system:"EPSG:8544", label: "EPSG:8544 / NAD83(HARN Corrected)"},
  {coordinate_system:"EPSG:8545", label: "EPSG:8545 / NAD83(HARN Corrected)"},
  {coordinate_system:"EPSG:8683", label: "EPSG:8683 / SRB_ETRS89"},
  {coordinate_system:"EPSG:8684", label: "EPSG:8684 / SRB_ETRS89"},
  {coordinate_system:"EPSG:8685", label: "EPSG:8685 / SRB_ETRS89"},
  {coordinate_system:"EPSG:8694", label: "EPSG:8694 / Camacupa 2015"},
  {coordinate_system:"EPSG:8697", label: "EPSG:8697 / RSAO13"},
  {coordinate_system:"EPSG:8698", label: "EPSG:8698 / RSAO13"},
  {coordinate_system:"EPSG:8699", label: "EPSG:8699 / RSAO13"},
  {coordinate_system:"EPSG:8816", label: "EPSG:8816 / MTRF-2000"},
  {coordinate_system:"EPSG:8817", label: "EPSG:8817 / MTRF-2000"},
  {coordinate_system:"EPSG:8818", label: "EPSG:8818 / MTRF-2000"},
  {coordinate_system:"EPSG:8860", label: "EPSG:8860 / NAD83(FBN)"},
  {coordinate_system:"EPSG:8888", label: "EPSG:8888 / WGS 84 (Transit)"},
  {coordinate_system:"EPSG:8898", label: "EPSG:8898 / RGWF96"},
  {coordinate_system:"EPSG:8899", label: "EPSG:8899 / RGWF96"},
  {coordinate_system:"EPSG:8900", label: "EPSG:8900 / RGWF96"},
  {coordinate_system:"EPSG:8901", label: "EPSG:8901 / RGWF96 (lon-lat)"},
  {coordinate_system:"EPSG:8902", label: "EPSG:8902 / RGWF96 (lon-lat)"},
  {coordinate_system:"EPSG:8905", label: "EPSG:8905 / CR-SIRGAS"},
  {coordinate_system:"EPSG:8906", label: "EPSG:8906 / CR-SIRGAS"},
  {coordinate_system:"EPSG:8907", label: "EPSG:8907 / CR-SIRGAS"},
  {coordinate_system:"EPSG:8915", label: "EPSG:8915 / SIRGAS-CON DGF00P01"},
  {coordinate_system:"EPSG:8916", label: "EPSG:8916 / SIRGAS-CON DGF00P01"},
  {coordinate_system:"EPSG:8917", label: "EPSG:8917 / SIRGAS-CON DGF01P01"},
  {coordinate_system:"EPSG:8918", label: "EPSG:8918 / SIRGAS-CON DGF01P01"},
  {coordinate_system:"EPSG:8919", label: "EPSG:8919 / SIRGAS-CON DGF01P02"},
  {coordinate_system:"EPSG:8920", label: "EPSG:8920 / SIRGAS-CON DGF01P02"},
  {coordinate_system:"EPSG:8921", label: "EPSG:8921 / SIRGAS-CON DGF02P01"},
  {coordinate_system:"EPSG:8922", label: "EPSG:8922 / SIRGAS-CON DGF02P01"},
  {coordinate_system:"EPSG:8923", label: "EPSG:8923 / SIRGAS-CON DGF04P01"},
  {coordinate_system:"EPSG:8924", label: "EPSG:8924 / SIRGAS-CON DGF04P01"},
  {coordinate_system:"EPSG:8925", label: "EPSG:8925 / SIRGAS-CON DGF05P01"},
  {coordinate_system:"EPSG:8926", label: "EPSG:8926 / SIRGAS-CON DGF05P01"},
  {coordinate_system:"EPSG:8927", label: "EPSG:8927 / SIRGAS-CON DGF06P01"},
  {coordinate_system:"EPSG:8928", label: "EPSG:8928 / SIRGAS-CON DGF06P01"},
  {coordinate_system:"EPSG:8929", label: "EPSG:8929 / SIRGAS-CON DGF07P01"},
  {coordinate_system:"EPSG:8930", label: "EPSG:8930 / SIRGAS-CON DGF07P01"},
  {coordinate_system:"EPSG:8931", label: "EPSG:8931 / SIRGAS-CON DGF08P01"},
  {coordinate_system:"EPSG:8932", label: "EPSG:8932 / SIRGAS-CON DGF08P01"},
  {coordinate_system:"EPSG:8933", label: "EPSG:8933 / SIRGAS-CON SIR09P01"},
  {coordinate_system:"EPSG:8934", label: "EPSG:8934 / SIRGAS-CON SIR09P01"},
  {coordinate_system:"EPSG:8935", label: "EPSG:8935 / SIRGAS-CON SIR10P01"},
  {coordinate_system:"EPSG:8936", label: "EPSG:8936 / SIRGAS-CON SIR10P01"},
  {coordinate_system:"EPSG:8937", label: "EPSG:8937 / SIRGAS-CON SIR11P01"},
  {coordinate_system:"EPSG:8938", label: "EPSG:8938 / SIRGAS-CON SIR11P01"},
  {coordinate_system:"EPSG:8939", label: "EPSG:8939 / SIRGAS-CON SIR13P01"},
  {coordinate_system:"EPSG:8940", label: "EPSG:8940 / SIRGAS-CON SIR13P01"},
  {coordinate_system:"EPSG:8941", label: "EPSG:8941 / SIRGAS-CON SIR14P01"},
  {coordinate_system:"EPSG:8942", label: "EPSG:8942 / SIRGAS-CON SIR14P01"},
  {coordinate_system:"EPSG:8943", label: "EPSG:8943 / SIRGAS-CON SIR15P01"},
  {coordinate_system:"EPSG:8944", label: "EPSG:8944 / SIRGAS-CON SIR15P01"},
  {coordinate_system:"EPSG:8945", label: "EPSG:8945 / SIRGAS-CON SIR17P01"},
  {coordinate_system:"EPSG:8946", label: "EPSG:8946 / SIRGAS-CON SIR17P01"},
  {coordinate_system:"EPSG:8947", label: "EPSG:8947 / SIRGAS-Chile 2010"},
  {coordinate_system:"EPSG:8948", label: "EPSG:8948 / SIRGAS-Chile 2010"},
  {coordinate_system:"EPSG:8949", label: "EPSG:8949 / SIRGAS-Chile 2010"},
  {coordinate_system:"EPSG:8972", label: "EPSG:8972 / SIRGAS-CON DGF00P01"},
  {coordinate_system:"EPSG:8973", label: "EPSG:8973 / SIRGAS-CON DGF01P01"},
  {coordinate_system:"EPSG:8974", label: "EPSG:8974 / SIRGAS-CON DGF01P02"},
  {coordinate_system:"EPSG:8975", label: "EPSG:8975 / SIRGAS-CON DGF02P01"},
  {coordinate_system:"EPSG:8976", label: "EPSG:8976 / SIRGAS-CON DGF04P01"},
  {coordinate_system:"EPSG:8977", label: "EPSG:8977 / SIRGAS-CON DGF05P01"},
  {coordinate_system:"EPSG:8978", label: "EPSG:8978 / SIRGAS-CON DGF06P01"},
  {coordinate_system:"EPSG:8979", label: "EPSG:8979 / SIRGAS-CON DGF07P01"},
  {coordinate_system:"EPSG:8980", label: "EPSG:8980 / SIRGAS-CON DGF08P01"},
  {coordinate_system:"EPSG:8981", label: "EPSG:8981 / SIRGAS-CON SIR09P01"},
  {coordinate_system:"EPSG:8982", label: "EPSG:8982 / SIRGAS-CON SIR10P01"},
  {coordinate_system:"EPSG:8983", label: "EPSG:8983 / SIRGAS-CON SIR11P01"},
  {coordinate_system:"EPSG:8984", label: "EPSG:8984 / SIRGAS-CON SIR13P01"},
  {coordinate_system:"EPSG:8985", label: "EPSG:8985 / SIRGAS-CON SIR14P01"},
  {coordinate_system:"EPSG:8986", label: "EPSG:8986 / SIRGAS-CON SIR15P01"},
  {coordinate_system:"EPSG:8987", label: "EPSG:8987 / SIRGAS-CON SIR17P01"},
  {coordinate_system:"EPSG:8988", label: "EPSG:8988 / ITRF88"},
  {coordinate_system:"EPSG:8989", label: "EPSG:8989 / ITRF89"},
  {coordinate_system:"EPSG:8990", label: "EPSG:8990 / ITRF90"},
  {coordinate_system:"EPSG:8991", label: "EPSG:8991 / ITRF91"},
  {coordinate_system:"EPSG:8992", label: "EPSG:8992 / ITRF92"},
  {coordinate_system:"EPSG:8993", label: "EPSG:8993 / ITRF93"},
  {coordinate_system:"EPSG:8994", label: "EPSG:8994 / ITRF94"},
  {coordinate_system:"EPSG:8995", label: "EPSG:8995 / ITRF96"},
  {coordinate_system:"EPSG:8996", label: "EPSG:8996 / ITRF97"},
  {coordinate_system:"EPSG:8997", label: "EPSG:8997 / ITRF2000"},
  {coordinate_system:"EPSG:8998", label: "EPSG:8998 / ITRF2005"},
  {coordinate_system:"EPSG:8999", label: "EPSG:8999 / ITRF2008"},
  {coordinate_system:"EPSG:9000", label: "EPSG:9000 / ITRF2014"},
  {coordinate_system:"EPSG:9001", label: "EPSG:9001 / IGS97"},
  {coordinate_system:"EPSG:9002", label: "EPSG:9002 / IGS97"},
  {coordinate_system:"EPSG:9003", label: "EPSG:9003 / IGS97"},
  {coordinate_system:"EPSG:9004", label: "EPSG:9004 / IGS00"},
  {coordinate_system:"EPSG:9005", label: "EPSG:9005 / IGS00"},
  {coordinate_system:"EPSG:9006", label: "EPSG:9006 / IGS00"},
  {coordinate_system:"EPSG:9007", label: "EPSG:9007 / IGb00"},
  {coordinate_system:"EPSG:9008", label: "EPSG:9008 / IGb00"},
  {coordinate_system:"EPSG:9009", label: "EPSG:9009 / IGb00"},
  {coordinate_system:"EPSG:9010", label: "EPSG:9010 / IGS05"},
  {coordinate_system:"EPSG:9011", label: "EPSG:9011 / IGS05"},
  {coordinate_system:"EPSG:9012", label: "EPSG:9012 / IGS05"},
  {coordinate_system:"EPSG:9013", label: "EPSG:9013 / IGS08"},
  {coordinate_system:"EPSG:9014", label: "EPSG:9014 / IGS08"},
  {coordinate_system:"EPSG:9015", label: "EPSG:9015 / IGb08"},
  {coordinate_system:"EPSG:9016", label: "EPSG:9016 / IGb08"},
  {coordinate_system:"EPSG:9017", label: "EPSG:9017 / IGb08"},
  {coordinate_system:"EPSG:9018", label: "EPSG:9018 / IGS14"},
  {coordinate_system:"EPSG:9019", label: "EPSG:9019 / IGS14"},
  {coordinate_system:"EPSG:9053", label: "EPSG:9053 / WGS 84 (G730)"},
  {coordinate_system:"EPSG:9054", label: "EPSG:9054 / WGS 84 (G873)"},
  {coordinate_system:"EPSG:9055", label: "EPSG:9055 / WGS 84 (G1150)"},
  {coordinate_system:"EPSG:9056", label: "EPSG:9056 / WGS 84 (G1674)"},
  {coordinate_system:"EPSG:9057", label: "EPSG:9057 / WGS 84 (G1762)"},
  {coordinate_system:"EPSG:9059", label: "EPSG:9059 / ETRF89"},
  {coordinate_system:"EPSG:9060", label: "EPSG:9060 / ETRF90"},
  {coordinate_system:"EPSG:9061", label: "EPSG:9061 / ETRF91"},
  {coordinate_system:"EPSG:9062", label: "EPSG:9062 / ETRF92"},
  {coordinate_system:"EPSG:9063", label: "EPSG:9063 / ETRF93"},
  {coordinate_system:"EPSG:9064", label: "EPSG:9064 / ETRF94"},
  {coordinate_system:"EPSG:9065", label: "EPSG:9065 / ETRF96"},
  {coordinate_system:"EPSG:9066", label: "EPSG:9066 / ETRF97"},
  {coordinate_system:"EPSG:9067", label: "EPSG:9067 / ETRF2000"},
  {coordinate_system:"EPSG:9068", label: "EPSG:9068 / ETRF2005"},
  {coordinate_system:"EPSG:9069", label: "EPSG:9069 / ETRF2014"},
  {coordinate_system:"EPSG:9070", label: "EPSG:9070 / NAD83(MARP00)"},
  {coordinate_system:"EPSG:9071", label: "EPSG:9071 / NAD83(MARP00)"},
  {coordinate_system:"EPSG:9072", label: "EPSG:9072 / NAD83(MARP00)"},
  {coordinate_system:"EPSG:9073", label: "EPSG:9073 / NAD83(PACP00)"},
  {coordinate_system:"EPSG:9074", label: "EPSG:9074 / NAD83(PACP00)"},
  {coordinate_system:"EPSG:9075", label: "EPSG:9075 / NAD83(PACP00)"},
  {coordinate_system:"EPSG:9138", label: "EPSG:9138 / KOSOVAREF01"},
  {coordinate_system:"EPSG:9139", label: "EPSG:9139 / KOSOVAREF01"},
  {coordinate_system:"EPSG:9140", label: "EPSG:9140 / KOSOVAREF01"},
  {coordinate_system:"EPSG:9146", label: "EPSG:9146 / SIRGAS-Chile 2013"},
  {coordinate_system:"EPSG:9147", label: "EPSG:9147 / SIRGAS-Chile 2013"},
  {coordinate_system:"EPSG:9148", label: "EPSG:9148 / SIRGAS-Chile 2013"},
  {coordinate_system:"EPSG:9151", label: "EPSG:9151 / SIRGAS-Chile 2016"},
  {coordinate_system:"EPSG:9152", label: "EPSG:9152 / SIRGAS-Chile 2016"},
  {coordinate_system:"EPSG:9153", label: "EPSG:9153 / SIRGAS-Chile 2016"},
  {coordinate_system:"EPSG:9248", label: "EPSG:9248 / Tapi Aike"},
  {coordinate_system:"EPSG:9251", label: "EPSG:9251 / MMN"},
  {coordinate_system:"EPSG:9253", label: "EPSG:9253 / MMS"},
  {coordinate_system:"EPSG:9266", label: "EPSG:9266 / MGI"},
  {coordinate_system:"EPSG:9267", label: "EPSG:9267 / MGI"},
  {coordinate_system:"EPSG:9292", label: "EPSG:9292 / ONGD17"},
  {coordinate_system:"EPSG:9293", label: "EPSG:9293 / ONGD17"},
  {coordinate_system:"EPSG:9294", label: "EPSG:9294 / ONGD17"},
  {coordinate_system:"EPSG:9299", label: "EPSG:9299 / HS2-IRF"},
  {coordinate_system:"EPSG:9307", label: "EPSG:9307 / ATRF2014"},
  {coordinate_system:"EPSG:9308", label: "EPSG:9308 / ATRF2014"},
  {coordinate_system:"EPSG:9309", label: "EPSG:9309 / ATRF2014"},
  {coordinate_system:"EPSG:9331", label: "EPSG:9331 / KSA-GRF17"},
  {coordinate_system:"EPSG:9332", label: "EPSG:9332 / KSA-GRF17"},
  {coordinate_system:"EPSG:9333", label: "EPSG:9333 / KSA-GRF17"},
  {coordinate_system:"EPSG:9364", label: "EPSG:9364 / TPEN11-IRF"},
  {coordinate_system:"EPSG:9372", label: "EPSG:9372 / MML07-IRF"},
  {coordinate_system:"EPSG:9378", label: "EPSG:9378 / IGb14"},
  {coordinate_system:"EPSG:9379", label: "EPSG:9379 / IGb14"},
  {coordinate_system:"EPSG:9380", label: "EPSG:9380 / IGb14"},
  {coordinate_system:"EPSG:9384", label: "EPSG:9384 / AbInvA96_2020-IRF"},
  {coordinate_system:"EPSG:9403", label: "EPSG:9403 / PN68"},
  {coordinate_system:"EPSG:9453", label: "EPSG:9453 / GBK19-IRF"},
  {coordinate_system:"EPSG:9468", label: "EPSG:9468 / SRGI2013"},
  {coordinate_system:"EPSG:9469", label: "EPSG:9469 / SRGI2013"},
  {coordinate_system:"EPSG:9470", label: "EPSG:9470 / SRGI2013"},
  {coordinate_system:"EPSG:9474", label: "EPSG:9474 / PZ-90.02"},
  {coordinate_system:"EPSG:9475", label: "EPSG:9475 / PZ-90.11"},
  {coordinate_system:"EPSG:9545", label: "EPSG:9545 / LTF2004(G)"},
  {coordinate_system:"EPSG:9546", label: "EPSG:9546 / LTF2004(G)"},
  {coordinate_system:"EPSG:9547", label: "EPSG:9547 / LTF2004(G)"},
  {coordinate_system:"EPSG:9694", label: "EPSG:9694 / REDGEOMIN"},
  {coordinate_system:"EPSG:9695", label: "EPSG:9695 / REDGEOMIN"},
  {coordinate_system:"EPSG:9696", label: "EPSG:9696 / REDGEOMIN"},
  {coordinate_system:"EPSG:9700", label: "EPSG:9700 / ETRF2000-PL"},
  {coordinate_system:"EPSG:9701", label: "EPSG:9701 / ETRF2000-PL"},
  {coordinate_system:"EPSG:9702", label: "EPSG:9702 / ETRF2000-PL"},
  {coordinate_system:"EPSG:9739", label: "EPSG:9739 / EOS21-IRF"},
  {coordinate_system:"EPSG:9753", label: "EPSG:9753 / WGS 84 (G2139)"},
  {coordinate_system:"EPSG:9754", label: "EPSG:9754 / WGS 84 (G2139)"},
  {coordinate_system:"EPSG:9755", label: "EPSG:9755 / WGS 84 (G2139)"},
  {coordinate_system:"EPSG:9758", label: "EPSG:9758 / ECML14_NB-IRF"},
  {coordinate_system:"EPSG:9763", label: "EPSG:9763 / EWR2-IRF"},
  {coordinate_system:"EPSG:9775", label: "EPSG:9775 / RGF93 v2"},
  {coordinate_system:"EPSG:9776", label: "EPSG:9776 / RGF93 v2"},
  {coordinate_system:"EPSG:9777", label: "EPSG:9777 / RGF93 v2"},
  {coordinate_system:"EPSG:9778", label: "EPSG:9778 / RGF93 v2 (lon-lat)"},
  {coordinate_system:"EPSG:9779", label: "EPSG:9779 / RGF93 v2 (lon-lat)"},
  {coordinate_system:"EPSG:9780", label: "EPSG:9780 / RGF93 v2b"},
  {coordinate_system:"EPSG:9781", label: "EPSG:9781 / RGF93 v2b"},
  {coordinate_system:"EPSG:9782", label: "EPSG:9782 / RGF93 v2b"},
  {coordinate_system:"EPSG:9783", label: "EPSG:9783 / RGF93 v2b (lon-lat)"},
  {coordinate_system:"EPSG:9784", label: "EPSG:9784 / RGF93 v2b (lon-lat)"},
  {coordinate_system:"EPSG:9866", label: "EPSG:9866 / MRH21-IRF"},
  {coordinate_system:"EPSG:9871", label: "EPSG:9871 / MOLDOR11-IRF"},
  {coordinate_system:"EPSG:9892", label: "EPSG:9892 / LUREF"},
  {coordinate_system:"EPSG:9893", label: "EPSG:9893 / LUREF"},
  {coordinate_system:"EPSG:9939", label: "EPSG:9939 / EBBWV14-IRF"},
  {coordinate_system:"EPSG:9964", label: "EPSG:9964 / HULLEE13-IRF"},
  {coordinate_system:"EPSG:9969", label: "EPSG:9969 / SCM22-IRF"},
  {coordinate_system:"EPSG:9974", label: "EPSG:9974 / FNL22-IRF"},
  {coordinate_system:"EPSG:9988", label: "EPSG:9988 / ITRF2020"},
  {coordinate_system:"EPSG:9989", label: "EPSG:9989 / ITRF2020"},
  {coordinate_system:"EPSG:9990", label: "EPSG:9990 / ITRF2020"},
  {coordinate_system:"EPSG:20033", label: "EPSG:20033 / MWC18-IRF"},
  {coordinate_system:"EPSG:20039", label: "EPSG:20039 / SIRGAS-Chile 2021"},
  {coordinate_system:"EPSG:20040", label: "EPSG:20040 / SIRGAS-Chile 2021"},
  {coordinate_system:"EPSG:20041", label: "EPSG:20041 / SIRGAS-Chile 2021"},
  {coordinate_system:"CZM:ALB", label: "ALB / Albany Grid 1984"},
  {coordinate_system:"CZM:ALB94", label: "ALB94 / Albany Grid 1994"},
  {coordinate_system:"CZM:BCG", label: "BCG / Busselton Coastal Grid 1984"},
  {coordinate_system:"CZM:BCG94", label: "BCG94 / Busselton Coastal Grid 1994"},
  {coordinate_system:"CZM:BIO94", label: "BIO94 / Barrow Island and Onslow Grid 1994"},
  {coordinate_system:"CZM:BRO", label: "BRO / Broome Grid 1984"},
  {coordinate_system:"CZM:BRO94", label: "BRO94 / Broome Grid 1994"},
  {coordinate_system:"CZM:CARN", label: "CARN / Carnarvon Grid 1984"},
  {coordinate_system:"CZM:CARN94", label: "CARN94 / Carnarvon Grid 1994"},
  {coordinate_system:"CZM:CIG92", label: "CIG92 / Christmas Island Grid 1992"},
  {coordinate_system:"CZM:CIG94", label: "CIG94 / Christmas Island Grid 1994"},
  {coordinate_system:"CZM:CKIG92", label: "CKIG92 / Cocos (Keeling) Islands Grid 1992"},
  {coordinate_system:"CZM:CKIG94", label: "CKIG94 / Cocos (Keeling) Islands Grid 1994"},
  {coordinate_system:"CZM:COL94", label: "COL94 / Collie Grid 1994"},
  {coordinate_system:"CZM:EG", label: "EG / Esperance Grid  1984"},
  {coordinate_system:"CZM:ESP94", label: "ESP94 / Esperance Grid 1994"},
  {coordinate_system:"CZM:EXM", label: "EXM / Exmouth Grid  1984"},
  {coordinate_system:"CZM:EXM94", label: "EXM94 / Exmouth Grid 1994"},
  {coordinate_system:"CZM:GCG", label: "GCG / Geraldton Coastal Grid 1984"},
  {coordinate_system:"CZM:GCG94", label: "GCG94 / Geraldton Coastal Grid 1994"},
  {coordinate_system:"CZM:GG", label: "GG / Goldfields Grid 1984"},
  {coordinate_system:"CZM:GOLD94", label: "GOLD94 / Goldfields Grid 1994"},
  {coordinate_system:"CZM:H1BANWIL2020", label: "H1BANWIL2020 / GDA2020 / Nth Banister to Williams 97 - 158 SLK"},
  {coordinate_system:"CZM:H1BANWIL94", label: "H1BANWIL94 / GDA94 / Nth Banister to Williams 97 - 158 SLK"},
  {coordinate_system:"CZM:JCG", label: "JCG / Jurien Coastal Grid 1984"},
  {coordinate_system:"CZM:JCG94", label: "JCG94 / Jurien Coastal Grid 1994"},
  {coordinate_system:"CZM:KALB94", label: "KALB94 / Kalbarri Grid 1994"},
  {coordinate_system:"CZM:KAR", label: "KAR / Karratha Grid 1984"},
  {coordinate_system:"CZM:KAR94", label: "KAR94 / Karratha Grid 1994"},
  {coordinate_system:"CZM:KG", label: "KG / Kununurra Grid 1984"},
  {coordinate_system:"CZM:KUN94", label: "KUN94 / Kununurra Grid 1994"},
  {coordinate_system:"CZM:LCG", label: "LCG / Lancelin Coastal Grid 1984"},
  {coordinate_system:"CZM:LCG94", label: "LCG94 / Lancelin Coastal Grid 1994"},
  {coordinate_system:"CZM:MRCG", label: "MRCG / Margaret River Coastal Grid 1984"},
  {coordinate_system:"CZM:MRCG94", label: "MRCG94 / Margaret River Coastal Grid 1994"},
  {coordinate_system:"CZM:OSGB36", label: "OSGB36 / EPSG:27700 OSGB36 / British National Grid"},
  {coordinate_system:"CZM:OSTN02", label: "OSTN02 / OSGB36 with OSTN02 grid shift / British National Grid"},
  {coordinate_system:"CZM:PCG", label: "PCG / Perth Coastal Grid 1984"},
  {coordinate_system:"CZM:PCG94", label: "PCG94 / Perth Coastal Grid 1994"},
  {coordinate_system:"CZM:PHG", label: "PHG / Port Hedland Grid 1984"},
  {coordinate_system:"CZM:PHG94", label: "PHG94 / Port Hedland Grid 1994"},
  {coordinate_system:"EPSG:2000", label: "EPSG:2000 / Anguilla 1957 / British West Indies Grid"},
  {coordinate_system:"EPSG:2001", label: "EPSG:2001 / Antigua 1943 / British West Indies Grid"},
  {coordinate_system:"EPSG:2002", label: "EPSG:2002 / Dominica 1945 / British West Indies Grid"},
  {coordinate_system:"EPSG:2003", label: "EPSG:2003 / Grenada 1953 / British West Indies Grid"},
  {coordinate_system:"EPSG:2004", label: "EPSG:2004 / Montserrat 1958 / British West Indies Grid"},
  {coordinate_system:"EPSG:2005", label: "EPSG:2005 / St. Kitts 1955 / British West Indies Grid"},
  {coordinate_system:"EPSG:2006", label: "EPSG:2006 / St. Lucia 1955 / British West Indies Grid"},
  {coordinate_system:"EPSG:2007", label: "EPSG:2007 / St. Vincent 45 / British West Indies Grid"},
  {coordinate_system:"EPSG:2009", label: "EPSG:2009 / NAD27(CGQ77) / SCoPQ zone 3"},
  {coordinate_system:"EPSG:2010", label: "EPSG:2010 / NAD27(CGQ77) / SCoPQ zone 4"},
  {coordinate_system:"EPSG:2011", label: "EPSG:2011 / NAD27(CGQ77) / SCoPQ zone 5"},
  {coordinate_system:"EPSG:2012", label: "EPSG:2012 / NAD27(CGQ77) / SCoPQ zone 6"},
  {coordinate_system:"EPSG:2013", label: "EPSG:2013 / NAD27(CGQ77) / SCoPQ zone 7"},
  {coordinate_system:"EPSG:2014", label: "EPSG:2014 / NAD27(CGQ77) / SCoPQ zone 8"},
  {coordinate_system:"EPSG:2015", label: "EPSG:2015 / NAD27(CGQ77) / SCoPQ zone 9"},
  {coordinate_system:"EPSG:2016", label: "EPSG:2016 / NAD27(CGQ77) / SCoPQ zone 10"},
  {coordinate_system:"EPSG:2017", label: "EPSG:2017 / NAD27(76) / MTM zone 8"},
  {coordinate_system:"EPSG:2018", label: "EPSG:2018 / NAD27(76) / MTM zone 9"},
  {coordinate_system:"EPSG:2019", label: "EPSG:2019 / NAD27(76) / MTM zone 10"},
  {coordinate_system:"EPSG:2020", label: "EPSG:2020 / NAD27(76) / MTM zone 11"},
  {coordinate_system:"EPSG:2021", label: "EPSG:2021 / NAD27(76) / MTM zone 12"},
  {coordinate_system:"EPSG:2022", label: "EPSG:2022 / NAD27(76) / MTM zone 13"},
  {coordinate_system:"EPSG:2023", label: "EPSG:2023 / NAD27(76) / MTM zone 14"},
  {coordinate_system:"EPSG:2024", label: "EPSG:2024 / NAD27(76) / MTM zone 15"},
  {coordinate_system:"EPSG:2025", label: "EPSG:2025 / NAD27(76) / MTM zone 16"},
  {coordinate_system:"EPSG:2026", label: "EPSG:2026 / NAD27(76) / MTM zone 17"},
  {coordinate_system:"EPSG:2027", label: "EPSG:2027 / NAD27(76) / UTM zone 15N"},
  {coordinate_system:"EPSG:2028", label: "EPSG:2028 / NAD27(76) / UTM zone 16N"},
  {coordinate_system:"EPSG:2029", label: "EPSG:2029 / NAD27(76) / UTM zone 17N"},
  {coordinate_system:"EPSG:2030", label: "EPSG:2030 / NAD27(76) / UTM zone 18N"},
  {coordinate_system:"EPSG:2031", label: "EPSG:2031 / NAD27(CGQ77) / UTM zone 17N"},
  {coordinate_system:"EPSG:2032", label: "EPSG:2032 / NAD27(CGQ77) / UTM zone 18N"},
  {coordinate_system:"EPSG:2033", label: "EPSG:2033 / NAD27(CGQ77) / UTM zone 19N"},
  {coordinate_system:"EPSG:2034", label: "EPSG:2034 / NAD27(CGQ77) / UTM zone 20N"},
  {coordinate_system:"EPSG:2035", label: "EPSG:2035 / NAD27(CGQ77) / UTM zone 21N"},
  {coordinate_system:"EPSG:2039", label: "EPSG:2039 / Israel 1993 / Israeli TM Grid"},
  {coordinate_system:"EPSG:2040", label: "EPSG:2040 / Locodjo 1965 / UTM zone 30N"},
  {coordinate_system:"EPSG:2041", label: "EPSG:2041 / Abidjan 1987 / UTM zone 30N"},
  {coordinate_system:"EPSG:2042", label: "EPSG:2042 / Locodjo 1965 / UTM zone 29N"},
  {coordinate_system:"EPSG:2043", label: "EPSG:2043 / Abidjan 1987 / UTM zone 29N"},
  {coordinate_system:"EPSG:2044", label: "EPSG:2044 / Hanoi 1972 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:2045", label: "EPSG:2045 / Hanoi 1972 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:2046", label: "EPSG:2046 / Hartebeesthoek94 / Lo15"},
  {coordinate_system:"EPSG:2047", label: "EPSG:2047 / Hartebeesthoek94 / Lo17"},
  {coordinate_system:"EPSG:2048", label: "EPSG:2048 / Hartebeesthoek94 / Lo19"},
  {coordinate_system:"EPSG:2049", label: "EPSG:2049 / Hartebeesthoek94 / Lo21"},
  {coordinate_system:"EPSG:2050", label: "EPSG:2050 / Hartebeesthoek94 / Lo23"},
  {coordinate_system:"EPSG:2051", label: "EPSG:2051 / Hartebeesthoek94 / Lo25"},
  {coordinate_system:"EPSG:2052", label: "EPSG:2052 / Hartebeesthoek94 / Lo27"},
  {coordinate_system:"EPSG:2053", label: "EPSG:2053 / Hartebeesthoek94 / Lo29"},
  {coordinate_system:"EPSG:2054", label: "EPSG:2054 / Hartebeesthoek94 / Lo31"},
  {coordinate_system:"EPSG:2055", label: "EPSG:2055 / Hartebeesthoek94 / Lo33"},
  {coordinate_system:"EPSG:2056", label: "EPSG:2056 / CH1903+ / LV95"},
  {coordinate_system:"EPSG:2057", label: "EPSG:2057 / Rassadiran / Nakhl e Taqi"},
  {coordinate_system:"EPSG:2058", label: "EPSG:2058 / ED50(ED77) / UTM zone 38N"},
  {coordinate_system:"EPSG:2059", label: "EPSG:2059 / ED50(ED77) / UTM zone 39N"},
  {coordinate_system:"EPSG:2060", label: "EPSG:2060 / ED50(ED77) / UTM zone 40N"},
  {coordinate_system:"EPSG:2061", label: "EPSG:2061 / ED50(ED77) / UTM zone 41N"},
  {coordinate_system:"EPSG:2062", label: "EPSG:2062 / Madrid 1870 (Madrid) / Spain LCC"},
  {coordinate_system:"EPSG:2065", label: "EPSG:2065 / S-JTSK (Ferro) / Krovak"},
  {coordinate_system:"EPSG:2066", label: "EPSG:2066 / Mount Dillon / Tobago Grid"},
  {coordinate_system:"EPSG:2067", label: "EPSG:2067 / Naparima 1955 / UTM zone 20N"},
  {coordinate_system:"EPSG:2068", label: "EPSG:2068 / ELD79 / Libya zone 5"},
  {coordinate_system:"EPSG:2069", label: "EPSG:2069 / ELD79 / Libya zone 6"},
  {coordinate_system:"EPSG:2070", label: "EPSG:2070 / ELD79 / Libya zone 7"},
  {coordinate_system:"EPSG:2071", label: "EPSG:2071 / ELD79 / Libya zone 8"},
  {coordinate_system:"EPSG:2072", label: "EPSG:2072 / ELD79 / Libya zone 9"},
  {coordinate_system:"EPSG:2073", label: "EPSG:2073 / ELD79 / Libya zone 10"},
  {coordinate_system:"EPSG:2074", label: "EPSG:2074 / ELD79 / Libya zone 11"},
  {coordinate_system:"EPSG:2075", label: "EPSG:2075 / ELD79 / Libya zone 12"},
  {coordinate_system:"EPSG:2076", label: "EPSG:2076 / ELD79 / Libya zone 13"},
  {coordinate_system:"EPSG:2077", label: "EPSG:2077 / ELD79 / UTM zone 32N"},
  {coordinate_system:"EPSG:2078", label: "EPSG:2078 / ELD79 / UTM zone 33N"},
  {coordinate_system:"EPSG:2079", label: "EPSG:2079 / ELD79 / UTM zone 34N"},
  {coordinate_system:"EPSG:2080", label: "EPSG:2080 / ELD79 / UTM zone 35N"},
  {coordinate_system:"EPSG:2081", label: "EPSG:2081 / Chos Malal 1914 / Argentina 2"},
  {coordinate_system:"EPSG:2082", label: "EPSG:2082 / Pampa del Castillo / Argentina 2"},
  {coordinate_system:"EPSG:2083", label: "EPSG:2083 / Hito XVIII 1963 / Argentina 2"},
  {coordinate_system:"EPSG:2084", label: "EPSG:2084 / Hito XVIII 1963 / UTM zone 19S"},
  {coordinate_system:"EPSG:2087", label: "EPSG:2087 / ELD79 / TM 12 NE"},
  {coordinate_system:"EPSG:2088", label: "EPSG:2088 / Carthage / TM 11 NE"},
  {coordinate_system:"EPSG:2089", label: "EPSG:2089 / Yemen NGN96 / UTM zone 38N"},
  {coordinate_system:"EPSG:2090", label: "EPSG:2090 / Yemen NGN96 / UTM zone 39N"},
  {coordinate_system:"EPSG:2093", label: "EPSG:2093 / Hanoi 1972 / GK 106 NE"},
  {coordinate_system:"EPSG:2094", label: "EPSG:2094 / WGS 72BE / TM 106 NE"},
  {coordinate_system:"EPSG:2095", label: "EPSG:2095 / Bissau / UTM zone 28N"},
  {coordinate_system:"EPSG:2096", label: "EPSG:2096 / Korean 1985 / East Belt"},
  {coordinate_system:"EPSG:2097", label: "EPSG:2097 / Korean 1985 / Central Belt"},
  {coordinate_system:"EPSG:2098", label: "EPSG:2098 / Korean 1985 / West Belt"},
  {coordinate_system:"EPSG:2099", label: "EPSG:2099 / Qatar 1948 / Qatar Grid"},
  {coordinate_system:"EPSG:2100", label: "EPSG:2100 / GGRS87 / Greek Grid"},
  {coordinate_system:"EPSG:2101", label: "EPSG:2101 / Lake / Maracaibo Grid M1"},
  {coordinate_system:"EPSG:2102", label: "EPSG:2102 / Lake / Maracaibo Grid"},
  {coordinate_system:"EPSG:2103", label: "EPSG:2103 / Lake / Maracaibo Grid M3"},
  {coordinate_system:"EPSG:2104", label: "EPSG:2104 / Lake / Maracaibo La Rosa Grid"},
  {coordinate_system:"EPSG:2105", label: "EPSG:2105 / NZGD2000 / Mount Eden 2000"},
  {coordinate_system:"EPSG:2106", label: "EPSG:2106 / NZGD2000 / Bay of Plenty 2000"},
  {coordinate_system:"EPSG:2107", label: "EPSG:2107 / NZGD2000 / Poverty Bay 2000"},
  {coordinate_system:"EPSG:2108", label: "EPSG:2108 / NZGD2000 / Hawkes Bay 2000"},
  {coordinate_system:"EPSG:2109", label: "EPSG:2109 / NZGD2000 / Taranaki 2000"},
  {coordinate_system:"EPSG:2110", label: "EPSG:2110 / NZGD2000 / Tuhirangi 2000"},
  {coordinate_system:"EPSG:2111", label: "EPSG:2111 / NZGD2000 / Wanganui 2000"},
  {coordinate_system:"EPSG:2112", label: "EPSG:2112 / NZGD2000 / Wairarapa 2000"},
  {coordinate_system:"EPSG:2113", label: "EPSG:2113 / NZGD2000 / Wellington 2000"},
  {coordinate_system:"EPSG:2114", label: "EPSG:2114 / NZGD2000 / Collingwood 2000"},
  {coordinate_system:"EPSG:2115", label: "EPSG:2115 / NZGD2000 / Nelson 2000"},
  {coordinate_system:"EPSG:2116", label: "EPSG:2116 / NZGD2000 / Karamea 2000"},
  {coordinate_system:"EPSG:2117", label: "EPSG:2117 / NZGD2000 / Buller 2000"},
  {coordinate_system:"EPSG:2118", label: "EPSG:2118 / NZGD2000 / Grey 2000"},
  {coordinate_system:"EPSG:2119", label: "EPSG:2119 / NZGD2000 / Amuri 2000"},
  {coordinate_system:"EPSG:2120", label: "EPSG:2120 / NZGD2000 / Marlborough 2000"},
  {coordinate_system:"EPSG:2121", label: "EPSG:2121 / NZGD2000 / Hokitika 2000"},
  {coordinate_system:"EPSG:2122", label: "EPSG:2122 / NZGD2000 / Okarito 2000"},
  {coordinate_system:"EPSG:2123", label: "EPSG:2123 / NZGD2000 / Jacksons Bay 2000"},
  {coordinate_system:"EPSG:2124", label: "EPSG:2124 / NZGD2000 / Mount Pleasant 2000"},
  {coordinate_system:"EPSG:2125", label: "EPSG:2125 / NZGD2000 / Gawler 2000"},
  {coordinate_system:"EPSG:2126", label: "EPSG:2126 / NZGD2000 / Timaru 2000"},
  {coordinate_system:"EPSG:2127", label: "EPSG:2127 / NZGD2000 / Lindis Peak 2000"},
  {coordinate_system:"EPSG:2128", label: "EPSG:2128 / NZGD2000 / Mount Nicholas 2000"},
  {coordinate_system:"EPSG:2129", label: "EPSG:2129 / NZGD2000 / Mount York 2000"},
  {coordinate_system:"EPSG:2130", label: "EPSG:2130 / NZGD2000 / Observation Point 2000"},
  {coordinate_system:"EPSG:2131", label: "EPSG:2131 / NZGD2000 / North Taieri 2000"},
  {coordinate_system:"EPSG:2132", label: "EPSG:2132 / NZGD2000 / Bluff 2000"},
  {coordinate_system:"EPSG:2133", label: "EPSG:2133 / NZGD2000 / UTM zone 58S"},
  {coordinate_system:"EPSG:2134", label: "EPSG:2134 / NZGD2000 / UTM zone 59S"},
  {coordinate_system:"EPSG:2135", label: "EPSG:2135 / NZGD2000 / UTM zone 60S"},
  {coordinate_system:"EPSG:2136", label: "EPSG:2136 / Accra / Ghana National Grid"},
  {coordinate_system:"EPSG:2137", label: "EPSG:2137 / Accra / TM 1 NW"},
  {coordinate_system:"EPSG:2138", label: "EPSG:2138 / NAD27(CGQ77) / Quebec Lambert"},
  {coordinate_system:"EPSG:2154", label: "EPSG:2154 / RGF93 v1 / Lambert-93"},
  {coordinate_system:"EPSG:2157", label: "EPSG:2157 / IRENET95 / Irish Transverse Mercator"},
  {coordinate_system:"EPSG:2158", label: "EPSG:2158 / IRENET95 / UTM zone 29N"},
  {coordinate_system:"EPSG:2159", label: "EPSG:2159 / Sierra Leone 1924 / New Colony Grid"},
  {coordinate_system:"EPSG:2160", label: "EPSG:2160 / Sierra Leone 1924 / New War Office Grid"},
  {coordinate_system:"EPSG:2161", label: "EPSG:2161 / Sierra Leone 1968 / UTM zone 28N"},
  {coordinate_system:"EPSG:2162", label: "EPSG:2162 / Sierra Leone 1968 / UTM zone 29N"},
  {coordinate_system:"EPSG:2164", label: "EPSG:2164 / Locodjo 1965 / TM 5 NW"},
  {coordinate_system:"EPSG:2165", label: "EPSG:2165 / Abidjan 1987 / TM 5 NW"},
  {coordinate_system:"EPSG:2169", label: "EPSG:2169 / LUREF / Luxembourg TM"},
  {coordinate_system:"EPSG:2172", label: "EPSG:2172 / Pulkovo 1942(58) / Poland zone II"},
  {coordinate_system:"EPSG:2173", label: "EPSG:2173 / Pulkovo 1942(58) / Poland zone III"},
  {coordinate_system:"EPSG:2174", label: "EPSG:2174 / Pulkovo 1942(58) / Poland zone IV"},
  {coordinate_system:"EPSG:2175", label: "EPSG:2175 / Pulkovo 1942(58) / Poland zone V"},
  {coordinate_system:"EPSG:2176", label: "EPSG:2176 / ETRF2000-PL / CS2000/15"},
  {coordinate_system:"EPSG:2177", label: "EPSG:2177 / ETRF2000-PL / CS2000/18"},
  {coordinate_system:"EPSG:2178", label: "EPSG:2178 / ETRF2000-PL / CS2000/21"},
  {coordinate_system:"EPSG:2179", label: "EPSG:2179 / ETRF2000-PL / CS2000/24"},
  {coordinate_system:"EPSG:2180", label: "EPSG:2180 / ETRF2000-PL / CS92"},
  {coordinate_system:"EPSG:2188", label: "EPSG:2188 / Azores Occidental 1939 / UTM zone 25N"},
  {coordinate_system:"EPSG:2189", label: "EPSG:2189 / Azores Central 1948 / UTM zone 26N"},
  {coordinate_system:"EPSG:2190", label: "EPSG:2190 / Azores Oriental 1940 / UTM zone 26N"},
  {coordinate_system:"EPSG:2193", label: "EPSG:2193 / NZGD2000 / New Zealand Transverse Mercator 2000"},
  {coordinate_system:"EPSG:2195", label: "EPSG:2195 / NAD83(HARN) / UTM zone 2S"},
  {coordinate_system:"EPSG:2196", label: "EPSG:2196 / ETRS89 / Kp2000 Jutland"},
  {coordinate_system:"EPSG:2197", label: "EPSG:2197 / ETRS89 / Kp2000 Zealand"},
  {coordinate_system:"EPSG:2198", label: "EPSG:2198 / ETRS89 / Kp2000 Bornholm"},
  {coordinate_system:"EPSG:2200", label: "EPSG:2200 / ATS77 / New Brunswick Stereographic (ATS77)"},
  {coordinate_system:"EPSG:2201", label: "EPSG:2201 / REGVEN / UTM zone 18N"},
  {coordinate_system:"EPSG:2202", label: "EPSG:2202 / REGVEN / UTM zone 19N"},
  {coordinate_system:"EPSG:2203", label: "EPSG:2203 / REGVEN / UTM zone 20N"},
  {coordinate_system:"EPSG:2204", label: "EPSG:2204 / NAD27 / Tennessee"},
  {coordinate_system:"EPSG:2205", label: "EPSG:2205 / NAD83 / Kentucky North"},
  {coordinate_system:"EPSG:2206", label: "EPSG:2206 / ED50 / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:2207", label: "EPSG:2207 / ED50 / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:2208", label: "EPSG:2208 / ED50 / 3-degree Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:2209", label: "EPSG:2209 / ED50 / 3-degree Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:2210", label: "EPSG:2210 / ED50 / 3-degree Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:2211", label: "EPSG:2211 / ED50 / 3-degree Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:2212", label: "EPSG:2212 / ED50 / 3-degree Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:2213", label: "EPSG:2213 / ETRS89 / TM 30 NE"},
  {coordinate_system:"EPSG:2215", label: "EPSG:2215 / Manoca 1962 / UTM zone 32N"},
  {coordinate_system:"EPSG:2216", label: "EPSG:2216 / Qornoq 1927 / UTM zone 22N"},
  {coordinate_system:"EPSG:2217", label: "EPSG:2217 / Qornoq 1927 / UTM zone 23N"},
  {coordinate_system:"EPSG:2218", label: "EPSG:2218 / Scoresbysund 1952 / Greenland zone 5 east"},
  {coordinate_system:"EPSG:2219", label: "EPSG:2219 / ATS77 / UTM zone 19N"},
  {coordinate_system:"EPSG:2220", label: "EPSG:2220 / ATS77 / UTM zone 20N"},
  {coordinate_system:"EPSG:2221", label: "EPSG:2221 / Scoresbysund 1952 / Greenland zone 6 east"},
  {coordinate_system:"EPSG:2222", label: "EPSG:2222 / NAD83 / Arizona East (ft)"},
  {coordinate_system:"EPSG:2223", label: "EPSG:2223 / NAD83 / Arizona Central (ft)"},
  {coordinate_system:"EPSG:2224", label: "EPSG:2224 / NAD83 / Arizona West (ft)"},
  {coordinate_system:"EPSG:2225", label: "EPSG:2225 / NAD83 / California zone 1 (ftUS)"},
  {coordinate_system:"EPSG:2226", label: "EPSG:2226 / NAD83 / California zone 2 (ftUS)"},
  {coordinate_system:"EPSG:2227", label: "EPSG:2227 / NAD83 / California zone 3 (ftUS)"},
  {coordinate_system:"EPSG:2228", label: "EPSG:2228 / NAD83 / California zone 4 (ftUS)"},
  {coordinate_system:"EPSG:2229", label: "EPSG:2229 / NAD83 / California zone 5 (ftUS)"},
  {coordinate_system:"EPSG:2230", label: "EPSG:2230 / NAD83 / California zone 6 (ftUS)"},
  {coordinate_system:"EPSG:2231", label: "EPSG:2231 / NAD83 / Colorado North (ftUS)"},
  {coordinate_system:"EPSG:2232", label: "EPSG:2232 / NAD83 / Colorado Central (ftUS)"},
  {coordinate_system:"EPSG:2233", label: "EPSG:2233 / NAD83 / Colorado South (ftUS)"},
  {coordinate_system:"EPSG:2234", label: "EPSG:2234 / NAD83 / Connecticut (ftUS)"},
  {coordinate_system:"EPSG:2235", label: "EPSG:2235 / NAD83 / Delaware (ftUS)"},
  {coordinate_system:"EPSG:2236", label: "EPSG:2236 / NAD83 / Florida East (ftUS)"},
  {coordinate_system:"EPSG:2237", label: "EPSG:2237 / NAD83 / Florida West (ftUS)"},
  {coordinate_system:"EPSG:2238", label: "EPSG:2238 / NAD83 / Florida North (ftUS)"},
  {coordinate_system:"EPSG:2239", label: "EPSG:2239 / NAD83 / Georgia East (ftUS)"},
  {coordinate_system:"EPSG:2240", label: "EPSG:2240 / NAD83 / Georgia West (ftUS)"},
  {coordinate_system:"EPSG:2241", label: "EPSG:2241 / NAD83 / Idaho East (ftUS)"},
  {coordinate_system:"EPSG:2242", label: "EPSG:2242 / NAD83 / Idaho Central (ftUS)"},
  {coordinate_system:"EPSG:2243", label: "EPSG:2243 / NAD83 / Idaho West (ftUS)"},
  {coordinate_system:"EPSG:2246", label: "EPSG:2246 / NAD83 / Kentucky North (ftUS)"},
  {coordinate_system:"EPSG:2247", label: "EPSG:2247 / NAD83 / Kentucky South (ftUS)"},
  {coordinate_system:"EPSG:2248", label: "EPSG:2248 / NAD83 / Maryland (ftUS)"},
  {coordinate_system:"EPSG:2249", label: "EPSG:2249 / NAD83 / Massachusetts Mainland (ftUS)"},
  {coordinate_system:"EPSG:2250", label: "EPSG:2250 / NAD83 / Massachusetts Island (ftUS)"},
  {coordinate_system:"EPSG:2251", label: "EPSG:2251 / NAD83 / Michigan North (ft)"},
  {coordinate_system:"EPSG:2252", label: "EPSG:2252 / NAD83 / Michigan Central (ft)"},
  {coordinate_system:"EPSG:2253", label: "EPSG:2253 / NAD83 / Michigan South (ft)"},
  {coordinate_system:"EPSG:2254", label: "EPSG:2254 / NAD83 / Mississippi East (ftUS)"},
  {coordinate_system:"EPSG:2255", label: "EPSG:2255 / NAD83 / Mississippi West (ftUS)"},
  {coordinate_system:"EPSG:2256", label: "EPSG:2256 / NAD83 / Montana (ft)"},
  {coordinate_system:"EPSG:2257", label: "EPSG:2257 / NAD83 / New Mexico East (ftUS)"},
  {coordinate_system:"EPSG:2258", label: "EPSG:2258 / NAD83 / New Mexico Central (ftUS)"},
  {coordinate_system:"EPSG:2259", label: "EPSG:2259 / NAD83 / New Mexico West (ftUS)"},
  {coordinate_system:"EPSG:2260", label: "EPSG:2260 / NAD83 / New York East (ftUS)"},
  {coordinate_system:"EPSG:2261", label: "EPSG:2261 / NAD83 / New York Central (ftUS)"},
  {coordinate_system:"EPSG:2262", label: "EPSG:2262 / NAD83 / New York West (ftUS)"},
  {coordinate_system:"EPSG:2263", label: "EPSG:2263 / NAD83 / New York Long Island (ftUS)"},
  {coordinate_system:"EPSG:2264", label: "EPSG:2264 / NAD83 / North Carolina (ftUS)"},
  {coordinate_system:"EPSG:2265", label: "EPSG:2265 / NAD83 / North Dakota North (ft)"},
  {coordinate_system:"EPSG:2266", label: "EPSG:2266 / NAD83 / North Dakota South (ft)"},
  {coordinate_system:"EPSG:2267", label: "EPSG:2267 / NAD83 / Oklahoma North (ftUS)"},
  {coordinate_system:"EPSG:2268", label: "EPSG:2268 / NAD83 / Oklahoma South (ftUS)"},
  {coordinate_system:"EPSG:2269", label: "EPSG:2269 / NAD83 / Oregon North (ft)"},
  {coordinate_system:"EPSG:2270", label: "EPSG:2270 / NAD83 / Oregon South (ft)"},
  {coordinate_system:"EPSG:2271", label: "EPSG:2271 / NAD83 / Pennsylvania North (ftUS)"},
  {coordinate_system:"EPSG:2272", label: "EPSG:2272 / NAD83 / Pennsylvania South (ftUS)"},
  {coordinate_system:"EPSG:2273", label: "EPSG:2273 / NAD83 / South Carolina (ft)"},
  {coordinate_system:"EPSG:2274", label: "EPSG:2274 / NAD83 / Tennessee (ftUS)"},
  {coordinate_system:"EPSG:2275", label: "EPSG:2275 / NAD83 / Texas North (ftUS)"},
  {coordinate_system:"EPSG:2276", label: "EPSG:2276 / NAD83 / Texas North Central (ftUS)"},
  {coordinate_system:"EPSG:2277", label: "EPSG:2277 / NAD83 / Texas Central (ftUS)"},
  {coordinate_system:"EPSG:2278", label: "EPSG:2278 / NAD83 / Texas South Central (ftUS)"},
  {coordinate_system:"EPSG:2279", label: "EPSG:2279 / NAD83 / Texas South (ftUS)"},
  {coordinate_system:"EPSG:2280", label: "EPSG:2280 / NAD83 / Utah North (ft)"},
  {coordinate_system:"EPSG:2281", label: "EPSG:2281 / NAD83 / Utah Central (ft)"},
  {coordinate_system:"EPSG:2282", label: "EPSG:2282 / NAD83 / Utah South (ft)"},
  {coordinate_system:"EPSG:2283", label: "EPSG:2283 / NAD83 / Virginia North (ftUS)"},
  {coordinate_system:"EPSG:2284", label: "EPSG:2284 / NAD83 / Virginia South (ftUS)"},
  {coordinate_system:"EPSG:2285", label: "EPSG:2285 / NAD83 / Washington North (ftUS)"},
  {coordinate_system:"EPSG:2286", label: "EPSG:2286 / NAD83 / Washington South (ftUS)"},
  {coordinate_system:"EPSG:2287", label: "EPSG:2287 / NAD83 / Wisconsin North (ftUS)"},
  {coordinate_system:"EPSG:2288", label: "EPSG:2288 / NAD83 / Wisconsin Central (ftUS)"},
  {coordinate_system:"EPSG:2289", label: "EPSG:2289 / NAD83 / Wisconsin South (ftUS)"},
  {coordinate_system:"EPSG:2290", label: "EPSG:2290 / ATS77 / Prince Edward Isl. Stereographic (ATS77)"},
  {coordinate_system:"EPSG:2294", label: "EPSG:2294 / ATS77 / MTM Nova Scotia zone 4"},
  {coordinate_system:"EPSG:2295", label: "EPSG:2295 / ATS77 / MTM Nova Scotia zone 5"},
  {coordinate_system:"EPSG:2296", label: "EPSG:2296 / Ammassalik 1958 / Greenland zone 7 east"},
  {coordinate_system:"EPSG:2299", label: "EPSG:2299 / Qornoq 1927 / Greenland zone 2 west"},
  {coordinate_system:"EPSG:2301", label: "EPSG:2301 / Qornoq 1927 / Greenland zone 3 west"},
  {coordinate_system:"EPSG:2303", label: "EPSG:2303 / Qornoq 1927 / Greenland zone 4 west"},
  {coordinate_system:"EPSG:2304", label: "EPSG:2304 / Qornoq 1927 / Greenland zone 5 west"},
  {coordinate_system:"EPSG:2305", label: "EPSG:2305 / Qornoq 1927 / Greenland zone 6 west"},
  {coordinate_system:"EPSG:2306", label: "EPSG:2306 / Qornoq 1927 / Greenland zone 7 west"},
  {coordinate_system:"EPSG:2307", label: "EPSG:2307 / Qornoq 1927 / Greenland zone 8 east"},
  {coordinate_system:"EPSG:2308", label: "EPSG:2308 / Batavia / TM 109 SE"},
  {coordinate_system:"EPSG:2309", label: "EPSG:2309 / WGS 84 / TM 116 SE"},
  {coordinate_system:"EPSG:2310", label: "EPSG:2310 / WGS 84 / TM 132 SE"},
  {coordinate_system:"EPSG:2311", label: "EPSG:2311 / WGS 84 / TM 6 NE"},
  {coordinate_system:"EPSG:2312", label: "EPSG:2312 / Garoua / UTM zone 33N"},
  {coordinate_system:"EPSG:2313", label: "EPSG:2313 / Kousseri / UTM zone 33N"},
  {coordinate_system:"EPSG:2314", label: "EPSG:2314 / Trinidad 1903 / Trinidad Grid (ftCla)"},
  {coordinate_system:"EPSG:2315", label: "EPSG:2315 / Campo Inchauspe / UTM zone 19S"},
  {coordinate_system:"EPSG:2316", label: "EPSG:2316 / Campo Inchauspe / UTM zone 20S"},
  {coordinate_system:"EPSG:2317", label: "EPSG:2317 / PSAD56 / ICN Regional"},
  {coordinate_system:"EPSG:2318", label: "EPSG:2318 / Ain el Abd / Aramco Lambert"},
  {coordinate_system:"EPSG:2319", label: "EPSG:2319 / ED50 / TM27"},
  {coordinate_system:"EPSG:2320", label: "EPSG:2320 / ED50 / TM30"},
  {coordinate_system:"EPSG:2321", label: "EPSG:2321 / ED50 / TM33"},
  {coordinate_system:"EPSG:2322", label: "EPSG:2322 / ED50 / TM36"},
  {coordinate_system:"EPSG:2323", label: "EPSG:2323 / ED50 / TM39"},
  {coordinate_system:"EPSG:2324", label: "EPSG:2324 / ED50 / TM42"},
  {coordinate_system:"EPSG:2325", label: "EPSG:2325 / ED50 / TM45"},
  {coordinate_system:"EPSG:2326", label: "EPSG:2326 / Hong Kong 1980 Grid System"},
  {coordinate_system:"EPSG:2327", label: "EPSG:2327 / Xian 1980 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:2328", label: "EPSG:2328 / Xian 1980 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:2329", label: "EPSG:2329 / Xian 1980 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:2330", label: "EPSG:2330 / Xian 1980 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:2331", label: "EPSG:2331 / Xian 1980 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:2332", label: "EPSG:2332 / Xian 1980 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:2333", label: "EPSG:2333 / Xian 1980 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:2334", label: "EPSG:2334 / Xian 1980 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:2335", label: "EPSG:2335 / Xian 1980 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:2336", label: "EPSG:2336 / Xian 1980 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:2337", label: "EPSG:2337 / Xian 1980 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:2338", label: "EPSG:2338 / Xian 1980 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2339", label: "EPSG:2339 / Xian 1980 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2340", label: "EPSG:2340 / Xian 1980 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2341", label: "EPSG:2341 / Xian 1980 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2342", label: "EPSG:2342 / Xian 1980 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2343", label: "EPSG:2343 / Xian 1980 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2344", label: "EPSG:2344 / Xian 1980 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2345", label: "EPSG:2345 / Xian 1980 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2346", label: "EPSG:2346 / Xian 1980 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2347", label: "EPSG:2347 / Xian 1980 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2348", label: "EPSG:2348 / Xian 1980 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2349", label: "EPSG:2349 / Xian 1980 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:2350", label: "EPSG:2350 / Xian 1980 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:2351", label: "EPSG:2351 / Xian 1980 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:2352", label: "EPSG:2352 / Xian 1980 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:2353", label: "EPSG:2353 / Xian 1980 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:2354", label: "EPSG:2354 / Xian 1980 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:2355", label: "EPSG:2355 / Xian 1980 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:2356", label: "EPSG:2356 / Xian 1980 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:2357", label: "EPSG:2357 / Xian 1980 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:2358", label: "EPSG:2358 / Xian 1980 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:2359", label: "EPSG:2359 / Xian 1980 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:2360", label: "EPSG:2360 / Xian 1980 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:2361", label: "EPSG:2361 / Xian 1980 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:2362", label: "EPSG:2362 / Xian 1980 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:2363", label: "EPSG:2363 / Xian 1980 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:2364", label: "EPSG:2364 / Xian 1980 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:2365", label: "EPSG:2365 / Xian 1980 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:2366", label: "EPSG:2366 / Xian 1980 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:2367", label: "EPSG:2367 / Xian 1980 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:2368", label: "EPSG:2368 / Xian 1980 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:2369", label: "EPSG:2369 / Xian 1980 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:2370", label: "EPSG:2370 / Xian 1980 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2371", label: "EPSG:2371 / Xian 1980 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:2372", label: "EPSG:2372 / Xian 1980 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2373", label: "EPSG:2373 / Xian 1980 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:2374", label: "EPSG:2374 / Xian 1980 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2375", label: "EPSG:2375 / Xian 1980 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:2376", label: "EPSG:2376 / Xian 1980 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2377", label: "EPSG:2377 / Xian 1980 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:2378", label: "EPSG:2378 / Xian 1980 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2379", label: "EPSG:2379 / Xian 1980 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:2380", label: "EPSG:2380 / Xian 1980 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2381", label: "EPSG:2381 / Xian 1980 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:2382", label: "EPSG:2382 / Xian 1980 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2383", label: "EPSG:2383 / Xian 1980 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:2384", label: "EPSG:2384 / Xian 1980 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2385", label: "EPSG:2385 / Xian 1980 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:2386", label: "EPSG:2386 / Xian 1980 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2387", label: "EPSG:2387 / Xian 1980 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:2388", label: "EPSG:2388 / Xian 1980 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2389", label: "EPSG:2389 / Xian 1980 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:2390", label: "EPSG:2390 / Xian 1980 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2391", label: "EPSG:2391 / KKJ / Finland zone 1"},
  {coordinate_system:"EPSG:2392", label: "EPSG:2392 / KKJ / Finland zone 2"},
  {coordinate_system:"EPSG:2393", label: "EPSG:2393 / KKJ / Finland Uniform Coordinate System"},
  {coordinate_system:"EPSG:2394", label: "EPSG:2394 / KKJ / Finland zone 4"},
  {coordinate_system:"EPSG:2395", label: "EPSG:2395 / South Yemen / Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:2396", label: "EPSG:2396 / South Yemen / Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:2397", label: "EPSG:2397 / Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:2398", label: "EPSG:2398 / Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:2399", label: "EPSG:2399 / Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:2401", label: "EPSG:2401 / Beijing 1954 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:2402", label: "EPSG:2402 / Beijing 1954 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:2403", label: "EPSG:2403 / Beijing 1954 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:2404", label: "EPSG:2404 / Beijing 1954 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:2405", label: "EPSG:2405 / Beijing 1954 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:2406", label: "EPSG:2406 / Beijing 1954 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:2407", label: "EPSG:2407 / Beijing 1954 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:2408", label: "EPSG:2408 / Beijing 1954 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:2409", label: "EPSG:2409 / Beijing 1954 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:2410", label: "EPSG:2410 / Beijing 1954 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:2411", label: "EPSG:2411 / Beijing 1954 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:2412", label: "EPSG:2412 / Beijing 1954 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:2413", label: "EPSG:2413 / Beijing 1954 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:2414", label: "EPSG:2414 / Beijing 1954 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:2415", label: "EPSG:2415 / Beijing 1954 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:2416", label: "EPSG:2416 / Beijing 1954 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:2417", label: "EPSG:2417 / Beijing 1954 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:2418", label: "EPSG:2418 / Beijing 1954 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:2419", label: "EPSG:2419 / Beijing 1954 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:2420", label: "EPSG:2420 / Beijing 1954 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:2421", label: "EPSG:2421 / Beijing 1954 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:2422", label: "EPSG:2422 / Beijing 1954 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2423", label: "EPSG:2423 / Beijing 1954 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:2424", label: "EPSG:2424 / Beijing 1954 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2425", label: "EPSG:2425 / Beijing 1954 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:2426", label: "EPSG:2426 / Beijing 1954 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2427", label: "EPSG:2427 / Beijing 1954 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:2428", label: "EPSG:2428 / Beijing 1954 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2429", label: "EPSG:2429 / Beijing 1954 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:2430", label: "EPSG:2430 / Beijing 1954 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2431", label: "EPSG:2431 / Beijing 1954 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:2432", label: "EPSG:2432 / Beijing 1954 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2433", label: "EPSG:2433 / Beijing 1954 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:2434", label: "EPSG:2434 / Beijing 1954 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2435", label: "EPSG:2435 / Beijing 1954 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:2436", label: "EPSG:2436 / Beijing 1954 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2437", label: "EPSG:2437 / Beijing 1954 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:2438", label: "EPSG:2438 / Beijing 1954 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2439", label: "EPSG:2439 / Beijing 1954 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:2440", label: "EPSG:2440 / Beijing 1954 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2441", label: "EPSG:2441 / Beijing 1954 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:2442", label: "EPSG:2442 / Beijing 1954 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2443", label: "EPSG:2443 / JGD2000 / Japan Plane Rectangular CS I"},
  {coordinate_system:"EPSG:2444", label: "EPSG:2444 / JGD2000 / Japan Plane Rectangular CS II"},
  {coordinate_system:"EPSG:2445", label: "EPSG:2445 / JGD2000 / Japan Plane Rectangular CS III"},
  {coordinate_system:"EPSG:2446", label: "EPSG:2446 / JGD2000 / Japan Plane Rectangular CS IV"},
  {coordinate_system:"EPSG:2447", label: "EPSG:2447 / JGD2000 / Japan Plane Rectangular CS V"},
  {coordinate_system:"EPSG:2448", label: "EPSG:2448 / JGD2000 / Japan Plane Rectangular CS VI"},
  {coordinate_system:"EPSG:2449", label: "EPSG:2449 / JGD2000 / Japan Plane Rectangular CS VII"},
  {coordinate_system:"EPSG:2450", label: "EPSG:2450 / JGD2000 / Japan Plane Rectangular CS VIII"},
  {coordinate_system:"EPSG:2451", label: "EPSG:2451 / JGD2000 / Japan Plane Rectangular CS IX"},
  {coordinate_system:"EPSG:2452", label: "EPSG:2452 / JGD2000 / Japan Plane Rectangular CS X"},
  {coordinate_system:"EPSG:2453", label: "EPSG:2453 / JGD2000 / Japan Plane Rectangular CS XI"},
  {coordinate_system:"EPSG:2454", label: "EPSG:2454 / JGD2000 / Japan Plane Rectangular CS XII"},
  {coordinate_system:"EPSG:2455", label: "EPSG:2455 / JGD2000 / Japan Plane Rectangular CS XIII"},
  {coordinate_system:"EPSG:2456", label: "EPSG:2456 / JGD2000 / Japan Plane Rectangular CS XIV"},
  {coordinate_system:"EPSG:2457", label: "EPSG:2457 / JGD2000 / Japan Plane Rectangular CS XV"},
  {coordinate_system:"EPSG:2458", label: "EPSG:2458 / JGD2000 / Japan Plane Rectangular CS XVI"},
  {coordinate_system:"EPSG:2459", label: "EPSG:2459 / JGD2000 / Japan Plane Rectangular CS XVII"},
  {coordinate_system:"EPSG:2460", label: "EPSG:2460 / JGD2000 / Japan Plane Rectangular CS XVIII"},
  {coordinate_system:"EPSG:2461", label: "EPSG:2461 / JGD2000 / Japan Plane Rectangular CS XIX"},
  {coordinate_system:"EPSG:2462", label: "EPSG:2462 / Albanian 1987 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:2463", label: "EPSG:2463 / Pulkovo 1995 / Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:2464", label: "EPSG:2464 / Pulkovo 1995 / Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:2465", label: "EPSG:2465 / Pulkovo 1995 / Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:2466", label: "EPSG:2466 / Pulkovo 1995 / Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:2467", label: "EPSG:2467 / Pulkovo 1995 / Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:2468", label: "EPSG:2468 / Pulkovo 1995 / Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:2469", label: "EPSG:2469 / Pulkovo 1995 / Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:2470", label: "EPSG:2470 / Pulkovo 1995 / Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:2471", label: "EPSG:2471 / Pulkovo 1995 / Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:2472", label: "EPSG:2472 / Pulkovo 1995 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2473", label: "EPSG:2473 / Pulkovo 1995 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2474", label: "EPSG:2474 / Pulkovo 1995 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2475", label: "EPSG:2475 / Pulkovo 1995 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2476", label: "EPSG:2476 / Pulkovo 1995 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2477", label: "EPSG:2477 / Pulkovo 1995 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2478", label: "EPSG:2478 / Pulkovo 1995 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2479", label: "EPSG:2479 / Pulkovo 1995 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2480", label: "EPSG:2480 / Pulkovo 1995 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2481", label: "EPSG:2481 / Pulkovo 1995 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2482", label: "EPSG:2482 / Pulkovo 1995 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2483", label: "EPSG:2483 / Pulkovo 1995 / Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:2484", label: "EPSG:2484 / Pulkovo 1995 / Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:2485", label: "EPSG:2485 / Pulkovo 1995 / Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:2486", label: "EPSG:2486 / Pulkovo 1995 / Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:2487", label: "EPSG:2487 / Pulkovo 1995 / Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:2488", label: "EPSG:2488 / Pulkovo 1995 / Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:2489", label: "EPSG:2489 / Pulkovo 1995 / Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:2490", label: "EPSG:2490 / Pulkovo 1995 / Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:2491", label: "EPSG:2491 / Pulkovo 1995 / Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:2494", label: "EPSG:2494 / Pulkovo 1942 / Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:2495", label: "EPSG:2495 / Pulkovo 1942 / Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:2496", label: "EPSG:2496 / Pulkovo 1942 / Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:2497", label: "EPSG:2497 / Pulkovo 1942 / Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:2498", label: "EPSG:2498 / Pulkovo 1942 / Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:2499", label: "EPSG:2499 / Pulkovo 1942 / Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:2500", label: "EPSG:2500 / Pulkovo 1942 / Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:2501", label: "EPSG:2501 / Pulkovo 1942 / Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:2502", label: "EPSG:2502 / Pulkovo 1942 / Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:2503", label: "EPSG:2503 / Pulkovo 1942 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2504", label: "EPSG:2504 / Pulkovo 1942 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2505", label: "EPSG:2505 / Pulkovo 1942 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2506", label: "EPSG:2506 / Pulkovo 1942 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2507", label: "EPSG:2507 / Pulkovo 1942 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2508", label: "EPSG:2508 / Pulkovo 1942 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2509", label: "EPSG:2509 / Pulkovo 1942 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2510", label: "EPSG:2510 / Pulkovo 1942 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2511", label: "EPSG:2511 / Pulkovo 1942 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2512", label: "EPSG:2512 / Pulkovo 1942 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2513", label: "EPSG:2513 / Pulkovo 1942 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2514", label: "EPSG:2514 / Pulkovo 1942 / Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:2515", label: "EPSG:2515 / Pulkovo 1942 / Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:2516", label: "EPSG:2516 / Pulkovo 1942 / Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:2517", label: "EPSG:2517 / Pulkovo 1942 / Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:2518", label: "EPSG:2518 / Pulkovo 1942 / Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:2519", label: "EPSG:2519 / Pulkovo 1942 / Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:2520", label: "EPSG:2520 / Pulkovo 1942 / Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:2521", label: "EPSG:2521 / Pulkovo 1942 / Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:2522", label: "EPSG:2522 / Pulkovo 1942 / Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:2523", label: "EPSG:2523 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:2524", label: "EPSG:2524 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:2525", label: "EPSG:2525 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:2526", label: "EPSG:2526 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:2527", label: "EPSG:2527 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:2528", label: "EPSG:2528 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:2529", label: "EPSG:2529 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:2530", label: "EPSG:2530 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:2531", label: "EPSG:2531 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:2532", label: "EPSG:2532 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:2533", label: "EPSG:2533 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:2534", label: "EPSG:2534 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:2535", label: "EPSG:2535 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:2536", label: "EPSG:2536 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:2537", label: "EPSG:2537 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:2538", label: "EPSG:2538 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:2539", label: "EPSG:2539 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:2540", label: "EPSG:2540 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:2541", label: "EPSG:2541 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:2542", label: "EPSG:2542 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:2543", label: "EPSG:2543 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:2544", label: "EPSG:2544 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:2545", label: "EPSG:2545 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:2546", label: "EPSG:2546 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:2547", label: "EPSG:2547 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:2548", label: "EPSG:2548 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:2549", label: "EPSG:2549 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:2551", label: "EPSG:2551 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:2552", label: "EPSG:2552 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:2553", label: "EPSG:2553 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:2554", label: "EPSG:2554 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:2555", label: "EPSG:2555 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:2556", label: "EPSG:2556 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:2557", label: "EPSG:2557 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:2558", label: "EPSG:2558 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:2559", label: "EPSG:2559 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:2560", label: "EPSG:2560 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:2561", label: "EPSG:2561 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:2562", label: "EPSG:2562 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:2563", label: "EPSG:2563 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 46"},
  {coordinate_system:"EPSG:2564", label: "EPSG:2564 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 47"},
  {coordinate_system:"EPSG:2565", label: "EPSG:2565 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 48"},
  {coordinate_system:"EPSG:2566", label: "EPSG:2566 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 49"},
  {coordinate_system:"EPSG:2567", label: "EPSG:2567 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 50"},
  {coordinate_system:"EPSG:2568", label: "EPSG:2568 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 51"},
  {coordinate_system:"EPSG:2569", label: "EPSG:2569 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 52"},
  {coordinate_system:"EPSG:2570", label: "EPSG:2570 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 53"},
  {coordinate_system:"EPSG:2571", label: "EPSG:2571 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 54"},
  {coordinate_system:"EPSG:2572", label: "EPSG:2572 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 55"},
  {coordinate_system:"EPSG:2573", label: "EPSG:2573 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 56"},
  {coordinate_system:"EPSG:2574", label: "EPSG:2574 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 57"},
  {coordinate_system:"EPSG:2575", label: "EPSG:2575 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 58"},
  {coordinate_system:"EPSG:2576", label: "EPSG:2576 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 59"},
  {coordinate_system:"EPSG:2578", label: "EPSG:2578 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 61"},
  {coordinate_system:"EPSG:2579", label: "EPSG:2579 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 62"},
  {coordinate_system:"EPSG:2580", label: "EPSG:2580 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 63"},
  {coordinate_system:"EPSG:2581", label: "EPSG:2581 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 64"},
  {coordinate_system:"EPSG:2582", label: "EPSG:2582 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:2583", label: "EPSG:2583 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 24E"},
  {coordinate_system:"EPSG:2584", label: "EPSG:2584 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:2585", label: "EPSG:2585 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 30E"},
  {coordinate_system:"EPSG:2586", label: "EPSG:2586 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:2587", label: "EPSG:2587 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 36E"},
  {coordinate_system:"EPSG:2588", label: "EPSG:2588 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:2589", label: "EPSG:2589 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 42E"},
  {coordinate_system:"EPSG:2590", label: "EPSG:2590 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:2591", label: "EPSG:2591 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 48E"},
  {coordinate_system:"EPSG:2592", label: "EPSG:2592 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:2593", label: "EPSG:2593 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 54E"},
  {coordinate_system:"EPSG:2594", label: "EPSG:2594 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:2595", label: "EPSG:2595 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 60E"},
  {coordinate_system:"EPSG:2596", label: "EPSG:2596 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:2597", label: "EPSG:2597 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 66E"},
  {coordinate_system:"EPSG:2598", label: "EPSG:2598 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:2599", label: "EPSG:2599 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 72E"},
  {coordinate_system:"EPSG:2601", label: "EPSG:2601 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2602", label: "EPSG:2602 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:2603", label: "EPSG:2603 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2604", label: "EPSG:2604 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:2605", label: "EPSG:2605 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2606", label: "EPSG:2606 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:2607", label: "EPSG:2607 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2608", label: "EPSG:2608 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:2609", label: "EPSG:2609 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2610", label: "EPSG:2610 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:2611", label: "EPSG:2611 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2612", label: "EPSG:2612 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:2613", label: "EPSG:2613 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2614", label: "EPSG:2614 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:2615", label: "EPSG:2615 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2616", label: "EPSG:2616 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:2617", label: "EPSG:2617 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2618", label: "EPSG:2618 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:2619", label: "EPSG:2619 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2620", label: "EPSG:2620 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:2621", label: "EPSG:2621 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2622", label: "EPSG:2622 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 138E"},
  {coordinate_system:"EPSG:2623", label: "EPSG:2623 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:2624", label: "EPSG:2624 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 144E"},
  {coordinate_system:"EPSG:2625", label: "EPSG:2625 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:2626", label: "EPSG:2626 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 150E"},
  {coordinate_system:"EPSG:2627", label: "EPSG:2627 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:2628", label: "EPSG:2628 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 156E"},
  {coordinate_system:"EPSG:2629", label: "EPSG:2629 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:2630", label: "EPSG:2630 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 162E"},
  {coordinate_system:"EPSG:2631", label: "EPSG:2631 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:2632", label: "EPSG:2632 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 168E"},
  {coordinate_system:"EPSG:2633", label: "EPSG:2633 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:2634", label: "EPSG:2634 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 174E"},
  {coordinate_system:"EPSG:2635", label: "EPSG:2635 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:2636", label: "EPSG:2636 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 180E"},
  {coordinate_system:"EPSG:2637", label: "EPSG:2637 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:2638", label: "EPSG:2638 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 174W"},
  {coordinate_system:"EPSG:2639", label: "EPSG:2639 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:2640", label: "EPSG:2640 / Pulkovo 1942 / 3-degree Gauss-Kruger CM 168W"},
  {coordinate_system:"EPSG:2641", label: "EPSG:2641 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:2642", label: "EPSG:2642 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:2643", label: "EPSG:2643 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:2644", label: "EPSG:2644 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:2645", label: "EPSG:2645 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:2646", label: "EPSG:2646 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:2647", label: "EPSG:2647 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:2648", label: "EPSG:2648 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:2649", label: "EPSG:2649 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:2650", label: "EPSG:2650 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:2651", label: "EPSG:2651 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:2652", label: "EPSG:2652 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:2653", label: "EPSG:2653 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:2654", label: "EPSG:2654 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:2655", label: "EPSG:2655 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:2656", label: "EPSG:2656 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:2657", label: "EPSG:2657 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:2658", label: "EPSG:2658 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:2659", label: "EPSG:2659 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:2660", label: "EPSG:2660 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:2661", label: "EPSG:2661 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:2662", label: "EPSG:2662 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:2663", label: "EPSG:2663 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:2664", label: "EPSG:2664 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:2665", label: "EPSG:2665 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:2666", label: "EPSG:2666 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:2667", label: "EPSG:2667 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:2668", label: "EPSG:2668 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:2669", label: "EPSG:2669 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:2670", label: "EPSG:2670 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:2671", label: "EPSG:2671 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:2672", label: "EPSG:2672 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:2673", label: "EPSG:2673 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:2674", label: "EPSG:2674 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:2675", label: "EPSG:2675 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:2676", label: "EPSG:2676 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:2677", label: "EPSG:2677 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:2678", label: "EPSG:2678 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:2679", label: "EPSG:2679 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:2680", label: "EPSG:2680 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 46"},
  {coordinate_system:"EPSG:2681", label: "EPSG:2681 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 47"},
  {coordinate_system:"EPSG:2682", label: "EPSG:2682 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 48"},
  {coordinate_system:"EPSG:2683", label: "EPSG:2683 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 49"},
  {coordinate_system:"EPSG:2684", label: "EPSG:2684 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 50"},
  {coordinate_system:"EPSG:2685", label: "EPSG:2685 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 51"},
  {coordinate_system:"EPSG:2686", label: "EPSG:2686 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 52"},
  {coordinate_system:"EPSG:2687", label: "EPSG:2687 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 53"},
  {coordinate_system:"EPSG:2688", label: "EPSG:2688 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 54"},
  {coordinate_system:"EPSG:2689", label: "EPSG:2689 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 55"},
  {coordinate_system:"EPSG:2690", label: "EPSG:2690 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 56"},
  {coordinate_system:"EPSG:2691", label: "EPSG:2691 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 57"},
  {coordinate_system:"EPSG:2692", label: "EPSG:2692 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 58"},
  {coordinate_system:"EPSG:2693", label: "EPSG:2693 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 59"},
  {coordinate_system:"EPSG:2695", label: "EPSG:2695 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 61"},
  {coordinate_system:"EPSG:2696", label: "EPSG:2696 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 62"},
  {coordinate_system:"EPSG:2697", label: "EPSG:2697 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 63"},
  {coordinate_system:"EPSG:2698", label: "EPSG:2698 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 64"},
  {coordinate_system:"EPSG:2699", label: "EPSG:2699 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:2700", label: "EPSG:2700 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 24E"},
  {coordinate_system:"EPSG:2701", label: "EPSG:2701 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:2702", label: "EPSG:2702 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 30E"},
  {coordinate_system:"EPSG:2703", label: "EPSG:2703 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:2704", label: "EPSG:2704 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 36E"},
  {coordinate_system:"EPSG:2705", label: "EPSG:2705 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:2706", label: "EPSG:2706 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 42E"},
  {coordinate_system:"EPSG:2707", label: "EPSG:2707 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:2708", label: "EPSG:2708 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 48E"},
  {coordinate_system:"EPSG:2709", label: "EPSG:2709 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:2710", label: "EPSG:2710 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 54E"},
  {coordinate_system:"EPSG:2711", label: "EPSG:2711 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:2712", label: "EPSG:2712 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 60E"},
  {coordinate_system:"EPSG:2713", label: "EPSG:2713 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:2714", label: "EPSG:2714 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 66E"},
  {coordinate_system:"EPSG:2715", label: "EPSG:2715 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:2716", label: "EPSG:2716 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 72E"},
  {coordinate_system:"EPSG:2717", label: "EPSG:2717 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2718", label: "EPSG:2718 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:2719", label: "EPSG:2719 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2720", label: "EPSG:2720 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:2721", label: "EPSG:2721 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2722", label: "EPSG:2722 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:2723", label: "EPSG:2723 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2724", label: "EPSG:2724 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:2725", label: "EPSG:2725 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2726", label: "EPSG:2726 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:2727", label: "EPSG:2727 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2728", label: "EPSG:2728 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:2729", label: "EPSG:2729 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2730", label: "EPSG:2730 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:2731", label: "EPSG:2731 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2732", label: "EPSG:2732 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:2733", label: "EPSG:2733 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2734", label: "EPSG:2734 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:2735", label: "EPSG:2735 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2736", label: "EPSG:2736 / Tete / UTM zone 36S"},
  {coordinate_system:"EPSG:2737", label: "EPSG:2737 / Tete / UTM zone 37S"},
  {coordinate_system:"EPSG:2738", label: "EPSG:2738 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:2739", label: "EPSG:2739 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2740", label: "EPSG:2740 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 138E"},
  {coordinate_system:"EPSG:2741", label: "EPSG:2741 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:2742", label: "EPSG:2742 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 144E"},
  {coordinate_system:"EPSG:2743", label: "EPSG:2743 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:2744", label: "EPSG:2744 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 150E"},
  {coordinate_system:"EPSG:2745", label: "EPSG:2745 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:2746", label: "EPSG:2746 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 156E"},
  {coordinate_system:"EPSG:2747", label: "EPSG:2747 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:2748", label: "EPSG:2748 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 162E"},
  {coordinate_system:"EPSG:2749", label: "EPSG:2749 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:2750", label: "EPSG:2750 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 168E"},
  {coordinate_system:"EPSG:2751", label: "EPSG:2751 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:2752", label: "EPSG:2752 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 174E"},
  {coordinate_system:"EPSG:2753", label: "EPSG:2753 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:2754", label: "EPSG:2754 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 180E"},
  {coordinate_system:"EPSG:2755", label: "EPSG:2755 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:2756", label: "EPSG:2756 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 174W"},
  {coordinate_system:"EPSG:2757", label: "EPSG:2757 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:2758", label: "EPSG:2758 / Pulkovo 1995 / 3-degree Gauss-Kruger CM 168W"},
  {coordinate_system:"EPSG:2759", label: "EPSG:2759 / NAD83(HARN) / Alabama East"},
  {coordinate_system:"EPSG:2760", label: "EPSG:2760 / NAD83(HARN) / Alabama West"},
  {coordinate_system:"EPSG:2761", label: "EPSG:2761 / NAD83(HARN) / Arizona East"},
  {coordinate_system:"EPSG:2762", label: "EPSG:2762 / NAD83(HARN) / Arizona Central"},
  {coordinate_system:"EPSG:2763", label: "EPSG:2763 / NAD83(HARN) / Arizona West"},
  {coordinate_system:"EPSG:2764", label: "EPSG:2764 / NAD83(HARN) / Arkansas North"},
  {coordinate_system:"EPSG:2765", label: "EPSG:2765 / NAD83(HARN) / Arkansas South"},
  {coordinate_system:"EPSG:2766", label: "EPSG:2766 / NAD83(HARN) / California zone 1"},
  {coordinate_system:"EPSG:2767", label: "EPSG:2767 / NAD83(HARN) / California zone 2"},
  {coordinate_system:"EPSG:2768", label: "EPSG:2768 / NAD83(HARN) / California zone 3"},
  {coordinate_system:"EPSG:2769", label: "EPSG:2769 / NAD83(HARN) / California zone 4"},
  {coordinate_system:"EPSG:2770", label: "EPSG:2770 / NAD83(HARN) / California zone 5"},
  {coordinate_system:"EPSG:2771", label: "EPSG:2771 / NAD83(HARN) / California zone 6"},
  {coordinate_system:"EPSG:2772", label: "EPSG:2772 / NAD83(HARN) / Colorado North"},
  {coordinate_system:"EPSG:2773", label: "EPSG:2773 / NAD83(HARN) / Colorado Central"},
  {coordinate_system:"EPSG:2774", label: "EPSG:2774 / NAD83(HARN) / Colorado South"},
  {coordinate_system:"EPSG:2775", label: "EPSG:2775 / NAD83(HARN) / Connecticut"},
  {coordinate_system:"EPSG:2776", label: "EPSG:2776 / NAD83(HARN) / Delaware"},
  {coordinate_system:"EPSG:2777", label: "EPSG:2777 / NAD83(HARN) / Florida East"},
  {coordinate_system:"EPSG:2778", label: "EPSG:2778 / NAD83(HARN) / Florida West"},
  {coordinate_system:"EPSG:2779", label: "EPSG:2779 / NAD83(HARN) / Florida North"},
  {coordinate_system:"EPSG:2780", label: "EPSG:2780 / NAD83(HARN) / Georgia East"},
  {coordinate_system:"EPSG:2781", label: "EPSG:2781 / NAD83(HARN) / Georgia West"},
  {coordinate_system:"EPSG:2782", label: "EPSG:2782 / NAD83(HARN) / Hawaii zone 1"},
  {coordinate_system:"EPSG:2783", label: "EPSG:2783 / NAD83(HARN) / Hawaii zone 2"},
  {coordinate_system:"EPSG:2784", label: "EPSG:2784 / NAD83(HARN) / Hawaii zone 3"},
  {coordinate_system:"EPSG:2785", label: "EPSG:2785 / NAD83(HARN) / Hawaii zone 4"},
  {coordinate_system:"EPSG:2786", label: "EPSG:2786 / NAD83(HARN) / Hawaii zone 5"},
  {coordinate_system:"EPSG:2787", label: "EPSG:2787 / NAD83(HARN) / Idaho East"},
  {coordinate_system:"EPSG:2788", label: "EPSG:2788 / NAD83(HARN) / Idaho Central"},
  {coordinate_system:"EPSG:2789", label: "EPSG:2789 / NAD83(HARN) / Idaho West"},
  {coordinate_system:"EPSG:2790", label: "EPSG:2790 / NAD83(HARN) / Illinois East"},
  {coordinate_system:"EPSG:2791", label: "EPSG:2791 / NAD83(HARN) / Illinois West"},
  {coordinate_system:"EPSG:2792", label: "EPSG:2792 / NAD83(HARN) / Indiana East"},
  {coordinate_system:"EPSG:2793", label: "EPSG:2793 / NAD83(HARN) / Indiana West"},
  {coordinate_system:"EPSG:2794", label: "EPSG:2794 / NAD83(HARN) / Iowa North"},
  {coordinate_system:"EPSG:2795", label: "EPSG:2795 / NAD83(HARN) / Iowa South"},
  {coordinate_system:"EPSG:2796", label: "EPSG:2796 / NAD83(HARN) / Kansas North"},
  {coordinate_system:"EPSG:2797", label: "EPSG:2797 / NAD83(HARN) / Kansas South"},
  {coordinate_system:"EPSG:2798", label: "EPSG:2798 / NAD83(HARN) / Kentucky North"},
  {coordinate_system:"EPSG:2799", label: "EPSG:2799 / NAD83(HARN) / Kentucky South"},
  {coordinate_system:"EPSG:2800", label: "EPSG:2800 / NAD83(HARN) / Louisiana North"},
  {coordinate_system:"EPSG:2801", label: "EPSG:2801 / NAD83(HARN) / Louisiana South"},
  {coordinate_system:"EPSG:2802", label: "EPSG:2802 / NAD83(HARN) / Maine East"},
  {coordinate_system:"EPSG:2803", label: "EPSG:2803 / NAD83(HARN) / Maine West"},
  {coordinate_system:"EPSG:2804", label: "EPSG:2804 / NAD83(HARN) / Maryland"},
  {coordinate_system:"EPSG:2805", label: "EPSG:2805 / NAD83(HARN) / Massachusetts Mainland"},
  {coordinate_system:"EPSG:2806", label: "EPSG:2806 / NAD83(HARN) / Massachusetts Island"},
  {coordinate_system:"EPSG:2807", label: "EPSG:2807 / NAD83(HARN) / Michigan North"},
  {coordinate_system:"EPSG:2808", label: "EPSG:2808 / NAD83(HARN) / Michigan Central"},
  {coordinate_system:"EPSG:2809", label: "EPSG:2809 / NAD83(HARN) / Michigan South"},
  {coordinate_system:"EPSG:2810", label: "EPSG:2810 / NAD83(HARN) / Minnesota North"},
  {coordinate_system:"EPSG:2811", label: "EPSG:2811 / NAD83(HARN) / Minnesota Central"},
  {coordinate_system:"EPSG:2812", label: "EPSG:2812 / NAD83(HARN) / Minnesota South"},
  {coordinate_system:"EPSG:2813", label: "EPSG:2813 / NAD83(HARN) / Mississippi East"},
  {coordinate_system:"EPSG:2814", label: "EPSG:2814 / NAD83(HARN) / Mississippi West"},
  {coordinate_system:"EPSG:2815", label: "EPSG:2815 / NAD83(HARN) / Missouri East"},
  {coordinate_system:"EPSG:2816", label: "EPSG:2816 / NAD83(HARN) / Missouri Central"},
  {coordinate_system:"EPSG:2817", label: "EPSG:2817 / NAD83(HARN) / Missouri West"},
  {coordinate_system:"EPSG:2818", label: "EPSG:2818 / NAD83(HARN) / Montana"},
  {coordinate_system:"EPSG:2819", label: "EPSG:2819 / NAD83(HARN) / Nebraska"},
  {coordinate_system:"EPSG:2820", label: "EPSG:2820 / NAD83(HARN) / Nevada East"},
  {coordinate_system:"EPSG:2821", label: "EPSG:2821 / NAD83(HARN) / Nevada Central"},
  {coordinate_system:"EPSG:2822", label: "EPSG:2822 / NAD83(HARN) / Nevada West"},
  {coordinate_system:"EPSG:2823", label: "EPSG:2823 / NAD83(HARN) / New Hampshire"},
  {coordinate_system:"EPSG:2824", label: "EPSG:2824 / NAD83(HARN) / New Jersey"},
  {coordinate_system:"EPSG:2825", label: "EPSG:2825 / NAD83(HARN) / New Mexico East"},
  {coordinate_system:"EPSG:2826", label: "EPSG:2826 / NAD83(HARN) / New Mexico Central"},
  {coordinate_system:"EPSG:2827", label: "EPSG:2827 / NAD83(HARN) / New Mexico West"},
  {coordinate_system:"EPSG:2828", label: "EPSG:2828 / NAD83(HARN) / New York East"},
  {coordinate_system:"EPSG:2829", label: "EPSG:2829 / NAD83(HARN) / New York Central"},
  {coordinate_system:"EPSG:2830", label: "EPSG:2830 / NAD83(HARN) / New York West"},
  {coordinate_system:"EPSG:2831", label: "EPSG:2831 / NAD83(HARN) / New York Long Island"},
  {coordinate_system:"EPSG:2832", label: "EPSG:2832 / NAD83(HARN) / North Dakota North"},
  {coordinate_system:"EPSG:2833", label: "EPSG:2833 / NAD83(HARN) / North Dakota South"},
  {coordinate_system:"EPSG:2834", label: "EPSG:2834 / NAD83(HARN) / Ohio North"},
  {coordinate_system:"EPSG:2835", label: "EPSG:2835 / NAD83(HARN) / Ohio South"},
  {coordinate_system:"EPSG:2836", label: "EPSG:2836 / NAD83(HARN) / Oklahoma North"},
  {coordinate_system:"EPSG:2837", label: "EPSG:2837 / NAD83(HARN) / Oklahoma South"},
  {coordinate_system:"EPSG:2838", label: "EPSG:2838 / NAD83(HARN) / Oregon North"},
  {coordinate_system:"EPSG:2839", label: "EPSG:2839 / NAD83(HARN) / Oregon South"},
  {coordinate_system:"EPSG:2840", label: "EPSG:2840 / NAD83(HARN) / Rhode Island"},
  {coordinate_system:"EPSG:2841", label: "EPSG:2841 / NAD83(HARN) / South Dakota North"},
  {coordinate_system:"EPSG:2842", label: "EPSG:2842 / NAD83(HARN) / South Dakota South"},
  {coordinate_system:"EPSG:2843", label: "EPSG:2843 / NAD83(HARN) / Tennessee"},
  {coordinate_system:"EPSG:2844", label: "EPSG:2844 / NAD83(HARN) / Texas North"},
  {coordinate_system:"EPSG:2845", label: "EPSG:2845 / NAD83(HARN) / Texas North Central"},
  {coordinate_system:"EPSG:2846", label: "EPSG:2846 / NAD83(HARN) / Texas Central"},
  {coordinate_system:"EPSG:2847", label: "EPSG:2847 / NAD83(HARN) / Texas South Central"},
  {coordinate_system:"EPSG:2848", label: "EPSG:2848 / NAD83(HARN) / Texas South"},
  {coordinate_system:"EPSG:2849", label: "EPSG:2849 / NAD83(HARN) / Utah North"},
  {coordinate_system:"EPSG:2850", label: "EPSG:2850 / NAD83(HARN) / Utah Central"},
  {coordinate_system:"EPSG:2851", label: "EPSG:2851 / NAD83(HARN) / Utah South"},
  {coordinate_system:"EPSG:2852", label: "EPSG:2852 / NAD83(HARN) / Vermont"},
  {coordinate_system:"EPSG:2853", label: "EPSG:2853 / NAD83(HARN) / Virginia North"},
  {coordinate_system:"EPSG:2854", label: "EPSG:2854 / NAD83(HARN) / Virginia South"},
  {coordinate_system:"EPSG:2855", label: "EPSG:2855 / NAD83(HARN) / Washington North"},
  {coordinate_system:"EPSG:2856", label: "EPSG:2856 / NAD83(HARN) / Washington South"},
  {coordinate_system:"EPSG:2857", label: "EPSG:2857 / NAD83(HARN) / West Virginia North"},
  {coordinate_system:"EPSG:2858", label: "EPSG:2858 / NAD83(HARN) / West Virginia South"},
  {coordinate_system:"EPSG:2859", label: "EPSG:2859 / NAD83(HARN) / Wisconsin North"},
  {coordinate_system:"EPSG:2860", label: "EPSG:2860 / NAD83(HARN) / Wisconsin Central"},
  {coordinate_system:"EPSG:2861", label: "EPSG:2861 / NAD83(HARN) / Wisconsin South"},
  {coordinate_system:"EPSG:2862", label: "EPSG:2862 / NAD83(HARN) / Wyoming East"},
  {coordinate_system:"EPSG:2863", label: "EPSG:2863 / NAD83(HARN) / Wyoming East Central"},
  {coordinate_system:"EPSG:2864", label: "EPSG:2864 / NAD83(HARN) / Wyoming West Central"},
  {coordinate_system:"EPSG:2865", label: "EPSG:2865 / NAD83(HARN) / Wyoming West"},
  {coordinate_system:"EPSG:2866", label: "EPSG:2866 / NAD83(HARN) / Puerto Rico and Virgin Is."},
  {coordinate_system:"EPSG:2867", label: "EPSG:2867 / NAD83(HARN) / Arizona East (ft)"},
  {coordinate_system:"EPSG:2868", label: "EPSG:2868 / NAD83(HARN) / Arizona Central (ft)"},
  {coordinate_system:"EPSG:2869", label: "EPSG:2869 / NAD83(HARN) / Arizona West (ft)"},
  {coordinate_system:"EPSG:2870", label: "EPSG:2870 / NAD83(HARN) / California zone 1 (ftUS)"},
  {coordinate_system:"EPSG:2871", label: "EPSG:2871 / NAD83(HARN) / California zone 2 (ftUS)"},
  {coordinate_system:"EPSG:2872", label: "EPSG:2872 / NAD83(HARN) / California zone 3 (ftUS)"},
  {coordinate_system:"EPSG:2873", label: "EPSG:2873 / NAD83(HARN) / California zone 4 (ftUS)"},
  {coordinate_system:"EPSG:2874", label: "EPSG:2874 / NAD83(HARN) / California zone 5 (ftUS)"},
  {coordinate_system:"EPSG:2875", label: "EPSG:2875 / NAD83(HARN) / California zone 6 (ftUS)"},
  {coordinate_system:"EPSG:2876", label: "EPSG:2876 / NAD83(HARN) / Colorado North (ftUS)"},
  {coordinate_system:"EPSG:2877", label: "EPSG:2877 / NAD83(HARN) / Colorado Central (ftUS)"},
  {coordinate_system:"EPSG:2878", label: "EPSG:2878 / NAD83(HARN) / Colorado South (ftUS)"},
  {coordinate_system:"EPSG:2879", label: "EPSG:2879 / NAD83(HARN) / Connecticut (ftUS)"},
  {coordinate_system:"EPSG:2880", label: "EPSG:2880 / NAD83(HARN) / Delaware (ftUS)"},
  {coordinate_system:"EPSG:2881", label: "EPSG:2881 / NAD83(HARN) / Florida East (ftUS)"},
  {coordinate_system:"EPSG:2882", label: "EPSG:2882 / NAD83(HARN) / Florida West (ftUS)"},
  {coordinate_system:"EPSG:2883", label: "EPSG:2883 / NAD83(HARN) / Florida North (ftUS)"},
  {coordinate_system:"EPSG:2884", label: "EPSG:2884 / NAD83(HARN) / Georgia East (ftUS)"},
  {coordinate_system:"EPSG:2885", label: "EPSG:2885 / NAD83(HARN) / Georgia West (ftUS)"},
  {coordinate_system:"EPSG:2886", label: "EPSG:2886 / NAD83(HARN) / Idaho East (ftUS)"},
  {coordinate_system:"EPSG:2887", label: "EPSG:2887 / NAD83(HARN) / Idaho Central (ftUS)"},
  {coordinate_system:"EPSG:2888", label: "EPSG:2888 / NAD83(HARN) / Idaho West (ftUS)"},
  {coordinate_system:"EPSG:2891", label: "EPSG:2891 / NAD83(HARN) / Kentucky North (ftUS)"},
  {coordinate_system:"EPSG:2892", label: "EPSG:2892 / NAD83(HARN) / Kentucky South (ftUS)"},
  {coordinate_system:"EPSG:2893", label: "EPSG:2893 / NAD83(HARN) / Maryland (ftUS)"},
  {coordinate_system:"EPSG:2894", label: "EPSG:2894 / NAD83(HARN) / Massachusetts Mainland (ftUS)"},
  {coordinate_system:"EPSG:2895", label: "EPSG:2895 / NAD83(HARN) / Massachusetts Island (ftUS)"},
  {coordinate_system:"EPSG:2896", label: "EPSG:2896 / NAD83(HARN) / Michigan North (ft)"},
  {coordinate_system:"EPSG:2897", label: "EPSG:2897 / NAD83(HARN) / Michigan Central (ft)"},
  {coordinate_system:"EPSG:2898", label: "EPSG:2898 / NAD83(HARN) / Michigan South (ft)"},
  {coordinate_system:"EPSG:2899", label: "EPSG:2899 / NAD83(HARN) / Mississippi East (ftUS)"},
  {coordinate_system:"EPSG:2900", label: "EPSG:2900 / NAD83(HARN) / Mississippi West (ftUS)"},
  {coordinate_system:"EPSG:2901", label: "EPSG:2901 / NAD83(HARN) / Montana (ft)"},
  {coordinate_system:"EPSG:2902", label: "EPSG:2902 / NAD83(HARN) / New Mexico East (ftUS)"},
  {coordinate_system:"EPSG:2903", label: "EPSG:2903 / NAD83(HARN) / New Mexico Central (ftUS)"},
  {coordinate_system:"EPSG:2904", label: "EPSG:2904 / NAD83(HARN) / New Mexico West (ftUS)"},
  {coordinate_system:"EPSG:2905", label: "EPSG:2905 / NAD83(HARN) / New York East (ftUS)"},
  {coordinate_system:"EPSG:2906", label: "EPSG:2906 / NAD83(HARN) / New York Central (ftUS)"},
  {coordinate_system:"EPSG:2907", label: "EPSG:2907 / NAD83(HARN) / New York West (ftUS)"},
  {coordinate_system:"EPSG:2908", label: "EPSG:2908 / NAD83(HARN) / New York Long Island (ftUS)"},
  {coordinate_system:"EPSG:2909", label: "EPSG:2909 / NAD83(HARN) / North Dakota North (ft)"},
  {coordinate_system:"EPSG:2910", label: "EPSG:2910 / NAD83(HARN) / North Dakota South (ft)"},
  {coordinate_system:"EPSG:2911", label: "EPSG:2911 / NAD83(HARN) / Oklahoma North (ftUS)"},
  {coordinate_system:"EPSG:2912", label: "EPSG:2912 / NAD83(HARN) / Oklahoma South (ftUS)"},
  {coordinate_system:"EPSG:2913", label: "EPSG:2913 / NAD83(HARN) / Oregon North (ft)"},
  {coordinate_system:"EPSG:2914", label: "EPSG:2914 / NAD83(HARN) / Oregon South (ft)"},
  {coordinate_system:"EPSG:2915", label: "EPSG:2915 / NAD83(HARN) / Tennessee (ftUS)"},
  {coordinate_system:"EPSG:2916", label: "EPSG:2916 / NAD83(HARN) / Texas North (ftUS)"},
  {coordinate_system:"EPSG:2917", label: "EPSG:2917 / NAD83(HARN) / Texas North Central (ftUS)"},
  {coordinate_system:"EPSG:2918", label: "EPSG:2918 / NAD83(HARN) / Texas Central (ftUS)"},
  {coordinate_system:"EPSG:2919", label: "EPSG:2919 / NAD83(HARN) / Texas South Central (ftUS)"},
  {coordinate_system:"EPSG:2920", label: "EPSG:2920 / NAD83(HARN) / Texas South (ftUS)"},
  {coordinate_system:"EPSG:2921", label: "EPSG:2921 / NAD83(HARN) / Utah North (ft)"},
  {coordinate_system:"EPSG:2922", label: "EPSG:2922 / NAD83(HARN) / Utah Central (ft)"},
  {coordinate_system:"EPSG:2923", label: "EPSG:2923 / NAD83(HARN) / Utah South (ft)"},
  {coordinate_system:"EPSG:2924", label: "EPSG:2924 / NAD83(HARN) / Virginia North (ftUS)"},
  {coordinate_system:"EPSG:2925", label: "EPSG:2925 / NAD83(HARN) / Virginia South (ftUS)"},
  {coordinate_system:"EPSG:2926", label: "EPSG:2926 / NAD83(HARN) / Washington North (ftUS)"},
  {coordinate_system:"EPSG:2927", label: "EPSG:2927 / NAD83(HARN) / Washington South (ftUS)"},
  {coordinate_system:"EPSG:2928", label: "EPSG:2928 / NAD83(HARN) / Wisconsin North (ftUS)"},
  {coordinate_system:"EPSG:2929", label: "EPSG:2929 / NAD83(HARN) / Wisconsin Central (ftUS)"},
  {coordinate_system:"EPSG:2930", label: "EPSG:2930 / NAD83(HARN) / Wisconsin South (ftUS)"},
  {coordinate_system:"EPSG:2931", label: "EPSG:2931 / Beduaram / TM 13 NE"},
  {coordinate_system:"EPSG:2932", label: "EPSG:2932 / QND95 / Qatar National Grid"},
  {coordinate_system:"EPSG:2933", label: "EPSG:2933 / Segara / UTM zone 50S"},
  {coordinate_system:"EPSG:2935", label: "EPSG:2935 / Pulkovo 1942 / CS63 zone A1"},
  {coordinate_system:"EPSG:2936", label: "EPSG:2936 / Pulkovo 1942 / CS63 zone A2"},
  {coordinate_system:"EPSG:2937", label: "EPSG:2937 / Pulkovo 1942 / CS63 zone A3"},
  {coordinate_system:"EPSG:2938", label: "EPSG:2938 / Pulkovo 1942 / CS63 zone A4"},
  {coordinate_system:"EPSG:2939", label: "EPSG:2939 / Pulkovo 1942 / CS63 zone K2"},
  {coordinate_system:"EPSG:2940", label: "EPSG:2940 / Pulkovo 1942 / CS63 zone K3"},
  {coordinate_system:"EPSG:2941", label: "EPSG:2941 / Pulkovo 1942 / CS63 zone K4"},
  {coordinate_system:"EPSG:2942", label: "EPSG:2942 / Porto Santo / UTM zone 28N"},
  {coordinate_system:"EPSG:2943", label: "EPSG:2943 / Selvagem Grande / UTM zone 28N"},
  {coordinate_system:"EPSG:2945", label: "EPSG:2945 / NAD83(CSRS) / MTM zone 3"},
  {coordinate_system:"EPSG:2946", label: "EPSG:2946 / NAD83(CSRS) / MTM zone 4"},
  {coordinate_system:"EPSG:2947", label: "EPSG:2947 / NAD83(CSRS) / MTM zone 5"},
  {coordinate_system:"EPSG:2948", label: "EPSG:2948 / NAD83(CSRS) / MTM zone 6"},
  {coordinate_system:"EPSG:2949", label: "EPSG:2949 / NAD83(CSRS) / MTM zone 7"},
  {coordinate_system:"EPSG:2950", label: "EPSG:2950 / NAD83(CSRS) / MTM zone 8"},
  {coordinate_system:"EPSG:2951", label: "EPSG:2951 / NAD83(CSRS) / MTM zone 9"},
  {coordinate_system:"EPSG:2952", label: "EPSG:2952 / NAD83(CSRS) / MTM zone 10"},
  {coordinate_system:"EPSG:2953", label: "EPSG:2953 / NAD83(CSRS) / New Brunswick Stereographic"},
  {coordinate_system:"EPSG:2954", label: "EPSG:2954 / NAD83(CSRS) / Prince Edward Isl. Stereographic (NAD83)"},
  {coordinate_system:"EPSG:2955", label: "EPSG:2955 / NAD83(CSRS) / UTM zone 11N"},
  {coordinate_system:"EPSG:2956", label: "EPSG:2956 / NAD83(CSRS) / UTM zone 12N"},
  {coordinate_system:"EPSG:2957", label: "EPSG:2957 / NAD83(CSRS) / UTM zone 13N"},
  {coordinate_system:"EPSG:2958", label: "EPSG:2958 / NAD83(CSRS) / UTM zone 17N"},
  {coordinate_system:"EPSG:2959", label: "EPSG:2959 / NAD83(CSRS) / UTM zone 18N"},
  {coordinate_system:"EPSG:2960", label: "EPSG:2960 / NAD83(CSRS) / UTM zone 19N"},
  {coordinate_system:"EPSG:2961", label: "EPSG:2961 / NAD83(CSRS) / UTM zone 20N"},
  {coordinate_system:"EPSG:2962", label: "EPSG:2962 / NAD83(CSRS) / UTM zone 21N"},
  {coordinate_system:"EPSG:2963", label: "EPSG:2963 / Lisbon 1890 (Lisbon) / Portugal Bonne"},
  {coordinate_system:"EPSG:2964", label: "EPSG:2964 / NAD27 / Alaska Albers"},
  {coordinate_system:"EPSG:2965", label: "EPSG:2965 / NAD83 / Indiana East (ftUS)"},
  {coordinate_system:"EPSG:2966", label: "EPSG:2966 / NAD83 / Indiana West (ftUS)"},
  {coordinate_system:"EPSG:2967", label: "EPSG:2967 / NAD83(HARN) / Indiana East (ftUS)"},
  {coordinate_system:"EPSG:2968", label: "EPSG:2968 / NAD83(HARN) / Indiana West (ftUS)"},
  {coordinate_system:"EPSG:2969", label: "EPSG:2969 / Fort Marigot / UTM zone 20N"},
  {coordinate_system:"EPSG:2970", label: "EPSG:2970 / Guadeloupe 1948 / UTM zone 20N"},
  {coordinate_system:"EPSG:2971", label: "EPSG:2971 / CSG67 / UTM zone 22N"},
  {coordinate_system:"EPSG:2972", label: "EPSG:2972 / RGFG95 / UTM zone 22N"},
  {coordinate_system:"EPSG:2973", label: "EPSG:2973 / Martinique 1938 / UTM zone 20N"},
  {coordinate_system:"EPSG:2975", label: "EPSG:2975 / RGR92 / UTM zone 40S"},
  {coordinate_system:"EPSG:2976", label: "EPSG:2976 / Tahiti 52 / UTM zone 6S"},
  {coordinate_system:"EPSG:2977", label: "EPSG:2977 / Tahaa 54 / UTM zone 5S"},
  {coordinate_system:"EPSG:2978", label: "EPSG:2978 / IGN72 Nuku Hiva / UTM zone 7S"},
  {coordinate_system:"EPSG:2980", label: "EPSG:2980 / Combani 1950 / UTM zone 38S"},
  {coordinate_system:"EPSG:2981", label: "EPSG:2981 / IGN56 Lifou / UTM zone 58S"},
  {coordinate_system:"EPSG:2985", label: "EPSG:2985 / Petrels 1972 / Terre Adelie Polar Stereographic"},
  {coordinate_system:"EPSG:2986", label: "EPSG:2986 / Perroud 1950 / Terre Adelie Polar Stereographic"},
  {coordinate_system:"EPSG:2987", label: "EPSG:2987 / Saint Pierre et Miquelon 1950 / UTM zone 21N"},
  {coordinate_system:"EPSG:2988", label: "EPSG:2988 / MOP78 / UTM zone 1S"},
  {coordinate_system:"EPSG:2991", label: "EPSG:2991 / NAD83 / Oregon LCC (m)"},
  {coordinate_system:"EPSG:2992", label: "EPSG:2992 / NAD83 / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:2993", label: "EPSG:2993 / NAD83(HARN) / Oregon LCC (m)"},
  {coordinate_system:"EPSG:2994", label: "EPSG:2994 / NAD83(HARN) / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:2995", label: "EPSG:2995 / IGN53 Mare / UTM zone 58S"},
  {coordinate_system:"EPSG:2996", label: "EPSG:2996 / ST84 Ile des Pins / UTM zone 58S"},
  {coordinate_system:"EPSG:2997", label: "EPSG:2997 / ST71 Belep / UTM zone 58S"},
  {coordinate_system:"EPSG:2998", label: "EPSG:2998 / NEA74 Noumea / UTM zone 58S"},
  {coordinate_system:"EPSG:2999", label: "EPSG:2999 / Grand Comoros / UTM zone 38S"},
  {coordinate_system:"EPSG:3000", label: "EPSG:3000 / Segara / NEIEZ"},
  {coordinate_system:"EPSG:3001", label: "EPSG:3001 / Batavia / NEIEZ"},
  {coordinate_system:"EPSG:3002", label: "EPSG:3002 / Makassar / NEIEZ"},
  {coordinate_system:"EPSG:3003", label: "EPSG:3003 / Monte Mario / Italy zone 1"},
  {coordinate_system:"EPSG:3004", label: "EPSG:3004 / Monte Mario / Italy zone 2"},
  {coordinate_system:"EPSG:3005", label: "EPSG:3005 / NAD83 / BC Albers"},
  {coordinate_system:"EPSG:3006", label: "EPSG:3006 / SWEREF99 TM"},
  {coordinate_system:"EPSG:3007", label: "EPSG:3007 / SWEREF99 12 00"},
  {coordinate_system:"EPSG:3008", label: "EPSG:3008 / SWEREF99 13 30"},
  {coordinate_system:"EPSG:3009", label: "EPSG:3009 / SWEREF99 15 00"},
  {coordinate_system:"EPSG:3010", label: "EPSG:3010 / SWEREF99 16 30"},
  {coordinate_system:"EPSG:3011", label: "EPSG:3011 / SWEREF99 18 00"},
  {coordinate_system:"EPSG:3012", label: "EPSG:3012 / SWEREF99 14 15"},
  {coordinate_system:"EPSG:3013", label: "EPSG:3013 / SWEREF99 15 45"},
  {coordinate_system:"EPSG:3014", label: "EPSG:3014 / SWEREF99 17 15"},
  {coordinate_system:"EPSG:3015", label: "EPSG:3015 / SWEREF99 18 45"},
  {coordinate_system:"EPSG:3016", label: "EPSG:3016 / SWEREF99 20 15"},
  {coordinate_system:"EPSG:3017", label: "EPSG:3017 / SWEREF99 21 45"},
  {coordinate_system:"EPSG:3018", label: "EPSG:3018 / SWEREF99 23 15"},
  {coordinate_system:"EPSG:3019", label: "EPSG:3019 / RT90 7.5 gon V"},
  {coordinate_system:"EPSG:3020", label: "EPSG:3020 / RT90 5 gon V"},
  {coordinate_system:"EPSG:3021", label: "EPSG:3021 / RT90 2.5 gon V"},
  {coordinate_system:"EPSG:3022", label: "EPSG:3022 / RT90 0 gon"},
  {coordinate_system:"EPSG:3023", label: "EPSG:3023 / RT90 2.5 gon O"},
  {coordinate_system:"EPSG:3024", label: "EPSG:3024 / RT90 5 gon O"},
  {coordinate_system:"EPSG:3025", label: "EPSG:3025 / RT38 7.5 gon V"},
  {coordinate_system:"EPSG:3026", label: "EPSG:3026 / RT38 5 gon V"},
  {coordinate_system:"EPSG:3027", label: "EPSG:3027 / RT38 2.5 gon V"},
  {coordinate_system:"EPSG:3028", label: "EPSG:3028 / RT38 0 gon"},
  {coordinate_system:"EPSG:3029", label: "EPSG:3029 / RT38 2.5 gon O"},
  {coordinate_system:"EPSG:3030", label: "EPSG:3030 / RT38 5 gon O"},
  {coordinate_system:"EPSG:3031", label: "EPSG:3031 / WGS 84 / Antarctic Polar Stereographic"},
  {coordinate_system:"EPSG:3032", label: "EPSG:3032 / WGS 84 / Australian Antarctic Polar Stereographic"},
  {coordinate_system:"EPSG:3033", label: "EPSG:3033 / WGS 84 / Australian Antarctic Lambert"},
  {coordinate_system:"EPSG:3034", label: "EPSG:3034 / ETRS89-extended / LCC Europe"},
  {coordinate_system:"EPSG:3035", label: "EPSG:3035 / ETRS89-extended / LAEA Europe"},
  {coordinate_system:"EPSG:3036", label: "EPSG:3036 / Moznet / UTM zone 36S"},
  {coordinate_system:"EPSG:3037", label: "EPSG:3037 / Moznet / UTM zone 37S"},
  {coordinate_system:"EPSG:3040", label: "EPSG:3040 / ETRS89 / UTM zone 28N (N-E)"},
  {coordinate_system:"EPSG:3041", label: "EPSG:3041 / ETRS89 / UTM zone 29N (N-E)"},
  {coordinate_system:"EPSG:3042", label: "EPSG:3042 / ETRS89 / UTM zone 30N (N-E)"},
  {coordinate_system:"EPSG:3043", label: "EPSG:3043 / ETRS89 / UTM zone 31N (N-E)"},
  {coordinate_system:"EPSG:3044", label: "EPSG:3044 / ETRS89 / UTM zone 32N (N-E)"},
  {coordinate_system:"EPSG:3045", label: "EPSG:3045 / ETRS89 / UTM zone 33N (N-E)"},
  {coordinate_system:"EPSG:3046", label: "EPSG:3046 / ETRS89 / UTM zone 34N (N-E)"},
  {coordinate_system:"EPSG:3047", label: "EPSG:3047 / ETRS89 / UTM zone 35N (N-E)"},
  {coordinate_system:"EPSG:3048", label: "EPSG:3048 / ETRS89 / UTM zone 36N (N-E)"},
  {coordinate_system:"EPSG:3049", label: "EPSG:3049 / ETRS89 / UTM zone 37N (N-E)"},
  {coordinate_system:"EPSG:3052", label: "EPSG:3052 / Reykjavik 1900 / Lambert 1900"},
  {coordinate_system:"EPSG:3053", label: "EPSG:3053 / Hjorsey 1955 / Lambert 1955"},
  {coordinate_system:"EPSG:3054", label: "EPSG:3054 / Hjorsey 1955 / UTM zone 26N"},
  {coordinate_system:"EPSG:3055", label: "EPSG:3055 / Hjorsey 1955 / UTM zone 27N"},
  {coordinate_system:"EPSG:3056", label: "EPSG:3056 / Hjorsey 1955 / UTM zone 28N"},
  {coordinate_system:"EPSG:3057", label: "EPSG:3057 / ISN93 / Lambert 1993"},
  {coordinate_system:"EPSG:3058", label: "EPSG:3058 / Helle 1954 / Jan Mayen Grid"},
  {coordinate_system:"EPSG:3059", label: "EPSG:3059 / LKS92 / Latvia TM"},
  {coordinate_system:"EPSG:3060", label: "EPSG:3060 / IGN72 Grande Terre / UTM zone 58S"},
  {coordinate_system:"EPSG:3061", label: "EPSG:3061 / Porto Santo 1995 / UTM zone 28N"},
  {coordinate_system:"EPSG:3062", label: "EPSG:3062 / Azores Oriental 1995 / UTM zone 26N"},
  {coordinate_system:"EPSG:3063", label: "EPSG:3063 / Azores Central 1995 / UTM zone 26N"},
  {coordinate_system:"EPSG:3064", label: "EPSG:3064 / IGM95 / UTM zone 32N"},
  {coordinate_system:"EPSG:3065", label: "EPSG:3065 / IGM95 / UTM zone 33N"},
  {coordinate_system:"EPSG:3066", label: "EPSG:3066 / ED50 / Jordan TM"},
  {coordinate_system:"EPSG:3067", label: "EPSG:3067 / ETRS89 / TM35FIN(E,N)"},
  {coordinate_system:"EPSG:3068", label: "EPSG:3068 / DHDN / Soldner Berlin"},
  {coordinate_system:"EPSG:3069", label: "EPSG:3069 / NAD27 / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:3070", label: "EPSG:3070 / NAD83 / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:3071", label: "EPSG:3071 / NAD83(HARN) / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:3072", label: "EPSG:3072 / NAD83 / Maine CS2000 East"},
  {coordinate_system:"EPSG:3074", label: "EPSG:3074 / NAD83 / Maine CS2000 West"},
  {coordinate_system:"EPSG:3075", label: "EPSG:3075 / NAD83(HARN) / Maine CS2000 East"},
  {coordinate_system:"EPSG:3077", label: "EPSG:3077 / NAD83(HARN) / Maine CS2000 West"},
  {coordinate_system:"EPSG:3078", label: "EPSG:3078 / NAD83 / Michigan Oblique Mercator"},
  {coordinate_system:"EPSG:3079", label: "EPSG:3079 / NAD83(HARN) / Michigan Oblique Mercator"},
  {coordinate_system:"EPSG:3080", label: "EPSG:3080 / NAD27 / Shackleford"},
  {coordinate_system:"EPSG:3081", label: "EPSG:3081 / NAD83 / Texas State Mapping System"},
  {coordinate_system:"EPSG:3082", label: "EPSG:3082 / NAD83 / Texas Centric Lambert Conformal"},
  {coordinate_system:"EPSG:3083", label: "EPSG:3083 / NAD83 / Texas Centric Albers Equal Area"},
  {coordinate_system:"EPSG:3084", label: "EPSG:3084 / NAD83(HARN) / Texas Centric Lambert Conformal"},
  {coordinate_system:"EPSG:3085", label: "EPSG:3085 / NAD83(HARN) / Texas Centric Albers Equal Area"},
  {coordinate_system:"EPSG:3086", label: "EPSG:3086 / NAD83 / Florida GDL Albers"},
  {coordinate_system:"EPSG:3087", label: "EPSG:3087 / NAD83(HARN) / Florida GDL Albers"},
  {coordinate_system:"EPSG:3088", label: "EPSG:3088 / NAD83 / Kentucky Single Zone"},
  {coordinate_system:"EPSG:3089", label: "EPSG:3089 / NAD83 / Kentucky Single Zone (ftUS)"},
  {coordinate_system:"EPSG:3090", label: "EPSG:3090 / NAD83(HARN) / Kentucky Single Zone"},
  {coordinate_system:"EPSG:3091", label: "EPSG:3091 / NAD83(HARN) / Kentucky Single Zone (ftUS)"},
  {coordinate_system:"EPSG:3092", label: "EPSG:3092 / Tokyo / UTM zone 51N"},
  {coordinate_system:"EPSG:3093", label: "EPSG:3093 / Tokyo / UTM zone 52N"},
  {coordinate_system:"EPSG:3094", label: "EPSG:3094 / Tokyo / UTM zone 53N"},
  {coordinate_system:"EPSG:3095", label: "EPSG:3095 / Tokyo / UTM zone 54N"},
  {coordinate_system:"EPSG:3096", label: "EPSG:3096 / Tokyo / UTM zone 55N"},
  {coordinate_system:"EPSG:3097", label: "EPSG:3097 / JGD2000 / UTM zone 51N"},
  {coordinate_system:"EPSG:3098", label: "EPSG:3098 / JGD2000 / UTM zone 52N"},
  {coordinate_system:"EPSG:3099", label: "EPSG:3099 / JGD2000 / UTM zone 53N"},
  {coordinate_system:"EPSG:3100", label: "EPSG:3100 / JGD2000 / UTM zone 54N"},
  {coordinate_system:"EPSG:3101", label: "EPSG:3101 / JGD2000 / UTM zone 55N"},
  {coordinate_system:"EPSG:3102", label: "EPSG:3102 / American Samoa 1962 / American Samoa Lambert"},
  {coordinate_system:"EPSG:3106", label: "EPSG:3106 / Gulshan 303 / TM 90 NE"},
  {coordinate_system:"EPSG:3107", label: "EPSG:3107 / GDA94 / SA Lambert"},
  {coordinate_system:"EPSG:3108", label: "EPSG:3108 / ETRS89 / Guernsey Grid"},
  {coordinate_system:"EPSG:3109", label: "EPSG:3109 / ETRS89 / Jersey Transverse Mercator"},
  {coordinate_system:"EPSG:3110", label: "EPSG:3110 / AGD66 / Vicgrid66"},
  {coordinate_system:"EPSG:3111", label: "EPSG:3111 / GDA94 / Vicgrid"},
  {coordinate_system:"EPSG:3112", label: "EPSG:3112 / GDA94 / Geoscience Australia Lambert"},
  {coordinate_system:"EPSG:3113", label: "EPSG:3113 / GDA94 / BCSG02"},
  {coordinate_system:"EPSG:3114", label: "EPSG:3114 / MAGNA-SIRGAS / Colombia Far West zone"},
  {coordinate_system:"EPSG:3115", label: "EPSG:3115 / MAGNA-SIRGAS / Colombia West zone"},
  {coordinate_system:"EPSG:3116", label: "EPSG:3116 / MAGNA-SIRGAS / Colombia Bogota zone"},
  {coordinate_system:"EPSG:3117", label: "EPSG:3117 / MAGNA-SIRGAS / Colombia East Central zone"},
  {coordinate_system:"EPSG:3118", label: "EPSG:3118 / MAGNA-SIRGAS / Colombia East zone"},
  {coordinate_system:"EPSG:3119", label: "EPSG:3119 / Douala 1948 / AEF west"},
  {coordinate_system:"EPSG:3120", label: "EPSG:3120 / Pulkovo 1942(58) / Poland zone I"},
  {coordinate_system:"EPSG:3121", label: "EPSG:3121 / PRS92 / Philippines zone 1"},
  {coordinate_system:"EPSG:3122", label: "EPSG:3122 / PRS92 / Philippines zone 2"},
  {coordinate_system:"EPSG:3123", label: "EPSG:3123 / PRS92 / Philippines zone 3"},
  {coordinate_system:"EPSG:3124", label: "EPSG:3124 / PRS92 / Philippines zone 4"},
  {coordinate_system:"EPSG:3125", label: "EPSG:3125 / PRS92 / Philippines zone 5"},
  {coordinate_system:"EPSG:3126", label: "EPSG:3126 / ETRS89 / ETRS-GK19FIN"},
  {coordinate_system:"EPSG:3127", label: "EPSG:3127 / ETRS89 / ETRS-GK20FIN"},
  {coordinate_system:"EPSG:3128", label: "EPSG:3128 / ETRS89 / ETRS-GK21FIN"},
  {coordinate_system:"EPSG:3129", label: "EPSG:3129 / ETRS89 / ETRS-GK22FIN"},
  {coordinate_system:"EPSG:3130", label: "EPSG:3130 / ETRS89 / ETRS-GK23FIN"},
  {coordinate_system:"EPSG:3131", label: "EPSG:3131 / ETRS89 / ETRS-GK24FIN"},
  {coordinate_system:"EPSG:3132", label: "EPSG:3132 / ETRS89 / ETRS-GK25FIN"},
  {coordinate_system:"EPSG:3133", label: "EPSG:3133 / ETRS89 / ETRS-GK26FIN"},
  {coordinate_system:"EPSG:3134", label: "EPSG:3134 / ETRS89 / ETRS-GK27FIN"},
  {coordinate_system:"EPSG:3135", label: "EPSG:3135 / ETRS89 / ETRS-GK28FIN"},
  {coordinate_system:"EPSG:3136", label: "EPSG:3136 / ETRS89 / ETRS-GK29FIN"},
  {coordinate_system:"EPSG:3137", label: "EPSG:3137 / ETRS89 / ETRS-GK30FIN"},
  {coordinate_system:"EPSG:3138", label: "EPSG:3138 / ETRS89 / ETRS-GK31FIN"},
  {coordinate_system:"EPSG:3139", label: "EPSG:3139 / Vanua Levu 1915 / Vanua Levu Grid"},
  {coordinate_system:"EPSG:3140", label: "EPSG:3140 / Viti Levu 1912 / Viti Levu Grid"},
  {coordinate_system:"EPSG:3141", label: "EPSG:3141 / Fiji 1956 / UTM zone 60S"},
  {coordinate_system:"EPSG:3142", label: "EPSG:3142 / Fiji 1956 / UTM zone 1S"},
  {coordinate_system:"EPSG:3144", label: "EPSG:3144 / FD54 / Faroe Lambert"},
  {coordinate_system:"EPSG:3145", label: "EPSG:3145 / ETRS89 / Faroe Lambert"},
  {coordinate_system:"EPSG:3148", label: "EPSG:3148 / Indian 1960 / UTM zone 48N"},
  {coordinate_system:"EPSG:3149", label: "EPSG:3149 / Indian 1960 / UTM zone 49N"},
  {coordinate_system:"EPSG:3152", label: "EPSG:3152 / ST74"},
  {coordinate_system:"EPSG:3153", label: "EPSG:3153 / NAD83(CSRS) / BC Albers"},
  {coordinate_system:"EPSG:3154", label: "EPSG:3154 / NAD83(CSRS) / UTM zone 7N"},
  {coordinate_system:"EPSG:3155", label: "EPSG:3155 / NAD83(CSRS) / UTM zone 8N"},
  {coordinate_system:"EPSG:3156", label: "EPSG:3156 / NAD83(CSRS) / UTM zone 9N"},
  {coordinate_system:"EPSG:3157", label: "EPSG:3157 / NAD83(CSRS) / UTM zone 10N"},
  {coordinate_system:"EPSG:3158", label: "EPSG:3158 / NAD83(CSRS) / UTM zone 14N"},
  {coordinate_system:"EPSG:3159", label: "EPSG:3159 / NAD83(CSRS) / UTM zone 15N"},
  {coordinate_system:"EPSG:3160", label: "EPSG:3160 / NAD83(CSRS) / UTM zone 16N"},
  {coordinate_system:"EPSG:3161", label: "EPSG:3161 / NAD83 / Ontario MNR Lambert"},
  {coordinate_system:"EPSG:3162", label: "EPSG:3162 / NAD83(CSRS) / Ontario MNR Lambert"},
  {coordinate_system:"EPSG:3163", label: "EPSG:3163 / RGNC91-93 / Lambert New Caledonia"},
  {coordinate_system:"EPSG:3164", label: "EPSG:3164 / ST87 Ouvea / UTM zone 58S"},
  {coordinate_system:"EPSG:3165", label: "EPSG:3165 / NEA74 Noumea / Noumea Lambert"},
  {coordinate_system:"EPSG:3166", label: "EPSG:3166 / NEA74 Noumea / Noumea Lambert 2"},
  {coordinate_system:"EPSG:3167", label: "EPSG:3167 / Kertau (RSO) / RSO Malaya (ch)"},
  {coordinate_system:"EPSG:3168", label: "EPSG:3168 / Kertau (RSO) / RSO Malaya (m)"},
  {coordinate_system:"EPSG:3169", label: "EPSG:3169 / RGNC91-93 / UTM zone 57S"},
  {coordinate_system:"EPSG:3170", label: "EPSG:3170 / RGNC91-93 / UTM zone 58S"},
  {coordinate_system:"EPSG:3171", label: "EPSG:3171 / RGNC91-93 / UTM zone 59S"},
  {coordinate_system:"EPSG:3172", label: "EPSG:3172 / IGN53 Mare / UTM zone 59S"},
  {coordinate_system:"EPSG:3173", label: "EPSG:3173 / fk89 / Faroe Lambert FK89"},
  {coordinate_system:"EPSG:3174", label: "EPSG:3174 / NAD83 / Great Lakes Albers"},
  {coordinate_system:"EPSG:3175", label: "EPSG:3175 / NAD83 / Great Lakes and St Lawrence Albers"},
  {coordinate_system:"EPSG:3176", label: "EPSG:3176 / Indian 1960 / TM 106 NE"},
  {coordinate_system:"EPSG:3177", label: "EPSG:3177 / LGD2006 / Libya TM"},
  {coordinate_system:"EPSG:3178", label: "EPSG:3178 / GR96 / UTM zone 18N"},
  {coordinate_system:"EPSG:3179", label: "EPSG:3179 / GR96 / UTM zone 19N"},
  {coordinate_system:"EPSG:3180", label: "EPSG:3180 / GR96 / UTM zone 20N"},
  {coordinate_system:"EPSG:3181", label: "EPSG:3181 / GR96 / UTM zone 21N"},
  {coordinate_system:"EPSG:3182", label: "EPSG:3182 / GR96 / UTM zone 22N"},
  {coordinate_system:"EPSG:3183", label: "EPSG:3183 / GR96 / UTM zone 23N"},
  {coordinate_system:"EPSG:3184", label: "EPSG:3184 / GR96 / UTM zone 24N"},
  {coordinate_system:"EPSG:3185", label: "EPSG:3185 / GR96 / UTM zone 25N"},
  {coordinate_system:"EPSG:3186", label: "EPSG:3186 / GR96 / UTM zone 26N"},
  {coordinate_system:"EPSG:3187", label: "EPSG:3187 / GR96 / UTM zone 27N"},
  {coordinate_system:"EPSG:3188", label: "EPSG:3188 / GR96 / UTM zone 28N"},
  {coordinate_system:"EPSG:3189", label: "EPSG:3189 / GR96 / UTM zone 29N"},
  {coordinate_system:"EPSG:3190", label: "EPSG:3190 / LGD2006 / Libya TM zone 5"},
  {coordinate_system:"EPSG:3191", label: "EPSG:3191 / LGD2006 / Libya TM zone 6"},
  {coordinate_system:"EPSG:3192", label: "EPSG:3192 / LGD2006 / Libya TM zone 7"},
  {coordinate_system:"EPSG:3193", label: "EPSG:3193 / LGD2006 / Libya TM zone 8"},
  {coordinate_system:"EPSG:3194", label: "EPSG:3194 / LGD2006 / Libya TM zone 9"},
  {coordinate_system:"EPSG:3195", label: "EPSG:3195 / LGD2006 / Libya TM zone 10"},
  {coordinate_system:"EPSG:3196", label: "EPSG:3196 / LGD2006 / Libya TM zone 11"},
  {coordinate_system:"EPSG:3197", label: "EPSG:3197 / LGD2006 / Libya TM zone 12"},
  {coordinate_system:"EPSG:3198", label: "EPSG:3198 / LGD2006 / Libya TM zone 13"},
  {coordinate_system:"EPSG:3199", label: "EPSG:3199 / LGD2006 / UTM zone 32N"},
  {coordinate_system:"EPSG:3200", label: "EPSG:3200 / FD58 / Iraq zone"},
  {coordinate_system:"EPSG:3201", label: "EPSG:3201 / LGD2006 / UTM zone 33N"},
  {coordinate_system:"EPSG:3202", label: "EPSG:3202 / LGD2006 / UTM zone 34N"},
  {coordinate_system:"EPSG:3203", label: "EPSG:3203 / LGD2006 / UTM zone 35N"},
  {coordinate_system:"EPSG:3204", label: "EPSG:3204 / WGS 84 / SCAR IMW SP19-20"},
  {coordinate_system:"EPSG:3205", label: "EPSG:3205 / WGS 84 / SCAR IMW SP21-22"},
  {coordinate_system:"EPSG:3206", label: "EPSG:3206 / WGS 84 / SCAR IMW SP23-24"},
  {coordinate_system:"EPSG:3207", label: "EPSG:3207 / WGS 84 / SCAR IMW SQ01-02"},
  {coordinate_system:"EPSG:3208", label: "EPSG:3208 / WGS 84 / SCAR IMW SQ19-20"},
  {coordinate_system:"EPSG:3209", label: "EPSG:3209 / WGS 84 / SCAR IMW SQ21-22"},
  {coordinate_system:"EPSG:3210", label: "EPSG:3210 / WGS 84 / SCAR IMW SQ37-38"},
  {coordinate_system:"EPSG:3211", label: "EPSG:3211 / WGS 84 / SCAR IMW SQ39-40"},
  {coordinate_system:"EPSG:3212", label: "EPSG:3212 / WGS 84 / SCAR IMW SQ41-42"},
  {coordinate_system:"EPSG:3213", label: "EPSG:3213 / WGS 84 / SCAR IMW SQ43-44"},
  {coordinate_system:"EPSG:3214", label: "EPSG:3214 / WGS 84 / SCAR IMW SQ45-46"},
  {coordinate_system:"EPSG:3215", label: "EPSG:3215 / WGS 84 / SCAR IMW SQ47-48"},
  {coordinate_system:"EPSG:3216", label: "EPSG:3216 / WGS 84 / SCAR IMW SQ49-50"},
  {coordinate_system:"EPSG:3217", label: "EPSG:3217 / WGS 84 / SCAR IMW SQ51-52"},
  {coordinate_system:"EPSG:3218", label: "EPSG:3218 / WGS 84 / SCAR IMW SQ53-54"},
  {coordinate_system:"EPSG:3219", label: "EPSG:3219 / WGS 84 / SCAR IMW SQ55-56"},
  {coordinate_system:"EPSG:3220", label: "EPSG:3220 / WGS 84 / SCAR IMW SQ57-58"},
  {coordinate_system:"EPSG:3221", label: "EPSG:3221 / WGS 84 / SCAR IMW SR13-14"},
  {coordinate_system:"EPSG:3222", label: "EPSG:3222 / WGS 84 / SCAR IMW SR15-16"},
  {coordinate_system:"EPSG:3223", label: "EPSG:3223 / WGS 84 / SCAR IMW SR17-18"},
  {coordinate_system:"EPSG:3224", label: "EPSG:3224 / WGS 84 / SCAR IMW SR19-20"},
  {coordinate_system:"EPSG:3225", label: "EPSG:3225 / WGS 84 / SCAR IMW SR27-28"},
  {coordinate_system:"EPSG:3226", label: "EPSG:3226 / WGS 84 / SCAR IMW SR29-30"},
  {coordinate_system:"EPSG:3227", label: "EPSG:3227 / WGS 84 / SCAR IMW SR31-32"},
  {coordinate_system:"EPSG:3228", label: "EPSG:3228 / WGS 84 / SCAR IMW SR33-34"},
  {coordinate_system:"EPSG:3229", label: "EPSG:3229 / WGS 84 / SCAR IMW SR35-36"},
  {coordinate_system:"EPSG:3230", label: "EPSG:3230 / WGS 84 / SCAR IMW SR37-38"},
  {coordinate_system:"EPSG:3231", label: "EPSG:3231 / WGS 84 / SCAR IMW SR39-40"},
  {coordinate_system:"EPSG:3232", label: "EPSG:3232 / WGS 84 / SCAR IMW SR41-42"},
  {coordinate_system:"EPSG:3233", label: "EPSG:3233 / WGS 84 / SCAR IMW SR43-44"},
  {coordinate_system:"EPSG:3234", label: "EPSG:3234 / WGS 84 / SCAR IMW SR45-46"},
  {coordinate_system:"EPSG:3235", label: "EPSG:3235 / WGS 84 / SCAR IMW SR47-48"},
  {coordinate_system:"EPSG:3236", label: "EPSG:3236 / WGS 84 / SCAR IMW SR49-50"},
  {coordinate_system:"EPSG:3237", label: "EPSG:3237 / WGS 84 / SCAR IMW SR51-52"},
  {coordinate_system:"EPSG:3238", label: "EPSG:3238 / WGS 84 / SCAR IMW SR53-54"},
  {coordinate_system:"EPSG:3239", label: "EPSG:3239 / WGS 84 / SCAR IMW SR55-56"},
  {coordinate_system:"EPSG:3240", label: "EPSG:3240 / WGS 84 / SCAR IMW SR57-58"},
  {coordinate_system:"EPSG:3241", label: "EPSG:3241 / WGS 84 / SCAR IMW SR59-60"},
  {coordinate_system:"EPSG:3242", label: "EPSG:3242 / WGS 84 / SCAR IMW SS04-06"},
  {coordinate_system:"EPSG:3243", label: "EPSG:3243 / WGS 84 / SCAR IMW SS07-09"},
  {coordinate_system:"EPSG:3244", label: "EPSG:3244 / WGS 84 / SCAR IMW SS10-12"},
  {coordinate_system:"EPSG:3245", label: "EPSG:3245 / WGS 84 / SCAR IMW SS13-15"},
  {coordinate_system:"EPSG:3246", label: "EPSG:3246 / WGS 84 / SCAR IMW SS16-18"},
  {coordinate_system:"EPSG:3247", label: "EPSG:3247 / WGS 84 / SCAR IMW SS19-21"},
  {coordinate_system:"EPSG:3248", label: "EPSG:3248 / WGS 84 / SCAR IMW SS25-27"},
  {coordinate_system:"EPSG:3249", label: "EPSG:3249 / WGS 84 / SCAR IMW SS28-30"},
  {coordinate_system:"EPSG:3250", label: "EPSG:3250 / WGS 84 / SCAR IMW SS31-33"},
  {coordinate_system:"EPSG:3251", label: "EPSG:3251 / WGS 84 / SCAR IMW SS34-36"},
  {coordinate_system:"EPSG:3252", label: "EPSG:3252 / WGS 84 / SCAR IMW SS37-39"},
  {coordinate_system:"EPSG:3253", label: "EPSG:3253 / WGS 84 / SCAR IMW SS40-42"},
  {coordinate_system:"EPSG:3254", label: "EPSG:3254 / WGS 84 / SCAR IMW SS43-45"},
  {coordinate_system:"EPSG:3255", label: "EPSG:3255 / WGS 84 / SCAR IMW SS46-48"},
  {coordinate_system:"EPSG:3256", label: "EPSG:3256 / WGS 84 / SCAR IMW SS49-51"},
  {coordinate_system:"EPSG:3257", label: "EPSG:3257 / WGS 84 / SCAR IMW SS52-54"},
  {coordinate_system:"EPSG:3258", label: "EPSG:3258 / WGS 84 / SCAR IMW SS55-57"},
  {coordinate_system:"EPSG:3259", label: "EPSG:3259 / WGS 84 / SCAR IMW SS58-60"},
  {coordinate_system:"EPSG:3260", label: "EPSG:3260 / WGS 84 / SCAR IMW ST01-04"},
  {coordinate_system:"EPSG:3261", label: "EPSG:3261 / WGS 84 / SCAR IMW ST05-08"},
  {coordinate_system:"EPSG:3262", label: "EPSG:3262 / WGS 84 / SCAR IMW ST09-12"},
  {coordinate_system:"EPSG:3263", label: "EPSG:3263 / WGS 84 / SCAR IMW ST13-16"},
  {coordinate_system:"EPSG:3264", label: "EPSG:3264 / WGS 84 / SCAR IMW ST17-20"},
  {coordinate_system:"EPSG:3265", label: "EPSG:3265 / WGS 84 / SCAR IMW ST21-24"},
  {coordinate_system:"EPSG:3266", label: "EPSG:3266 / WGS 84 / SCAR IMW ST25-28"},
  {coordinate_system:"EPSG:3267", label: "EPSG:3267 / WGS 84 / SCAR IMW ST29-32"},
  {coordinate_system:"EPSG:3268", label: "EPSG:3268 / WGS 84 / SCAR IMW ST33-36"},
  {coordinate_system:"EPSG:3269", label: "EPSG:3269 / WGS 84 / SCAR IMW ST37-40"},
  {coordinate_system:"EPSG:3270", label: "EPSG:3270 / WGS 84 / SCAR IMW ST41-44"},
  {coordinate_system:"EPSG:3271", label: "EPSG:3271 / WGS 84 / SCAR IMW ST45-48"},
  {coordinate_system:"EPSG:3272", label: "EPSG:3272 / WGS 84 / SCAR IMW ST49-52"},
  {coordinate_system:"EPSG:3273", label: "EPSG:3273 / WGS 84 / SCAR IMW ST53-56"},
  {coordinate_system:"EPSG:3274", label: "EPSG:3274 / WGS 84 / SCAR IMW ST57-60"},
  {coordinate_system:"EPSG:3275", label: "EPSG:3275 / WGS 84 / SCAR IMW SU01-05"},
  {coordinate_system:"EPSG:3276", label: "EPSG:3276 / WGS 84 / SCAR IMW SU06-10"},
  {coordinate_system:"EPSG:3277", label: "EPSG:3277 / WGS 84 / SCAR IMW SU11-15"},
  {coordinate_system:"EPSG:3278", label: "EPSG:3278 / WGS 84 / SCAR IMW SU16-20"},
  {coordinate_system:"EPSG:3279", label: "EPSG:3279 / WGS 84 / SCAR IMW SU21-25"},
  {coordinate_system:"EPSG:3280", label: "EPSG:3280 / WGS 84 / SCAR IMW SU26-30"},
  {coordinate_system:"EPSG:3281", label: "EPSG:3281 / WGS 84 / SCAR IMW SU31-35"},
  {coordinate_system:"EPSG:3282", label: "EPSG:3282 / WGS 84 / SCAR IMW SU36-40"},
  {coordinate_system:"EPSG:3283", label: "EPSG:3283 / WGS 84 / SCAR IMW SU41-45"},
  {coordinate_system:"EPSG:3284", label: "EPSG:3284 / WGS 84 / SCAR IMW SU46-50"},
  {coordinate_system:"EPSG:3285", label: "EPSG:3285 / WGS 84 / SCAR IMW SU51-55"},
  {coordinate_system:"EPSG:3286", label: "EPSG:3286 / WGS 84 / SCAR IMW SU56-60"},
  {coordinate_system:"EPSG:3287", label: "EPSG:3287 / WGS 84 / SCAR IMW SV01-10"},
  {coordinate_system:"EPSG:3288", label: "EPSG:3288 / WGS 84 / SCAR IMW SV11-20"},
  {coordinate_system:"EPSG:3289", label: "EPSG:3289 / WGS 84 / SCAR IMW SV21-30"},
  {coordinate_system:"EPSG:3290", label: "EPSG:3290 / WGS 84 / SCAR IMW SV31-40"},
  {coordinate_system:"EPSG:3291", label: "EPSG:3291 / WGS 84 / SCAR IMW SV41-50"},
  {coordinate_system:"EPSG:3292", label: "EPSG:3292 / WGS 84 / SCAR IMW SV51-60"},
  {coordinate_system:"EPSG:3293", label: "EPSG:3293 / WGS 84 / SCAR IMW SW01-60"},
  {coordinate_system:"EPSG:3294", label: "EPSG:3294 / WGS 84 / USGS Transantarctic Mountains"},
  {coordinate_system:"EPSG:3295", label: "EPSG:3295 / Guam 1963 / Yap Islands"},
  {coordinate_system:"EPSG:3296", label: "EPSG:3296 / RGPF / UTM zone 5S"},
  {coordinate_system:"EPSG:3297", label: "EPSG:3297 / RGPF / UTM zone 6S"},
  {coordinate_system:"EPSG:3298", label: "EPSG:3298 / RGPF / UTM zone 7S"},
  {coordinate_system:"EPSG:3299", label: "EPSG:3299 / RGPF / UTM zone 8S"},
  {coordinate_system:"EPSG:3300", label: "EPSG:3300 / Estonian Coordinate System of 1992"},
  {coordinate_system:"EPSG:3301", label: "EPSG:3301 / Estonian Coordinate System of 1997"},
  {coordinate_system:"EPSG:3302", label: "EPSG:3302 / IGN63 Hiva Oa / UTM zone 7S"},
  {coordinate_system:"EPSG:3303", label: "EPSG:3303 / Fatu Iva 72 / UTM zone 7S"},
  {coordinate_system:"EPSG:3304", label: "EPSG:3304 / Tahiti 79 / UTM zone 6S"},
  {coordinate_system:"EPSG:3305", label: "EPSG:3305 / Moorea 87 / UTM zone 6S"},
  {coordinate_system:"EPSG:3306", label: "EPSG:3306 / Maupiti 83 / UTM zone 5S"},
  {coordinate_system:"EPSG:3307", label: "EPSG:3307 / Nakhl-e Ghanem / UTM zone 39N"},
  {coordinate_system:"EPSG:3308", label: "EPSG:3308 / GDA94 / NSW Lambert"},
  {coordinate_system:"EPSG:3309", label: "EPSG:3309 / NAD27 / California Albers"},
  {coordinate_system:"EPSG:3310", label: "EPSG:3310 / NAD83 / California Albers"},
  {coordinate_system:"EPSG:3311", label: "EPSG:3311 / NAD83(HARN) / California Albers"},
  {coordinate_system:"EPSG:3312", label: "EPSG:3312 / CSG67 / UTM zone 21N"},
  {coordinate_system:"EPSG:3313", label: "EPSG:3313 / RGFG95 / UTM zone 21N"},
  {coordinate_system:"EPSG:3316", label: "EPSG:3316 / Kasai 1953 / Congo TM zone 22"},
  {coordinate_system:"EPSG:3317", label: "EPSG:3317 / Kasai 1953 / Congo TM zone 24"},
  {coordinate_system:"EPSG:3318", label: "EPSG:3318 / IGC 1962 / Congo TM zone 12"},
  {coordinate_system:"EPSG:3319", label: "EPSG:3319 / IGC 1962 / Congo TM zone 14"},
  {coordinate_system:"EPSG:3320", label: "EPSG:3320 / IGC 1962 / Congo TM zone 16"},
  {coordinate_system:"EPSG:3321", label: "EPSG:3321 / IGC 1962 / Congo TM zone 18"},
  {coordinate_system:"EPSG:3322", label: "EPSG:3322 / IGC 1962 / Congo TM zone 20"},
  {coordinate_system:"EPSG:3323", label: "EPSG:3323 / IGC 1962 / Congo TM zone 22"},
  {coordinate_system:"EPSG:3324", label: "EPSG:3324 / IGC 1962 / Congo TM zone 24"},
  {coordinate_system:"EPSG:3325", label: "EPSG:3325 / IGC 1962 / Congo TM zone 26"},
  {coordinate_system:"EPSG:3326", label: "EPSG:3326 / IGC 1962 / Congo TM zone 28"},
  {coordinate_system:"EPSG:3327", label: "EPSG:3327 / IGC 1962 / Congo TM zone 30"},
  {coordinate_system:"EPSG:3328", label: "EPSG:3328 / Pulkovo 1942(58) / GUGiK-80"},
  {coordinate_system:"EPSG:3329", label: "EPSG:3329 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:3330", label: "EPSG:3330 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:3331", label: "EPSG:3331 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:3332", label: "EPSG:3332 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:3333", label: "EPSG:3333 / Pulkovo 1942(58) / Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:3334", label: "EPSG:3334 / Pulkovo 1942(58) / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3335", label: "EPSG:3335 / Pulkovo 1942(58) / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:3336", label: "EPSG:3336 / IGN 1962 Kerguelen / UTM zone 42S"},
  {coordinate_system:"EPSG:3337", label: "EPSG:3337 / Le Pouce 1934 / Mauritius Grid"},
  {coordinate_system:"EPSG:3338", label: "EPSG:3338 / NAD83 / Alaska Albers"},
  {coordinate_system:"EPSG:3339", label: "EPSG:3339 / IGCB 1955 / Congo TM zone 12"},
  {coordinate_system:"EPSG:3340", label: "EPSG:3340 / IGCB 1955 / Congo TM zone 14"},
  {coordinate_system:"EPSG:3341", label: "EPSG:3341 / IGCB 1955 / Congo TM zone 16"},
  {coordinate_system:"EPSG:3342", label: "EPSG:3342 / IGCB 1955 / UTM zone 33S"},
  {coordinate_system:"EPSG:3343", label: "EPSG:3343 / Mauritania 1999 / UTM zone 28N"},
  {coordinate_system:"EPSG:3344", label: "EPSG:3344 / Mauritania 1999 / UTM zone 29N"},
  {coordinate_system:"EPSG:3345", label: "EPSG:3345 / Mauritania 1999 / UTM zone 30N"},
  {coordinate_system:"EPSG:3346", label: "EPSG:3346 / LKS94 / Lithuania TM"},
  {coordinate_system:"EPSG:3347", label: "EPSG:3347 / NAD83 / Statistics Canada Lambert"},
  {coordinate_system:"EPSG:3348", label: "EPSG:3348 / NAD83(CSRS) / Statistics Canada Lambert"},
  {coordinate_system:"EPSG:3350", label: "EPSG:3350 / Pulkovo 1942 / CS63 zone C0"},
  {coordinate_system:"EPSG:3351", label: "EPSG:3351 / Pulkovo 1942 / CS63 zone C1"},
  {coordinate_system:"EPSG:3352", label: "EPSG:3352 / Pulkovo 1942 / CS63 zone C2"},
  {coordinate_system:"EPSG:3353", label: "EPSG:3353 / Mhast (onshore) / UTM zone 32S"},
  {coordinate_system:"EPSG:3354", label: "EPSG:3354 / Mhast (offshore) / UTM zone 32S"},
  {coordinate_system:"EPSG:3355", label: "EPSG:3355 / Egypt Gulf of Suez S-650 TL / Red Belt"},
  {coordinate_system:"EPSG:3358", label: "EPSG:3358 / NAD83(HARN) / North Carolina"},
  {coordinate_system:"EPSG:3360", label: "EPSG:3360 / NAD83(HARN) / South Carolina"},
  {coordinate_system:"EPSG:3361", label: "EPSG:3361 / NAD83(HARN) / South Carolina (ft)"},
  {coordinate_system:"EPSG:3362", label: "EPSG:3362 / NAD83(HARN) / Pennsylvania North"},
  {coordinate_system:"EPSG:3363", label: "EPSG:3363 / NAD83(HARN) / Pennsylvania North (ftUS)"},
  {coordinate_system:"EPSG:3364", label: "EPSG:3364 / NAD83(HARN) / Pennsylvania South"},
  {coordinate_system:"EPSG:3365", label: "EPSG:3365 / NAD83(HARN) / Pennsylvania South (ftUS)"},
  {coordinate_system:"EPSG:3367", label: "EPSG:3367 / IGN Astro 1960 / UTM zone 28N"},
  {coordinate_system:"EPSG:3368", label: "EPSG:3368 / IGN Astro 1960 / UTM zone 29N"},
  {coordinate_system:"EPSG:3369", label: "EPSG:3369 / IGN Astro 1960 / UTM zone 30N"},
  {coordinate_system:"EPSG:3370", label: "EPSG:3370 / NAD27 / UTM zone 59N"},
  {coordinate_system:"EPSG:3371", label: "EPSG:3371 / NAD27 / UTM zone 60N"},
  {coordinate_system:"EPSG:3372", label: "EPSG:3372 / NAD83 / UTM zone 59N"},
  {coordinate_system:"EPSG:3373", label: "EPSG:3373 / NAD83 / UTM zone 60N"},
  {coordinate_system:"EPSG:3374", label: "EPSG:3374 / FD54 / UTM zone 29N"},
  {coordinate_system:"EPSG:3375", label: "EPSG:3375 / GDM2000 / Peninsula RSO"},
  {coordinate_system:"EPSG:3376", label: "EPSG:3376 / GDM2000 / East Malaysia BRSO"},
  {coordinate_system:"EPSG:3377", label: "EPSG:3377 / GDM2000 / Johor Grid"},
  {coordinate_system:"EPSG:3378", label: "EPSG:3378 / GDM2000 / Sembilan and Melaka Grid"},
  {coordinate_system:"EPSG:3379", label: "EPSG:3379 / GDM2000 / Pahang Grid"},
  {coordinate_system:"EPSG:3380", label: "EPSG:3380 / GDM2000 / Selangor Grid"},
  {coordinate_system:"EPSG:3381", label: "EPSG:3381 / GDM2000 / Terengganu Grid"},
  {coordinate_system:"EPSG:3382", label: "EPSG:3382 / GDM2000 / Pinang Grid"},
  {coordinate_system:"EPSG:3383", label: "EPSG:3383 / GDM2000 / Kedah and Perlis Grid"},
  {coordinate_system:"EPSG:3384", label: "EPSG:3384 / GDM2000 / Perak Grid"},
  {coordinate_system:"EPSG:3385", label: "EPSG:3385 / GDM2000 / Kelantan Grid"},
  {coordinate_system:"EPSG:3386", label: "EPSG:3386 / KKJ / Finland zone 0"},
  {coordinate_system:"EPSG:3387", label: "EPSG:3387 / KKJ / Finland zone 5"},
  {coordinate_system:"EPSG:3388", label: "EPSG:3388 / Pulkovo 1942 / Caspian Sea Mercator"},
  {coordinate_system:"EPSG:3389", label: "EPSG:3389 / Pulkovo 1942 / 3-degree Gauss-Kruger zone 60"},
  {coordinate_system:"EPSG:3390", label: "EPSG:3390 / Pulkovo 1995 / 3-degree Gauss-Kruger zone 60"},
  {coordinate_system:"EPSG:3391", label: "EPSG:3391 / Karbala 1979 / UTM zone 37N"},
  {coordinate_system:"EPSG:3392", label: "EPSG:3392 / Karbala 1979 / UTM zone 38N"},
  {coordinate_system:"EPSG:3393", label: "EPSG:3393 / Karbala 1979 / UTM zone 39N"},
  {coordinate_system:"EPSG:3394", label: "EPSG:3394 / Nahrwan 1934 / Iraq zone"},
  {coordinate_system:"EPSG:3395", label: "EPSG:3395 / WGS 84 / World Mercator"},
  {coordinate_system:"EPSG:3396", label: "EPSG:3396 / PD/83 / 3-degree Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:3397", label: "EPSG:3397 / PD/83 / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3398", label: "EPSG:3398 / RD/83 / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3399", label: "EPSG:3399 / RD/83 / 3-degree Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:3400", label: "EPSG:3400 / NAD83 / Alberta 10-TM (Forest)"},
  {coordinate_system:"EPSG:3401", label: "EPSG:3401 / NAD83 / Alberta 10-TM (Resource)"},
  {coordinate_system:"EPSG:3402", label: "EPSG:3402 / NAD83(CSRS) / Alberta 10-TM (Forest)"},
  {coordinate_system:"EPSG:3403", label: "EPSG:3403 / NAD83(CSRS) / Alberta 10-TM (Resource)"},
  {coordinate_system:"EPSG:3404", label: "EPSG:3404 / NAD83(HARN) / North Carolina (ftUS)"},
  {coordinate_system:"EPSG:3405", label: "EPSG:3405 / VN-2000 / UTM zone 48N"},
  {coordinate_system:"EPSG:3406", label: "EPSG:3406 / VN-2000 / UTM zone 49N"},
  {coordinate_system:"EPSG:3407", label: "EPSG:3407 / Hong Kong 1963 Grid System"},
  {coordinate_system:"EPSG:3413", label: "EPSG:3413 / WGS 84 / NSIDC Sea Ice Polar Stereographic North"},
  {coordinate_system:"EPSG:3414", label: "EPSG:3414 / SVY21 / Singapore TM"},
  {coordinate_system:"EPSG:3415", label: "EPSG:3415 / WGS 72BE / South China Sea Lambert"},
  {coordinate_system:"EPSG:3416", label: "EPSG:3416 / ETRS89 / Austria Lambert"},
  {coordinate_system:"EPSG:3417", label: "EPSG:3417 / NAD83 / Iowa North (ftUS)"},
  {coordinate_system:"EPSG:3418", label: "EPSG:3418 / NAD83 / Iowa South (ftUS)"},
  {coordinate_system:"EPSG:3419", label: "EPSG:3419 / NAD83 / Kansas North (ftUS)"},
  {coordinate_system:"EPSG:3420", label: "EPSG:3420 / NAD83 / Kansas South (ftUS)"},
  {coordinate_system:"EPSG:3421", label: "EPSG:3421 / NAD83 / Nevada East (ftUS)"},
  {coordinate_system:"EPSG:3422", label: "EPSG:3422 / NAD83 / Nevada Central (ftUS)"},
  {coordinate_system:"EPSG:3423", label: "EPSG:3423 / NAD83 / Nevada West (ftUS)"},
  {coordinate_system:"EPSG:3424", label: "EPSG:3424 / NAD83 / New Jersey (ftUS)"},
  {coordinate_system:"EPSG:3425", label: "EPSG:3425 / NAD83(HARN) / Iowa North (ftUS)"},
  {coordinate_system:"EPSG:3426", label: "EPSG:3426 / NAD83(HARN) / Iowa South (ftUS)"},
  {coordinate_system:"EPSG:3427", label: "EPSG:3427 / NAD83(HARN) / Kansas North (ftUS)"},
  {coordinate_system:"EPSG:3428", label: "EPSG:3428 / NAD83(HARN) / Kansas South (ftUS)"},
  {coordinate_system:"EPSG:3429", label: "EPSG:3429 / NAD83(HARN) / Nevada East (ftUS)"},
  {coordinate_system:"EPSG:3430", label: "EPSG:3430 / NAD83(HARN) / Nevada Central (ftUS)"},
  {coordinate_system:"EPSG:3431", label: "EPSG:3431 / NAD83(HARN) / Nevada West (ftUS)"},
  {coordinate_system:"EPSG:3432", label: "EPSG:3432 / NAD83(HARN) / New Jersey (ftUS)"},
  {coordinate_system:"EPSG:3433", label: "EPSG:3433 / NAD83 / Arkansas North (ftUS)"},
  {coordinate_system:"EPSG:3434", label: "EPSG:3434 / NAD83 / Arkansas South (ftUS)"},
  {coordinate_system:"EPSG:3435", label: "EPSG:3435 / NAD83 / Illinois East (ftUS)"},
  {coordinate_system:"EPSG:3436", label: "EPSG:3436 / NAD83 / Illinois West (ftUS)"},
  {coordinate_system:"EPSG:3437", label: "EPSG:3437 / NAD83 / New Hampshire (ftUS)"},
  {coordinate_system:"EPSG:3438", label: "EPSG:3438 / NAD83 / Rhode Island (ftUS)"},
  {coordinate_system:"EPSG:3439", label: "EPSG:3439 / PSD93 / UTM zone 39N"},
  {coordinate_system:"EPSG:3440", label: "EPSG:3440 / PSD93 / UTM zone 40N"},
  {coordinate_system:"EPSG:3441", label: "EPSG:3441 / NAD83(HARN) / Arkansas North (ftUS)"},
  {coordinate_system:"EPSG:3442", label: "EPSG:3442 / NAD83(HARN) / Arkansas South (ftUS)"},
  {coordinate_system:"EPSG:3443", label: "EPSG:3443 / NAD83(HARN) / Illinois East (ftUS)"},
  {coordinate_system:"EPSG:3444", label: "EPSG:3444 / NAD83(HARN) / Illinois West (ftUS)"},
  {coordinate_system:"EPSG:3445", label: "EPSG:3445 / NAD83(HARN) / New Hampshire (ftUS)"},
  {coordinate_system:"EPSG:3446", label: "EPSG:3446 / NAD83(HARN) / Rhode Island (ftUS)"},
  {coordinate_system:"EPSG:3447", label: "EPSG:3447 / ETRS89 / Belgian Lambert 2005"},
  {coordinate_system:"EPSG:3448", label: "EPSG:3448 / JAD2001 / Jamaica Metric Grid"},
  {coordinate_system:"EPSG:3449", label: "EPSG:3449 / JAD2001 / UTM zone 17N"},
  {coordinate_system:"EPSG:3450", label: "EPSG:3450 / JAD2001 / UTM zone 18N"},
  {coordinate_system:"EPSG:3451", label: "EPSG:3451 / NAD83 / Louisiana North (ftUS)"},
  {coordinate_system:"EPSG:3452", label: "EPSG:3452 / NAD83 / Louisiana South (ftUS)"},
  {coordinate_system:"EPSG:3453", label: "EPSG:3453 / NAD83 / Louisiana Offshore (ftUS)"},
  {coordinate_system:"EPSG:3455", label: "EPSG:3455 / NAD83 / South Dakota South (ftUS)"},
  {coordinate_system:"EPSG:3456", label: "EPSG:3456 / NAD83(HARN) / Louisiana North (ftUS)"},
  {coordinate_system:"EPSG:3457", label: "EPSG:3457 / NAD83(HARN) / Louisiana South (ftUS)"},
  {coordinate_system:"EPSG:3458", label: "EPSG:3458 / NAD83(HARN) / South Dakota North (ftUS)"},
  {coordinate_system:"EPSG:3459", label: "EPSG:3459 / NAD83(HARN) / South Dakota South (ftUS)"},
  {coordinate_system:"EPSG:3460", label: "EPSG:3460 / Fiji 1986 / Fiji Map Grid"},
  {coordinate_system:"EPSG:3461", label: "EPSG:3461 / Dabola 1981 / UTM zone 28N"},
  {coordinate_system:"EPSG:3462", label: "EPSG:3462 / Dabola 1981 / UTM zone 29N"},
  {coordinate_system:"EPSG:3463", label: "EPSG:3463 / NAD83 / Maine CS2000 Central"},
  {coordinate_system:"EPSG:3464", label: "EPSG:3464 / NAD83(HARN) / Maine CS2000 Central"},
  {coordinate_system:"EPSG:3465", label: "EPSG:3465 / NAD83(NSRS2007) / Alabama East"},
  {coordinate_system:"EPSG:3466", label: "EPSG:3466 / NAD83(NSRS2007) / Alabama West"},
  {coordinate_system:"EPSG:3467", label: "EPSG:3467 / NAD83(NSRS2007) / Alaska Albers"},
  {coordinate_system:"EPSG:3468", label: "EPSG:3468 / NAD83(NSRS2007) / Alaska zone 1"},
  {coordinate_system:"EPSG:3469", label: "EPSG:3469 / NAD83(NSRS2007) / Alaska zone 2"},
  {coordinate_system:"EPSG:3470", label: "EPSG:3470 / NAD83(NSRS2007) / Alaska zone 3"},
  {coordinate_system:"EPSG:3471", label: "EPSG:3471 / NAD83(NSRS2007) / Alaska zone 4"},
  {coordinate_system:"EPSG:3472", label: "EPSG:3472 / NAD83(NSRS2007) / Alaska zone 5"},
  {coordinate_system:"EPSG:3473", label: "EPSG:3473 / NAD83(NSRS2007) / Alaska zone 6"},
  {coordinate_system:"EPSG:3474", label: "EPSG:3474 / NAD83(NSRS2007) / Alaska zone 7"},
  {coordinate_system:"EPSG:3475", label: "EPSG:3475 / NAD83(NSRS2007) / Alaska zone 8"},
  {coordinate_system:"EPSG:3476", label: "EPSG:3476 / NAD83(NSRS2007) / Alaska zone 9"},
  {coordinate_system:"EPSG:3477", label: "EPSG:3477 / NAD83(NSRS2007) / Alaska zone 10"},
  {coordinate_system:"EPSG:3478", label: "EPSG:3478 / NAD83(NSRS2007) / Arizona Central"},
  {coordinate_system:"EPSG:3479", label: "EPSG:3479 / NAD83(NSRS2007) / Arizona Central (ft)"},
  {coordinate_system:"EPSG:3480", label: "EPSG:3480 / NAD83(NSRS2007) / Arizona East"},
  {coordinate_system:"EPSG:3481", label: "EPSG:3481 / NAD83(NSRS2007) / Arizona East (ft)"},
  {coordinate_system:"EPSG:3482", label: "EPSG:3482 / NAD83(NSRS2007) / Arizona West"},
  {coordinate_system:"EPSG:3483", label: "EPSG:3483 / NAD83(NSRS2007) / Arizona West (ft)"},
  {coordinate_system:"EPSG:3484", label: "EPSG:3484 / NAD83(NSRS2007) / Arkansas North"},
  {coordinate_system:"EPSG:3485", label: "EPSG:3485 / NAD83(NSRS2007) / Arkansas North (ftUS)"},
  {coordinate_system:"EPSG:3486", label: "EPSG:3486 / NAD83(NSRS2007) / Arkansas South"},
  {coordinate_system:"EPSG:3487", label: "EPSG:3487 / NAD83(NSRS2007) / Arkansas South (ftUS)"},
  {coordinate_system:"EPSG:3488", label: "EPSG:3488 / NAD83(NSRS2007) / California Albers"},
  {coordinate_system:"EPSG:3489", label: "EPSG:3489 / NAD83(NSRS2007) / California zone 1"},
  {coordinate_system:"EPSG:3490", label: "EPSG:3490 / NAD83(NSRS2007) / California zone 1 (ftUS)"},
  {coordinate_system:"EPSG:3491", label: "EPSG:3491 / NAD83(NSRS2007) / California zone 2"},
  {coordinate_system:"EPSG:3492", label: "EPSG:3492 / NAD83(NSRS2007) / California zone 2 (ftUS)"},
  {coordinate_system:"EPSG:3493", label: "EPSG:3493 / NAD83(NSRS2007) / California zone 3"},
  {coordinate_system:"EPSG:3494", label: "EPSG:3494 / NAD83(NSRS2007) / California zone 3 (ftUS)"},
  {coordinate_system:"EPSG:3495", label: "EPSG:3495 / NAD83(NSRS2007) / California zone 4"},
  {coordinate_system:"EPSG:3496", label: "EPSG:3496 / NAD83(NSRS2007) / California zone 4 (ftUS)"},
  {coordinate_system:"EPSG:3497", label: "EPSG:3497 / NAD83(NSRS2007) / California zone 5"},
  {coordinate_system:"EPSG:3498", label: "EPSG:3498 / NAD83(NSRS2007) / California zone 5 (ftUS)"},
  {coordinate_system:"EPSG:3499", label: "EPSG:3499 / NAD83(NSRS2007) / California zone 6"},
  {coordinate_system:"EPSG:3500", label: "EPSG:3500 / NAD83(NSRS2007) / California zone 6 (ftUS)"},
  {coordinate_system:"EPSG:3501", label: "EPSG:3501 / NAD83(NSRS2007) / Colorado Central"},
  {coordinate_system:"EPSG:3502", label: "EPSG:3502 / NAD83(NSRS2007) / Colorado Central (ftUS)"},
  {coordinate_system:"EPSG:3503", label: "EPSG:3503 / NAD83(NSRS2007) / Colorado North"},
  {coordinate_system:"EPSG:3504", label: "EPSG:3504 / NAD83(NSRS2007) / Colorado North (ftUS)"},
  {coordinate_system:"EPSG:3505", label: "EPSG:3505 / NAD83(NSRS2007) / Colorado South"},
  {coordinate_system:"EPSG:3506", label: "EPSG:3506 / NAD83(NSRS2007) / Colorado South (ftUS)"},
  {coordinate_system:"EPSG:3507", label: "EPSG:3507 / NAD83(NSRS2007) / Connecticut"},
  {coordinate_system:"EPSG:3508", label: "EPSG:3508 / NAD83(NSRS2007) / Connecticut (ftUS)"},
  {coordinate_system:"EPSG:3509", label: "EPSG:3509 / NAD83(NSRS2007) / Delaware"},
  {coordinate_system:"EPSG:3510", label: "EPSG:3510 / NAD83(NSRS2007) / Delaware (ftUS)"},
  {coordinate_system:"EPSG:3511", label: "EPSG:3511 / NAD83(NSRS2007) / Florida East"},
  {coordinate_system:"EPSG:3512", label: "EPSG:3512 / NAD83(NSRS2007) / Florida East (ftUS)"},
  {coordinate_system:"EPSG:3513", label: "EPSG:3513 / NAD83(NSRS2007) / Florida GDL Albers"},
  {coordinate_system:"EPSG:3514", label: "EPSG:3514 / NAD83(NSRS2007) / Florida North"},
  {coordinate_system:"EPSG:3515", label: "EPSG:3515 / NAD83(NSRS2007) / Florida North (ftUS)"},
  {coordinate_system:"EPSG:3516", label: "EPSG:3516 / NAD83(NSRS2007) / Florida West"},
  {coordinate_system:"EPSG:3517", label: "EPSG:3517 / NAD83(NSRS2007) / Florida West (ftUS)"},
  {coordinate_system:"EPSG:3518", label: "EPSG:3518 / NAD83(NSRS2007) / Georgia East"},
  {coordinate_system:"EPSG:3519", label: "EPSG:3519 / NAD83(NSRS2007) / Georgia East (ftUS)"},
  {coordinate_system:"EPSG:3520", label: "EPSG:3520 / NAD83(NSRS2007) / Georgia West"},
  {coordinate_system:"EPSG:3521", label: "EPSG:3521 / NAD83(NSRS2007) / Georgia West (ftUS)"},
  {coordinate_system:"EPSG:3522", label: "EPSG:3522 / NAD83(NSRS2007) / Idaho Central"},
  {coordinate_system:"EPSG:3523", label: "EPSG:3523 / NAD83(NSRS2007) / Idaho Central (ftUS)"},
  {coordinate_system:"EPSG:3524", label: "EPSG:3524 / NAD83(NSRS2007) / Idaho East"},
  {coordinate_system:"EPSG:3525", label: "EPSG:3525 / NAD83(NSRS2007) / Idaho East (ftUS)"},
  {coordinate_system:"EPSG:3526", label: "EPSG:3526 / NAD83(NSRS2007) / Idaho West"},
  {coordinate_system:"EPSG:3527", label: "EPSG:3527 / NAD83(NSRS2007) / Idaho West (ftUS)"},
  {coordinate_system:"EPSG:3528", label: "EPSG:3528 / NAD83(NSRS2007) / Illinois East"},
  {coordinate_system:"EPSG:3529", label: "EPSG:3529 / NAD83(NSRS2007) / Illinois East (ftUS)"},
  {coordinate_system:"EPSG:3530", label: "EPSG:3530 / NAD83(NSRS2007) / Illinois West"},
  {coordinate_system:"EPSG:3531", label: "EPSG:3531 / NAD83(NSRS2007) / Illinois West (ftUS)"},
  {coordinate_system:"EPSG:3532", label: "EPSG:3532 / NAD83(NSRS2007) / Indiana East"},
  {coordinate_system:"EPSG:3533", label: "EPSG:3533 / NAD83(NSRS2007) / Indiana East (ftUS)"},
  {coordinate_system:"EPSG:3534", label: "EPSG:3534 / NAD83(NSRS2007) / Indiana West"},
  {coordinate_system:"EPSG:3535", label: "EPSG:3535 / NAD83(NSRS2007) / Indiana West (ftUS)"},
  {coordinate_system:"EPSG:3536", label: "EPSG:3536 / NAD83(NSRS2007) / Iowa North"},
  {coordinate_system:"EPSG:3537", label: "EPSG:3537 / NAD83(NSRS2007) / Iowa North (ftUS)"},
  {coordinate_system:"EPSG:3538", label: "EPSG:3538 / NAD83(NSRS2007) / Iowa South"},
  {coordinate_system:"EPSG:3539", label: "EPSG:3539 / NAD83(NSRS2007) / Iowa South (ftUS)"},
  {coordinate_system:"EPSG:3540", label: "EPSG:3540 / NAD83(NSRS2007) / Kansas North"},
  {coordinate_system:"EPSG:3541", label: "EPSG:3541 / NAD83(NSRS2007) / Kansas North (ftUS)"},
  {coordinate_system:"EPSG:3542", label: "EPSG:3542 / NAD83(NSRS2007) / Kansas South"},
  {coordinate_system:"EPSG:3543", label: "EPSG:3543 / NAD83(NSRS2007) / Kansas South (ftUS)"},
  {coordinate_system:"EPSG:3544", label: "EPSG:3544 / NAD83(NSRS2007) / Kentucky North"},
  {coordinate_system:"EPSG:3545", label: "EPSG:3545 / NAD83(NSRS2007) / Kentucky North (ftUS)"},
  {coordinate_system:"EPSG:3546", label: "EPSG:3546 / NAD83(NSRS2007) / Kentucky Single Zone"},
  {coordinate_system:"EPSG:3547", label: "EPSG:3547 / NAD83(NSRS2007) / Kentucky Single Zone (ftUS)"},
  {coordinate_system:"EPSG:3548", label: "EPSG:3548 / NAD83(NSRS2007) / Kentucky South"},
  {coordinate_system:"EPSG:3549", label: "EPSG:3549 / NAD83(NSRS2007) / Kentucky South (ftUS)"},
  {coordinate_system:"EPSG:3550", label: "EPSG:3550 / NAD83(NSRS2007) / Louisiana North"},
  {coordinate_system:"EPSG:3551", label: "EPSG:3551 / NAD83(NSRS2007) / Louisiana North (ftUS)"},
  {coordinate_system:"EPSG:3552", label: "EPSG:3552 / NAD83(NSRS2007) / Louisiana South"},
  {coordinate_system:"EPSG:3553", label: "EPSG:3553 / NAD83(NSRS2007) / Louisiana South (ftUS)"},
  {coordinate_system:"EPSG:3554", label: "EPSG:3554 / NAD83(NSRS2007) / Maine CS2000 Central"},
  {coordinate_system:"EPSG:3555", label: "EPSG:3555 / NAD83(NSRS2007) / Maine CS2000 East"},
  {coordinate_system:"EPSG:3556", label: "EPSG:3556 / NAD83(NSRS2007) / Maine CS2000 West"},
  {coordinate_system:"EPSG:3557", label: "EPSG:3557 / NAD83(NSRS2007) / Maine East"},
  {coordinate_system:"EPSG:3558", label: "EPSG:3558 / NAD83(NSRS2007) / Maine West"},
  {coordinate_system:"EPSG:3559", label: "EPSG:3559 / NAD83(NSRS2007) / Maryland"},
  {coordinate_system:"EPSG:3560", label: "EPSG:3560 / NAD83 / Utah North (ftUS)"},
  {coordinate_system:"EPSG:3561", label: "EPSG:3561 / Old Hawaiian / Hawaii zone 1"},
  {coordinate_system:"EPSG:3562", label: "EPSG:3562 / Old Hawaiian / Hawaii zone 2"},
  {coordinate_system:"EPSG:3563", label: "EPSG:3563 / Old Hawaiian / Hawaii zone 3"},
  {coordinate_system:"EPSG:3564", label: "EPSG:3564 / Old Hawaiian / Hawaii zone 4"},
  {coordinate_system:"EPSG:3565", label: "EPSG:3565 / Old Hawaiian / Hawaii zone 5"},
  {coordinate_system:"EPSG:3566", label: "EPSG:3566 / NAD83 / Utah Central (ftUS)"},
  {coordinate_system:"EPSG:3567", label: "EPSG:3567 / NAD83 / Utah South (ftUS)"},
  {coordinate_system:"EPSG:3568", label: "EPSG:3568 / NAD83(HARN) / Utah North (ftUS)"},
  {coordinate_system:"EPSG:3569", label: "EPSG:3569 / NAD83(HARN) / Utah Central (ftUS)"},
  {coordinate_system:"EPSG:3570", label: "EPSG:3570 / NAD83(HARN) / Utah South (ftUS)"},
  {coordinate_system:"EPSG:3571", label: "EPSG:3571 / WGS 84 / North Pole LAEA Bering Sea"},
  {coordinate_system:"EPSG:3572", label: "EPSG:3572 / WGS 84 / North Pole LAEA Alaska"},
  {coordinate_system:"EPSG:3573", label: "EPSG:3573 / WGS 84 / North Pole LAEA Canada"},
  {coordinate_system:"EPSG:3574", label: "EPSG:3574 / WGS 84 / North Pole LAEA Atlantic"},
  {coordinate_system:"EPSG:3575", label: "EPSG:3575 / WGS 84 / North Pole LAEA Europe"},
  {coordinate_system:"EPSG:3576", label: "EPSG:3576 / WGS 84 / North Pole LAEA Russia"},
  {coordinate_system:"EPSG:3577", label: "EPSG:3577 / GDA94 / Australian Albers"},
  {coordinate_system:"EPSG:3578", label: "EPSG:3578 / NAD83 / Yukon Albers"},
  {coordinate_system:"EPSG:3579", label: "EPSG:3579 / NAD83(CSRS) / Yukon Albers"},
  {coordinate_system:"EPSG:3580", label: "EPSG:3580 / NAD83 / NWT Lambert"},
  {coordinate_system:"EPSG:3581", label: "EPSG:3581 / NAD83(CSRS) / NWT Lambert"},
  {coordinate_system:"EPSG:3582", label: "EPSG:3582 / NAD83(NSRS2007) / Maryland (ftUS)"},
  {coordinate_system:"EPSG:3583", label: "EPSG:3583 / NAD83(NSRS2007) / Massachusetts Island"},
  {coordinate_system:"EPSG:3584", label: "EPSG:3584 / NAD83(NSRS2007) / Massachusetts Island (ftUS)"},
  {coordinate_system:"EPSG:3585", label: "EPSG:3585 / NAD83(NSRS2007) / Massachusetts Mainland"},
  {coordinate_system:"EPSG:3586", label: "EPSG:3586 / NAD83(NSRS2007) / Massachusetts Mainland (ftUS)"},
  {coordinate_system:"EPSG:3587", label: "EPSG:3587 / NAD83(NSRS2007) / Michigan Central"},
  {coordinate_system:"EPSG:3588", label: "EPSG:3588 / NAD83(NSRS2007) / Michigan Central (ft)"},
  {coordinate_system:"EPSG:3589", label: "EPSG:3589 / NAD83(NSRS2007) / Michigan North"},
  {coordinate_system:"EPSG:3590", label: "EPSG:3590 / NAD83(NSRS2007) / Michigan North (ft)"},
  {coordinate_system:"EPSG:3591", label: "EPSG:3591 / NAD83(NSRS2007) / Michigan Oblique Mercator"},
  {coordinate_system:"EPSG:3592", label: "EPSG:3592 / NAD83(NSRS2007) / Michigan South"},
  {coordinate_system:"EPSG:3593", label: "EPSG:3593 / NAD83(NSRS2007) / Michigan South (ft)"},
  {coordinate_system:"EPSG:3594", label: "EPSG:3594 / NAD83(NSRS2007) / Minnesota Central"},
  {coordinate_system:"EPSG:3595", label: "EPSG:3595 / NAD83(NSRS2007) / Minnesota North"},
  {coordinate_system:"EPSG:3596", label: "EPSG:3596 / NAD83(NSRS2007) / Minnesota South"},
  {coordinate_system:"EPSG:3597", label: "EPSG:3597 / NAD83(NSRS2007) / Mississippi East"},
  {coordinate_system:"EPSG:3598", label: "EPSG:3598 / NAD83(NSRS2007) / Mississippi East (ftUS)"},
  {coordinate_system:"EPSG:3599", label: "EPSG:3599 / NAD83(NSRS2007) / Mississippi West"},
  {coordinate_system:"EPSG:3600", label: "EPSG:3600 / NAD83(NSRS2007) / Mississippi West (ftUS)"},
  {coordinate_system:"EPSG:3601", label: "EPSG:3601 / NAD83(NSRS2007) / Missouri Central"},
  {coordinate_system:"EPSG:3602", label: "EPSG:3602 / NAD83(NSRS2007) / Missouri East"},
  {coordinate_system:"EPSG:3603", label: "EPSG:3603 / NAD83(NSRS2007) / Missouri West"},
  {coordinate_system:"EPSG:3604", label: "EPSG:3604 / NAD83(NSRS2007) / Montana"},
  {coordinate_system:"EPSG:3605", label: "EPSG:3605 / NAD83(NSRS2007) / Montana (ft)"},
  {coordinate_system:"EPSG:3606", label: "EPSG:3606 / NAD83(NSRS2007) / Nebraska"},
  {coordinate_system:"EPSG:3607", label: "EPSG:3607 / NAD83(NSRS2007) / Nevada Central"},
  {coordinate_system:"EPSG:3608", label: "EPSG:3608 / NAD83(NSRS2007) / Nevada Central (ftUS)"},
  {coordinate_system:"EPSG:3609", label: "EPSG:3609 / NAD83(NSRS2007) / Nevada East"},
  {coordinate_system:"EPSG:3610", label: "EPSG:3610 / NAD83(NSRS2007) / Nevada East (ftUS)"},
  {coordinate_system:"EPSG:3611", label: "EPSG:3611 / NAD83(NSRS2007) / Nevada West"},
  {coordinate_system:"EPSG:3612", label: "EPSG:3612 / NAD83(NSRS2007) / Nevada West (ftUS)"},
  {coordinate_system:"EPSG:3613", label: "EPSG:3613 / NAD83(NSRS2007) / New Hampshire"},
  {coordinate_system:"EPSG:3614", label: "EPSG:3614 / NAD83(NSRS2007) / New Hampshire (ftUS)"},
  {coordinate_system:"EPSG:3615", label: "EPSG:3615 / NAD83(NSRS2007) / New Jersey"},
  {coordinate_system:"EPSG:3616", label: "EPSG:3616 / NAD83(NSRS2007) / New Jersey (ftUS)"},
  {coordinate_system:"EPSG:3617", label: "EPSG:3617 / NAD83(NSRS2007) / New Mexico Central"},
  {coordinate_system:"EPSG:3618", label: "EPSG:3618 / NAD83(NSRS2007) / New Mexico Central (ftUS)"},
  {coordinate_system:"EPSG:3619", label: "EPSG:3619 / NAD83(NSRS2007) / New Mexico East"},
  {coordinate_system:"EPSG:3620", label: "EPSG:3620 / NAD83(NSRS2007) / New Mexico East (ftUS)"},
  {coordinate_system:"EPSG:3621", label: "EPSG:3621 / NAD83(NSRS2007) / New Mexico West"},
  {coordinate_system:"EPSG:3622", label: "EPSG:3622 / NAD83(NSRS2007) / New Mexico West (ftUS)"},
  {coordinate_system:"EPSG:3623", label: "EPSG:3623 / NAD83(NSRS2007) / New York Central"},
  {coordinate_system:"EPSG:3624", label: "EPSG:3624 / NAD83(NSRS2007) / New York Central (ftUS)"},
  {coordinate_system:"EPSG:3625", label: "EPSG:3625 / NAD83(NSRS2007) / New York East"},
  {coordinate_system:"EPSG:3626", label: "EPSG:3626 / NAD83(NSRS2007) / New York East (ftUS)"},
  {coordinate_system:"EPSG:3627", label: "EPSG:3627 / NAD83(NSRS2007) / New York Long Island"},
  {coordinate_system:"EPSG:3628", label: "EPSG:3628 / NAD83(NSRS2007) / New York Long Island (ftUS)"},
  {coordinate_system:"EPSG:3629", label: "EPSG:3629 / NAD83(NSRS2007) / New York West"},
  {coordinate_system:"EPSG:3630", label: "EPSG:3630 / NAD83(NSRS2007) / New York West (ftUS)"},
  {coordinate_system:"EPSG:3631", label: "EPSG:3631 / NAD83(NSRS2007) / North Carolina"},
  {coordinate_system:"EPSG:3632", label: "EPSG:3632 / NAD83(NSRS2007) / North Carolina (ftUS)"},
  {coordinate_system:"EPSG:3633", label: "EPSG:3633 / NAD83(NSRS2007) / North Dakota North"},
  {coordinate_system:"EPSG:3634", label: "EPSG:3634 / NAD83(NSRS2007) / North Dakota North (ft)"},
  {coordinate_system:"EPSG:3635", label: "EPSG:3635 / NAD83(NSRS2007) / North Dakota South"},
  {coordinate_system:"EPSG:3636", label: "EPSG:3636 / NAD83(NSRS2007) / North Dakota South (ft)"},
  {coordinate_system:"EPSG:3637", label: "EPSG:3637 / NAD83(NSRS2007) / Ohio North"},
  {coordinate_system:"EPSG:3638", label: "EPSG:3638 / NAD83(NSRS2007) / Ohio South"},
  {coordinate_system:"EPSG:3639", label: "EPSG:3639 / NAD83(NSRS2007) / Oklahoma North"},
  {coordinate_system:"EPSG:3640", label: "EPSG:3640 / NAD83(NSRS2007) / Oklahoma North (ftUS)"},
  {coordinate_system:"EPSG:3641", label: "EPSG:3641 / NAD83(NSRS2007) / Oklahoma South"},
  {coordinate_system:"EPSG:3642", label: "EPSG:3642 / NAD83(NSRS2007) / Oklahoma South (ftUS)"},
  {coordinate_system:"EPSG:3643", label: "EPSG:3643 / NAD83(NSRS2007) / Oregon LCC (m)"},
  {coordinate_system:"EPSG:3644", label: "EPSG:3644 / NAD83(NSRS2007) / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:3645", label: "EPSG:3645 / NAD83(NSRS2007) / Oregon North"},
  {coordinate_system:"EPSG:3646", label: "EPSG:3646 / NAD83(NSRS2007) / Oregon North (ft)"},
  {coordinate_system:"EPSG:3647", label: "EPSG:3647 / NAD83(NSRS2007) / Oregon South"},
  {coordinate_system:"EPSG:3648", label: "EPSG:3648 / NAD83(NSRS2007) / Oregon South (ft)"},
  {coordinate_system:"EPSG:3649", label: "EPSG:3649 / NAD83(NSRS2007) / Pennsylvania North"},
  {coordinate_system:"EPSG:3650", label: "EPSG:3650 / NAD83(NSRS2007) / Pennsylvania North (ftUS)"},
  {coordinate_system:"EPSG:3651", label: "EPSG:3651 / NAD83(NSRS2007) / Pennsylvania South"},
  {coordinate_system:"EPSG:3652", label: "EPSG:3652 / NAD83(NSRS2007) / Pennsylvania South (ftUS)"},
  {coordinate_system:"EPSG:3653", label: "EPSG:3653 / NAD83(NSRS2007) / Rhode Island"},
  {coordinate_system:"EPSG:3654", label: "EPSG:3654 / NAD83(NSRS2007) / Rhode Island (ftUS)"},
  {coordinate_system:"EPSG:3655", label: "EPSG:3655 / NAD83(NSRS2007) / South Carolina"},
  {coordinate_system:"EPSG:3656", label: "EPSG:3656 / NAD83(NSRS2007) / South Carolina (ft)"},
  {coordinate_system:"EPSG:3657", label: "EPSG:3657 / NAD83(NSRS2007) / South Dakota North"},
  {coordinate_system:"EPSG:3658", label: "EPSG:3658 / NAD83(NSRS2007) / South Dakota North (ftUS)"},
  {coordinate_system:"EPSG:3659", label: "EPSG:3659 / NAD83(NSRS2007) / South Dakota South"},
  {coordinate_system:"EPSG:3660", label: "EPSG:3660 / NAD83(NSRS2007) / South Dakota South (ftUS)"},
  {coordinate_system:"EPSG:3661", label: "EPSG:3661 / NAD83(NSRS2007) / Tennessee"},
  {coordinate_system:"EPSG:3662", label: "EPSG:3662 / NAD83(NSRS2007) / Tennessee (ftUS)"},
  {coordinate_system:"EPSG:3663", label: "EPSG:3663 / NAD83(NSRS2007) / Texas Central"},
  {coordinate_system:"EPSG:3664", label: "EPSG:3664 / NAD83(NSRS2007) / Texas Central (ftUS)"},
  {coordinate_system:"EPSG:3665", label: "EPSG:3665 / NAD83(NSRS2007) / Texas Centric Albers Equal Area"},
  {coordinate_system:"EPSG:3666", label: "EPSG:3666 / NAD83(NSRS2007) / Texas Centric Lambert Conformal"},
  {coordinate_system:"EPSG:3667", label: "EPSG:3667 / NAD83(NSRS2007) / Texas North"},
  {coordinate_system:"EPSG:3668", label: "EPSG:3668 / NAD83(NSRS2007) / Texas North (ftUS)"},
  {coordinate_system:"EPSG:3669", label: "EPSG:3669 / NAD83(NSRS2007) / Texas North Central"},
  {coordinate_system:"EPSG:3670", label: "EPSG:3670 / NAD83(NSRS2007) / Texas North Central (ftUS)"},
  {coordinate_system:"EPSG:3671", label: "EPSG:3671 / NAD83(NSRS2007) / Texas South"},
  {coordinate_system:"EPSG:3672", label: "EPSG:3672 / NAD83(NSRS2007) / Texas South (ftUS)"},
  {coordinate_system:"EPSG:3673", label: "EPSG:3673 / NAD83(NSRS2007) / Texas South Central"},
  {coordinate_system:"EPSG:3674", label: "EPSG:3674 / NAD83(NSRS2007) / Texas South Central (ftUS)"},
  {coordinate_system:"EPSG:3675", label: "EPSG:3675 / NAD83(NSRS2007) / Utah Central"},
  {coordinate_system:"EPSG:3676", label: "EPSG:3676 / NAD83(NSRS2007) / Utah Central (ft)"},
  {coordinate_system:"EPSG:3677", label: "EPSG:3677 / NAD83(NSRS2007) / Utah Central (ftUS)"},
  {coordinate_system:"EPSG:3678", label: "EPSG:3678 / NAD83(NSRS2007) / Utah North"},
  {coordinate_system:"EPSG:3679", label: "EPSG:3679 / NAD83(NSRS2007) / Utah North (ft)"},
  {coordinate_system:"EPSG:3680", label: "EPSG:3680 / NAD83(NSRS2007) / Utah North (ftUS)"},
  {coordinate_system:"EPSG:3681", label: "EPSG:3681 / NAD83(NSRS2007) / Utah South"},
  {coordinate_system:"EPSG:3682", label: "EPSG:3682 / NAD83(NSRS2007) / Utah South (ft)"},
  {coordinate_system:"EPSG:3683", label: "EPSG:3683 / NAD83(NSRS2007) / Utah South (ftUS)"},
  {coordinate_system:"EPSG:3684", label: "EPSG:3684 / NAD83(NSRS2007) / Vermont"},
  {coordinate_system:"EPSG:3685", label: "EPSG:3685 / NAD83(NSRS2007) / Virginia North"},
  {coordinate_system:"EPSG:3686", label: "EPSG:3686 / NAD83(NSRS2007) / Virginia North (ftUS)"},
  {coordinate_system:"EPSG:3687", label: "EPSG:3687 / NAD83(NSRS2007) / Virginia South"},
  {coordinate_system:"EPSG:3688", label: "EPSG:3688 / NAD83(NSRS2007) / Virginia South (ftUS)"},
  {coordinate_system:"EPSG:3689", label: "EPSG:3689 / NAD83(NSRS2007) / Washington North"},
  {coordinate_system:"EPSG:3690", label: "EPSG:3690 / NAD83(NSRS2007) / Washington North (ftUS)"},
  {coordinate_system:"EPSG:3691", label: "EPSG:3691 / NAD83(NSRS2007) / Washington South"},
  {coordinate_system:"EPSG:3692", label: "EPSG:3692 / NAD83(NSRS2007) / Washington South (ftUS)"},
  {coordinate_system:"EPSG:3693", label: "EPSG:3693 / NAD83(NSRS2007) / West Virginia North"},
  {coordinate_system:"EPSG:3694", label: "EPSG:3694 / NAD83(NSRS2007) / West Virginia South"},
  {coordinate_system:"EPSG:3695", label: "EPSG:3695 / NAD83(NSRS2007) / Wisconsin Central"},
  {coordinate_system:"EPSG:3696", label: "EPSG:3696 / NAD83(NSRS2007) / Wisconsin Central (ftUS)"},
  {coordinate_system:"EPSG:3697", label: "EPSG:3697 / NAD83(NSRS2007) / Wisconsin North"},
  {coordinate_system:"EPSG:3698", label: "EPSG:3698 / NAD83(NSRS2007) / Wisconsin North (ftUS)"},
  {coordinate_system:"EPSG:3699", label: "EPSG:3699 / NAD83(NSRS2007) / Wisconsin South"},
  {coordinate_system:"EPSG:3700", label: "EPSG:3700 / NAD83(NSRS2007) / Wisconsin South (ftUS)"},
  {coordinate_system:"EPSG:3701", label: "EPSG:3701 / NAD83(NSRS2007) / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:3702", label: "EPSG:3702 / NAD83(NSRS2007) / Wyoming East"},
  {coordinate_system:"EPSG:3703", label: "EPSG:3703 / NAD83(NSRS2007) / Wyoming East Central"},
  {coordinate_system:"EPSG:3704", label: "EPSG:3704 / NAD83(NSRS2007) / Wyoming West Central"},
  {coordinate_system:"EPSG:3705", label: "EPSG:3705 / NAD83(NSRS2007) / Wyoming West"},
  {coordinate_system:"EPSG:3706", label: "EPSG:3706 / NAD83(NSRS2007) / UTM zone 59N"},
  {coordinate_system:"EPSG:3707", label: "EPSG:3707 / NAD83(NSRS2007) / UTM zone 60N"},
  {coordinate_system:"EPSG:3708", label: "EPSG:3708 / NAD83(NSRS2007) / UTM zone 1N"},
  {coordinate_system:"EPSG:3709", label: "EPSG:3709 / NAD83(NSRS2007) / UTM zone 2N"},
  {coordinate_system:"EPSG:3710", label: "EPSG:3710 / NAD83(NSRS2007) / UTM zone 3N"},
  {coordinate_system:"EPSG:3711", label: "EPSG:3711 / NAD83(NSRS2007) / UTM zone 4N"},
  {coordinate_system:"EPSG:3712", label: "EPSG:3712 / NAD83(NSRS2007) / UTM zone 5N"},
  {coordinate_system:"EPSG:3713", label: "EPSG:3713 / NAD83(NSRS2007) / UTM zone 6N"},
  {coordinate_system:"EPSG:3714", label: "EPSG:3714 / NAD83(NSRS2007) / UTM zone 7N"},
  {coordinate_system:"EPSG:3715", label: "EPSG:3715 / NAD83(NSRS2007) / UTM zone 8N"},
  {coordinate_system:"EPSG:3716", label: "EPSG:3716 / NAD83(NSRS2007) / UTM zone 9N"},
  {coordinate_system:"EPSG:3717", label: "EPSG:3717 / NAD83(NSRS2007) / UTM zone 10N"},
  {coordinate_system:"EPSG:3718", label: "EPSG:3718 / NAD83(NSRS2007) / UTM zone 11N"},
  {coordinate_system:"EPSG:3719", label: "EPSG:3719 / NAD83(NSRS2007) / UTM zone 12N"},
  {coordinate_system:"EPSG:3720", label: "EPSG:3720 / NAD83(NSRS2007) / UTM zone 13N"},
  {coordinate_system:"EPSG:3721", label: "EPSG:3721 / NAD83(NSRS2007) / UTM zone 14N"},
  {coordinate_system:"EPSG:3722", label: "EPSG:3722 / NAD83(NSRS2007) / UTM zone 15N"},
  {coordinate_system:"EPSG:3723", label: "EPSG:3723 / NAD83(NSRS2007) / UTM zone 16N"},
  {coordinate_system:"EPSG:3724", label: "EPSG:3724 / NAD83(NSRS2007) / UTM zone 17N"},
  {coordinate_system:"EPSG:3725", label: "EPSG:3725 / NAD83(NSRS2007) / UTM zone 18N"},
  {coordinate_system:"EPSG:3726", label: "EPSG:3726 / NAD83(NSRS2007) / UTM zone 19N"},
  {coordinate_system:"EPSG:3727", label: "EPSG:3727 / Reunion 1947 / TM Reunion"},
  {coordinate_system:"EPSG:3728", label: "EPSG:3728 / NAD83(NSRS2007) / Ohio North (ftUS)"},
  {coordinate_system:"EPSG:3729", label: "EPSG:3729 / NAD83(NSRS2007) / Ohio South (ftUS)"},
  {coordinate_system:"EPSG:3730", label: "EPSG:3730 / NAD83(NSRS2007) / Wyoming East (ftUS)"},
  {coordinate_system:"EPSG:3731", label: "EPSG:3731 / NAD83(NSRS2007) / Wyoming East Central (ftUS)"},
  {coordinate_system:"EPSG:3732", label: "EPSG:3732 / NAD83(NSRS2007) / Wyoming West Central (ftUS)"},
  {coordinate_system:"EPSG:3733", label: "EPSG:3733 / NAD83(NSRS2007) / Wyoming West (ftUS)"},
  {coordinate_system:"EPSG:3734", label: "EPSG:3734 / NAD83 / Ohio North (ftUS)"},
  {coordinate_system:"EPSG:3735", label: "EPSG:3735 / NAD83 / Ohio South (ftUS)"},
  {coordinate_system:"EPSG:3736", label: "EPSG:3736 / NAD83 / Wyoming East (ftUS)"},
  {coordinate_system:"EPSG:3737", label: "EPSG:3737 / NAD83 / Wyoming East Central (ftUS)"},
  {coordinate_system:"EPSG:3738", label: "EPSG:3738 / NAD83 / Wyoming West Central (ftUS)"},
  {coordinate_system:"EPSG:3739", label: "EPSG:3739 / NAD83 / Wyoming West (ftUS)"},
  {coordinate_system:"EPSG:3740", label: "EPSG:3740 / NAD83(HARN) / UTM zone 10N"},
  {coordinate_system:"EPSG:3741", label: "EPSG:3741 / NAD83(HARN) / UTM zone 11N"},
  {coordinate_system:"EPSG:3742", label: "EPSG:3742 / NAD83(HARN) / UTM zone 12N"},
  {coordinate_system:"EPSG:3743", label: "EPSG:3743 / NAD83(HARN) / UTM zone 13N"},
  {coordinate_system:"EPSG:3744", label: "EPSG:3744 / NAD83(HARN) / UTM zone 14N"},
  {coordinate_system:"EPSG:3745", label: "EPSG:3745 / NAD83(HARN) / UTM zone 15N"},
  {coordinate_system:"EPSG:3746", label: "EPSG:3746 / NAD83(HARN) / UTM zone 16N"},
  {coordinate_system:"EPSG:3747", label: "EPSG:3747 / NAD83(HARN) / UTM zone 17N"},
  {coordinate_system:"EPSG:3748", label: "EPSG:3748 / NAD83(HARN) / UTM zone 18N"},
  {coordinate_system:"EPSG:3749", label: "EPSG:3749 / NAD83(HARN) / UTM zone 19N"},
  {coordinate_system:"EPSG:3750", label: "EPSG:3750 / NAD83(HARN) / UTM zone 4N"},
  {coordinate_system:"EPSG:3751", label: "EPSG:3751 / NAD83(HARN) / UTM zone 5N"},
  {coordinate_system:"EPSG:3753", label: "EPSG:3753 / NAD83(HARN) / Ohio North (ftUS)"},
  {coordinate_system:"EPSG:3754", label: "EPSG:3754 / NAD83(HARN) / Ohio South (ftUS)"},
  {coordinate_system:"EPSG:3755", label: "EPSG:3755 / NAD83(HARN) / Wyoming East (ftUS)"},
  {coordinate_system:"EPSG:3756", label: "EPSG:3756 / NAD83(HARN) / Wyoming East Central (ftUS)"},
  {coordinate_system:"EPSG:3757", label: "EPSG:3757 / NAD83(HARN) / Wyoming West Central (ftUS)"},
  {coordinate_system:"EPSG:3758", label: "EPSG:3758 / NAD83(HARN) / Wyoming West (ftUS)"},
  {coordinate_system:"EPSG:3759", label: "EPSG:3759 / NAD83 / Hawaii zone 3 (ftUS)"},
  {coordinate_system:"EPSG:3760", label: "EPSG:3760 / NAD83(HARN) / Hawaii zone 3 (ftUS)"},
  {coordinate_system:"EPSG:3761", label: "EPSG:3761 / NAD83(CSRS) / UTM zone 22N"},
  {coordinate_system:"EPSG:3762", label: "EPSG:3762 / WGS 84 / South Georgia Lambert"},
  {coordinate_system:"EPSG:3763", label: "EPSG:3763 / ETRS89 / Portugal TM06"},
  {coordinate_system:"EPSG:3764", label: "EPSG:3764 / NZGD2000 / Chatham Island Circuit 2000"},
  {coordinate_system:"EPSG:3765", label: "EPSG:3765 / HTRS96 / Croatia TM"},
  {coordinate_system:"EPSG:3766", label: "EPSG:3766 / HTRS96 / Croatia LCC"},
  {coordinate_system:"EPSG:3767", label: "EPSG:3767 / HTRS96 / UTM zone 33N"},
  {coordinate_system:"EPSG:3768", label: "EPSG:3768 / HTRS96 / UTM zone 34N"},
  {coordinate_system:"EPSG:3769", label: "EPSG:3769 / Bermuda 1957 / UTM zone 20N"},
  {coordinate_system:"EPSG:3770", label: "EPSG:3770 / BDA2000 / Bermuda 2000 National Grid"},
  {coordinate_system:"EPSG:3771", label: "EPSG:3771 / NAD27 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:3772", label: "EPSG:3772 / NAD27 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:3773", label: "EPSG:3773 / NAD27 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:3775", label: "EPSG:3775 / NAD83 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:3776", label: "EPSG:3776 / NAD83 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:3777", label: "EPSG:3777 / NAD83 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:3779", label: "EPSG:3779 / NAD83(CSRS) / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:3780", label: "EPSG:3780 / NAD83(CSRS) / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:3781", label: "EPSG:3781 / NAD83(CSRS) / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:3783", label: "EPSG:3783 / Pitcairn 2006 / Pitcairn TM 2006"},
  {coordinate_system:"EPSG:3784", label: "EPSG:3784 / Pitcairn 1967 / UTM zone 9S"},
  {coordinate_system:"EPSG:3788", label: "EPSG:3788 / NZGD2000 / Auckland Islands TM 2000"},
  {coordinate_system:"EPSG:3789", label: "EPSG:3789 / NZGD2000 / Campbell Island TM 2000"},
  {coordinate_system:"EPSG:3790", label: "EPSG:3790 / NZGD2000 / Antipodes Islands TM 2000"},
  {coordinate_system:"EPSG:3791", label: "EPSG:3791 / NZGD2000 / Raoul Island TM 2000"},
  {coordinate_system:"EPSG:3793", label: "EPSG:3793 / NZGD2000 / Chatham Islands TM 2000"},
  {coordinate_system:"EPSG:3794", label: "EPSG:3794 / Slovenia 1996 / Slovene National Grid"},
  {coordinate_system:"EPSG:3795", label: "EPSG:3795 / NAD27 / Cuba Norte"},
  {coordinate_system:"EPSG:3796", label: "EPSG:3796 / NAD27 / Cuba Sur"},
  {coordinate_system:"EPSG:3797", label: "EPSG:3797 / NAD27 / MTQ Lambert"},
  {coordinate_system:"EPSG:3798", label: "EPSG:3798 / NAD83 / MTQ Lambert"},
  {coordinate_system:"EPSG:3799", label: "EPSG:3799 / NAD83(CSRS) / MTQ Lambert"},
  {coordinate_system:"EPSG:3800", label: "EPSG:3800 / NAD27 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:3801", label: "EPSG:3801 / NAD83 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:3802", label: "EPSG:3802 / NAD83(CSRS) / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:3812", label: "EPSG:3812 / ETRS89 / Belgian Lambert 2008"},
  {coordinate_system:"EPSG:3814", label: "EPSG:3814 / NAD83 / Mississippi TM"},
  {coordinate_system:"EPSG:3815", label: "EPSG:3815 / NAD83(HARN) / Mississippi TM"},
  {coordinate_system:"EPSG:3816", label: "EPSG:3816 / NAD83(NSRS2007) / Mississippi TM"},
  {coordinate_system:"EPSG:3825", label: "EPSG:3825 / TWD97 / TM2 zone 119"},
  {coordinate_system:"EPSG:3826", label: "EPSG:3826 / TWD97 / TM2 zone 121"},
  {coordinate_system:"EPSG:3827", label: "EPSG:3827 / TWD67 / TM2 zone 119"},
  {coordinate_system:"EPSG:3828", label: "EPSG:3828 / TWD67 / TM2 zone 121"},
  {coordinate_system:"EPSG:3829", label: "EPSG:3829 / Hu Tzu Shan 1950 / UTM zone 51N"},
  {coordinate_system:"EPSG:3832", label: "EPSG:3832 / WGS 84 / PDC Mercator"},
  {coordinate_system:"EPSG:3833", label: "EPSG:3833 / Pulkovo 1942(58) / Gauss-Kruger zone 2"},
  {coordinate_system:"EPSG:3834", label: "EPSG:3834 / Pulkovo 1942(83) / Gauss-Kruger zone 2"},
  {coordinate_system:"EPSG:3835", label: "EPSG:3835 / Pulkovo 1942(83) / Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:3836", label: "EPSG:3836 / Pulkovo 1942(83) / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3837", label: "EPSG:3837 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:3838", label: "EPSG:3838 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3839", label: "EPSG:3839 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:3840", label: "EPSG:3840 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:3841", label: "EPSG:3841 / Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:3844", label: "EPSG:3844 / Pulkovo 1942(58) / Stereo70"},
  {coordinate_system:"EPSG:3845", label: "EPSG:3845 / SWEREF99 / RT90 7.5 gon V emulation"},
  {coordinate_system:"EPSG:3846", label: "EPSG:3846 / SWEREF99 / RT90 5 gon V emulation"},
  {coordinate_system:"EPSG:3847", label: "EPSG:3847 / SWEREF99 / RT90 2.5 gon V emulation"},
  {coordinate_system:"EPSG:3848", label: "EPSG:3848 / SWEREF99 / RT90 0 gon emulation"},
  {coordinate_system:"EPSG:3849", label: "EPSG:3849 / SWEREF99 / RT90 2.5 gon O emulation"},
  {coordinate_system:"EPSG:3850", label: "EPSG:3850 / SWEREF99 / RT90 5 gon O emulation"},
  {coordinate_system:"EPSG:3851", label: "EPSG:3851 / NZGD2000 / NZCS2000"},
  {coordinate_system:"EPSG:3852", label: "EPSG:3852 / RSRGD2000 / DGLC2000"},
  {coordinate_system:"EPSG:3854", label: "EPSG:3854 / County ST74"},
  {coordinate_system:"EPSG:3857", label: "EPSG:3857 / WGS 84 / Pseudo-Mercator"},
  {coordinate_system:"EPSG:3873", label: "EPSG:3873 / ETRS89 / GK19FIN"},
  {coordinate_system:"EPSG:3874", label: "EPSG:3874 / ETRS89 / GK20FIN"},
  {coordinate_system:"EPSG:3875", label: "EPSG:3875 / ETRS89 / GK21FIN"},
  {coordinate_system:"EPSG:3876", label: "EPSG:3876 / ETRS89 / GK22FIN"},
  {coordinate_system:"EPSG:3877", label: "EPSG:3877 / ETRS89 / GK23FIN"},
  {coordinate_system:"EPSG:3878", label: "EPSG:3878 / ETRS89 / GK24FIN"},
  {coordinate_system:"EPSG:3879", label: "EPSG:3879 / ETRS89 / GK25FIN"},
  {coordinate_system:"EPSG:3880", label: "EPSG:3880 / ETRS89 / GK26FIN"},
  {coordinate_system:"EPSG:3881", label: "EPSG:3881 / ETRS89 / GK27FIN"},
  {coordinate_system:"EPSG:3882", label: "EPSG:3882 / ETRS89 / GK28FIN"},
  {coordinate_system:"EPSG:3883", label: "EPSG:3883 / ETRS89 / GK29FIN"},
  {coordinate_system:"EPSG:3884", label: "EPSG:3884 / ETRS89 / GK30FIN"},
  {coordinate_system:"EPSG:3885", label: "EPSG:3885 / ETRS89 / GK31FIN"},
  {coordinate_system:"EPSG:3890", label: "EPSG:3890 / IGRS / UTM zone 37N"},
  {coordinate_system:"EPSG:3891", label: "EPSG:3891 / IGRS / UTM zone 38N"},
  {coordinate_system:"EPSG:3892", label: "EPSG:3892 / IGRS / UTM zone 39N"},
  {coordinate_system:"EPSG:3893", label: "EPSG:3893 / ED50 / Iraq National Grid"},
  {coordinate_system:"EPSG:3912", label: "EPSG:3912 / MGI 1901 / Slovene National Grid"},
  {coordinate_system:"EPSG:3920", label: "EPSG:3920 / Puerto Rico / UTM zone 20N"},
  {coordinate_system:"EPSG:3942", label: "EPSG:3942 / RGF93 v1 / CC42"},
  {coordinate_system:"EPSG:3943", label: "EPSG:3943 / RGF93 v1 / CC43"},
  {coordinate_system:"EPSG:3944", label: "EPSG:3944 / RGF93 v1 / CC44"},
  {coordinate_system:"EPSG:3945", label: "EPSG:3945 / RGF93 v1 / CC45"},
  {coordinate_system:"EPSG:3946", label: "EPSG:3946 / RGF93 v1 / CC46"},
  {coordinate_system:"EPSG:3947", label: "EPSG:3947 / RGF93 v1 / CC47"},
  {coordinate_system:"EPSG:3948", label: "EPSG:3948 / RGF93 v1 / CC48"},
  {coordinate_system:"EPSG:3949", label: "EPSG:3949 / RGF93 v1 / CC49"},
  {coordinate_system:"EPSG:3950", label: "EPSG:3950 / RGF93 v1 / CC50"},
  {coordinate_system:"EPSG:3968", label: "EPSG:3968 / NAD83 / Virginia Lambert"},
  {coordinate_system:"EPSG:3969", label: "EPSG:3969 / NAD83(HARN) / Virginia Lambert"},
  {coordinate_system:"EPSG:3970", label: "EPSG:3970 / NAD83(NSRS2007) / Virginia Lambert"},
  {coordinate_system:"EPSG:3976", label: "EPSG:3976 / WGS 84 / NSIDC Sea Ice Polar Stereographic South"},
  {coordinate_system:"EPSG:3978", label: "EPSG:3978 / NAD83 / Canada Atlas Lambert"},
  {coordinate_system:"EPSG:3979", label: "EPSG:3979 / NAD83(CSRS) / Canada Atlas Lambert"},
  {coordinate_system:"EPSG:3986", label: "EPSG:3986 / Katanga 1955 / Katanga Gauss zone A"},
  {coordinate_system:"EPSG:3987", label: "EPSG:3987 / Katanga 1955 / Katanga Gauss zone B"},
  {coordinate_system:"EPSG:3988", label: "EPSG:3988 / Katanga 1955 / Katanga Gauss zone C"},
  {coordinate_system:"EPSG:3989", label: "EPSG:3989 / Katanga 1955 / Katanga Gauss zone D"},
  {coordinate_system:"EPSG:3991", label: "EPSG:3991 / Puerto Rico State Plane CS of 1927"},
  {coordinate_system:"EPSG:3992", label: "EPSG:3992 / Puerto Rico / St. Croix"},
  {coordinate_system:"EPSG:3993", label: "EPSG:3993 / Guam 1963 / Guam SPCS"},
  {coordinate_system:"EPSG:3994", label: "EPSG:3994 / WGS 84 / Mercator 41"},
  {coordinate_system:"EPSG:3995", label: "EPSG:3995 / WGS 84 / Arctic Polar Stereographic"},
  {coordinate_system:"EPSG:3996", label: "EPSG:3996 / WGS 84 / IBCAO Polar Stereographic"},
  {coordinate_system:"EPSG:3997", label: "EPSG:3997 / WGS 84 / Dubai Local TM"},
  {coordinate_system:"EPSG:4026", label: "EPSG:4026 / MOLDREF99 / Moldova TM"},
  {coordinate_system:"EPSG:4037", label: "EPSG:4037 / WGS 84 / TMzn35N"},
  {coordinate_system:"EPSG:4038", label: "EPSG:4038 / WGS 84 / TMzn36N"},
  {coordinate_system:"EPSG:4048", label: "EPSG:4048 / RGRDC 2005 / Congo TM zone 12"},
  {coordinate_system:"EPSG:4049", label: "EPSG:4049 / RGRDC 2005 / Congo TM zone 14"},
  {coordinate_system:"EPSG:4050", label: "EPSG:4050 / RGRDC 2005 / Congo TM zone 16"},
  {coordinate_system:"EPSG:4051", label: "EPSG:4051 / RGRDC 2005 / Congo TM zone 18"},
  {coordinate_system:"EPSG:4056", label: "EPSG:4056 / RGRDC 2005 / Congo TM zone 20"},
  {coordinate_system:"EPSG:4057", label: "EPSG:4057 / RGRDC 2005 / Congo TM zone 22"},
  {coordinate_system:"EPSG:4058", label: "EPSG:4058 / RGRDC 2005 / Congo TM zone 24"},
  {coordinate_system:"EPSG:4059", label: "EPSG:4059 / RGRDC 2005 / Congo TM zone 26"},
  {coordinate_system:"EPSG:4060", label: "EPSG:4060 / RGRDC 2005 / Congo TM zone 28"},
  {coordinate_system:"EPSG:4061", label: "EPSG:4061 / RGRDC 2005 / UTM zone 33S"},
  {coordinate_system:"EPSG:4062", label: "EPSG:4062 / RGRDC 2005 / UTM zone 34S"},
  {coordinate_system:"EPSG:4063", label: "EPSG:4063 / RGRDC 2005 / UTM zone 35S"},
  {coordinate_system:"EPSG:4071", label: "EPSG:4071 / Chua / UTM zone 23S"},
  {coordinate_system:"EPSG:4082", label: "EPSG:4082 / REGCAN95 / UTM zone 27N"},
  {coordinate_system:"EPSG:4083", label: "EPSG:4083 / REGCAN95 / UTM zone 28N"},
  {coordinate_system:"EPSG:4087", label: "EPSG:4087 / WGS 84 / World Equidistant Cylindrical"},
  {coordinate_system:"EPSG:4093", label: "EPSG:4093 / ETRS89 / DKTM1"},
  {coordinate_system:"EPSG:4094", label: "EPSG:4094 / ETRS89 / DKTM2"},
  {coordinate_system:"EPSG:4095", label: "EPSG:4095 / ETRS89 / DKTM3"},
  {coordinate_system:"EPSG:4096", label: "EPSG:4096 / ETRS89 / DKTM4"},
  {coordinate_system:"EPSG:4217", label: "EPSG:4217 / NAD83 / BLM 59N (ftUS)"},
  {coordinate_system:"EPSG:4390", label: "EPSG:4390 / Kertau 1968 / Johor Grid"},
  {coordinate_system:"EPSG:4391", label: "EPSG:4391 / Kertau 1968 / Sembilan and Melaka Grid"},
  {coordinate_system:"EPSG:4392", label: "EPSG:4392 / Kertau 1968 / Pahang Grid"},
  {coordinate_system:"EPSG:4393", label: "EPSG:4393 / Kertau 1968 / Selangor Grid"},
  {coordinate_system:"EPSG:4394", label: "EPSG:4394 / Kertau 1968 / Terengganu Grid"},
  {coordinate_system:"EPSG:4395", label: "EPSG:4395 / Kertau 1968 / Pinang Grid"},
  {coordinate_system:"EPSG:4396", label: "EPSG:4396 / Kertau 1968 / Kedah and Perlis Grid"},
  {coordinate_system:"EPSG:4397", label: "EPSG:4397 / Kertau 1968 / Perak Revised Grid"},
  {coordinate_system:"EPSG:4398", label: "EPSG:4398 / Kertau 1968 / Kelantan Grid"},
  {coordinate_system:"EPSG:4399", label: "EPSG:4399 / NAD27 / BLM 59N (ftUS)"},
  {coordinate_system:"EPSG:4400", label: "EPSG:4400 / NAD27 / BLM 60N (ftUS)"},
  {coordinate_system:"EPSG:4401", label: "EPSG:4401 / NAD27 / BLM 1N (ftUS)"},
  {coordinate_system:"EPSG:4402", label: "EPSG:4402 / NAD27 / BLM 2N (ftUS)"},
  {coordinate_system:"EPSG:4403", label: "EPSG:4403 / NAD27 / BLM 3N (ftUS)"},
  {coordinate_system:"EPSG:4404", label: "EPSG:4404 / NAD27 / BLM 4N (ftUS)"},
  {coordinate_system:"EPSG:4405", label: "EPSG:4405 / NAD27 / BLM 5N (ftUS)"},
  {coordinate_system:"EPSG:4406", label: "EPSG:4406 / NAD27 / BLM 6N (ftUS)"},
  {coordinate_system:"EPSG:4407", label: "EPSG:4407 / NAD27 / BLM 7N (ftUS)"},
  {coordinate_system:"EPSG:4408", label: "EPSG:4408 / NAD27 / BLM 8N (ftUS)"},
  {coordinate_system:"EPSG:4409", label: "EPSG:4409 / NAD27 / BLM 9N (ftUS)"},
  {coordinate_system:"EPSG:4410", label: "EPSG:4410 / NAD27 / BLM 10N (ftUS)"},
  {coordinate_system:"EPSG:4411", label: "EPSG:4411 / NAD27 / BLM 11N (ftUS)"},
  {coordinate_system:"EPSG:4412", label: "EPSG:4412 / NAD27 / BLM 12N (ftUS)"},
  {coordinate_system:"EPSG:4413", label: "EPSG:4413 / NAD27 / BLM 13N (ftUS)"},
  {coordinate_system:"EPSG:4414", label: "EPSG:4414 / NAD83(HARN) / Guam Map Grid"},
  {coordinate_system:"EPSG:4415", label: "EPSG:4415 / Katanga 1955 / Katanga Lambert"},
  {coordinate_system:"EPSG:4417", label: "EPSG:4417 / Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:4418", label: "EPSG:4418 / NAD27 / BLM 18N (ftUS)"},
  {coordinate_system:"EPSG:4419", label: "EPSG:4419 / NAD27 / BLM 19N (ftUS)"},
  {coordinate_system:"EPSG:4420", label: "EPSG:4420 / NAD83 / BLM 60N (ftUS)"},
  {coordinate_system:"EPSG:4421", label: "EPSG:4421 / NAD83 / BLM 1N (ftUS)"},
  {coordinate_system:"EPSG:4422", label: "EPSG:4422 / NAD83 / BLM 2N (ftUS)"},
  {coordinate_system:"EPSG:4423", label: "EPSG:4423 / NAD83 / BLM 3N (ftUS)"},
  {coordinate_system:"EPSG:4424", label: "EPSG:4424 / NAD83 / BLM 4N (ftUS)"},
  {coordinate_system:"EPSG:4425", label: "EPSG:4425 / NAD83 / BLM 5N (ftUS)"},
  {coordinate_system:"EPSG:4426", label: "EPSG:4426 / NAD83 / BLM 6N (ftUS)"},
  {coordinate_system:"EPSG:4427", label: "EPSG:4427 / NAD83 / BLM 7N (ftUS)"},
  {coordinate_system:"EPSG:4428", label: "EPSG:4428 / NAD83 / BLM 8N (ftUS)"},
  {coordinate_system:"EPSG:4429", label: "EPSG:4429 / NAD83 / BLM 9N (ftUS)"},
  {coordinate_system:"EPSG:4430", label: "EPSG:4430 / NAD83 / BLM 10N (ftUS)"},
  {coordinate_system:"EPSG:4431", label: "EPSG:4431 / NAD83 / BLM 11N (ftUS)"},
  {coordinate_system:"EPSG:4432", label: "EPSG:4432 / NAD83 / BLM 12N (ftUS)"},
  {coordinate_system:"EPSG:4433", label: "EPSG:4433 / NAD83 / BLM 13N (ftUS)"},
  {coordinate_system:"EPSG:4434", label: "EPSG:4434 / Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:4437", label: "EPSG:4437 / NAD83(NSRS2007) / Puerto Rico and Virgin Is."},
  {coordinate_system:"EPSG:4438", label: "EPSG:4438 / NAD83 / BLM 18N (ftUS)"},
  {coordinate_system:"EPSG:4439", label: "EPSG:4439 / NAD83 / BLM 19N (ftUS)"},
  {coordinate_system:"EPSG:4455", label: "EPSG:4455 / NAD27 / Pennsylvania South"},
  {coordinate_system:"EPSG:4456", label: "EPSG:4456 / NAD27 / New York Long Island"},
  {coordinate_system:"EPSG:4457", label: "EPSG:4457 / NAD83 / South Dakota North (ftUS)"},
  {coordinate_system:"EPSG:4462", label: "EPSG:4462 / WGS 84 / Australian Centre for Remote Sensing Lambert"},
  {coordinate_system:"EPSG:4467", label: "EPSG:4467 / RGSPM06 / UTM zone 21N"},
  {coordinate_system:"EPSG:4471", label: "EPSG:4471 / RGM04 / UTM zone 38S"},
  {coordinate_system:"EPSG:4484", label: "EPSG:4484 / Mexico ITRF92 / UTM zone 11N"},
  {coordinate_system:"EPSG:4485", label: "EPSG:4485 / Mexico ITRF92 / UTM zone 12N"},
  {coordinate_system:"EPSG:4486", label: "EPSG:4486 / Mexico ITRF92 / UTM zone 13N"},
  {coordinate_system:"EPSG:4487", label: "EPSG:4487 / Mexico ITRF92 / UTM zone 14N"},
  {coordinate_system:"EPSG:4488", label: "EPSG:4488 / Mexico ITRF92 / UTM zone 15N"},
  {coordinate_system:"EPSG:4489", label: "EPSG:4489 / Mexico ITRF92 / UTM zone 16N"},
  {coordinate_system:"EPSG:4491", label: "EPSG:4491 / CGCS2000 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:4492", label: "EPSG:4492 / CGCS2000 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:4493", label: "EPSG:4493 / CGCS2000 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:4494", label: "EPSG:4494 / CGCS2000 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:4495", label: "EPSG:4495 / CGCS2000 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:4496", label: "EPSG:4496 / CGCS2000 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:4497", label: "EPSG:4497 / CGCS2000 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:4498", label: "EPSG:4498 / CGCS2000 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:4499", label: "EPSG:4499 / CGCS2000 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:4500", label: "EPSG:4500 / CGCS2000 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:4501", label: "EPSG:4501 / CGCS2000 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:4502", label: "EPSG:4502 / CGCS2000 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:4503", label: "EPSG:4503 / CGCS2000 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:4504", label: "EPSG:4504 / CGCS2000 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:4505", label: "EPSG:4505 / CGCS2000 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:4506", label: "EPSG:4506 / CGCS2000 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:4507", label: "EPSG:4507 / CGCS2000 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:4508", label: "EPSG:4508 / CGCS2000 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:4509", label: "EPSG:4509 / CGCS2000 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:4510", label: "EPSG:4510 / CGCS2000 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:4511", label: "EPSG:4511 / CGCS2000 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:4512", label: "EPSG:4512 / CGCS2000 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:4513", label: "EPSG:4513 / CGCS2000 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:4514", label: "EPSG:4514 / CGCS2000 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:4515", label: "EPSG:4515 / CGCS2000 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:4516", label: "EPSG:4516 / CGCS2000 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:4517", label: "EPSG:4517 / CGCS2000 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:4518", label: "EPSG:4518 / CGCS2000 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:4519", label: "EPSG:4519 / CGCS2000 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:4520", label: "EPSG:4520 / CGCS2000 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:4521", label: "EPSG:4521 / CGCS2000 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:4522", label: "EPSG:4522 / CGCS2000 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:4523", label: "EPSG:4523 / CGCS2000 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:4524", label: "EPSG:4524 / CGCS2000 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:4525", label: "EPSG:4525 / CGCS2000 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:4526", label: "EPSG:4526 / CGCS2000 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:4527", label: "EPSG:4527 / CGCS2000 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:4528", label: "EPSG:4528 / CGCS2000 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:4529", label: "EPSG:4529 / CGCS2000 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:4530", label: "EPSG:4530 / CGCS2000 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:4531", label: "EPSG:4531 / CGCS2000 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:4532", label: "EPSG:4532 / CGCS2000 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:4533", label: "EPSG:4533 / CGCS2000 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:4534", label: "EPSG:4534 / CGCS2000 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:4535", label: "EPSG:4535 / CGCS2000 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:4536", label: "EPSG:4536 / CGCS2000 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:4537", label: "EPSG:4537 / CGCS2000 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:4538", label: "EPSG:4538 / CGCS2000 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:4539", label: "EPSG:4539 / CGCS2000 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:4540", label: "EPSG:4540 / CGCS2000 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:4541", label: "EPSG:4541 / CGCS2000 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:4542", label: "EPSG:4542 / CGCS2000 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:4543", label: "EPSG:4543 / CGCS2000 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:4544", label: "EPSG:4544 / CGCS2000 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:4545", label: "EPSG:4545 / CGCS2000 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:4546", label: "EPSG:4546 / CGCS2000 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:4547", label: "EPSG:4547 / CGCS2000 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:4548", label: "EPSG:4548 / CGCS2000 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:4549", label: "EPSG:4549 / CGCS2000 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:4550", label: "EPSG:4550 / CGCS2000 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:4551", label: "EPSG:4551 / CGCS2000 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:4552", label: "EPSG:4552 / CGCS2000 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:4553", label: "EPSG:4553 / CGCS2000 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:4554", label: "EPSG:4554 / CGCS2000 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:4559", label: "EPSG:4559 / RRAF 1991 / UTM zone 20N"},
  {coordinate_system:"EPSG:4568", label: "EPSG:4568 / New Beijing / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:4569", label: "EPSG:4569 / New Beijing / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:4570", label: "EPSG:4570 / New Beijing / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:4571", label: "EPSG:4571 / New Beijing / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:4572", label: "EPSG:4572 / New Beijing / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:4573", label: "EPSG:4573 / New Beijing / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:4574", label: "EPSG:4574 / New Beijing / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:4575", label: "EPSG:4575 / New Beijing / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:4576", label: "EPSG:4576 / New Beijing / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:4577", label: "EPSG:4577 / New Beijing / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:4578", label: "EPSG:4578 / New Beijing / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:4579", label: "EPSG:4579 / New Beijing / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:4580", label: "EPSG:4580 / New Beijing / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:4581", label: "EPSG:4581 / New Beijing / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:4582", label: "EPSG:4582 / New Beijing / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:4583", label: "EPSG:4583 / New Beijing / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:4584", label: "EPSG:4584 / New Beijing / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:4585", label: "EPSG:4585 / New Beijing / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:4586", label: "EPSG:4586 / New Beijing / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:4587", label: "EPSG:4587 / New Beijing / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:4588", label: "EPSG:4588 / New Beijing / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:4589", label: "EPSG:4589 / New Beijing / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:4647", label: "EPSG:4647 / ETRS89 / UTM zone 32N (zE-N)"},
  {coordinate_system:"EPSG:4652", label: "EPSG:4652 / New Beijing / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:4653", label: "EPSG:4653 / New Beijing / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:4654", label: "EPSG:4654 / New Beijing / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:4655", label: "EPSG:4655 / New Beijing / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:4656", label: "EPSG:4656 / New Beijing / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:4766", label: "EPSG:4766 / New Beijing / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:4767", label: "EPSG:4767 / New Beijing / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:4768", label: "EPSG:4768 / New Beijing / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:4769", label: "EPSG:4769 / New Beijing / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:4770", label: "EPSG:4770 / New Beijing / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:4771", label: "EPSG:4771 / New Beijing / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:4772", label: "EPSG:4772 / New Beijing / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:4773", label: "EPSG:4773 / New Beijing / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:4774", label: "EPSG:4774 / New Beijing / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:4775", label: "EPSG:4775 / New Beijing / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:4776", label: "EPSG:4776 / New Beijing / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:4777", label: "EPSG:4777 / New Beijing / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:4778", label: "EPSG:4778 / New Beijing / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:4779", label: "EPSG:4779 / New Beijing / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:4780", label: "EPSG:4780 / New Beijing / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:4781", label: "EPSG:4781 / New Beijing / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:4782", label: "EPSG:4782 / New Beijing / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:4783", label: "EPSG:4783 / New Beijing / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:4784", label: "EPSG:4784 / New Beijing / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:4785", label: "EPSG:4785 / New Beijing / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:4786", label: "EPSG:4786 / New Beijing / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:4787", label: "EPSG:4787 / New Beijing / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:4788", label: "EPSG:4788 / New Beijing / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:4789", label: "EPSG:4789 / New Beijing / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:4790", label: "EPSG:4790 / New Beijing / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:4791", label: "EPSG:4791 / New Beijing / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:4792", label: "EPSG:4792 / New Beijing / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:4793", label: "EPSG:4793 / New Beijing / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:4794", label: "EPSG:4794 / New Beijing / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:4795", label: "EPSG:4795 / New Beijing / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:4796", label: "EPSG:4796 / New Beijing / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:4797", label: "EPSG:4797 / New Beijing / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:4798", label: "EPSG:4798 / New Beijing / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:4799", label: "EPSG:4799 / New Beijing / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:4800", label: "EPSG:4800 / New Beijing / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:4812", label: "EPSG:4812 / New Beijing / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:4822", label: "EPSG:4822 / New Beijing / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:4826", label: "EPSG:4826 / WGS 84 / Cape Verde National"},
  {coordinate_system:"EPSG:4839", label: "EPSG:4839 / ETRS89 / LCC Germany (N-E)"},
  {coordinate_system:"EPSG:5014", label: "EPSG:5014 / PTRA08 / UTM zone 25N"},
  {coordinate_system:"EPSG:5015", label: "EPSG:5015 / PTRA08 / UTM zone 26N"},
  {coordinate_system:"EPSG:5016", label: "EPSG:5016 / PTRA08 / UTM zone 28N"},
  {coordinate_system:"EPSG:5017", label: "EPSG:5017 / Lisbon 1890 / Portugal Bonne New"},
  {coordinate_system:"EPSG:5018", label: "EPSG:5018 / Lisbon / Portuguese Grid New"},
  {coordinate_system:"EPSG:5041", label: "EPSG:5041 / WGS 84 / UPS North (E,N)"},
  {coordinate_system:"EPSG:5042", label: "EPSG:5042 / WGS 84 / UPS South (E,N)"},
  {coordinate_system:"EPSG:5048", label: "EPSG:5048 / ETRS89 / TM35FIN(N,E)"},
  {coordinate_system:"EPSG:5069", label: "EPSG:5069 / NAD27 / Conus Albers"},
  {coordinate_system:"EPSG:5070", label: "EPSG:5070 / NAD83 / Conus Albers"},
  {coordinate_system:"EPSG:5071", label: "EPSG:5071 / NAD83(HARN) / Conus Albers"},
  {coordinate_system:"EPSG:5072", label: "EPSG:5072 / NAD83(NSRS2007) / Conus Albers"},
  {coordinate_system:"EPSG:5105", label: "EPSG:5105 / ETRS89 / NTM zone 5"},
  {coordinate_system:"EPSG:5106", label: "EPSG:5106 / ETRS89 / NTM zone 6"},
  {coordinate_system:"EPSG:5107", label: "EPSG:5107 / ETRS89 / NTM zone 7"},
  {coordinate_system:"EPSG:5108", label: "EPSG:5108 / ETRS89 / NTM zone 8"},
  {coordinate_system:"EPSG:5109", label: "EPSG:5109 / ETRS89 / NTM zone 9"},
  {coordinate_system:"EPSG:5110", label: "EPSG:5110 / ETRS89 / NTM zone 10"},
  {coordinate_system:"EPSG:5111", label: "EPSG:5111 / ETRS89 / NTM zone 11"},
  {coordinate_system:"EPSG:5112", label: "EPSG:5112 / ETRS89 / NTM zone 12"},
  {coordinate_system:"EPSG:5113", label: "EPSG:5113 / ETRS89 / NTM zone 13"},
  {coordinate_system:"EPSG:5114", label: "EPSG:5114 / ETRS89 / NTM zone 14"},
  {coordinate_system:"EPSG:5115", label: "EPSG:5115 / ETRS89 / NTM zone 15"},
  {coordinate_system:"EPSG:5116", label: "EPSG:5116 / ETRS89 / NTM zone 16"},
  {coordinate_system:"EPSG:5117", label: "EPSG:5117 / ETRS89 / NTM zone 17"},
  {coordinate_system:"EPSG:5118", label: "EPSG:5118 / ETRS89 / NTM zone 18"},
  {coordinate_system:"EPSG:5119", label: "EPSG:5119 / ETRS89 / NTM zone 19"},
  {coordinate_system:"EPSG:5120", label: "EPSG:5120 / ETRS89 / NTM zone 20"},
  {coordinate_system:"EPSG:5121", label: "EPSG:5121 / ETRS89 / NTM zone 21"},
  {coordinate_system:"EPSG:5122", label: "EPSG:5122 / ETRS89 / NTM zone 22"},
  {coordinate_system:"EPSG:5123", label: "EPSG:5123 / ETRS89 / NTM zone 23"},
  {coordinate_system:"EPSG:5124", label: "EPSG:5124 / ETRS89 / NTM zone 24"},
  {coordinate_system:"EPSG:5125", label: "EPSG:5125 / ETRS89 / NTM zone 25"},
  {coordinate_system:"EPSG:5126", label: "EPSG:5126 / ETRS89 / NTM zone 26"},
  {coordinate_system:"EPSG:5127", label: "EPSG:5127 / ETRS89 / NTM zone 27"},
  {coordinate_system:"EPSG:5128", label: "EPSG:5128 / ETRS89 / NTM zone 28"},
  {coordinate_system:"EPSG:5129", label: "EPSG:5129 / ETRS89 / NTM zone 29"},
  {coordinate_system:"EPSG:5130", label: "EPSG:5130 / ETRS89 / NTM zone 30"},
  {coordinate_system:"EPSG:5167", label: "EPSG:5167 / Korean 1985 / East Sea Belt"},
  {coordinate_system:"EPSG:5168", label: "EPSG:5168 / Korean 1985 / Central Belt Jeju"},
  {coordinate_system:"EPSG:5169", label: "EPSG:5169 / Tokyo 1892 / Korea West Belt"},
  {coordinate_system:"EPSG:5170", label: "EPSG:5170 / Tokyo 1892 / Korea Central Belt"},
  {coordinate_system:"EPSG:5171", label: "EPSG:5171 / Tokyo 1892 / Korea East Belt"},
  {coordinate_system:"EPSG:5172", label: "EPSG:5172 / Tokyo 1892 / Korea East Sea Belt"},
  {coordinate_system:"EPSG:5173", label: "EPSG:5173 / Korean 1985 / Modified West Belt"},
  {coordinate_system:"EPSG:5174", label: "EPSG:5174 / Korean 1985 / Modified Central Belt"},
  {coordinate_system:"EPSG:5175", label: "EPSG:5175 / Korean 1985 / Modified Central Belt Jeju"},
  {coordinate_system:"EPSG:5176", label: "EPSG:5176 / Korean 1985 / Modified East Belt"},
  {coordinate_system:"EPSG:5177", label: "EPSG:5177 / Korean 1985 / Modified East Sea Belt"},
  {coordinate_system:"EPSG:5178", label: "EPSG:5178 / Korean 1985 / Unified CS"},
  {coordinate_system:"EPSG:5179", label: "EPSG:5179 / Korea 2000 / Unified CS"},
  {coordinate_system:"EPSG:5180", label: "EPSG:5180 / Korea 2000 / West Belt"},
  {coordinate_system:"EPSG:5181", label: "EPSG:5181 / Korea 2000 / Central Belt"},
  {coordinate_system:"EPSG:5182", label: "EPSG:5182 / Korea 2000 / Central Belt Jeju"},
  {coordinate_system:"EPSG:5183", label: "EPSG:5183 / Korea 2000 / East Belt"},
  {coordinate_system:"EPSG:5184", label: "EPSG:5184 / Korea 2000 / East Sea Belt"},
  {coordinate_system:"EPSG:5185", label: "EPSG:5185 / Korea 2000 / West Belt 2010"},
  {coordinate_system:"EPSG:5186", label: "EPSG:5186 / Korea 2000 / Central Belt 2010"},
  {coordinate_system:"EPSG:5187", label: "EPSG:5187 / Korea 2000 / East Belt 2010"},
  {coordinate_system:"EPSG:5188", label: "EPSG:5188 / Korea 2000 / East Sea Belt 2010"},
  {coordinate_system:"EPSG:5221", label: "EPSG:5221 / S-JTSK (Ferro) / Krovak East North"},
  {coordinate_system:"EPSG:5223", label: "EPSG:5223 / WGS 84 / Gabon TM"},
  {coordinate_system:"EPSG:5224", label: "EPSG:5224 / S-JTSK/05 (Ferro) / Modified Krovak"},
  {coordinate_system:"EPSG:5225", label: "EPSG:5225 / S-JTSK/05 (Ferro) / Modified Krovak East North"},
  {coordinate_system:"EPSG:5234", label: "EPSG:5234 / Kandawala / Sri Lanka Grid"},
  {coordinate_system:"EPSG:5235", label: "EPSG:5235 / SLD99 / Sri Lanka Grid 1999"},
  {coordinate_system:"EPSG:5243", label: "EPSG:5243 / ETRS89 / LCC Germany (E-N)"},
  {coordinate_system:"EPSG:5247", label: "EPSG:5247 / GDBD2009 / Brunei BRSO"},
  {coordinate_system:"EPSG:5253", label: "EPSG:5253 / TUREF / TM27"},
  {coordinate_system:"EPSG:5254", label: "EPSG:5254 / TUREF / TM30"},
  {coordinate_system:"EPSG:5255", label: "EPSG:5255 / TUREF / TM33"},
  {coordinate_system:"EPSG:5256", label: "EPSG:5256 / TUREF / TM36"},
  {coordinate_system:"EPSG:5257", label: "EPSG:5257 / TUREF / TM39"},
  {coordinate_system:"EPSG:5258", label: "EPSG:5258 / TUREF / TM42"},
  {coordinate_system:"EPSG:5259", label: "EPSG:5259 / TUREF / TM45"},
  {coordinate_system:"EPSG:5266", label: "EPSG:5266 / DRUKREF 03 / Bhutan National Grid"},
  {coordinate_system:"EPSG:5269", label: "EPSG:5269 / TUREF / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:5270", label: "EPSG:5270 / TUREF / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:5271", label: "EPSG:5271 / TUREF / 3-degree Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:5272", label: "EPSG:5272 / TUREF / 3-degree Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:5273", label: "EPSG:5273 / TUREF / 3-degree Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:5274", label: "EPSG:5274 / TUREF / 3-degree Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:5275", label: "EPSG:5275 / TUREF / 3-degree Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:5292", label: "EPSG:5292 / DRUKREF 03 / Bumthang TM"},
  {coordinate_system:"EPSG:5293", label: "EPSG:5293 / DRUKREF 03 / Chhukha TM"},
  {coordinate_system:"EPSG:5294", label: "EPSG:5294 / DRUKREF 03 / Dagana TM"},
  {coordinate_system:"EPSG:5295", label: "EPSG:5295 / DRUKREF 03 / Gasa TM"},
  {coordinate_system:"EPSG:5296", label: "EPSG:5296 / DRUKREF 03 / Ha TM"},
  {coordinate_system:"EPSG:5297", label: "EPSG:5297 / DRUKREF 03 / Lhuentse TM"},
  {coordinate_system:"EPSG:5298", label: "EPSG:5298 / DRUKREF 03 / Mongar TM"},
  {coordinate_system:"EPSG:5299", label: "EPSG:5299 / DRUKREF 03 / Paro TM"},
  {coordinate_system:"EPSG:5300", label: "EPSG:5300 / DRUKREF 03 / Pemagatshel TM"},
  {coordinate_system:"EPSG:5301", label: "EPSG:5301 / DRUKREF 03 / Punakha TM"},
  {coordinate_system:"EPSG:5302", label: "EPSG:5302 / DRUKREF 03 / Samdrup Jongkhar TM"},
  {coordinate_system:"EPSG:5303", label: "EPSG:5303 / DRUKREF 03 / Samtse TM"},
  {coordinate_system:"EPSG:5304", label: "EPSG:5304 / DRUKREF 03 / Sarpang TM"},
  {coordinate_system:"EPSG:5305", label: "EPSG:5305 / DRUKREF 03 / Thimphu TM"},
  {coordinate_system:"EPSG:5306", label: "EPSG:5306 / DRUKREF 03 / Trashigang TM"},
  {coordinate_system:"EPSG:5307", label: "EPSG:5307 / DRUKREF 03 / Trongsa TM"},
  {coordinate_system:"EPSG:5308", label: "EPSG:5308 / DRUKREF 03 / Tsirang TM"},
  {coordinate_system:"EPSG:5309", label: "EPSG:5309 / DRUKREF 03 / Wangdue Phodrang TM"},
  {coordinate_system:"EPSG:5310", label: "EPSG:5310 / DRUKREF 03 / Yangtse TM"},
  {coordinate_system:"EPSG:5311", label: "EPSG:5311 / DRUKREF 03 / Zhemgang TM"},
  {coordinate_system:"EPSG:5316", label: "EPSG:5316 / ETRS89 / Faroe TM"},
  {coordinate_system:"EPSG:5320", label: "EPSG:5320 / NAD83 / Teranet Ontario Lambert"},
  {coordinate_system:"EPSG:5321", label: "EPSG:5321 / NAD83(CSRS) / Teranet Ontario Lambert"},
  {coordinate_system:"EPSG:5325", label: "EPSG:5325 / ISN2004 / Lambert 2004"},
  {coordinate_system:"EPSG:5329", label: "EPSG:5329 / Segara (Jakarta) / NEIEZ"},
  {coordinate_system:"EPSG:5330", label: "EPSG:5330 / Batavia (Jakarta) / NEIEZ"},
  {coordinate_system:"EPSG:5331", label: "EPSG:5331 / Makassar (Jakarta) / NEIEZ"},
  {coordinate_system:"EPSG:5337", label: "EPSG:5337 / Aratu / UTM zone 25S"},
  {coordinate_system:"EPSG:5343", label: "EPSG:5343 / POSGAR 2007 / Argentina 1"},
  {coordinate_system:"EPSG:5344", label: "EPSG:5344 / POSGAR 2007 / Argentina 2"},
  {coordinate_system:"EPSG:5345", label: "EPSG:5345 / POSGAR 2007 / Argentina 3"},
  {coordinate_system:"EPSG:5346", label: "EPSG:5346 / POSGAR 2007 / Argentina 4"},
  {coordinate_system:"EPSG:5347", label: "EPSG:5347 / POSGAR 2007 / Argentina 5"},
  {coordinate_system:"EPSG:5348", label: "EPSG:5348 / POSGAR 2007 / Argentina 6"},
  {coordinate_system:"EPSG:5349", label: "EPSG:5349 / POSGAR 2007 / Argentina 7"},
  {coordinate_system:"EPSG:5355", label: "EPSG:5355 / MARGEN / UTM zone 20S"},
  {coordinate_system:"EPSG:5356", label: "EPSG:5356 / MARGEN / UTM zone 19S"},
  {coordinate_system:"EPSG:5357", label: "EPSG:5357 / MARGEN / UTM zone 21S"},
  {coordinate_system:"EPSG:5361", label: "EPSG:5361 / SIRGAS-Chile 2002 / UTM zone 19S"},
  {coordinate_system:"EPSG:5362", label: "EPSG:5362 / SIRGAS-Chile 2002 / UTM zone 18S"},
  {coordinate_system:"EPSG:5367", label: "EPSG:5367 / CR05 / CRTM05"},
  {coordinate_system:"EPSG:5382", label: "EPSG:5382 / SIRGAS-ROU98 / UTM zone 21S"},
  {coordinate_system:"EPSG:5383", label: "EPSG:5383 / SIRGAS-ROU98 / UTM zone 22S"},
  {coordinate_system:"EPSG:5387", label: "EPSG:5387 / Peru96 / UTM zone 18S"},
  {coordinate_system:"EPSG:5389", label: "EPSG:5389 / Peru96 / UTM zone 19S"},
  {coordinate_system:"EPSG:5396", label: "EPSG:5396 / SIRGAS 2000 / UTM zone 26S"},
  {coordinate_system:"EPSG:5456", label: "EPSG:5456 / Ocotepeque 1935 / Costa Rica Norte"},
  {coordinate_system:"EPSG:5457", label: "EPSG:5457 / Ocotepeque 1935 / Costa Rica Sur"},
  {coordinate_system:"EPSG:5459", label: "EPSG:5459 / Ocotepeque 1935 / Guatemala Sur"},
  {coordinate_system:"EPSG:5460", label: "EPSG:5460 / Ocotepeque 1935 / El Salvador Lambert"},
  {coordinate_system:"EPSG:5461", label: "EPSG:5461 / Ocotepeque 1935 / Nicaragua Norte"},
  {coordinate_system:"EPSG:5462", label: "EPSG:5462 / Ocotepeque 1935 / Nicaragua Sur"},
  {coordinate_system:"EPSG:5463", label: "EPSG:5463 / SAD69 / UTM zone 17N"},
  {coordinate_system:"EPSG:5469", label: "EPSG:5469 / Panama-Colon 1911 / Panama Lambert"},
  {coordinate_system:"EPSG:5472", label: "EPSG:5472 / Panama-Colon 1911 / Panama Polyconic"},
  {coordinate_system:"EPSG:5479", label: "EPSG:5479 / RSRGD2000 / MSLC2000"},
  {coordinate_system:"EPSG:5480", label: "EPSG:5480 / RSRGD2000 / BCLC2000"},
  {coordinate_system:"EPSG:5481", label: "EPSG:5481 / RSRGD2000 / PCLC2000"},
  {coordinate_system:"EPSG:5482", label: "EPSG:5482 / RSRGD2000 / RSPS2000"},
  {coordinate_system:"EPSG:5490", label: "EPSG:5490 / RGAF09 / UTM zone 20N"},
  {coordinate_system:"EPSG:5513", label: "EPSG:5513 / S-JTSK / Krovak"},
  {coordinate_system:"EPSG:5514", label: "EPSG:5514 / S-JTSK / Krovak East North"},
  {coordinate_system:"EPSG:5515", label: "EPSG:5515 / S-JTSK/05 / Modified Krovak"},
  {coordinate_system:"EPSG:5516", label: "EPSG:5516 / S-JTSK/05 / Modified Krovak East North"},
  {coordinate_system:"EPSG:5518", label: "EPSG:5518 / CI1971 / Chatham Islands Map Grid"},
  {coordinate_system:"EPSG:5519", label: "EPSG:5519 / CI1979 / Chatham Islands Map Grid"},
  {coordinate_system:"EPSG:5520", label: "EPSG:5520 / DHDN / 3-degree Gauss-Kruger zone 1"},
  {coordinate_system:"EPSG:5523", label: "EPSG:5523 / WGS 84 / Gabon TM 2011"},
  {coordinate_system:"EPSG:5530", label: "EPSG:5530 / SAD69(96) / Brazil Polyconic"},
  {coordinate_system:"EPSG:5531", label: "EPSG:5531 / SAD69(96) / UTM zone 21S"},
  {coordinate_system:"EPSG:5533", label: "EPSG:5533 / SAD69(96) / UTM zone 23S"},
  {coordinate_system:"EPSG:5534", label: "EPSG:5534 / SAD69(96) / UTM zone 24S"},
  {coordinate_system:"EPSG:5535", label: "EPSG:5535 / SAD69(96) / UTM zone 25S"},
  {coordinate_system:"EPSG:5536", label: "EPSG:5536 / Corrego Alegre 1961 / UTM zone 21S"},
  {coordinate_system:"EPSG:5537", label: "EPSG:5537 / Corrego Alegre 1961 / UTM zone 22S"},
  {coordinate_system:"EPSG:5538", label: "EPSG:5538 / Corrego Alegre 1961 / UTM zone 23S"},
  {coordinate_system:"EPSG:5539", label: "EPSG:5539 / Corrego Alegre 1961 / UTM zone 24S"},
  {coordinate_system:"EPSG:5550", label: "EPSG:5550 / PNG94 / PNGMG94 zone 54"},
  {coordinate_system:"EPSG:5551", label: "EPSG:5551 / PNG94 / PNGMG94 zone 55"},
  {coordinate_system:"EPSG:5552", label: "EPSG:5552 / PNG94 / PNGMG94 zone 56"},
  {coordinate_system:"EPSG:5559", label: "EPSG:5559 / Ocotepeque 1935 / Guatemala Norte"},
  {coordinate_system:"EPSG:5562", label: "EPSG:5562 / UCS-2000 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:5563", label: "EPSG:5563 / UCS-2000 / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:5564", label: "EPSG:5564 / UCS-2000 / Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:5565", label: "EPSG:5565 / UCS-2000 / Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:5566", label: "EPSG:5566 / UCS-2000 / Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:5567", label: "EPSG:5567 / UCS-2000 / Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:5568", label: "EPSG:5568 / UCS-2000 / Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:5569", label: "EPSG:5569 / UCS-2000 / Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:5588", label: "EPSG:5588 / NAD27 / New Brunswick Stereographic (NAD27)"},
  {coordinate_system:"EPSG:5589", label: "EPSG:5589 / Sibun Gorge 1922 / Colony Grid"},
  {coordinate_system:"EPSG:5596", label: "EPSG:5596 / FEH2010 / Fehmarnbelt TM"},
  {coordinate_system:"EPSG:5623", label: "EPSG:5623 / NAD27 / Michigan East"},
  {coordinate_system:"EPSG:5624", label: "EPSG:5624 / NAD27 / Michigan Old Central"},
  {coordinate_system:"EPSG:5625", label: "EPSG:5625 / NAD27 / Michigan West"},
  {coordinate_system:"EPSG:5627", label: "EPSG:5627 / ED50 / TM 6 NE"},
  {coordinate_system:"EPSG:5629", label: "EPSG:5629 / Moznet / UTM zone 38S"},
  {coordinate_system:"EPSG:5631", label: "EPSG:5631 / Pulkovo 1942(58) / Gauss-Kruger zone 2 (E-N)"},
  {coordinate_system:"EPSG:5632", label: "EPSG:5632 / PTRA08 / LCC Europe"},
  {coordinate_system:"EPSG:5633", label: "EPSG:5633 / PTRA08 / LAEA Europe"},
  {coordinate_system:"EPSG:5634", label: "EPSG:5634 / REGCAN95 / LCC Europe"},
  {coordinate_system:"EPSG:5635", label: "EPSG:5635 / REGCAN95 / LAEA Europe"},
  {coordinate_system:"EPSG:5636", label: "EPSG:5636 / TUREF / LAEA Europe"},
  {coordinate_system:"EPSG:5637", label: "EPSG:5637 / TUREF / LCC Europe"},
  {coordinate_system:"EPSG:5638", label: "EPSG:5638 / ISN2004 / LAEA Europe"},
  {coordinate_system:"EPSG:5639", label: "EPSG:5639 / ISN2004 / LCC Europe"},
  {coordinate_system:"EPSG:5641", label: "EPSG:5641 / SIRGAS 2000 / Brazil Mercator"},
  {coordinate_system:"EPSG:5643", label: "EPSG:5643 / ED50 / SPBA LCC"},
  {coordinate_system:"EPSG:5644", label: "EPSG:5644 / RGR92 / UTM zone 39S"},
  {coordinate_system:"EPSG:5646", label: "EPSG:5646 / NAD83 / Vermont (ftUS)"},
  {coordinate_system:"EPSG:5649", label: "EPSG:5649 / ETRS89 / UTM zone 31N (zE-N)"},
  {coordinate_system:"EPSG:5650", label: "EPSG:5650 / ETRS89 / UTM zone 33N (zE-N)"},
  {coordinate_system:"EPSG:5651", label: "EPSG:5651 / ETRS89 / UTM zone 31N (N-zE)"},
  {coordinate_system:"EPSG:5652", label: "EPSG:5652 / ETRS89 / UTM zone 32N (N-zE)"},
  {coordinate_system:"EPSG:5653", label: "EPSG:5653 / ETRS89 / UTM zone 33N (N-zE)"},
  {coordinate_system:"EPSG:5654", label: "EPSG:5654 / NAD83(HARN) / Vermont (ftUS)"},
  {coordinate_system:"EPSG:5655", label: "EPSG:5655 / NAD83(NSRS2007) / Vermont (ftUS)"},
  {coordinate_system:"EPSG:5659", label: "EPSG:5659 / Monte Mario / TM Emilia-Romagna"},
  {coordinate_system:"EPSG:5663", label: "EPSG:5663 / Pulkovo 1942(58) / Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5664", label: "EPSG:5664 / Pulkovo 1942(83) / Gauss-Kruger zone 2 (E-N)"},
  {coordinate_system:"EPSG:5665", label: "EPSG:5665 / Pulkovo 1942(83) / Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5666", label: "EPSG:5666 / PD/83 / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5667", label: "EPSG:5667 / PD/83 / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5668", label: "EPSG:5668 / RD/83 / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5669", label: "EPSG:5669 / RD/83 / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5670", label: "EPSG:5670 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5671", label: "EPSG:5671 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5672", label: "EPSG:5672 / Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5673", label: "EPSG:5673 / Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5674", label: "EPSG:5674 / Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5675", label: "EPSG:5675 / Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5676", label: "EPSG:5676 / DHDN / 3-degree Gauss-Kruger zone 2 (E-N)"},
  {coordinate_system:"EPSG:5677", label: "EPSG:5677 / DHDN / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5678", label: "EPSG:5678 / DHDN / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5679", label: "EPSG:5679 / DHDN / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5680", label: "EPSG:5680 / DHDN / 3-degree Gauss-Kruger zone 1 (E-N)"},
  {coordinate_system:"EPSG:5682", label: "EPSG:5682 / DB_REF / 3-degree Gauss-Kruger zone 2 (E-N)"},
  {coordinate_system:"EPSG:5683", label: "EPSG:5683 / DB_REF / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5684", label: "EPSG:5684 / DB_REF / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5685", label: "EPSG:5685 / DB_REF / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5700", label: "EPSG:5700 / NZGD2000 / UTM zone 1S"},
  {coordinate_system:"EPSG:5825", label: "EPSG:5825 / AGD66 / ACT Standard Grid"},
  {coordinate_system:"EPSG:5836", label: "EPSG:5836 / Yemen NGN96 / UTM zone 37N"},
  {coordinate_system:"EPSG:5837", label: "EPSG:5837 / Yemen NGN96 / UTM zone 40N"},
  {coordinate_system:"EPSG:5839", label: "EPSG:5839 / Peru96 / UTM zone 17S"},
  {coordinate_system:"EPSG:5842", label: "EPSG:5842 / WGS 84 / TM 12 SE"},
  {coordinate_system:"EPSG:5844", label: "EPSG:5844 / RGRDC 2005 / Congo TM zone 30"},
  {coordinate_system:"EPSG:5858", label: "EPSG:5858 / SAD69(96) / UTM zone 22S"},
  {coordinate_system:"EPSG:5875", label: "EPSG:5875 / SAD69(96) / UTM zone 18S"},
  {coordinate_system:"EPSG:5876", label: "EPSG:5876 / SAD69(96) / UTM zone 19S"},
  {coordinate_system:"EPSG:5877", label: "EPSG:5877 / SAD69(96) / UTM zone 20S"},
  {coordinate_system:"EPSG:5879", label: "EPSG:5879 / Cadastre 1997 / UTM zone 38S"},
  {coordinate_system:"EPSG:5880", label: "EPSG:5880 / SIRGAS 2000 / Brazil Polyconic"},
  {coordinate_system:"EPSG:5887", label: "EPSG:5887 / TGD2005 / Tonga Map Grid"},
  {coordinate_system:"EPSG:5896", label: "EPSG:5896 / VN-2000 / TM-3 zone 481"},
  {coordinate_system:"EPSG:5897", label: "EPSG:5897 / VN-2000 / TM-3 zone 482"},
  {coordinate_system:"EPSG:5898", label: "EPSG:5898 / VN-2000 / TM-3 zone 491"},
  {coordinate_system:"EPSG:5899", label: "EPSG:5899 / VN-2000 / TM-3 107-45"},
  {coordinate_system:"EPSG:5921", label: "EPSG:5921 / WGS 84 / EPSG Arctic Regional zone A1"},
  {coordinate_system:"EPSG:5922", label: "EPSG:5922 / WGS 84 / EPSG Arctic Regional zone A2"},
  {coordinate_system:"EPSG:5923", label: "EPSG:5923 / WGS 84 / EPSG Arctic Regional zone A3"},
  {coordinate_system:"EPSG:5924", label: "EPSG:5924 / WGS 84 / EPSG Arctic Regional zone A4"},
  {coordinate_system:"EPSG:5925", label: "EPSG:5925 / WGS 84 / EPSG Arctic Regional zone A5"},
  {coordinate_system:"EPSG:5926", label: "EPSG:5926 / WGS 84 / EPSG Arctic Regional zone B1"},
  {coordinate_system:"EPSG:5927", label: "EPSG:5927 / WGS 84 / EPSG Arctic Regional zone B2"},
  {coordinate_system:"EPSG:5928", label: "EPSG:5928 / WGS 84 / EPSG Arctic Regional zone B3"},
  {coordinate_system:"EPSG:5929", label: "EPSG:5929 / WGS 84 / EPSG Arctic Regional zone B4"},
  {coordinate_system:"EPSG:5930", label: "EPSG:5930 / WGS 84 / EPSG Arctic Regional zone B5"},
  {coordinate_system:"EPSG:5931", label: "EPSG:5931 / WGS 84 / EPSG Arctic Regional zone C1"},
  {coordinate_system:"EPSG:5932", label: "EPSG:5932 / WGS 84 / EPSG Arctic Regional zone C2"},
  {coordinate_system:"EPSG:5933", label: "EPSG:5933 / WGS 84 / EPSG Arctic Regional zone C3"},
  {coordinate_system:"EPSG:5934", label: "EPSG:5934 / WGS 84 / EPSG Arctic Regional zone C4"},
  {coordinate_system:"EPSG:5935", label: "EPSG:5935 / WGS 84 / EPSG Arctic Regional zone C5"},
  {coordinate_system:"EPSG:5936", label: "EPSG:5936 / WGS 84 / EPSG Alaska Polar Stereographic"},
  {coordinate_system:"EPSG:5937", label: "EPSG:5937 / WGS 84 / EPSG Canada Polar Stereographic"},
  {coordinate_system:"EPSG:5938", label: "EPSG:5938 / WGS 84 / EPSG Greenland Polar Stereographic"},
  {coordinate_system:"EPSG:5939", label: "EPSG:5939 / WGS 84 / EPSG Norway Polar Stereographic"},
  {coordinate_system:"EPSG:5940", label: "EPSG:5940 / WGS 84 / EPSG Russia Polar Stereographic"},
  {coordinate_system:"EPSG:6050", label: "EPSG:6050 / GR96 / EPSG Arctic zone 1-25"},
  {coordinate_system:"EPSG:6051", label: "EPSG:6051 / GR96 / EPSG Arctic zone 2-18"},
  {coordinate_system:"EPSG:6052", label: "EPSG:6052 / GR96 / EPSG Arctic zone 2-20"},
  {coordinate_system:"EPSG:6053", label: "EPSG:6053 / GR96 / EPSG Arctic zone 3-29"},
  {coordinate_system:"EPSG:6054", label: "EPSG:6054 / GR96 / EPSG Arctic zone 3-31"},
  {coordinate_system:"EPSG:6055", label: "EPSG:6055 / GR96 / EPSG Arctic zone 3-33"},
  {coordinate_system:"EPSG:6056", label: "EPSG:6056 / GR96 / EPSG Arctic zone 4-20"},
  {coordinate_system:"EPSG:6057", label: "EPSG:6057 / GR96 / EPSG Arctic zone 4-22"},
  {coordinate_system:"EPSG:6058", label: "EPSG:6058 / GR96 / EPSG Arctic zone 4-24"},
  {coordinate_system:"EPSG:6059", label: "EPSG:6059 / GR96 / EPSG Arctic zone 5-41"},
  {coordinate_system:"EPSG:6060", label: "EPSG:6060 / GR96 / EPSG Arctic zone 5-43"},
  {coordinate_system:"EPSG:6061", label: "EPSG:6061 / GR96 / EPSG Arctic zone 5-45"},
  {coordinate_system:"EPSG:6062", label: "EPSG:6062 / GR96 / EPSG Arctic zone 6-26"},
  {coordinate_system:"EPSG:6063", label: "EPSG:6063 / GR96 / EPSG Arctic zone 6-28"},
  {coordinate_system:"EPSG:6064", label: "EPSG:6064 / GR96 / EPSG Arctic zone 6-30"},
  {coordinate_system:"EPSG:6065", label: "EPSG:6065 / GR96 / EPSG Arctic zone 7-11"},
  {coordinate_system:"EPSG:6066", label: "EPSG:6066 / GR96 / EPSG Arctic zone 7-13"},
  {coordinate_system:"EPSG:6067", label: "EPSG:6067 / GR96 / EPSG Arctic zone 8-20"},
  {coordinate_system:"EPSG:6068", label: "EPSG:6068 / GR96 / EPSG Arctic zone 8-22"},
  {coordinate_system:"EPSG:6069", label: "EPSG:6069 / ETRS89 / EPSG Arctic zone 2-22"},
  {coordinate_system:"EPSG:6070", label: "EPSG:6070 / ETRS89 / EPSG Arctic zone 3-11"},
  {coordinate_system:"EPSG:6071", label: "EPSG:6071 / ETRS89 / EPSG Arctic zone 4-26"},
  {coordinate_system:"EPSG:6072", label: "EPSG:6072 / ETRS89 / EPSG Arctic zone 4-28"},
  {coordinate_system:"EPSG:6073", label: "EPSG:6073 / ETRS89 / EPSG Arctic zone 5-11"},
  {coordinate_system:"EPSG:6074", label: "EPSG:6074 / ETRS89 / EPSG Arctic zone 5-13"},
  {coordinate_system:"EPSG:6075", label: "EPSG:6075 / WGS 84 / EPSG Arctic zone 2-24"},
  {coordinate_system:"EPSG:6076", label: "EPSG:6076 / WGS 84 / EPSG Arctic zone 2-26"},
  {coordinate_system:"EPSG:6077", label: "EPSG:6077 / WGS 84 / EPSG Arctic zone 3-13"},
  {coordinate_system:"EPSG:6078", label: "EPSG:6078 / WGS 84 / EPSG Arctic zone 3-15"},
  {coordinate_system:"EPSG:6079", label: "EPSG:6079 / WGS 84 / EPSG Arctic zone 3-17"},
  {coordinate_system:"EPSG:6080", label: "EPSG:6080 / WGS 84 / EPSG Arctic zone 3-19"},
  {coordinate_system:"EPSG:6081", label: "EPSG:6081 / WGS 84 / EPSG Arctic zone 4-30"},
  {coordinate_system:"EPSG:6082", label: "EPSG:6082 / WGS 84 / EPSG Arctic zone 4-32"},
  {coordinate_system:"EPSG:6083", label: "EPSG:6083 / WGS 84 / EPSG Arctic zone 4-34"},
  {coordinate_system:"EPSG:6084", label: "EPSG:6084 / WGS 84 / EPSG Arctic zone 4-36"},
  {coordinate_system:"EPSG:6085", label: "EPSG:6085 / WGS 84 / EPSG Arctic zone 4-38"},
  {coordinate_system:"EPSG:6086", label: "EPSG:6086 / WGS 84 / EPSG Arctic zone 4-40"},
  {coordinate_system:"EPSG:6087", label: "EPSG:6087 / WGS 84 / EPSG Arctic zone 5-15"},
  {coordinate_system:"EPSG:6088", label: "EPSG:6088 / WGS 84 / EPSG Arctic zone 5-17"},
  {coordinate_system:"EPSG:6089", label: "EPSG:6089 / WGS 84 / EPSG Arctic zone 5-19"},
  {coordinate_system:"EPSG:6090", label: "EPSG:6090 / WGS 84 / EPSG Arctic zone 5-21"},
  {coordinate_system:"EPSG:6091", label: "EPSG:6091 / WGS 84 / EPSG Arctic zone 5-23"},
  {coordinate_system:"EPSG:6092", label: "EPSG:6092 / WGS 84 / EPSG Arctic zone 5-25"},
  {coordinate_system:"EPSG:6093", label: "EPSG:6093 / WGS 84 / EPSG Arctic zone 5-27"},
  {coordinate_system:"EPSG:6094", label: "EPSG:6094 / NAD83(NSRS2007) / EPSG Arctic zone 5-29"},
  {coordinate_system:"EPSG:6095", label: "EPSG:6095 / NAD83(NSRS2007) / EPSG Arctic zone 5-31"},
  {coordinate_system:"EPSG:6096", label: "EPSG:6096 / NAD83(NSRS2007) / EPSG Arctic zone 6-14"},
  {coordinate_system:"EPSG:6097", label: "EPSG:6097 / NAD83(NSRS2007) / EPSG Arctic zone 6-16"},
  {coordinate_system:"EPSG:6098", label: "EPSG:6098 / NAD83(CSRS) / EPSG Arctic zone 1-23"},
  {coordinate_system:"EPSG:6099", label: "EPSG:6099 / NAD83(CSRS) / EPSG Arctic zone 2-14"},
  {coordinate_system:"EPSG:6100", label: "EPSG:6100 / NAD83(CSRS) / EPSG Arctic zone 2-16"},
  {coordinate_system:"EPSG:6101", label: "EPSG:6101 / NAD83(CSRS) / EPSG Arctic zone 3-25"},
  {coordinate_system:"EPSG:6102", label: "EPSG:6102 / NAD83(CSRS) / EPSG Arctic zone 3-27"},
  {coordinate_system:"EPSG:6103", label: "EPSG:6103 / NAD83(CSRS) / EPSG Arctic zone 3-29"},
  {coordinate_system:"EPSG:6104", label: "EPSG:6104 / NAD83(CSRS) / EPSG Arctic zone 4-14"},
  {coordinate_system:"EPSG:6105", label: "EPSG:6105 / NAD83(CSRS) / EPSG Arctic zone 4-16"},
  {coordinate_system:"EPSG:6106", label: "EPSG:6106 / NAD83(CSRS) / EPSG Arctic zone 4-18"},
  {coordinate_system:"EPSG:6107", label: "EPSG:6107 / NAD83(CSRS) / EPSG Arctic zone 5-33"},
  {coordinate_system:"EPSG:6108", label: "EPSG:6108 / NAD83(CSRS) / EPSG Arctic zone 5-35"},
  {coordinate_system:"EPSG:6109", label: "EPSG:6109 / NAD83(CSRS) / EPSG Arctic zone 5-37"},
  {coordinate_system:"EPSG:6110", label: "EPSG:6110 / NAD83(CSRS) / EPSG Arctic zone 5-39"},
  {coordinate_system:"EPSG:6111", label: "EPSG:6111 / NAD83(CSRS) / EPSG Arctic zone 6-18"},
  {coordinate_system:"EPSG:6112", label: "EPSG:6112 / NAD83(CSRS) / EPSG Arctic zone 6-20"},
  {coordinate_system:"EPSG:6113", label: "EPSG:6113 / NAD83(CSRS) / EPSG Arctic zone 6-22"},
  {coordinate_system:"EPSG:6114", label: "EPSG:6114 / NAD83(CSRS) / EPSG Arctic zone 6-24"},
  {coordinate_system:"EPSG:6115", label: "EPSG:6115 / WGS 84 / EPSG Arctic zone 1-27"},
  {coordinate_system:"EPSG:6116", label: "EPSG:6116 / WGS 84 / EPSG Arctic zone 1-29"},
  {coordinate_system:"EPSG:6117", label: "EPSG:6117 / WGS 84 / EPSG Arctic zone 1-31"},
  {coordinate_system:"EPSG:6118", label: "EPSG:6118 / WGS 84 / EPSG Arctic zone 1-21"},
  {coordinate_system:"EPSG:6119", label: "EPSG:6119 / WGS 84 / EPSG Arctic zone 2-28"},
  {coordinate_system:"EPSG:6120", label: "EPSG:6120 / WGS 84 / EPSG Arctic zone 2-10"},
  {coordinate_system:"EPSG:6121", label: "EPSG:6121 / WGS 84 / EPSG Arctic zone 2-12"},
  {coordinate_system:"EPSG:6122", label: "EPSG:6122 / WGS 84 / EPSG Arctic zone 3-21"},
  {coordinate_system:"EPSG:6123", label: "EPSG:6123 / WGS 84 / EPSG Arctic zone 3-23"},
  {coordinate_system:"EPSG:6124", label: "EPSG:6124 / WGS 84 / EPSG Arctic zone 4-12"},
  {coordinate_system:"EPSG:6125", label: "EPSG:6125 / ETRS89 / EPSG Arctic zone 5-47"},
  {coordinate_system:"EPSG:6128", label: "EPSG:6128 / Grand Cayman National Grid 1959"},
  {coordinate_system:"EPSG:6129", label: "EPSG:6129 / Sister Islands National Grid 1961"},
  {coordinate_system:"EPSG:6201", label: "EPSG:6201 / NAD27 / Michigan Central"},
  {coordinate_system:"EPSG:6202", label: "EPSG:6202 / NAD27 / Michigan South"},
  {coordinate_system:"EPSG:6204", label: "EPSG:6204 / Macedonia State Coordinate System"},
  {coordinate_system:"EPSG:6210", label: "EPSG:6210 / SIRGAS 2000 / UTM zone 23N"},
  {coordinate_system:"EPSG:6211", label: "EPSG:6211 / SIRGAS 2000 / UTM zone 24N"},
  {coordinate_system:"EPSG:6244", label: "EPSG:6244 / MAGNA-SIRGAS / Arauca urban grid"},
  {coordinate_system:"EPSG:6245", label: "EPSG:6245 / MAGNA-SIRGAS / Armenia urban grid"},
  {coordinate_system:"EPSG:6246", label: "EPSG:6246 / MAGNA-SIRGAS / Barranquilla urban grid"},
  {coordinate_system:"EPSG:6247", label: "EPSG:6247 / MAGNA-SIRGAS / Bogota urban grid"},
  {coordinate_system:"EPSG:6248", label: "EPSG:6248 / MAGNA-SIRGAS / Bucaramanga urban grid"},
  {coordinate_system:"EPSG:6249", label: "EPSG:6249 / MAGNA-SIRGAS / Cali urban grid"},
  {coordinate_system:"EPSG:6250", label: "EPSG:6250 / MAGNA-SIRGAS / Cartagena urban grid"},
  {coordinate_system:"EPSG:6251", label: "EPSG:6251 / MAGNA-SIRGAS / Cucuta urban grid"},
  {coordinate_system:"EPSG:6252", label: "EPSG:6252 / MAGNA-SIRGAS / Florencia urban grid"},
  {coordinate_system:"EPSG:6253", label: "EPSG:6253 / MAGNA-SIRGAS / Ibague urban grid"},
  {coordinate_system:"EPSG:6254", label: "EPSG:6254 / MAGNA-SIRGAS / Inirida urban grid"},
  {coordinate_system:"EPSG:6255", label: "EPSG:6255 / MAGNA-SIRGAS / Leticia urban grid"},
  {coordinate_system:"EPSG:6256", label: "EPSG:6256 / MAGNA-SIRGAS / Manizales urban grid"},
  {coordinate_system:"EPSG:6257", label: "EPSG:6257 / MAGNA-SIRGAS / Medellin urban grid"},
  {coordinate_system:"EPSG:6258", label: "EPSG:6258 / MAGNA-SIRGAS / Mitu urban grid"},
  {coordinate_system:"EPSG:6259", label: "EPSG:6259 / MAGNA-SIRGAS / Mocoa urban grid"},
  {coordinate_system:"EPSG:6260", label: "EPSG:6260 / MAGNA-SIRGAS / Monteria urban grid"},
  {coordinate_system:"EPSG:6261", label: "EPSG:6261 / MAGNA-SIRGAS / Neiva urban grid"},
  {coordinate_system:"EPSG:6262", label: "EPSG:6262 / MAGNA-SIRGAS / Pasto urban grid"},
  {coordinate_system:"EPSG:6263", label: "EPSG:6263 / MAGNA-SIRGAS / Pereira urban grid"},
  {coordinate_system:"EPSG:6264", label: "EPSG:6264 / MAGNA-SIRGAS / Popayan urban grid"},
  {coordinate_system:"EPSG:6265", label: "EPSG:6265 / MAGNA-SIRGAS / Puerto Carreno urban grid"},
  {coordinate_system:"EPSG:6266", label: "EPSG:6266 / MAGNA-SIRGAS / Quibdo urban grid"},
  {coordinate_system:"EPSG:6267", label: "EPSG:6267 / MAGNA-SIRGAS / Riohacha urban grid"},
  {coordinate_system:"EPSG:6268", label: "EPSG:6268 / MAGNA-SIRGAS / San Andres urban grid"},
  {coordinate_system:"EPSG:6269", label: "EPSG:6269 / MAGNA-SIRGAS / San Jose del Guaviare urban grid"},
  {coordinate_system:"EPSG:6270", label: "EPSG:6270 / MAGNA-SIRGAS / Santa Marta urban grid"},
  {coordinate_system:"EPSG:6271", label: "EPSG:6271 / MAGNA-SIRGAS / Sucre urban grid"},
  {coordinate_system:"EPSG:6272", label: "EPSG:6272 / MAGNA-SIRGAS / Tunja urban grid"},
  {coordinate_system:"EPSG:6273", label: "EPSG:6273 / MAGNA-SIRGAS / Valledupar urban grid"},
  {coordinate_system:"EPSG:6274", label: "EPSG:6274 / MAGNA-SIRGAS / Villavicencio urban grid"},
  {coordinate_system:"EPSG:6275", label: "EPSG:6275 / MAGNA-SIRGAS / Yopal urban grid"},
  {coordinate_system:"EPSG:6307", label: "EPSG:6307 / NAD83(CORS96) / Puerto Rico and Virgin Is."},
  {coordinate_system:"EPSG:6312", label: "EPSG:6312 / CGRS93 / Cyprus Local Transverse Mercator"},
  {coordinate_system:"EPSG:6316", label: "EPSG:6316 / MGI 1901 / Balkans zone 7"},
  {coordinate_system:"EPSG:6328", label: "EPSG:6328 / NAD83(2011) / UTM zone 59N"},
  {coordinate_system:"EPSG:6329", label: "EPSG:6329 / NAD83(2011) / UTM zone 60N"},
  {coordinate_system:"EPSG:6330", label: "EPSG:6330 / NAD83(2011) / UTM zone 1N"},
  {coordinate_system:"EPSG:6331", label: "EPSG:6331 / NAD83(2011) / UTM zone 2N"},
  {coordinate_system:"EPSG:6332", label: "EPSG:6332 / NAD83(2011) / UTM zone 3N"},
  {coordinate_system:"EPSG:6333", label: "EPSG:6333 / NAD83(2011) / UTM zone 4N"},
  {coordinate_system:"EPSG:6334", label: "EPSG:6334 / NAD83(2011) / UTM zone 5N"},
  {coordinate_system:"EPSG:6335", label: "EPSG:6335 / NAD83(2011) / UTM zone 6N"},
  {coordinate_system:"EPSG:6336", label: "EPSG:6336 / NAD83(2011) / UTM zone 7N"},
  {coordinate_system:"EPSG:6337", label: "EPSG:6337 / NAD83(2011) / UTM zone 8N"},
  {coordinate_system:"EPSG:6338", label: "EPSG:6338 / NAD83(2011) / UTM zone 9N"},
  {coordinate_system:"EPSG:6339", label: "EPSG:6339 / NAD83(2011) / UTM zone 10N"},
  {coordinate_system:"EPSG:6340", label: "EPSG:6340 / NAD83(2011) / UTM zone 11N"},
  {coordinate_system:"EPSG:6341", label: "EPSG:6341 / NAD83(2011) / UTM zone 12N"},
  {coordinate_system:"EPSG:6342", label: "EPSG:6342 / NAD83(2011) / UTM zone 13N"},
  {coordinate_system:"EPSG:6343", label: "EPSG:6343 / NAD83(2011) / UTM zone 14N"},
  {coordinate_system:"EPSG:6344", label: "EPSG:6344 / NAD83(2011) / UTM zone 15N"},
  {coordinate_system:"EPSG:6345", label: "EPSG:6345 / NAD83(2011) / UTM zone 16N"},
  {coordinate_system:"EPSG:6346", label: "EPSG:6346 / NAD83(2011) / UTM zone 17N"},
  {coordinate_system:"EPSG:6347", label: "EPSG:6347 / NAD83(2011) / UTM zone 18N"},
  {coordinate_system:"EPSG:6348", label: "EPSG:6348 / NAD83(2011) / UTM zone 19N"},
  {coordinate_system:"EPSG:6350", label: "EPSG:6350 / NAD83(2011) / Conus Albers"},
  {coordinate_system:"EPSG:6351", label: "EPSG:6351 / NAD83(2011) / EPSG Arctic zone 5-29"},
  {coordinate_system:"EPSG:6352", label: "EPSG:6352 / NAD83(2011) / EPSG Arctic zone 5-31"},
  {coordinate_system:"EPSG:6353", label: "EPSG:6353 / NAD83(2011) / EPSG Arctic zone 6-14"},
  {coordinate_system:"EPSG:6354", label: "EPSG:6354 / NAD83(2011) / EPSG Arctic zone 6-16"},
  {coordinate_system:"EPSG:6355", label: "EPSG:6355 / NAD83(2011) / Alabama East"},
  {coordinate_system:"EPSG:6356", label: "EPSG:6356 / NAD83(2011) / Alabama West"},
  {coordinate_system:"EPSG:6362", label: "EPSG:6362 / Mexico ITRF92 / LCC"},
  {coordinate_system:"EPSG:6366", label: "EPSG:6366 / Mexico ITRF2008 / UTM zone 11N"},
  {coordinate_system:"EPSG:6367", label: "EPSG:6367 / Mexico ITRF2008 / UTM zone 12N"},
  {coordinate_system:"EPSG:6368", label: "EPSG:6368 / Mexico ITRF2008 / UTM zone 13N"},
  {coordinate_system:"EPSG:6369", label: "EPSG:6369 / Mexico ITRF2008 / UTM zone 14N"},
  {coordinate_system:"EPSG:6370", label: "EPSG:6370 / Mexico ITRF2008 / UTM zone 15N"},
  {coordinate_system:"EPSG:6371", label: "EPSG:6371 / Mexico ITRF2008 / UTM zone 16N"},
  {coordinate_system:"EPSG:6372", label: "EPSG:6372 / Mexico ITRF2008 / LCC"},
  {coordinate_system:"EPSG:6381", label: "EPSG:6381 / UCS-2000 / Ukraine TM zone 7"},
  {coordinate_system:"EPSG:6382", label: "EPSG:6382 / UCS-2000 / Ukraine TM zone 8"},
  {coordinate_system:"EPSG:6383", label: "EPSG:6383 / UCS-2000 / Ukraine TM zone 9"},
  {coordinate_system:"EPSG:6384", label: "EPSG:6384 / UCS-2000 / Ukraine TM zone 10"},
  {coordinate_system:"EPSG:6385", label: "EPSG:6385 / UCS-2000 / Ukraine TM zone 11"},
  {coordinate_system:"EPSG:6386", label: "EPSG:6386 / UCS-2000 / Ukraine TM zone 12"},
  {coordinate_system:"EPSG:6387", label: "EPSG:6387 / UCS-2000 / Ukraine TM zone 13"},
  {coordinate_system:"EPSG:6391", label: "EPSG:6391 / Cayman Islands National Grid 2011"},
  {coordinate_system:"EPSG:6393", label: "EPSG:6393 / NAD83(2011) / Alaska Albers"},
  {coordinate_system:"EPSG:6394", label: "EPSG:6394 / NAD83(2011) / Alaska zone 1"},
  {coordinate_system:"EPSG:6395", label: "EPSG:6395 / NAD83(2011) / Alaska zone 2"},
  {coordinate_system:"EPSG:6396", label: "EPSG:6396 / NAD83(2011) / Alaska zone 3"},
  {coordinate_system:"EPSG:6397", label: "EPSG:6397 / NAD83(2011) / Alaska zone 4"},
  {coordinate_system:"EPSG:6398", label: "EPSG:6398 / NAD83(2011) / Alaska zone 5"},
  {coordinate_system:"EPSG:6399", label: "EPSG:6399 / NAD83(2011) / Alaska zone 6"},
  {coordinate_system:"EPSG:6400", label: "EPSG:6400 / NAD83(2011) / Alaska zone 7"},
  {coordinate_system:"EPSG:6401", label: "EPSG:6401 / NAD83(2011) / Alaska zone 8"},
  {coordinate_system:"EPSG:6402", label: "EPSG:6402 / NAD83(2011) / Alaska zone 9"},
  {coordinate_system:"EPSG:6403", label: "EPSG:6403 / NAD83(2011) / Alaska zone 10"},
  {coordinate_system:"EPSG:6404", label: "EPSG:6404 / NAD83(2011) / Arizona Central"},
  {coordinate_system:"EPSG:6405", label: "EPSG:6405 / NAD83(2011) / Arizona Central (ft)"},
  {coordinate_system:"EPSG:6406", label: "EPSG:6406 / NAD83(2011) / Arizona East"},
  {coordinate_system:"EPSG:6407", label: "EPSG:6407 / NAD83(2011) / Arizona East (ft)"},
  {coordinate_system:"EPSG:6408", label: "EPSG:6408 / NAD83(2011) / Arizona West"},
  {coordinate_system:"EPSG:6409", label: "EPSG:6409 / NAD83(2011) / Arizona West (ft)"},
  {coordinate_system:"EPSG:6410", label: "EPSG:6410 / NAD83(2011) / Arkansas North"},
  {coordinate_system:"EPSG:6411", label: "EPSG:6411 / NAD83(2011) / Arkansas North (ftUS)"},
  {coordinate_system:"EPSG:6412", label: "EPSG:6412 / NAD83(2011) / Arkansas South"},
  {coordinate_system:"EPSG:6413", label: "EPSG:6413 / NAD83(2011) / Arkansas South (ftUS)"},
  {coordinate_system:"EPSG:6414", label: "EPSG:6414 / NAD83(2011) / California Albers"},
  {coordinate_system:"EPSG:6415", label: "EPSG:6415 / NAD83(2011) / California zone 1"},
  {coordinate_system:"EPSG:6416", label: "EPSG:6416 / NAD83(2011) / California zone 1 (ftUS)"},
  {coordinate_system:"EPSG:6417", label: "EPSG:6417 / NAD83(2011) / California zone 2"},
  {coordinate_system:"EPSG:6418", label: "EPSG:6418 / NAD83(2011) / California zone 2 (ftUS)"},
  {coordinate_system:"EPSG:6419", label: "EPSG:6419 / NAD83(2011) / California zone 3"},
  {coordinate_system:"EPSG:6420", label: "EPSG:6420 / NAD83(2011) / California zone 3 (ftUS)"},
  {coordinate_system:"EPSG:6421", label: "EPSG:6421 / NAD83(2011) / California zone 4"},
  {coordinate_system:"EPSG:6422", label: "EPSG:6422 / NAD83(2011) / California zone 4 (ftUS)"},
  {coordinate_system:"EPSG:6423", label: "EPSG:6423 / NAD83(2011) / California zone 5"},
  {coordinate_system:"EPSG:6424", label: "EPSG:6424 / NAD83(2011) / California zone 5 (ftUS)"},
  {coordinate_system:"EPSG:6425", label: "EPSG:6425 / NAD83(2011) / California zone 6"},
  {coordinate_system:"EPSG:6426", label: "EPSG:6426 / NAD83(2011) / California zone 6 (ftUS)"},
  {coordinate_system:"EPSG:6427", label: "EPSG:6427 / NAD83(2011) / Colorado Central"},
  {coordinate_system:"EPSG:6428", label: "EPSG:6428 / NAD83(2011) / Colorado Central (ftUS)"},
  {coordinate_system:"EPSG:6429", label: "EPSG:6429 / NAD83(2011) / Colorado North"},
  {coordinate_system:"EPSG:6430", label: "EPSG:6430 / NAD83(2011) / Colorado North (ftUS)"},
  {coordinate_system:"EPSG:6431", label: "EPSG:6431 / NAD83(2011) / Colorado South"},
  {coordinate_system:"EPSG:6432", label: "EPSG:6432 / NAD83(2011) / Colorado South (ftUS)"},
  {coordinate_system:"EPSG:6433", label: "EPSG:6433 / NAD83(2011) / Connecticut"},
  {coordinate_system:"EPSG:6434", label: "EPSG:6434 / NAD83(2011) / Connecticut (ftUS)"},
  {coordinate_system:"EPSG:6435", label: "EPSG:6435 / NAD83(2011) / Delaware"},
  {coordinate_system:"EPSG:6436", label: "EPSG:6436 / NAD83(2011) / Delaware (ftUS)"},
  {coordinate_system:"EPSG:6437", label: "EPSG:6437 / NAD83(2011) / Florida East"},
  {coordinate_system:"EPSG:6438", label: "EPSG:6438 / NAD83(2011) / Florida East (ftUS)"},
  {coordinate_system:"EPSG:6439", label: "EPSG:6439 / NAD83(2011) / Florida GDL Albers"},
  {coordinate_system:"EPSG:6440", label: "EPSG:6440 / NAD83(2011) / Florida North"},
  {coordinate_system:"EPSG:6441", label: "EPSG:6441 / NAD83(2011) / Florida North (ftUS)"},
  {coordinate_system:"EPSG:6442", label: "EPSG:6442 / NAD83(2011) / Florida West"},
  {coordinate_system:"EPSG:6443", label: "EPSG:6443 / NAD83(2011) / Florida West (ftUS)"},
  {coordinate_system:"EPSG:6444", label: "EPSG:6444 / NAD83(2011) / Georgia East"},
  {coordinate_system:"EPSG:6445", label: "EPSG:6445 / NAD83(2011) / Georgia East (ftUS)"},
  {coordinate_system:"EPSG:6446", label: "EPSG:6446 / NAD83(2011) / Georgia West"},
  {coordinate_system:"EPSG:6447", label: "EPSG:6447 / NAD83(2011) / Georgia West (ftUS)"},
  {coordinate_system:"EPSG:6448", label: "EPSG:6448 / NAD83(2011) / Idaho Central"},
  {coordinate_system:"EPSG:6449", label: "EPSG:6449 / NAD83(2011) / Idaho Central (ftUS)"},
  {coordinate_system:"EPSG:6450", label: "EPSG:6450 / NAD83(2011) / Idaho East"},
  {coordinate_system:"EPSG:6451", label: "EPSG:6451 / NAD83(2011) / Idaho East (ftUS)"},
  {coordinate_system:"EPSG:6452", label: "EPSG:6452 / NAD83(2011) / Idaho West"},
  {coordinate_system:"EPSG:6453", label: "EPSG:6453 / NAD83(2011) / Idaho West (ftUS)"},
  {coordinate_system:"EPSG:6454", label: "EPSG:6454 / NAD83(2011) / Illinois East"},
  {coordinate_system:"EPSG:6455", label: "EPSG:6455 / NAD83(2011) / Illinois East (ftUS)"},
  {coordinate_system:"EPSG:6456", label: "EPSG:6456 / NAD83(2011) / Illinois West"},
  {coordinate_system:"EPSG:6457", label: "EPSG:6457 / NAD83(2011) / Illinois West (ftUS)"},
  {coordinate_system:"EPSG:6458", label: "EPSG:6458 / NAD83(2011) / Indiana East"},
  {coordinate_system:"EPSG:6459", label: "EPSG:6459 / NAD83(2011) / Indiana East (ftUS)"},
  {coordinate_system:"EPSG:6460", label: "EPSG:6460 / NAD83(2011) / Indiana West"},
  {coordinate_system:"EPSG:6461", label: "EPSG:6461 / NAD83(2011) / Indiana West (ftUS)"},
  {coordinate_system:"EPSG:6462", label: "EPSG:6462 / NAD83(2011) / Iowa North"},
  {coordinate_system:"EPSG:6463", label: "EPSG:6463 / NAD83(2011) / Iowa North (ftUS)"},
  {coordinate_system:"EPSG:6464", label: "EPSG:6464 / NAD83(2011) / Iowa South"},
  {coordinate_system:"EPSG:6465", label: "EPSG:6465 / NAD83(2011) / Iowa South (ftUS)"},
  {coordinate_system:"EPSG:6466", label: "EPSG:6466 / NAD83(2011) / Kansas North"},
  {coordinate_system:"EPSG:6467", label: "EPSG:6467 / NAD83(2011) / Kansas North (ftUS)"},
  {coordinate_system:"EPSG:6468", label: "EPSG:6468 / NAD83(2011) / Kansas South"},
  {coordinate_system:"EPSG:6469", label: "EPSG:6469 / NAD83(2011) / Kansas South (ftUS)"},
  {coordinate_system:"EPSG:6470", label: "EPSG:6470 / NAD83(2011) / Kentucky North"},
  {coordinate_system:"EPSG:6471", label: "EPSG:6471 / NAD83(2011) / Kentucky North (ftUS)"},
  {coordinate_system:"EPSG:6472", label: "EPSG:6472 / NAD83(2011) / Kentucky Single Zone"},
  {coordinate_system:"EPSG:6473", label: "EPSG:6473 / NAD83(2011) / Kentucky Single Zone (ftUS)"},
  {coordinate_system:"EPSG:6474", label: "EPSG:6474 / NAD83(2011) / Kentucky South"},
  {coordinate_system:"EPSG:6475", label: "EPSG:6475 / NAD83(2011) / Kentucky South (ftUS)"},
  {coordinate_system:"EPSG:6476", label: "EPSG:6476 / NAD83(2011) / Louisiana North"},
  {coordinate_system:"EPSG:6477", label: "EPSG:6477 / NAD83(2011) / Louisiana North (ftUS)"},
  {coordinate_system:"EPSG:6478", label: "EPSG:6478 / NAD83(2011) / Louisiana South"},
  {coordinate_system:"EPSG:6479", label: "EPSG:6479 / NAD83(2011) / Louisiana South (ftUS)"},
  {coordinate_system:"EPSG:6480", label: "EPSG:6480 / NAD83(2011) / Maine CS2000 Central"},
  {coordinate_system:"EPSG:6481", label: "EPSG:6481 / NAD83(2011) / Maine CS2000 East"},
  {coordinate_system:"EPSG:6482", label: "EPSG:6482 / NAD83(2011) / Maine CS2000 West"},
  {coordinate_system:"EPSG:6483", label: "EPSG:6483 / NAD83(2011) / Maine East"},
  {coordinate_system:"EPSG:6484", label: "EPSG:6484 / NAD83(2011) / Maine East (ftUS)"},
  {coordinate_system:"EPSG:6485", label: "EPSG:6485 / NAD83(2011) / Maine West"},
  {coordinate_system:"EPSG:6486", label: "EPSG:6486 / NAD83(2011) / Maine West (ftUS)"},
  {coordinate_system:"EPSG:6487", label: "EPSG:6487 / NAD83(2011) / Maryland"},
  {coordinate_system:"EPSG:6488", label: "EPSG:6488 / NAD83(2011) / Maryland (ftUS)"},
  {coordinate_system:"EPSG:6489", label: "EPSG:6489 / NAD83(2011) / Massachusetts Island"},
  {coordinate_system:"EPSG:6490", label: "EPSG:6490 / NAD83(2011) / Massachusetts Island (ftUS)"},
  {coordinate_system:"EPSG:6491", label: "EPSG:6491 / NAD83(2011) / Massachusetts Mainland"},
  {coordinate_system:"EPSG:6492", label: "EPSG:6492 / NAD83(2011) / Massachusetts Mainland (ftUS)"},
  {coordinate_system:"EPSG:6493", label: "EPSG:6493 / NAD83(2011) / Michigan Central"},
  {coordinate_system:"EPSG:6494", label: "EPSG:6494 / NAD83(2011) / Michigan Central (ft)"},
  {coordinate_system:"EPSG:6495", label: "EPSG:6495 / NAD83(2011) / Michigan North"},
  {coordinate_system:"EPSG:6496", label: "EPSG:6496 / NAD83(2011) / Michigan North (ft)"},
  {coordinate_system:"EPSG:6497", label: "EPSG:6497 / NAD83(2011) / Michigan Oblique Mercator"},
  {coordinate_system:"EPSG:6498", label: "EPSG:6498 / NAD83(2011) / Michigan South"},
  {coordinate_system:"EPSG:6499", label: "EPSG:6499 / NAD83(2011) / Michigan South (ft)"},
  {coordinate_system:"EPSG:6500", label: "EPSG:6500 / NAD83(2011) / Minnesota Central"},
  {coordinate_system:"EPSG:6501", label: "EPSG:6501 / NAD83(2011) / Minnesota Central (ftUS)"},
  {coordinate_system:"EPSG:6502", label: "EPSG:6502 / NAD83(2011) / Minnesota North"},
  {coordinate_system:"EPSG:6503", label: "EPSG:6503 / NAD83(2011) / Minnesota North (ftUS)"},
  {coordinate_system:"EPSG:6504", label: "EPSG:6504 / NAD83(2011) / Minnesota South"},
  {coordinate_system:"EPSG:6505", label: "EPSG:6505 / NAD83(2011) / Minnesota South (ftUS)"},
  {coordinate_system:"EPSG:6506", label: "EPSG:6506 / NAD83(2011) / Mississippi East"},
  {coordinate_system:"EPSG:6507", label: "EPSG:6507 / NAD83(2011) / Mississippi East (ftUS)"},
  {coordinate_system:"EPSG:6508", label: "EPSG:6508 / NAD83(2011) / Mississippi TM"},
  {coordinate_system:"EPSG:6509", label: "EPSG:6509 / NAD83(2011) / Mississippi West"},
  {coordinate_system:"EPSG:6510", label: "EPSG:6510 / NAD83(2011) / Mississippi West (ftUS)"},
  {coordinate_system:"EPSG:6511", label: "EPSG:6511 / NAD83(2011) / Missouri Central"},
  {coordinate_system:"EPSG:6512", label: "EPSG:6512 / NAD83(2011) / Missouri East"},
  {coordinate_system:"EPSG:6513", label: "EPSG:6513 / NAD83(2011) / Missouri West"},
  {coordinate_system:"EPSG:6514", label: "EPSG:6514 / NAD83(2011) / Montana"},
  {coordinate_system:"EPSG:6515", label: "EPSG:6515 / NAD83(2011) / Montana (ft)"},
  {coordinate_system:"EPSG:6516", label: "EPSG:6516 / NAD83(2011) / Nebraska"},
  {coordinate_system:"EPSG:6518", label: "EPSG:6518 / NAD83(2011) / Nevada Central"},
  {coordinate_system:"EPSG:6519", label: "EPSG:6519 / NAD83(2011) / Nevada Central (ftUS)"},
  {coordinate_system:"EPSG:6520", label: "EPSG:6520 / NAD83(2011) / Nevada East"},
  {coordinate_system:"EPSG:6521", label: "EPSG:6521 / NAD83(2011) / Nevada East (ftUS)"},
  {coordinate_system:"EPSG:6522", label: "EPSG:6522 / NAD83(2011) / Nevada West"},
  {coordinate_system:"EPSG:6523", label: "EPSG:6523 / NAD83(2011) / Nevada West (ftUS)"},
  {coordinate_system:"EPSG:6524", label: "EPSG:6524 / NAD83(2011) / New Hampshire"},
  {coordinate_system:"EPSG:6525", label: "EPSG:6525 / NAD83(2011) / New Hampshire (ftUS)"},
  {coordinate_system:"EPSG:6526", label: "EPSG:6526 / NAD83(2011) / New Jersey"},
  {coordinate_system:"EPSG:6527", label: "EPSG:6527 / NAD83(2011) / New Jersey (ftUS)"},
  {coordinate_system:"EPSG:6528", label: "EPSG:6528 / NAD83(2011) / New Mexico Central"},
  {coordinate_system:"EPSG:6529", label: "EPSG:6529 / NAD83(2011) / New Mexico Central (ftUS)"},
  {coordinate_system:"EPSG:6530", label: "EPSG:6530 / NAD83(2011) / New Mexico East"},
  {coordinate_system:"EPSG:6531", label: "EPSG:6531 / NAD83(2011) / New Mexico East (ftUS)"},
  {coordinate_system:"EPSG:6532", label: "EPSG:6532 / NAD83(2011) / New Mexico West"},
  {coordinate_system:"EPSG:6533", label: "EPSG:6533 / NAD83(2011) / New Mexico West (ftUS)"},
  {coordinate_system:"EPSG:6534", label: "EPSG:6534 / NAD83(2011) / New York Central"},
  {coordinate_system:"EPSG:6535", label: "EPSG:6535 / NAD83(2011) / New York Central (ftUS)"},
  {coordinate_system:"EPSG:6536", label: "EPSG:6536 / NAD83(2011) / New York East"},
  {coordinate_system:"EPSG:6537", label: "EPSG:6537 / NAD83(2011) / New York East (ftUS)"},
  {coordinate_system:"EPSG:6538", label: "EPSG:6538 / NAD83(2011) / New York Long Island"},
  {coordinate_system:"EPSG:6539", label: "EPSG:6539 / NAD83(2011) / New York Long Island (ftUS)"},
  {coordinate_system:"EPSG:6540", label: "EPSG:6540 / NAD83(2011) / New York West"},
  {coordinate_system:"EPSG:6541", label: "EPSG:6541 / NAD83(2011) / New York West (ftUS)"},
  {coordinate_system:"EPSG:6542", label: "EPSG:6542 / NAD83(2011) / North Carolina"},
  {coordinate_system:"EPSG:6543", label: "EPSG:6543 / NAD83(2011) / North Carolina (ftUS)"},
  {coordinate_system:"EPSG:6544", label: "EPSG:6544 / NAD83(2011) / North Dakota North"},
  {coordinate_system:"EPSG:6545", label: "EPSG:6545 / NAD83(2011) / North Dakota North (ft)"},
  {coordinate_system:"EPSG:6546", label: "EPSG:6546 / NAD83(2011) / North Dakota South"},
  {coordinate_system:"EPSG:6547", label: "EPSG:6547 / NAD83(2011) / North Dakota South (ft)"},
  {coordinate_system:"EPSG:6548", label: "EPSG:6548 / NAD83(2011) / Ohio North"},
  {coordinate_system:"EPSG:6549", label: "EPSG:6549 / NAD83(2011) / Ohio North (ftUS)"},
  {coordinate_system:"EPSG:6550", label: "EPSG:6550 / NAD83(2011) / Ohio South"},
  {coordinate_system:"EPSG:6551", label: "EPSG:6551 / NAD83(2011) / Ohio South (ftUS)"},
  {coordinate_system:"EPSG:6552", label: "EPSG:6552 / NAD83(2011) / Oklahoma North"},
  {coordinate_system:"EPSG:6553", label: "EPSG:6553 / NAD83(2011) / Oklahoma North (ftUS)"},
  {coordinate_system:"EPSG:6554", label: "EPSG:6554 / NAD83(2011) / Oklahoma South"},
  {coordinate_system:"EPSG:6555", label: "EPSG:6555 / NAD83(2011) / Oklahoma South (ftUS)"},
  {coordinate_system:"EPSG:6556", label: "EPSG:6556 / NAD83(2011) / Oregon LCC (m)"},
  {coordinate_system:"EPSG:6557", label: "EPSG:6557 / NAD83(2011) / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:6558", label: "EPSG:6558 / NAD83(2011) / Oregon North"},
  {coordinate_system:"EPSG:6559", label: "EPSG:6559 / NAD83(2011) / Oregon North (ft)"},
  {coordinate_system:"EPSG:6560", label: "EPSG:6560 / NAD83(2011) / Oregon South"},
  {coordinate_system:"EPSG:6561", label: "EPSG:6561 / NAD83(2011) / Oregon South (ft)"},
  {coordinate_system:"EPSG:6562", label: "EPSG:6562 / NAD83(2011) / Pennsylvania North"},
  {coordinate_system:"EPSG:6563", label: "EPSG:6563 / NAD83(2011) / Pennsylvania North (ftUS)"},
  {coordinate_system:"EPSG:6564", label: "EPSG:6564 / NAD83(2011) / Pennsylvania South"},
  {coordinate_system:"EPSG:6565", label: "EPSG:6565 / NAD83(2011) / Pennsylvania South (ftUS)"},
  {coordinate_system:"EPSG:6566", label: "EPSG:6566 / NAD83(2011) / Puerto Rico and Virgin Is."},
  {coordinate_system:"EPSG:6567", label: "EPSG:6567 / NAD83(2011) / Rhode Island"},
  {coordinate_system:"EPSG:6568", label: "EPSG:6568 / NAD83(2011) / Rhode Island (ftUS)"},
  {coordinate_system:"EPSG:6569", label: "EPSG:6569 / NAD83(2011) / South Carolina"},
  {coordinate_system:"EPSG:6570", label: "EPSG:6570 / NAD83(2011) / South Carolina (ft)"},
  {coordinate_system:"EPSG:6571", label: "EPSG:6571 / NAD83(2011) / South Dakota North"},
  {coordinate_system:"EPSG:6572", label: "EPSG:6572 / NAD83(2011) / South Dakota North (ftUS)"},
  {coordinate_system:"EPSG:6573", label: "EPSG:6573 / NAD83(2011) / South Dakota South"},
  {coordinate_system:"EPSG:6574", label: "EPSG:6574 / NAD83(2011) / South Dakota South (ftUS)"},
  {coordinate_system:"EPSG:6575", label: "EPSG:6575 / NAD83(2011) / Tennessee"},
  {coordinate_system:"EPSG:6576", label: "EPSG:6576 / NAD83(2011) / Tennessee (ftUS)"},
  {coordinate_system:"EPSG:6577", label: "EPSG:6577 / NAD83(2011) / Texas Central"},
  {coordinate_system:"EPSG:6578", label: "EPSG:6578 / NAD83(2011) / Texas Central (ftUS)"},
  {coordinate_system:"EPSG:6579", label: "EPSG:6579 / NAD83(2011) / Texas Centric Albers Equal Area"},
  {coordinate_system:"EPSG:6580", label: "EPSG:6580 / NAD83(2011) / Texas Centric Lambert Conformal"},
  {coordinate_system:"EPSG:6581", label: "EPSG:6581 / NAD83(2011) / Texas North"},
  {coordinate_system:"EPSG:6582", label: "EPSG:6582 / NAD83(2011) / Texas North (ftUS)"},
  {coordinate_system:"EPSG:6583", label: "EPSG:6583 / NAD83(2011) / Texas North Central"},
  {coordinate_system:"EPSG:6584", label: "EPSG:6584 / NAD83(2011) / Texas North Central (ftUS)"},
  {coordinate_system:"EPSG:6585", label: "EPSG:6585 / NAD83(2011) / Texas South"},
  {coordinate_system:"EPSG:6586", label: "EPSG:6586 / NAD83(2011) / Texas South (ftUS)"},
  {coordinate_system:"EPSG:6587", label: "EPSG:6587 / NAD83(2011) / Texas South Central"},
  {coordinate_system:"EPSG:6588", label: "EPSG:6588 / NAD83(2011) / Texas South Central (ftUS)"},
  {coordinate_system:"EPSG:6589", label: "EPSG:6589 / NAD83(2011) / Vermont"},
  {coordinate_system:"EPSG:6590", label: "EPSG:6590 / NAD83(2011) / Vermont (ftUS)"},
  {coordinate_system:"EPSG:6591", label: "EPSG:6591 / NAD83(2011) / Virginia Lambert"},
  {coordinate_system:"EPSG:6592", label: "EPSG:6592 / NAD83(2011) / Virginia North"},
  {coordinate_system:"EPSG:6593", label: "EPSG:6593 / NAD83(2011) / Virginia North (ftUS)"},
  {coordinate_system:"EPSG:6594", label: "EPSG:6594 / NAD83(2011) / Virginia South"},
  {coordinate_system:"EPSG:6595", label: "EPSG:6595 / NAD83(2011) / Virginia South (ftUS)"},
  {coordinate_system:"EPSG:6596", label: "EPSG:6596 / NAD83(2011) / Washington North"},
  {coordinate_system:"EPSG:6597", label: "EPSG:6597 / NAD83(2011) / Washington North (ftUS)"},
  {coordinate_system:"EPSG:6598", label: "EPSG:6598 / NAD83(2011) / Washington South"},
  {coordinate_system:"EPSG:6599", label: "EPSG:6599 / NAD83(2011) / Washington South (ftUS)"},
  {coordinate_system:"EPSG:6600", label: "EPSG:6600 / NAD83(2011) / West Virginia North"},
  {coordinate_system:"EPSG:6601", label: "EPSG:6601 / NAD83(2011) / West Virginia North (ftUS)"},
  {coordinate_system:"EPSG:6602", label: "EPSG:6602 / NAD83(2011) / West Virginia South"},
  {coordinate_system:"EPSG:6603", label: "EPSG:6603 / NAD83(2011) / West Virginia South (ftUS)"},
  {coordinate_system:"EPSG:6605", label: "EPSG:6605 / NAD83(2011) / Wisconsin Central (ftUS)"},
  {coordinate_system:"EPSG:6606", label: "EPSG:6606 / NAD83(2011) / Wisconsin North"},
  {coordinate_system:"EPSG:6607", label: "EPSG:6607 / NAD83(2011) / Wisconsin North (ftUS)"},
  {coordinate_system:"EPSG:6608", label: "EPSG:6608 / NAD83(2011) / Wisconsin South"},
  {coordinate_system:"EPSG:6609", label: "EPSG:6609 / NAD83(2011) / Wisconsin South (ftUS)"},
  {coordinate_system:"EPSG:6610", label: "EPSG:6610 / NAD83(2011) / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:6611", label: "EPSG:6611 / NAD83(2011) / Wyoming East"},
  {coordinate_system:"EPSG:6612", label: "EPSG:6612 / NAD83(2011) / Wyoming East (ftUS)"},
  {coordinate_system:"EPSG:6613", label: "EPSG:6613 / NAD83(2011) / Wyoming East Central"},
  {coordinate_system:"EPSG:6614", label: "EPSG:6614 / NAD83(2011) / Wyoming East Central (ftUS)"},
  {coordinate_system:"EPSG:6615", label: "EPSG:6615 / NAD83(2011) / Wyoming West"},
  {coordinate_system:"EPSG:6616", label: "EPSG:6616 / NAD83(2011) / Wyoming West (ftUS)"},
  {coordinate_system:"EPSG:6617", label: "EPSG:6617 / NAD83(2011) / Wyoming West Central"},
  {coordinate_system:"EPSG:6618", label: "EPSG:6618 / NAD83(2011) / Wyoming West Central (ftUS)"},
  {coordinate_system:"EPSG:6619", label: "EPSG:6619 / NAD83(2011) / Utah Central"},
  {coordinate_system:"EPSG:6620", label: "EPSG:6620 / NAD83(2011) / Utah North"},
  {coordinate_system:"EPSG:6621", label: "EPSG:6621 / NAD83(2011) / Utah South"},
  {coordinate_system:"EPSG:6622", label: "EPSG:6622 / NAD83(CSRS) / Quebec Lambert"},
  {coordinate_system:"EPSG:6623", label: "EPSG:6623 / NAD83 / Quebec Albers"},
  {coordinate_system:"EPSG:6624", label: "EPSG:6624 / NAD83(CSRS) / Quebec Albers"},
  {coordinate_system:"EPSG:6625", label: "EPSG:6625 / NAD83(2011) / Utah Central (ftUS)"},
  {coordinate_system:"EPSG:6626", label: "EPSG:6626 / NAD83(2011) / Utah North (ftUS)"},
  {coordinate_system:"EPSG:6627", label: "EPSG:6627 / NAD83(2011) / Utah South (ftUS)"},
  {coordinate_system:"EPSG:6628", label: "EPSG:6628 / NAD83(PA11) / Hawaii zone 1"},
  {coordinate_system:"EPSG:6629", label: "EPSG:6629 / NAD83(PA11) / Hawaii zone 2"},
  {coordinate_system:"EPSG:6630", label: "EPSG:6630 / NAD83(PA11) / Hawaii zone 3"},
  {coordinate_system:"EPSG:6631", label: "EPSG:6631 / NAD83(PA11) / Hawaii zone 4"},
  {coordinate_system:"EPSG:6632", label: "EPSG:6632 / NAD83(PA11) / Hawaii zone 5"},
  {coordinate_system:"EPSG:6633", label: "EPSG:6633 / NAD83(PA11) / Hawaii zone 3 (ftUS)"},
  {coordinate_system:"EPSG:6634", label: "EPSG:6634 / NAD83(PA11) / UTM zone 4N"},
  {coordinate_system:"EPSG:6635", label: "EPSG:6635 / NAD83(PA11) / UTM zone 5N"},
  {coordinate_system:"EPSG:6636", label: "EPSG:6636 / NAD83(PA11) / UTM zone 2S"},
  {coordinate_system:"EPSG:6637", label: "EPSG:6637 / NAD83(MA11) / Guam Map Grid"},
  {coordinate_system:"EPSG:6646", label: "EPSG:6646 / Karbala 1979 / Iraq National Grid"},
  {coordinate_system:"EPSG:6669", label: "EPSG:6669 / JGD2011 / Japan Plane Rectangular CS I"},
  {coordinate_system:"EPSG:6670", label: "EPSG:6670 / JGD2011 / Japan Plane Rectangular CS II"},
  {coordinate_system:"EPSG:6671", label: "EPSG:6671 / JGD2011 / Japan Plane Rectangular CS III"},
  {coordinate_system:"EPSG:6672", label: "EPSG:6672 / JGD2011 / Japan Plane Rectangular CS IV"},
  {coordinate_system:"EPSG:6673", label: "EPSG:6673 / JGD2011 / Japan Plane Rectangular CS V"},
  {coordinate_system:"EPSG:6674", label: "EPSG:6674 / JGD2011 / Japan Plane Rectangular CS VI"},
  {coordinate_system:"EPSG:6675", label: "EPSG:6675 / JGD2011 / Japan Plane Rectangular CS VII"},
  {coordinate_system:"EPSG:6676", label: "EPSG:6676 / JGD2011 / Japan Plane Rectangular CS VIII"},
  {coordinate_system:"EPSG:6677", label: "EPSG:6677 / JGD2011 / Japan Plane Rectangular CS IX"},
  {coordinate_system:"EPSG:6678", label: "EPSG:6678 / JGD2011 / Japan Plane Rectangular CS X"},
  {coordinate_system:"EPSG:6679", label: "EPSG:6679 / JGD2011 / Japan Plane Rectangular CS XI"},
  {coordinate_system:"EPSG:6680", label: "EPSG:6680 / JGD2011 / Japan Plane Rectangular CS XII"},
  {coordinate_system:"EPSG:6681", label: "EPSG:6681 / JGD2011 / Japan Plane Rectangular CS XIII"},
  {coordinate_system:"EPSG:6682", label: "EPSG:6682 / JGD2011 / Japan Plane Rectangular CS XIV"},
  {coordinate_system:"EPSG:6683", label: "EPSG:6683 / JGD2011 / Japan Plane Rectangular CS XV"},
  {coordinate_system:"EPSG:6684", label: "EPSG:6684 / JGD2011 / Japan Plane Rectangular CS XVI"},
  {coordinate_system:"EPSG:6685", label: "EPSG:6685 / JGD2011 / Japan Plane Rectangular CS XVII"},
  {coordinate_system:"EPSG:6686", label: "EPSG:6686 / JGD2011 / Japan Plane Rectangular CS XVIII"},
  {coordinate_system:"EPSG:6687", label: "EPSG:6687 / JGD2011 / Japan Plane Rectangular CS XIX"},
  {coordinate_system:"EPSG:6688", label: "EPSG:6688 / JGD2011 / UTM zone 51N"},
  {coordinate_system:"EPSG:6689", label: "EPSG:6689 / JGD2011 / UTM zone 52N"},
  {coordinate_system:"EPSG:6690", label: "EPSG:6690 / JGD2011 / UTM zone 53N"},
  {coordinate_system:"EPSG:6691", label: "EPSG:6691 / JGD2011 / UTM zone 54N"},
  {coordinate_system:"EPSG:6692", label: "EPSG:6692 / JGD2011 / UTM zone 55N"},
  {coordinate_system:"EPSG:6703", label: "EPSG:6703 / WGS 84 / TM 60 SW"},
  {coordinate_system:"EPSG:6707", label: "EPSG:6707 / RDN2008 / UTM zone 32N (N-E)"},
  {coordinate_system:"EPSG:6708", label: "EPSG:6708 / RDN2008 / UTM zone 33N (N-E)"},
  {coordinate_system:"EPSG:6709", label: "EPSG:6709 / RDN2008 / UTM zone 34N (N-E)"},
  {coordinate_system:"EPSG:6720", label: "EPSG:6720 / WGS 84 / CIG92"},
  {coordinate_system:"EPSG:6721", label: "EPSG:6721 / GDA94 / CIG94"},
  {coordinate_system:"EPSG:6722", label: "EPSG:6722 / WGS 84 / CKIG92"},
  {coordinate_system:"EPSG:6723", label: "EPSG:6723 / GDA94 / CKIG94"},
  {coordinate_system:"EPSG:6736", label: "EPSG:6736 / GDA94 / MGA zone 46"},
  {coordinate_system:"EPSG:6737", label: "EPSG:6737 / GDA94 / MGA zone 47"},
  {coordinate_system:"EPSG:6738", label: "EPSG:6738 / GDA94 / MGA zone 59"},
  {coordinate_system:"EPSG:6784", label: "EPSG:6784 / NAD83(CORS96) / Oregon Baker zone (m)"},
  {coordinate_system:"EPSG:6785", label: "EPSG:6785 / NAD83(CORS96) / Oregon Baker zone (ft)"},
  {coordinate_system:"EPSG:6786", label: "EPSG:6786 / NAD83(2011) / Oregon Baker zone (m)"},
  {coordinate_system:"EPSG:6787", label: "EPSG:6787 / NAD83(2011) / Oregon Baker zone (ft)"},
  {coordinate_system:"EPSG:6788", label: "EPSG:6788 / NAD83(CORS96) / Oregon Bend-Klamath Falls zone (m)"},
  {coordinate_system:"EPSG:6789", label: "EPSG:6789 / NAD83(CORS96) / Oregon Bend-Klamath Falls zone (ft)"},
  {coordinate_system:"EPSG:6790", label: "EPSG:6790 / NAD83(2011) / Oregon Bend-Klamath Falls zone (m)"},
  {coordinate_system:"EPSG:6791", label: "EPSG:6791 / NAD83(2011) / Oregon Bend-Klamath Falls zone (ft)"},
  {coordinate_system:"EPSG:6792", label: "EPSG:6792 / NAD83(CORS96) / Oregon Bend-Redmond-Prineville zone (m)"},
  {coordinate_system:"EPSG:6793", label: "EPSG:6793 / NAD83(CORS96) / Oregon Bend-Redmond-Prineville zone (ft)"},
  {coordinate_system:"EPSG:6794", label: "EPSG:6794 / NAD83(2011) / Oregon Bend-Redmond-Prineville zone (m)"},
  {coordinate_system:"EPSG:6795", label: "EPSG:6795 / NAD83(2011) / Oregon Bend-Redmond-Prineville zone (ft)"},
  {coordinate_system:"EPSG:6796", label: "EPSG:6796 / NAD83(CORS96) / Oregon Bend-Burns zone (m)"},
  {coordinate_system:"EPSG:6797", label: "EPSG:6797 / NAD83(CORS96) / Oregon Bend-Burns zone (ft)"},
  {coordinate_system:"EPSG:6798", label: "EPSG:6798 / NAD83(2011) / Oregon Bend-Burns zone (m)"},
  {coordinate_system:"EPSG:6799", label: "EPSG:6799 / NAD83(2011) / Oregon Bend-Burns zone (ft)"},
  {coordinate_system:"EPSG:6800", label: "EPSG:6800 / NAD83(CORS96) / Oregon Canyonville-Grants Pass zone (m)"},
  {coordinate_system:"EPSG:6801", label: "EPSG:6801 / NAD83(CORS96) / Oregon Canyonville-Grants Pass zone (ft)"},
  {coordinate_system:"EPSG:6802", label: "EPSG:6802 / NAD83(2011) / Oregon Canyonville-Grants Pass zone (m)"},
  {coordinate_system:"EPSG:6803", label: "EPSG:6803 / NAD83(2011) / Oregon Canyonville-Grants Pass zone (ft)"},
  {coordinate_system:"EPSG:6804", label: "EPSG:6804 / NAD83(CORS96) / Oregon Columbia River East zone (m)"},
  {coordinate_system:"EPSG:6805", label: "EPSG:6805 / NAD83(CORS96) / Oregon Columbia River East zone (ft)"},
  {coordinate_system:"EPSG:6806", label: "EPSG:6806 / NAD83(2011) / Oregon Columbia River East zone (m)"},
  {coordinate_system:"EPSG:6807", label: "EPSG:6807 / NAD83(2011) / Oregon Columbia River East zone (ft)"},
  {coordinate_system:"EPSG:6808", label: "EPSG:6808 / NAD83(CORS96) / Oregon Columbia River West zone (m)"},
  {coordinate_system:"EPSG:6809", label: "EPSG:6809 / NAD83(CORS96) / Oregon Columbia River West zone (ft)"},
  {coordinate_system:"EPSG:6810", label: "EPSG:6810 / NAD83(2011) / Oregon Columbia River West zone (m)"},
  {coordinate_system:"EPSG:6811", label: "EPSG:6811 / NAD83(2011) / Oregon Columbia River West zone (ft)"},
  {coordinate_system:"EPSG:6812", label: "EPSG:6812 / NAD83(CORS96) / Oregon Cottage Grove-Canyonville zone (m)"},
  {coordinate_system:"EPSG:6813", label: "EPSG:6813 / NAD83(CORS96) / Oregon Cottage Grove-Canyonville zone (ft)"},
  {coordinate_system:"EPSG:6814", label: "EPSG:6814 / NAD83(2011) / Oregon Cottage Grove-Canyonville zone (m)"},
  {coordinate_system:"EPSG:6815", label: "EPSG:6815 / NAD83(2011) / Oregon Cottage Grove-Canyonville zone (ft)"},
  {coordinate_system:"EPSG:6816", label: "EPSG:6816 / NAD83(CORS96) / Oregon Dufur-Madras zone (m)"},
  {coordinate_system:"EPSG:6817", label: "EPSG:6817 / NAD83(CORS96) / Oregon Dufur-Madras zone (ft)"},
  {coordinate_system:"EPSG:6818", label: "EPSG:6818 / NAD83(2011) / Oregon Dufur-Madras zone (m)"},
  {coordinate_system:"EPSG:6819", label: "EPSG:6819 / NAD83(2011) / Oregon Dufur-Madras zone (ft)"},
  {coordinate_system:"EPSG:6820", label: "EPSG:6820 / NAD83(CORS96) / Oregon Eugene zone (m)"},
  {coordinate_system:"EPSG:6821", label: "EPSG:6821 / NAD83(CORS96) / Oregon Eugene zone (ft)"},
  {coordinate_system:"EPSG:6822", label: "EPSG:6822 / NAD83(2011) / Oregon Eugene zone (m)"},
  {coordinate_system:"EPSG:6823", label: "EPSG:6823 / NAD83(2011) / Oregon Eugene zone (ft)"},
  {coordinate_system:"EPSG:6824", label: "EPSG:6824 / NAD83(CORS96) / Oregon Grants Pass-Ashland zone (m)"},
  {coordinate_system:"EPSG:6825", label: "EPSG:6825 / NAD83(CORS96) / Oregon Grants Pass-Ashland zone (ft)"},
  {coordinate_system:"EPSG:6826", label: "EPSG:6826 / NAD83(2011) / Oregon Grants Pass-Ashland zone (m)"},
  {coordinate_system:"EPSG:6827", label: "EPSG:6827 / NAD83(2011) / Oregon Grants Pass-Ashland zone (ft)"},
  {coordinate_system:"EPSG:6828", label: "EPSG:6828 / NAD83(CORS96) / Oregon Gresham-Warm Springs zone (m)"},
  {coordinate_system:"EPSG:6829", label: "EPSG:6829 / NAD83(CORS96) / Oregon Gresham-Warm Springs zone (ft)"},
  {coordinate_system:"EPSG:6830", label: "EPSG:6830 / NAD83(2011) / Oregon Gresham-Warm Springs zone (m)"},
  {coordinate_system:"EPSG:6831", label: "EPSG:6831 / NAD83(2011) / Oregon Gresham-Warm Springs zone (ft)"},
  {coordinate_system:"EPSG:6832", label: "EPSG:6832 / NAD83(CORS96) / Oregon La Grande zone (m)"},
  {coordinate_system:"EPSG:6833", label: "EPSG:6833 / NAD83(CORS96) / Oregon La Grande zone (ft)"},
  {coordinate_system:"EPSG:6834", label: "EPSG:6834 / NAD83(2011) / Oregon La Grande zone (m)"},
  {coordinate_system:"EPSG:6835", label: "EPSG:6835 / NAD83(2011) / Oregon La Grande zone (ft)"},
  {coordinate_system:"EPSG:6836", label: "EPSG:6836 / NAD83(CORS96) / Oregon Ontario zone (m)"},
  {coordinate_system:"EPSG:6837", label: "EPSG:6837 / NAD83(CORS96) / Oregon Ontario zone (ft)"},
  {coordinate_system:"EPSG:6838", label: "EPSG:6838 / NAD83(2011) / Oregon Ontario zone (m)"},
  {coordinate_system:"EPSG:6839", label: "EPSG:6839 / NAD83(2011) / Oregon Ontario zone (ft)"},
  {coordinate_system:"EPSG:6840", label: "EPSG:6840 / NAD83(CORS96) / Oregon Coast zone (m)"},
  {coordinate_system:"EPSG:6841", label: "EPSG:6841 / NAD83(CORS96) / Oregon Coast zone (ft)"},
  {coordinate_system:"EPSG:6842", label: "EPSG:6842 / NAD83(2011) / Oregon Coast zone (m)"},
  {coordinate_system:"EPSG:6843", label: "EPSG:6843 / NAD83(2011) / Oregon Coast zone (ft)"},
  {coordinate_system:"EPSG:6844", label: "EPSG:6844 / NAD83(CORS96) / Oregon Pendleton zone (m)"},
  {coordinate_system:"EPSG:6845", label: "EPSG:6845 / NAD83(CORS96) / Oregon Pendleton zone (ft)"},
  {coordinate_system:"EPSG:6846", label: "EPSG:6846 / NAD83(2011) / Oregon Pendleton zone (m)"},
  {coordinate_system:"EPSG:6847", label: "EPSG:6847 / NAD83(2011) / Oregon Pendleton zone (ft)"},
  {coordinate_system:"EPSG:6848", label: "EPSG:6848 / NAD83(CORS96) / Oregon Pendleton-La Grande zone (m)"},
  {coordinate_system:"EPSG:6849", label: "EPSG:6849 / NAD83(CORS96) / Oregon Pendleton-La Grande zone (ft)"},
  {coordinate_system:"EPSG:6850", label: "EPSG:6850 / NAD83(2011) / Oregon Pendleton-La Grande zone (m)"},
  {coordinate_system:"EPSG:6851", label: "EPSG:6851 / NAD83(2011) / Oregon Pendleton-La Grande zone (ft)"},
  {coordinate_system:"EPSG:6852", label: "EPSG:6852 / NAD83(CORS96) / Oregon Portland zone (m)"},
  {coordinate_system:"EPSG:6853", label: "EPSG:6853 / NAD83(CORS96) / Oregon Portland zone (ft)"},
  {coordinate_system:"EPSG:6854", label: "EPSG:6854 / NAD83(2011) / Oregon Portland zone (m)"},
  {coordinate_system:"EPSG:6855", label: "EPSG:6855 / NAD83(2011) / Oregon Portland zone (ft)"},
  {coordinate_system:"EPSG:6856", label: "EPSG:6856 / NAD83(CORS96) / Oregon Salem zone (m)"},
  {coordinate_system:"EPSG:6857", label: "EPSG:6857 / NAD83(CORS96) / Oregon Salem zone (ft)"},
  {coordinate_system:"EPSG:6858", label: "EPSG:6858 / NAD83(2011) / Oregon Salem zone (m)"},
  {coordinate_system:"EPSG:6859", label: "EPSG:6859 / NAD83(2011) / Oregon Salem zone (ft)"},
  {coordinate_system:"EPSG:6860", label: "EPSG:6860 / NAD83(CORS96) / Oregon Santiam Pass zone (m)"},
  {coordinate_system:"EPSG:6861", label: "EPSG:6861 / NAD83(CORS96) / Oregon Santiam Pass zone (ft)"},
  {coordinate_system:"EPSG:6862", label: "EPSG:6862 / NAD83(2011) / Oregon Santiam Pass zone (m)"},
  {coordinate_system:"EPSG:6863", label: "EPSG:6863 / NAD83(2011) / Oregon Santiam Pass zone (ft)"},
  {coordinate_system:"EPSG:6867", label: "EPSG:6867 / NAD83(CORS96) / Oregon LCC (m)"},
  {coordinate_system:"EPSG:6868", label: "EPSG:6868 / NAD83(CORS96) / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:6870", label: "EPSG:6870 / ETRS89 / Albania TM 2010"},
  {coordinate_system:"EPSG:6875", label: "EPSG:6875 / RDN2008 / Italy zone (N-E)"},
  {coordinate_system:"EPSG:6876", label: "EPSG:6876 / RDN2008 / Zone 12 (N-E)"},
  {coordinate_system:"EPSG:6879", label: "EPSG:6879 / NAD83(2011) / Wisconsin Central"},
  {coordinate_system:"EPSG:6880", label: "EPSG:6880 / NAD83(2011) / Nebraska (ftUS)"},
  {coordinate_system:"EPSG:6884", label: "EPSG:6884 / NAD83(CORS96) / Oregon North"},
  {coordinate_system:"EPSG:6885", label: "EPSG:6885 / NAD83(CORS96) / Oregon North (ft)"},
  {coordinate_system:"EPSG:6886", label: "EPSG:6886 / NAD83(CORS96) / Oregon South"},
  {coordinate_system:"EPSG:6887", label: "EPSG:6887 / NAD83(CORS96) / Oregon South (ft)"},
  {coordinate_system:"EPSG:6915", label: "EPSG:6915 / South East Island 1943 / UTM zone 40N"},
  {coordinate_system:"EPSG:6922", label: "EPSG:6922 / NAD83 / Kansas LCC"},
  {coordinate_system:"EPSG:6923", label: "EPSG:6923 / NAD83 / Kansas LCC (ftUS)"},
  {coordinate_system:"EPSG:6924", label: "EPSG:6924 / NAD83(2011) / Kansas LCC"},
  {coordinate_system:"EPSG:6925", label: "EPSG:6925 / NAD83(2011) / Kansas LCC (ftUS)"},
  {coordinate_system:"EPSG:6931", label: "EPSG:6931 / WGS 84 / NSIDC EASE-Grid 2.0 North"},
  {coordinate_system:"EPSG:6932", label: "EPSG:6932 / WGS 84 / NSIDC EASE-Grid 2.0 South"},
  {coordinate_system:"EPSG:6933", label: "EPSG:6933 / WGS 84 / NSIDC EASE-Grid 2.0 Global"},
  {coordinate_system:"EPSG:6962", label: "EPSG:6962 / ETRS89 / Albania LCC 2010"},
  {coordinate_system:"EPSG:6966", label: "EPSG:6966 / NAD27 / Michigan North"},
  {coordinate_system:"EPSG:6984", label: "EPSG:6984 / Israeli Grid 05"},
  {coordinate_system:"EPSG:6991", label: "EPSG:6991 / Israeli Grid 05/12"},
  {coordinate_system:"EPSG:7005", label: "EPSG:7005 / Nahrwan 1934 / UTM zone 37N"},
  {coordinate_system:"EPSG:7006", label: "EPSG:7006 / Nahrwan 1934 / UTM zone 38N"},
  {coordinate_system:"EPSG:7007", label: "EPSG:7007 / Nahrwan 1934 / UTM zone 39N"},
  {coordinate_system:"EPSG:7057", label: "EPSG:7057 / NAD83(2011) / IaRCS zone 1"},
  {coordinate_system:"EPSG:7058", label: "EPSG:7058 / NAD83(2011) / IaRCS zone 2"},
  {coordinate_system:"EPSG:7059", label: "EPSG:7059 / NAD83(2011) / IaRCS zone 3"},
  {coordinate_system:"EPSG:7060", label: "EPSG:7060 / NAD83(2011) / IaRCS zone 4"},
  {coordinate_system:"EPSG:7061", label: "EPSG:7061 / NAD83(2011) / IaRCS zone 5"},
  {coordinate_system:"EPSG:7062", label: "EPSG:7062 / NAD83(2011) / IaRCS zone 6"},
  {coordinate_system:"EPSG:7063", label: "EPSG:7063 / NAD83(2011) / IaRCS zone 7"},
  {coordinate_system:"EPSG:7064", label: "EPSG:7064 / NAD83(2011) / IaRCS zone 8"},
  {coordinate_system:"EPSG:7065", label: "EPSG:7065 / NAD83(2011) / IaRCS zone 9"},
  {coordinate_system:"EPSG:7066", label: "EPSG:7066 / NAD83(2011) / IaRCS zone 10"},
  {coordinate_system:"EPSG:7067", label: "EPSG:7067 / NAD83(2011) / IaRCS zone 11"},
  {coordinate_system:"EPSG:7068", label: "EPSG:7068 / NAD83(2011) / IaRCS zone 12"},
  {coordinate_system:"EPSG:7069", label: "EPSG:7069 / NAD83(2011) / IaRCS zone 13"},
  {coordinate_system:"EPSG:7070", label: "EPSG:7070 / NAD83(2011) / IaRCS zone 14"},
  {coordinate_system:"EPSG:7074", label: "EPSG:7074 / RGTAAF07 / UTM zone 37S"},
  {coordinate_system:"EPSG:7075", label: "EPSG:7075 / RGTAAF07 / UTM zone 38S"},
  {coordinate_system:"EPSG:7076", label: "EPSG:7076 / RGTAAF07 / UTM zone 39S"},
  {coordinate_system:"EPSG:7077", label: "EPSG:7077 / RGTAAF07 / UTM zone 40S"},
  {coordinate_system:"EPSG:7078", label: "EPSG:7078 / RGTAAF07 / UTM zone 41S"},
  {coordinate_system:"EPSG:7079", label: "EPSG:7079 / RGTAAF07 / UTM zone 42S"},
  {coordinate_system:"EPSG:7080", label: "EPSG:7080 / RGTAAF07 / UTM zone 43S"},
  {coordinate_system:"EPSG:7081", label: "EPSG:7081 / RGTAAF07 / UTM zone 44S"},
  {coordinate_system:"EPSG:7109", label: "EPSG:7109 / NAD83(2011) / RMTCRS St Mary (m)"},
  {coordinate_system:"EPSG:7110", label: "EPSG:7110 / NAD83(2011) / RMTCRS Blackfeet (m)"},
  {coordinate_system:"EPSG:7111", label: "EPSG:7111 / NAD83(2011) / RMTCRS Milk River (m)"},
  {coordinate_system:"EPSG:7112", label: "EPSG:7112 / NAD83(2011) / RMTCRS Fort Belknap (m)"},
  {coordinate_system:"EPSG:7113", label: "EPSG:7113 / NAD83(2011) / RMTCRS Fort Peck Assiniboine (m)"},
  {coordinate_system:"EPSG:7114", label: "EPSG:7114 / NAD83(2011) / RMTCRS Fort Peck Sioux (m)"},
  {coordinate_system:"EPSG:7115", label: "EPSG:7115 / NAD83(2011) / RMTCRS Crow (m)"},
  {coordinate_system:"EPSG:7116", label: "EPSG:7116 / NAD83(2011) / RMTCRS Bobcat (m)"},
  {coordinate_system:"EPSG:7117", label: "EPSG:7117 / NAD83(2011) / RMTCRS Billings (m)"},
  {coordinate_system:"EPSG:7118", label: "EPSG:7118 / NAD83(2011) / RMTCRS Wind River (m)"},
  {coordinate_system:"EPSG:7119", label: "EPSG:7119 / NAD83(2011) / RMTCRS St Mary (ft)"},
  {coordinate_system:"EPSG:7120", label: "EPSG:7120 / NAD83(2011) / RMTCRS Blackfeet (ft)"},
  {coordinate_system:"EPSG:7121", label: "EPSG:7121 / NAD83(2011) / RMTCRS Milk River (ft)"},
  {coordinate_system:"EPSG:7122", label: "EPSG:7122 / NAD83(2011) / RMTCRS Fort Belknap (ft)"},
  {coordinate_system:"EPSG:7123", label: "EPSG:7123 / NAD83(2011) / RMTCRS Fort Peck Assiniboine (ft)"},
  {coordinate_system:"EPSG:7124", label: "EPSG:7124 / NAD83(2011) / RMTCRS Fort Peck Sioux (ft)"},
  {coordinate_system:"EPSG:7125", label: "EPSG:7125 / NAD83(2011) / RMTCRS Crow (ft)"},
  {coordinate_system:"EPSG:7126", label: "EPSG:7126 / NAD83(2011) / RMTCRS Bobcat (ft)"},
  {coordinate_system:"EPSG:7127", label: "EPSG:7127 / NAD83(2011) / RMTCRS Billings (ft)"},
  {coordinate_system:"EPSG:7128", label: "EPSG:7128 / NAD83(2011) / RMTCRS Wind River (ftUS)"},
  {coordinate_system:"EPSG:7131", label: "EPSG:7131 / NAD83(2011) / San Francisco CS13"},
  {coordinate_system:"EPSG:7132", label: "EPSG:7132 / NAD83(2011) / San Francisco CS13 (ftUS)"},
  {coordinate_system:"EPSG:7142", label: "EPSG:7142 / Palestine 1923 / Palestine Grid modified"},
  {coordinate_system:"EPSG:7257", label: "EPSG:7257 / NAD83(2011) / InGCS Adams (m)"},
  {coordinate_system:"EPSG:7258", label: "EPSG:7258 / NAD83(2011) / InGCS Adams (ftUS)"},
  {coordinate_system:"EPSG:7259", label: "EPSG:7259 / NAD83(2011) / InGCS Allen (m)"},
  {coordinate_system:"EPSG:7260", label: "EPSG:7260 / NAD83(2011) / InGCS Allen (ftUS)"},
  {coordinate_system:"EPSG:7261", label: "EPSG:7261 / NAD83(2011) / InGCS Bartholomew (m)"},
  {coordinate_system:"EPSG:7262", label: "EPSG:7262 / NAD83(2011) / InGCS Bartholomew (ftUS)"},
  {coordinate_system:"EPSG:7263", label: "EPSG:7263 / NAD83(2011) / InGCS Benton (m)"},
  {coordinate_system:"EPSG:7264", label: "EPSG:7264 / NAD83(2011) / InGCS Benton (ftUS)"},
  {coordinate_system:"EPSG:7265", label: "EPSG:7265 / NAD83(2011) / InGCS Blackford-Delaware (m)"},
  {coordinate_system:"EPSG:7266", label: "EPSG:7266 / NAD83(2011) / InGCS Blackford-Delaware (ftUS)"},
  {coordinate_system:"EPSG:7267", label: "EPSG:7267 / NAD83(2011) / InGCS Boone-Hendricks (m)"},
  {coordinate_system:"EPSG:7268", label: "EPSG:7268 / NAD83(2011) / InGCS Boone-Hendricks (ftUS)"},
  {coordinate_system:"EPSG:7269", label: "EPSG:7269 / NAD83(2011) / InGCS Brown (m)"},
  {coordinate_system:"EPSG:7270", label: "EPSG:7270 / NAD83(2011) / InGCS Brown (ftUS)"},
  {coordinate_system:"EPSG:7271", label: "EPSG:7271 / NAD83(2011) / InGCS Carroll (m)"},
  {coordinate_system:"EPSG:7272", label: "EPSG:7272 / NAD83(2011) / InGCS Carroll (ftUS)"},
  {coordinate_system:"EPSG:7273", label: "EPSG:7273 / NAD83(2011) / InGCS Cass (m)"},
  {coordinate_system:"EPSG:7274", label: "EPSG:7274 / NAD83(2011) / InGCS Cass (ftUS)"},
  {coordinate_system:"EPSG:7275", label: "EPSG:7275 / NAD83(2011) / InGCS Clark-Floyd-Scott (m)"},
  {coordinate_system:"EPSG:7276", label: "EPSG:7276 / NAD83(2011) / InGCS Clark-Floyd-Scott (ftUS)"},
  {coordinate_system:"EPSG:7277", label: "EPSG:7277 / NAD83(2011) / InGCS Clay (m)"},
  {coordinate_system:"EPSG:7278", label: "EPSG:7278 / NAD83(2011) / InGCS Clay (ftUS)"},
  {coordinate_system:"EPSG:7279", label: "EPSG:7279 / NAD83(2011) / InGCS Clinton (m)"},
  {coordinate_system:"EPSG:7280", label: "EPSG:7280 / NAD83(2011) / InGCS Clinton (ftUS)"},
  {coordinate_system:"EPSG:7281", label: "EPSG:7281 / NAD83(2011) / InGCS Crawford-Lawrence-Orange (m)"},
  {coordinate_system:"EPSG:7282", label: "EPSG:7282 / NAD83(2011) / InGCS Crawford-Lawrence-Orange (ftUS)"},
  {coordinate_system:"EPSG:7283", label: "EPSG:7283 / NAD83(2011) / InGCS Daviess-Greene (m)"},
  {coordinate_system:"EPSG:7284", label: "EPSG:7284 / NAD83(2011) / InGCS Daviess-Greene (ftUS)"},
  {coordinate_system:"EPSG:7285", label: "EPSG:7285 / NAD83(2011) / InGCS Dearborn-Ohio-Switzerland (m)"},
  {coordinate_system:"EPSG:7286", label: "EPSG:7286 / NAD83(2011) / InGCS Dearborn-Ohio-Switzerland (ftUS)"},
  {coordinate_system:"EPSG:7287", label: "EPSG:7287 / NAD83(2011) / InGCS Decatur-Rush (m)"},
  {coordinate_system:"EPSG:7288", label: "EPSG:7288 / NAD83(2011) / InGCS Decatur-Rush (ftUS)"},
  {coordinate_system:"EPSG:7289", label: "EPSG:7289 / NAD83(2011) / InGCS DeKalb (m)"},
  {coordinate_system:"EPSG:7290", label: "EPSG:7290 / NAD83(2011) / InGCS DeKalb (ftUS)"},
  {coordinate_system:"EPSG:7291", label: "EPSG:7291 / NAD83(2011) / InGCS Dubois-Martin (m)"},
  {coordinate_system:"EPSG:7292", label: "EPSG:7292 / NAD83(2011) / InGCS Dubois-Martin (ftUS)"},
  {coordinate_system:"EPSG:7293", label: "EPSG:7293 / NAD83(2011) / InGCS Elkhart-Kosciusko-Wabash (m)"},
  {coordinate_system:"EPSG:7294", label: "EPSG:7294 / NAD83(2011) / InGCS Elkhart-Kosciusko-Wabash (ftUS)"},
  {coordinate_system:"EPSG:7295", label: "EPSG:7295 / NAD83(2011) / InGCS Fayette-Franklin-Union (m)"},
  {coordinate_system:"EPSG:7296", label: "EPSG:7296 / NAD83(2011) / InGCS Fayette-Franklin-Union (ftUS)"},
  {coordinate_system:"EPSG:7297", label: "EPSG:7297 / NAD83(2011) / InGCS Fountain-Warren (m)"},
  {coordinate_system:"EPSG:7298", label: "EPSG:7298 / NAD83(2011) / InGCS Fountain-Warren (ftUS)"},
  {coordinate_system:"EPSG:7299", label: "EPSG:7299 / NAD83(2011) / InGCS Fulton-Marshall-St. Joseph (m)"},
  {coordinate_system:"EPSG:7300", label: "EPSG:7300 / NAD83(2011) / InGCS Fulton-Marshall-St. Joseph (ftUS)"},
  {coordinate_system:"EPSG:7301", label: "EPSG:7301 / NAD83(2011) / InGCS Gibson (m)"},
  {coordinate_system:"EPSG:7302", label: "EPSG:7302 / NAD83(2011) / InGCS Gibson (ftUS)"},
  {coordinate_system:"EPSG:7303", label: "EPSG:7303 / NAD83(2011) / InGCS Grant (m)"},
  {coordinate_system:"EPSG:7304", label: "EPSG:7304 / NAD83(2011) / InGCS Grant (ftUS)"},
  {coordinate_system:"EPSG:7305", label: "EPSG:7305 / NAD83(2011) / InGCS Hamilton-Tipton (m)"},
  {coordinate_system:"EPSG:7306", label: "EPSG:7306 / NAD83(2011) / InGCS Hamilton-Tipton (ftUS)"},
  {coordinate_system:"EPSG:7307", label: "EPSG:7307 / NAD83(2011) / InGCS Hancock-Madison (m)"},
  {coordinate_system:"EPSG:7308", label: "EPSG:7308 / NAD83(2011) / InGCS Hancock-Madison (ftUS)"},
  {coordinate_system:"EPSG:7309", label: "EPSG:7309 / NAD83(2011) / InGCS Harrison-Washington (m)"},
  {coordinate_system:"EPSG:7310", label: "EPSG:7310 / NAD83(2011) / InGCS Harrison-Washington (ftUS)"},
  {coordinate_system:"EPSG:7311", label: "EPSG:7311 / NAD83(2011) / InGCS Henry (m)"},
  {coordinate_system:"EPSG:7312", label: "EPSG:7312 / NAD83(2011) / InGCS Henry (ftUS)"},
  {coordinate_system:"EPSG:7313", label: "EPSG:7313 / NAD83(2011) / InGCS Howard-Miami (m)"},
  {coordinate_system:"EPSG:7314", label: "EPSG:7314 / NAD83(2011) / InGCS Howard-Miami (ftUS)"},
  {coordinate_system:"EPSG:7315", label: "EPSG:7315 / NAD83(2011) / InGCS Huntington-Whitley (m)"},
  {coordinate_system:"EPSG:7316", label: "EPSG:7316 / NAD83(2011) / InGCS Huntington-Whitley (ftUS)"},
  {coordinate_system:"EPSG:7317", label: "EPSG:7317 / NAD83(2011) / InGCS Jackson (m)"},
  {coordinate_system:"EPSG:7318", label: "EPSG:7318 / NAD83(2011) / InGCS Jackson (ftUS)"},
  {coordinate_system:"EPSG:7319", label: "EPSG:7319 / NAD83(2011) / InGCS Jasper-Porter (m)"},
  {coordinate_system:"EPSG:7320", label: "EPSG:7320 / NAD83(2011) / InGCS Jasper-Porter (ftUS)"},
  {coordinate_system:"EPSG:7321", label: "EPSG:7321 / NAD83(2011) / InGCS Jay (m)"},
  {coordinate_system:"EPSG:7322", label: "EPSG:7322 / NAD83(2011) / InGCS Jay (ftUS)"},
  {coordinate_system:"EPSG:7323", label: "EPSG:7323 / NAD83(2011) / InGCS Jefferson (m)"},
  {coordinate_system:"EPSG:7324", label: "EPSG:7324 / NAD83(2011) / InGCS Jefferson (ftUS)"},
  {coordinate_system:"EPSG:7325", label: "EPSG:7325 / NAD83(2011) / InGCS Jennings (m)"},
  {coordinate_system:"EPSG:7326", label: "EPSG:7326 / NAD83(2011) / InGCS Jennings (ftUS)"},
  {coordinate_system:"EPSG:7327", label: "EPSG:7327 / NAD83(2011) / InGCS Johnson-Marion (m)"},
  {coordinate_system:"EPSG:7328", label: "EPSG:7328 / NAD83(2011) / InGCS Johnson-Marion (ftUS)"},
  {coordinate_system:"EPSG:7329", label: "EPSG:7329 / NAD83(2011) / InGCS Knox (m)"},
  {coordinate_system:"EPSG:7330", label: "EPSG:7330 / NAD83(2011) / InGCS Knox (ftUS)"},
  {coordinate_system:"EPSG:7331", label: "EPSG:7331 / NAD83(2011) / InGCS LaGrange-Noble (m)"},
  {coordinate_system:"EPSG:7332", label: "EPSG:7332 / NAD83(2011) / InGCS LaGrange-Noble (ftUS)"},
  {coordinate_system:"EPSG:7333", label: "EPSG:7333 / NAD83(2011) / InGCS Lake-Newton (m)"},
  {coordinate_system:"EPSG:7334", label: "EPSG:7334 / NAD83(2011) / InGCS Lake-Newton (ftUS)"},
  {coordinate_system:"EPSG:7335", label: "EPSG:7335 / NAD83(2011) / InGCS LaPorte-Pulaski-Starke (m)"},
  {coordinate_system:"EPSG:7336", label: "EPSG:7336 / NAD83(2011) / InGCS LaPorte-Pulaski-Starke (ftUS)"},
  {coordinate_system:"EPSG:7337", label: "EPSG:7337 / NAD83(2011) / InGCS Monroe-Morgan (m)"},
  {coordinate_system:"EPSG:7338", label: "EPSG:7338 / NAD83(2011) / InGCS Monroe-Morgan (ftUS)"},
  {coordinate_system:"EPSG:7339", label: "EPSG:7339 / NAD83(2011) / InGCS Montgomery-Putnam (m)"},
  {coordinate_system:"EPSG:7340", label: "EPSG:7340 / NAD83(2011) / InGCS Montgomery-Putnam (ftUS)"},
  {coordinate_system:"EPSG:7341", label: "EPSG:7341 / NAD83(2011) / InGCS Owen (m)"},
  {coordinate_system:"EPSG:7342", label: "EPSG:7342 / NAD83(2011) / InGCS Owen (ftUS)"},
  {coordinate_system:"EPSG:7343", label: "EPSG:7343 / NAD83(2011) / InGCS Parke-Vermillion (m)"},
  {coordinate_system:"EPSG:7344", label: "EPSG:7344 / NAD83(2011) / InGCS Parke-Vermillion (ftUS)"},
  {coordinate_system:"EPSG:7345", label: "EPSG:7345 / NAD83(2011) / InGCS Perry (m)"},
  {coordinate_system:"EPSG:7346", label: "EPSG:7346 / NAD83(2011) / InGCS Perry (ftUS)"},
  {coordinate_system:"EPSG:7347", label: "EPSG:7347 / NAD83(2011) / InGCS Pike-Warrick (m)"},
  {coordinate_system:"EPSG:7348", label: "EPSG:7348 / NAD83(2011) / InGCS Pike-Warrick (ftUS)"},
  {coordinate_system:"EPSG:7349", label: "EPSG:7349 / NAD83(2011) / InGCS Posey (m)"},
  {coordinate_system:"EPSG:7350", label: "EPSG:7350 / NAD83(2011) / InGCS Posey (ftUS)"},
  {coordinate_system:"EPSG:7351", label: "EPSG:7351 / NAD83(2011) / InGCS Randolph-Wayne (m)"},
  {coordinate_system:"EPSG:7352", label: "EPSG:7352 / NAD83(2011) / InGCS Randolph-Wayne (ftUS)"},
  {coordinate_system:"EPSG:7353", label: "EPSG:7353 / NAD83(2011) / InGCS Ripley (m)"},
  {coordinate_system:"EPSG:7354", label: "EPSG:7354 / NAD83(2011) / InGCS Ripley (ftUS)"},
  {coordinate_system:"EPSG:7355", label: "EPSG:7355 / NAD83(2011) / InGCS Shelby (m)"},
  {coordinate_system:"EPSG:7356", label: "EPSG:7356 / NAD83(2011) / InGCS Shelby (ftUS)"},
  {coordinate_system:"EPSG:7357", label: "EPSG:7357 / NAD83(2011) / InGCS Spencer (m)"},
  {coordinate_system:"EPSG:7358", label: "EPSG:7358 / NAD83(2011) / InGCS Spencer (ftUS)"},
  {coordinate_system:"EPSG:7359", label: "EPSG:7359 / NAD83(2011) / InGCS Steuben (m)"},
  {coordinate_system:"EPSG:7360", label: "EPSG:7360 / NAD83(2011) / InGCS Steuben (ftUS)"},
  {coordinate_system:"EPSG:7361", label: "EPSG:7361 / NAD83(2011) / InGCS Sullivan (m)"},
  {coordinate_system:"EPSG:7362", label: "EPSG:7362 / NAD83(2011) / InGCS Sullivan (ftUS)"},
  {coordinate_system:"EPSG:7363", label: "EPSG:7363 / NAD83(2011) / InGCS Tippecanoe-White (m)"},
  {coordinate_system:"EPSG:7364", label: "EPSG:7364 / NAD83(2011) / InGCS Tippecanoe-White (ftUS)"},
  {coordinate_system:"EPSG:7365", label: "EPSG:7365 / NAD83(2011) / InGCS Vanderburgh (m)"},
  {coordinate_system:"EPSG:7366", label: "EPSG:7366 / NAD83(2011) / InGCS Vanderburgh (ftUS)"},
  {coordinate_system:"EPSG:7367", label: "EPSG:7367 / NAD83(2011) / InGCS Vigo (m)"},
  {coordinate_system:"EPSG:7368", label: "EPSG:7368 / NAD83(2011) / InGCS Vigo (ftUS)"},
  {coordinate_system:"EPSG:7369", label: "EPSG:7369 / NAD83(2011) / InGCS Wells (m)"},
  {coordinate_system:"EPSG:7370", label: "EPSG:7370 / NAD83(2011) / InGCS Wells (ftUS)"},
  {coordinate_system:"EPSG:7374", label: "EPSG:7374 / ONGD14 / UTM zone 39N"},
  {coordinate_system:"EPSG:7375", label: "EPSG:7375 / ONGD14 / UTM zone 40N"},
  {coordinate_system:"EPSG:7376", label: "EPSG:7376 / ONGD14 / UTM zone 41N"},
  {coordinate_system:"EPSG:7528", label: "EPSG:7528 / NAD83(2011) / WISCRS Adams and Juneau (m)"},
  {coordinate_system:"EPSG:7529", label: "EPSG:7529 / NAD83(2011) / WISCRS Ashland (m)"},
  {coordinate_system:"EPSG:7530", label: "EPSG:7530 / NAD83(2011) / WISCRS Barron (m)"},
  {coordinate_system:"EPSG:7531", label: "EPSG:7531 / NAD83(2011) / WISCRS Bayfield (m)"},
  {coordinate_system:"EPSG:7532", label: "EPSG:7532 / NAD83(2011) / WISCRS Brown (m)"},
  {coordinate_system:"EPSG:7533", label: "EPSG:7533 / NAD83(2011) / WISCRS Buffalo (m)"},
  {coordinate_system:"EPSG:7534", label: "EPSG:7534 / NAD83(2011) / WISCRS Burnett (m)"},
  {coordinate_system:"EPSG:7535", label: "EPSG:7535 / NAD83(2011) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (m)"},
  {coordinate_system:"EPSG:7536", label: "EPSG:7536 / NAD83(2011) / WISCRS Chippewa (m)"},
  {coordinate_system:"EPSG:7537", label: "EPSG:7537 / NAD83(2011) / WISCRS Clark (m)"},
  {coordinate_system:"EPSG:7538", label: "EPSG:7538 / NAD83(2011) / WISCRS Columbia (m)"},
  {coordinate_system:"EPSG:7539", label: "EPSG:7539 / NAD83(2011) / WISCRS Crawford (m)"},
  {coordinate_system:"EPSG:7540", label: "EPSG:7540 / NAD83(2011) / WISCRS Dane (m)"},
  {coordinate_system:"EPSG:7541", label: "EPSG:7541 / NAD83(2011) / WISCRS Dodge and Jefferson (m)"},
  {coordinate_system:"EPSG:7542", label: "EPSG:7542 / NAD83(2011) / WISCRS Door (m)"},
  {coordinate_system:"EPSG:7543", label: "EPSG:7543 / NAD83(2011) / WISCRS Douglas (m)"},
  {coordinate_system:"EPSG:7544", label: "EPSG:7544 / NAD83(2011) / WISCRS Dunn (m)"},
  {coordinate_system:"EPSG:7545", label: "EPSG:7545 / NAD83(2011) / WISCRS Eau Claire (m)"},
  {coordinate_system:"EPSG:7546", label: "EPSG:7546 / NAD83(2011) / WISCRS Florence (m)"},
  {coordinate_system:"EPSG:7547", label: "EPSG:7547 / NAD83(2011) / WISCRS Forest (m)"},
  {coordinate_system:"EPSG:7548", label: "EPSG:7548 / NAD83(2011) / WISCRS Grant (m)"},
  {coordinate_system:"EPSG:7549", label: "EPSG:7549 / NAD83(2011) / WISCRS Green and Lafayette (m)"},
  {coordinate_system:"EPSG:7550", label: "EPSG:7550 / NAD83(2011) / WISCRS Green Lake and Marquette (m)"},
  {coordinate_system:"EPSG:7551", label: "EPSG:7551 / NAD83(2011) / WISCRS Iowa (m)"},
  {coordinate_system:"EPSG:7552", label: "EPSG:7552 / NAD83(2011) / WISCRS Iron (m)"},
  {coordinate_system:"EPSG:7553", label: "EPSG:7553 / NAD83(2011) / WISCRS Jackson (m)"},
  {coordinate_system:"EPSG:7554", label: "EPSG:7554 / NAD83(2011) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (m)"},
  {coordinate_system:"EPSG:7555", label: "EPSG:7555 / NAD83(2011) / WISCRS Kewaunee, Manitowoc and Sheboygan (m)"},
  {coordinate_system:"EPSG:7556", label: "EPSG:7556 / NAD83(2011) / WISCRS La Crosse (m)"},
  {coordinate_system:"EPSG:7557", label: "EPSG:7557 / NAD83(2011) / WISCRS Langlade (m)"},
  {coordinate_system:"EPSG:7558", label: "EPSG:7558 / NAD83(2011) / WISCRS Lincoln (m)"},
  {coordinate_system:"EPSG:7559", label: "EPSG:7559 / NAD83(2011) / WISCRS Marathon (m)"},
  {coordinate_system:"EPSG:7560", label: "EPSG:7560 / NAD83(2011) / WISCRS Marinette (m)"},
  {coordinate_system:"EPSG:7561", label: "EPSG:7561 / NAD83(2011) / WISCRS Menominee (m)"},
  {coordinate_system:"EPSG:7562", label: "EPSG:7562 / NAD83(2011) / WISCRS Monroe (m)"},
  {coordinate_system:"EPSG:7563", label: "EPSG:7563 / NAD83(2011) / WISCRS Oconto (m)"},
  {coordinate_system:"EPSG:7564", label: "EPSG:7564 / NAD83(2011) / WISCRS Oneida (m)"},
  {coordinate_system:"EPSG:7565", label: "EPSG:7565 / NAD83(2011) / WISCRS Pepin and Pierce (m)"},
  {coordinate_system:"EPSG:7566", label: "EPSG:7566 / NAD83(2011) / WISCRS Polk (m)"},
  {coordinate_system:"EPSG:7567", label: "EPSG:7567 / NAD83(2011) / WISCRS Portage (m)"},
  {coordinate_system:"EPSG:7568", label: "EPSG:7568 / NAD83(2011) / WISCRS Price (m)"},
  {coordinate_system:"EPSG:7569", label: "EPSG:7569 / NAD83(2011) / WISCRS Richland (m)"},
  {coordinate_system:"EPSG:7570", label: "EPSG:7570 / NAD83(2011) / WISCRS Rock (m)"},
  {coordinate_system:"EPSG:7571", label: "EPSG:7571 / NAD83(2011) / WISCRS Rusk (m)"},
  {coordinate_system:"EPSG:7572", label: "EPSG:7572 / NAD83(2011) / WISCRS Sauk (m)"},
  {coordinate_system:"EPSG:7573", label: "EPSG:7573 / NAD83(2011) / WISCRS Sawyer (m)"},
  {coordinate_system:"EPSG:7574", label: "EPSG:7574 / NAD83(2011) / WISCRS Shawano (m)"},
  {coordinate_system:"EPSG:7575", label: "EPSG:7575 / NAD83(2011) / WISCRS St. Croix (m)"},
  {coordinate_system:"EPSG:7576", label: "EPSG:7576 / NAD83(2011) / WISCRS Taylor (m)"},
  {coordinate_system:"EPSG:7577", label: "EPSG:7577 / NAD83(2011) / WISCRS Trempealeau (m)"},
  {coordinate_system:"EPSG:7578", label: "EPSG:7578 / NAD83(2011) / WISCRS Vernon (m)"},
  {coordinate_system:"EPSG:7579", label: "EPSG:7579 / NAD83(2011) / WISCRS Vilas (m)"},
  {coordinate_system:"EPSG:7580", label: "EPSG:7580 / NAD83(2011) / WISCRS Walworth (m)"},
  {coordinate_system:"EPSG:7581", label: "EPSG:7581 / NAD83(2011) / WISCRS Washburn (m)"},
  {coordinate_system:"EPSG:7582", label: "EPSG:7582 / NAD83(2011) / WISCRS Washington (m)"},
  {coordinate_system:"EPSG:7583", label: "EPSG:7583 / NAD83(2011) / WISCRS Waukesha (m)"},
  {coordinate_system:"EPSG:7584", label: "EPSG:7584 / NAD83(2011) / WISCRS Waupaca (m)"},
  {coordinate_system:"EPSG:7585", label: "EPSG:7585 / NAD83(2011) / WISCRS Waushara (m)"},
  {coordinate_system:"EPSG:7586", label: "EPSG:7586 / NAD83(2011) / WISCRS Wood (m)"},
  {coordinate_system:"EPSG:7587", label: "EPSG:7587 / NAD83(2011) / WISCRS Adams and Juneau (ftUS)"},
  {coordinate_system:"EPSG:7588", label: "EPSG:7588 / NAD83(2011) / WISCRS Ashland (ftUS)"},
  {coordinate_system:"EPSG:7589", label: "EPSG:7589 / NAD83(2011) / WISCRS Barron (ftUS)"},
  {coordinate_system:"EPSG:7590", label: "EPSG:7590 / NAD83(2011) / WISCRS Bayfield (ftUS)"},
  {coordinate_system:"EPSG:7591", label: "EPSG:7591 / NAD83(2011) / WISCRS Brown (ftUS)"},
  {coordinate_system:"EPSG:7592", label: "EPSG:7592 / NAD83(2011) / WISCRS Buffalo (ftUS)"},
  {coordinate_system:"EPSG:7593", label: "EPSG:7593 / NAD83(2011) / WISCRS Burnett (ftUS)"},
  {coordinate_system:"EPSG:7594", label: "EPSG:7594 / NAD83(2011) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (ftUS)"},
  {coordinate_system:"EPSG:7595", label: "EPSG:7595 / NAD83(2011) / WISCRS Chippewa (ftUS)"},
  {coordinate_system:"EPSG:7596", label: "EPSG:7596 / NAD83(2011) / WISCRS Clark (ftUS)"},
  {coordinate_system:"EPSG:7597", label: "EPSG:7597 / NAD83(2011) / WISCRS Columbia (ftUS)"},
  {coordinate_system:"EPSG:7598", label: "EPSG:7598 / NAD83(2011) / WISCRS Crawford (ftUS)"},
  {coordinate_system:"EPSG:7599", label: "EPSG:7599 / NAD83(2011) / WISCRS Dane (ftUS)"},
  {coordinate_system:"EPSG:7600", label: "EPSG:7600 / NAD83(2011) / WISCRS Dodge and Jefferson (ftUS)"},
  {coordinate_system:"EPSG:7601", label: "EPSG:7601 / NAD83(2011) / WISCRS Door (ftUS)"},
  {coordinate_system:"EPSG:7602", label: "EPSG:7602 / NAD83(2011) / WISCRS Douglas (ftUS)"},
  {coordinate_system:"EPSG:7603", label: "EPSG:7603 / NAD83(2011) / WISCRS Dunn (ftUS)"},
  {coordinate_system:"EPSG:7604", label: "EPSG:7604 / NAD83(2011) / WISCRS Eau Claire (ftUS)"},
  {coordinate_system:"EPSG:7605", label: "EPSG:7605 / NAD83(2011) / WISCRS Florence (ftUS)"},
  {coordinate_system:"EPSG:7606", label: "EPSG:7606 / NAD83(2011) / WISCRS Forest (ftUS)"},
  {coordinate_system:"EPSG:7607", label: "EPSG:7607 / NAD83(2011) / WISCRS Grant (ftUS)"},
  {coordinate_system:"EPSG:7608", label: "EPSG:7608 / NAD83(2011) / WISCRS Green and Lafayette (ftUS)"},
  {coordinate_system:"EPSG:7609", label: "EPSG:7609 / NAD83(2011) / WISCRS Green Lake and Marquette (ftUS)"},
  {coordinate_system:"EPSG:7610", label: "EPSG:7610 / NAD83(2011) / WISCRS Iowa (ftUS)"},
  {coordinate_system:"EPSG:7611", label: "EPSG:7611 / NAD83(2011) / WISCRS Iron (ftUS)"},
  {coordinate_system:"EPSG:7612", label: "EPSG:7612 / NAD83(2011) / WISCRS Jackson (ftUS)"},
  {coordinate_system:"EPSG:7613", label: "EPSG:7613 / NAD83(2011) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (ftUS)"},
  {coordinate_system:"EPSG:7614", label: "EPSG:7614 / NAD83(2011) / WISCRS Kewaunee, Manitowoc and Sheboygan (ftUS)"},
  {coordinate_system:"EPSG:7615", label: "EPSG:7615 / NAD83(2011) / WISCRS La Crosse (ftUS)"},
  {coordinate_system:"EPSG:7616", label: "EPSG:7616 / NAD83(2011) / WISCRS Langlade (ftUS)"},
  {coordinate_system:"EPSG:7617", label: "EPSG:7617 / NAD83(2011) / WISCRS Lincoln (ftUS)"},
  {coordinate_system:"EPSG:7618", label: "EPSG:7618 / NAD83(2011) / WISCRS Marathon (ftUS)"},
  {coordinate_system:"EPSG:7619", label: "EPSG:7619 / NAD83(2011) / WISCRS Marinette (ftUS)"},
  {coordinate_system:"EPSG:7620", label: "EPSG:7620 / NAD83(2011) / WISCRS Menominee (ftUS)"},
  {coordinate_system:"EPSG:7621", label: "EPSG:7621 / NAD83(2011) / WISCRS Monroe (ftUS)"},
  {coordinate_system:"EPSG:7622", label: "EPSG:7622 / NAD83(2011) / WISCRS Oconto (ftUS)"},
  {coordinate_system:"EPSG:7623", label: "EPSG:7623 / NAD83(2011) / WISCRS Oneida (ftUS)"},
  {coordinate_system:"EPSG:7624", label: "EPSG:7624 / NAD83(2011) / WISCRS Pepin and Pierce (ftUS)"},
  {coordinate_system:"EPSG:7625", label: "EPSG:7625 / NAD83(2011) / WISCRS Polk (ftUS)"},
  {coordinate_system:"EPSG:7626", label: "EPSG:7626 / NAD83(2011) / WISCRS Portage (ftUS)"},
  {coordinate_system:"EPSG:7627", label: "EPSG:7627 / NAD83(2011) / WISCRS Price (ftUS)"},
  {coordinate_system:"EPSG:7628", label: "EPSG:7628 / NAD83(2011) / WISCRS Richland (ftUS)"},
  {coordinate_system:"EPSG:7629", label: "EPSG:7629 / NAD83(2011) / WISCRS Rock (ftUS)"},
  {coordinate_system:"EPSG:7630", label: "EPSG:7630 / NAD83(2011) / WISCRS Rusk (ftUS)"},
  {coordinate_system:"EPSG:7631", label: "EPSG:7631 / NAD83(2011) / WISCRS Sauk (ftUS)"},
  {coordinate_system:"EPSG:7632", label: "EPSG:7632 / NAD83(2011) / WISCRS Sawyer (ftUS)"},
  {coordinate_system:"EPSG:7633", label: "EPSG:7633 / NAD83(2011) / WISCRS Shawano (ftUS)"},
  {coordinate_system:"EPSG:7634", label: "EPSG:7634 / NAD83(2011) / WISCRS St. Croix (ftUS)"},
  {coordinate_system:"EPSG:7635", label: "EPSG:7635 / NAD83(2011) / WISCRS Taylor (ftUS)"},
  {coordinate_system:"EPSG:7636", label: "EPSG:7636 / NAD83(2011) / WISCRS Trempealeau (ftUS)"},
  {coordinate_system:"EPSG:7637", label: "EPSG:7637 / NAD83(2011) / WISCRS Vernon (ftUS)"},
  {coordinate_system:"EPSG:7638", label: "EPSG:7638 / NAD83(2011) / WISCRS Vilas (ftUS)"},
  {coordinate_system:"EPSG:7639", label: "EPSG:7639 / NAD83(2011) / WISCRS Walworth (ftUS)"},
  {coordinate_system:"EPSG:7640", label: "EPSG:7640 / NAD83(2011) / WISCRS Washburn (ftUS)"},
  {coordinate_system:"EPSG:7641", label: "EPSG:7641 / NAD83(2011) / WISCRS Washington (ftUS)"},
  {coordinate_system:"EPSG:7642", label: "EPSG:7642 / NAD83(2011) / WISCRS Waukesha (ftUS)"},
  {coordinate_system:"EPSG:7643", label: "EPSG:7643 / NAD83(2011) / WISCRS Waupaca (ftUS)"},
  {coordinate_system:"EPSG:7644", label: "EPSG:7644 / NAD83(2011) / WISCRS Waushara (ftUS)"},
  {coordinate_system:"EPSG:7645", label: "EPSG:7645 / NAD83(2011) / WISCRS Wood (ftUS)"},
  {coordinate_system:"EPSG:7692", label: "EPSG:7692 / Kyrg-06 / zone 1"},
  {coordinate_system:"EPSG:7693", label: "EPSG:7693 / Kyrg-06 / zone 2"},
  {coordinate_system:"EPSG:7694", label: "EPSG:7694 / Kyrg-06 / zone 3"},
  {coordinate_system:"EPSG:7695", label: "EPSG:7695 / Kyrg-06 / zone 4"},
  {coordinate_system:"EPSG:7696", label: "EPSG:7696 / Kyrg-06 / zone 5"},
  {coordinate_system:"EPSG:7755", label: "EPSG:7755 / WGS 84 / India NSF LCC"},
  {coordinate_system:"EPSG:7756", label: "EPSG:7756 / WGS 84 / Andhra Pradesh"},
  {coordinate_system:"EPSG:7757", label: "EPSG:7757 / WGS 84 / Arunachal Pradesh"},
  {coordinate_system:"EPSG:7758", label: "EPSG:7758 / WGS 84 / Assam"},
  {coordinate_system:"EPSG:7759", label: "EPSG:7759 / WGS 84 / Bihar"},
  {coordinate_system:"EPSG:7760", label: "EPSG:7760 / WGS 84 / Delhi"},
  {coordinate_system:"EPSG:7761", label: "EPSG:7761 / WGS 84 / Gujarat"},
  {coordinate_system:"EPSG:7762", label: "EPSG:7762 / WGS 84 / Haryana"},
  {coordinate_system:"EPSG:7763", label: "EPSG:7763 / WGS 84 / Himachal Pradesh"},
  {coordinate_system:"EPSG:7764", label: "EPSG:7764 / WGS 84 / Jammu and Kashmir"},
  {coordinate_system:"EPSG:7765", label: "EPSG:7765 / WGS 84 / Jharkhand"},
  {coordinate_system:"EPSG:7766", label: "EPSG:7766 / WGS 84 / Madhya Pradesh"},
  {coordinate_system:"EPSG:7767", label: "EPSG:7767 / WGS 84 / Maharashtra"},
  {coordinate_system:"EPSG:7768", label: "EPSG:7768 / WGS 84 / Manipur"},
  {coordinate_system:"EPSG:7769", label: "EPSG:7769 / WGS 84 / Meghalaya"},
  {coordinate_system:"EPSG:7770", label: "EPSG:7770 / WGS 84 / Nagaland"},
  {coordinate_system:"EPSG:7771", label: "EPSG:7771 / WGS 84 / India Northeast"},
  {coordinate_system:"EPSG:7772", label: "EPSG:7772 / WGS 84 / Orissa"},
  {coordinate_system:"EPSG:7773", label: "EPSG:7773 / WGS 84 / Punjab"},
  {coordinate_system:"EPSG:7774", label: "EPSG:7774 / WGS 84 / Rajasthan"},
  {coordinate_system:"EPSG:7775", label: "EPSG:7775 / WGS 84 / Uttar Pradesh"},
  {coordinate_system:"EPSG:7776", label: "EPSG:7776 / WGS 84 / Uttaranchal"},
  {coordinate_system:"EPSG:7777", label: "EPSG:7777 / WGS 84 / Andaman and Nicobar"},
  {coordinate_system:"EPSG:7778", label: "EPSG:7778 / WGS 84 / Chhattisgarh"},
  {coordinate_system:"EPSG:7779", label: "EPSG:7779 / WGS 84 / Goa"},
  {coordinate_system:"EPSG:7780", label: "EPSG:7780 / WGS 84 / Karnataka"},
  {coordinate_system:"EPSG:7781", label: "EPSG:7781 / WGS 84 / Kerala"},
  {coordinate_system:"EPSG:7782", label: "EPSG:7782 / WGS 84 / Lakshadweep"},
  {coordinate_system:"EPSG:7783", label: "EPSG:7783 / WGS 84 / Mizoram"},
  {coordinate_system:"EPSG:7784", label: "EPSG:7784 / WGS 84 / Sikkim"},
  {coordinate_system:"EPSG:7785", label: "EPSG:7785 / WGS 84 / Tamil Nadu"},
  {coordinate_system:"EPSG:7786", label: "EPSG:7786 / WGS 84 / Tripura"},
  {coordinate_system:"EPSG:7787", label: "EPSG:7787 / WGS 84 / West Bengal"},
  {coordinate_system:"EPSG:7791", label: "EPSG:7791 / RDN2008 / UTM zone 32N"},
  {coordinate_system:"EPSG:7792", label: "EPSG:7792 / RDN2008 / UTM zone 33N"},
  {coordinate_system:"EPSG:7793", label: "EPSG:7793 / RDN2008 / UTM zone 34N"},
  {coordinate_system:"EPSG:7794", label: "EPSG:7794 / RDN2008 / Italy zone (E-N)"},
  {coordinate_system:"EPSG:7795", label: "EPSG:7795 / RDN2008 / Zone 12 (E-N)"},
  {coordinate_system:"EPSG:7799", label: "EPSG:7799 / BGS2005 / UTM zone 34N (N-E)"},
  {coordinate_system:"EPSG:7800", label: "EPSG:7800 / BGS2005 / UTM zone 35N (N-E)"},
  {coordinate_system:"EPSG:7801", label: "EPSG:7801 / BGS2005 / CCS2005"},
  {coordinate_system:"EPSG:7803", label: "EPSG:7803 / BGS2005 / UTM zone 34N"},
  {coordinate_system:"EPSG:7805", label: "EPSG:7805 / BGS2005 / UTM zone 36N"},
  {coordinate_system:"EPSG:7825", label: "EPSG:7825 / Pulkovo 1942 / CS63 zone X1"},
  {coordinate_system:"EPSG:7826", label: "EPSG:7826 / Pulkovo 1942 / CS63 zone X2"},
  {coordinate_system:"EPSG:7827", label: "EPSG:7827 / Pulkovo 1942 / CS63 zone X3"},
  {coordinate_system:"EPSG:7828", label: "EPSG:7828 / Pulkovo 1942 / CS63 zone X4"},
  {coordinate_system:"EPSG:7829", label: "EPSG:7829 / Pulkovo 1942 / CS63 zone X5"},
  {coordinate_system:"EPSG:7830", label: "EPSG:7830 / Pulkovo 1942 / CS63 zone X6"},
  {coordinate_system:"EPSG:7831", label: "EPSG:7831 / Pulkovo 1942 / CS63 zone X7"},
  {coordinate_system:"EPSG:7845", label: "EPSG:7845 / GDA2020 / GA LCC"},
  {coordinate_system:"EPSG:7846", label: "EPSG:7846 / GDA2020 / MGA zone 46"},
  {coordinate_system:"EPSG:7847", label: "EPSG:7847 / GDA2020 / MGA zone 47"},
  {coordinate_system:"EPSG:7848", label: "EPSG:7848 / GDA2020 / MGA zone 48"},
  {coordinate_system:"EPSG:7849", label: "EPSG:7849 / GDA2020 / MGA zone 49"},
  {coordinate_system:"EPSG:7850", label: "EPSG:7850 / GDA2020 / MGA zone 50"},
  {coordinate_system:"EPSG:7851", label: "EPSG:7851 / GDA2020 / MGA zone 51"},
  {coordinate_system:"EPSG:7852", label: "EPSG:7852 / GDA2020 / MGA zone 52"},
  {coordinate_system:"EPSG:7853", label: "EPSG:7853 / GDA2020 / MGA zone 53"},
  {coordinate_system:"EPSG:7854", label: "EPSG:7854 / GDA2020 / MGA zone 54"},
  {coordinate_system:"EPSG:7855", label: "EPSG:7855 / GDA2020 / MGA zone 55"},
  {coordinate_system:"EPSG:7856", label: "EPSG:7856 / GDA2020 / MGA zone 56"},
  {coordinate_system:"EPSG:7857", label: "EPSG:7857 / GDA2020 / MGA zone 57"},
  {coordinate_system:"EPSG:7858", label: "EPSG:7858 / GDA2020 / MGA zone 58"},
  {coordinate_system:"EPSG:7859", label: "EPSG:7859 / GDA2020 / MGA zone 59"},
  {coordinate_system:"EPSG:7877", label: "EPSG:7877 / Astro DOS 71 / SHLG71"},
  {coordinate_system:"EPSG:7878", label: "EPSG:7878 / Astro DOS 71 / UTM zone 30S"},
  {coordinate_system:"EPSG:7882", label: "EPSG:7882 / St. Helena Tritan / SHLG(Tritan)"},
  {coordinate_system:"EPSG:7883", label: "EPSG:7883 / St. Helena Tritan / UTM zone 30S"},
  {coordinate_system:"EPSG:7887", label: "EPSG:7887 / SHMG2015"},
  {coordinate_system:"EPSG:7899", label: "EPSG:7899 / GDA2020 / Vicgrid"},
  {coordinate_system:"EPSG:7991", label: "EPSG:7991 / NAD27 / MTM zone 10"},
  {coordinate_system:"EPSG:7992", label: "EPSG:7992 / Malongo 1987 / UTM zone 33S"},
  {coordinate_system:"EPSG:8013", label: "EPSG:8013 / GDA2020 / ALB2020"},
  {coordinate_system:"EPSG:8014", label: "EPSG:8014 / GDA2020 / BIO2020"},
  {coordinate_system:"EPSG:8015", label: "EPSG:8015 / GDA2020 / BRO2020"},
  {coordinate_system:"EPSG:8016", label: "EPSG:8016 / GDA2020 / BCG2020"},
  {coordinate_system:"EPSG:8017", label: "EPSG:8017 / GDA2020 / CARN2020"},
  {coordinate_system:"EPSG:8018", label: "EPSG:8018 / GDA2020 / CIG2020"},
  {coordinate_system:"EPSG:8019", label: "EPSG:8019 / GDA2020 / CKIG2020"},
  {coordinate_system:"EPSG:8020", label: "EPSG:8020 / GDA2020 / COL2020"},
  {coordinate_system:"EPSG:8021", label: "EPSG:8021 / GDA2020 / ESP2020"},
  {coordinate_system:"EPSG:8022", label: "EPSG:8022 / GDA2020 / EXM2020"},
  {coordinate_system:"EPSG:8023", label: "EPSG:8023 / GDA2020 / GCG2020"},
  {coordinate_system:"EPSG:8024", label: "EPSG:8024 / GDA2020 / GOLD2020"},
  {coordinate_system:"EPSG:8025", label: "EPSG:8025 / GDA2020 / JCG2020"},
  {coordinate_system:"EPSG:8026", label: "EPSG:8026 / GDA2020 / KALB2020"},
  {coordinate_system:"EPSG:8027", label: "EPSG:8027 / GDA2020 / KAR2020"},
  {coordinate_system:"EPSG:8028", label: "EPSG:8028 / GDA2020 / KUN2020"},
  {coordinate_system:"EPSG:8029", label: "EPSG:8029 / GDA2020 / LCG2020"},
  {coordinate_system:"EPSG:8030", label: "EPSG:8030 / GDA2020 / MRCG2020"},
  {coordinate_system:"EPSG:8031", label: "EPSG:8031 / GDA2020 / PCG2020"},
  {coordinate_system:"EPSG:8032", label: "EPSG:8032 / GDA2020 / PHG2020"},
  {coordinate_system:"EPSG:8035", label: "EPSG:8035 / WGS 84 / TM Zone 20N (ftUS)"},
  {coordinate_system:"EPSG:8036", label: "EPSG:8036 / WGS 84 / TM Zone 21N (ftUS)"},
  {coordinate_system:"EPSG:8044", label: "EPSG:8044 / Gusterberg Grid (Ferro)"},
  {coordinate_system:"EPSG:8045", label: "EPSG:8045 / St. Stephen Grid (Ferro)"},
  {coordinate_system:"EPSG:8058", label: "EPSG:8058 / GDA2020 / NSW Lambert"},
  {coordinate_system:"EPSG:8059", label: "EPSG:8059 / GDA2020 / SA Lambert"},
  {coordinate_system:"EPSG:8065", label: "EPSG:8065 / NAD83(2011) / PCCS zone 1 (ft)"},
  {coordinate_system:"EPSG:8066", label: "EPSG:8066 / NAD83(2011) / PCCS zone 2 (ft)"},
  {coordinate_system:"EPSG:8067", label: "EPSG:8067 / NAD83(2011) / PCCS zone 3 (ft)"},
  {coordinate_system:"EPSG:8068", label: "EPSG:8068 / NAD83(2011) / PCCS zone 4 (ft)"},
  {coordinate_system:"EPSG:8082", label: "EPSG:8082 / NAD83(CSRS)v6 / MTM NS 2010 zone 4"},
  {coordinate_system:"EPSG:8083", label: "EPSG:8083 / NAD83(CSRS)v6 / MTM NS 2010 zone 5"},
  {coordinate_system:"EPSG:8088", label: "EPSG:8088 / ISN2016 / Lambert 2016"},
  {coordinate_system:"EPSG:8090", label: "EPSG:8090 / NAD83(HARN) / WISCRS Florence (m)"},
  {coordinate_system:"EPSG:8091", label: "EPSG:8091 / NAD83(HARN) / WISCRS Florence (ftUS)"},
  {coordinate_system:"EPSG:8092", label: "EPSG:8092 / NAD83(HARN) / WISCRS Eau Claire (m)"},
  {coordinate_system:"EPSG:8093", label: "EPSG:8093 / NAD83(HARN) / WISCRS Eau Claire (ftUS)"},
  {coordinate_system:"EPSG:8095", label: "EPSG:8095 / NAD83(HARN) / WISCRS Wood (m)"},
  {coordinate_system:"EPSG:8096", label: "EPSG:8096 / NAD83(HARN) / WISCRS Wood (ftUS)"},
  {coordinate_system:"EPSG:8097", label: "EPSG:8097 / NAD83(HARN) / WISCRS Waushara (m)"},
  {coordinate_system:"EPSG:8098", label: "EPSG:8098 / NAD83(HARN) / WISCRS Waushara (ftUS)"},
  {coordinate_system:"EPSG:8099", label: "EPSG:8099 / NAD83(HARN) / WISCRS Waupaca (m)"},
  {coordinate_system:"EPSG:8100", label: "EPSG:8100 / NAD83(HARN) / WISCRS Waupaca (ftUS)"},
  {coordinate_system:"EPSG:8101", label: "EPSG:8101 / NAD83(HARN) / WISCRS Waukesha (m)"},
  {coordinate_system:"EPSG:8102", label: "EPSG:8102 / NAD83(HARN) / WISCRS Waukesha (ftUS)"},
  {coordinate_system:"EPSG:8103", label: "EPSG:8103 / NAD83(HARN) / WISCRS Washington (m)"},
  {coordinate_system:"EPSG:8104", label: "EPSG:8104 / NAD83(HARN) / WISCRS Washington (ftUS)"},
  {coordinate_system:"EPSG:8105", label: "EPSG:8105 / NAD83(HARN) / WISCRS Washburn (m)"},
  {coordinate_system:"EPSG:8106", label: "EPSG:8106 / NAD83(HARN) / WISCRS Washburn (ftUS)"},
  {coordinate_system:"EPSG:8107", label: "EPSG:8107 / NAD83(HARN) / WISCRS Walworth (m)"},
  {coordinate_system:"EPSG:8108", label: "EPSG:8108 / NAD83(HARN) / WISCRS Walworth (ftUS)"},
  {coordinate_system:"EPSG:8109", label: "EPSG:8109 / NAD83(HARN) / WISCRS Vilas (m)"},
  {coordinate_system:"EPSG:8110", label: "EPSG:8110 / NAD83(HARN) / WISCRS Vilas (ftUS)"},
  {coordinate_system:"EPSG:8111", label: "EPSG:8111 / NAD83(HARN) / WISCRS Vernon (m)"},
  {coordinate_system:"EPSG:8112", label: "EPSG:8112 / NAD83(HARN) / WISCRS Vernon (ftUS)"},
  {coordinate_system:"EPSG:8113", label: "EPSG:8113 / NAD83(HARN) / WISCRS Trempealeau (m)"},
  {coordinate_system:"EPSG:8114", label: "EPSG:8114 / NAD83(HARN) / WISCRS Trempealeau (ftUS)"},
  {coordinate_system:"EPSG:8115", label: "EPSG:8115 / NAD83(HARN) / WISCRS Taylor (m)"},
  {coordinate_system:"EPSG:8116", label: "EPSG:8116 / NAD83(HARN) / WISCRS Taylor (ftUS)"},
  {coordinate_system:"EPSG:8117", label: "EPSG:8117 / NAD83(HARN) / WISCRS St. Croix (m)"},
  {coordinate_system:"EPSG:8118", label: "EPSG:8118 / NAD83(HARN) / WISCRS St. Croix (ftUS)"},
  {coordinate_system:"EPSG:8119", label: "EPSG:8119 / NAD83(HARN) / WISCRS Shawano (m)"},
  {coordinate_system:"EPSG:8120", label: "EPSG:8120 / NAD83(HARN) / WISCRS Shawano (ftUS)"},
  {coordinate_system:"EPSG:8121", label: "EPSG:8121 / NAD83(HARN) / WISCRS Sawyer (m)"},
  {coordinate_system:"EPSG:8122", label: "EPSG:8122 / NAD83(HARN) / WISCRS Sawyer (ftUS)"},
  {coordinate_system:"EPSG:8123", label: "EPSG:8123 / NAD83(HARN) / WISCRS Sauk (m)"},
  {coordinate_system:"EPSG:8124", label: "EPSG:8124 / NAD83(HARN) / WISCRS Sauk (ftUS)"},
  {coordinate_system:"EPSG:8125", label: "EPSG:8125 / NAD83(HARN) / WISCRS Rusk (m)"},
  {coordinate_system:"EPSG:8126", label: "EPSG:8126 / NAD83(HARN) / WISCRS Rusk (ftUS)"},
  {coordinate_system:"EPSG:8127", label: "EPSG:8127 / NAD83(HARN) / WISCRS Rock (m)"},
  {coordinate_system:"EPSG:8128", label: "EPSG:8128 / NAD83(HARN) / WISCRS Rock (ftUS)"},
  {coordinate_system:"EPSG:8129", label: "EPSG:8129 / NAD83(HARN) / WISCRS Richland (m)"},
  {coordinate_system:"EPSG:8130", label: "EPSG:8130 / NAD83(HARN) / WISCRS Richland (ftUS)"},
  {coordinate_system:"EPSG:8131", label: "EPSG:8131 / NAD83(HARN) / WISCRS Price (m)"},
  {coordinate_system:"EPSG:8132", label: "EPSG:8132 / NAD83(HARN) / WISCRS Price (ftUS)"},
  {coordinate_system:"EPSG:8133", label: "EPSG:8133 / NAD83(HARN) / WISCRS Portage (m)"},
  {coordinate_system:"EPSG:8134", label: "EPSG:8134 / NAD83(HARN) / WISCRS Portage (ftUS)"},
  {coordinate_system:"EPSG:8135", label: "EPSG:8135 / NAD83(HARN) / WISCRS Polk (m)"},
  {coordinate_system:"EPSG:8136", label: "EPSG:8136 / NAD83(HARN) / WISCRS Polk (ftUS)"},
  {coordinate_system:"EPSG:8137", label: "EPSG:8137 / NAD83(HARN) / WISCRS Pepin and Pierce (m)"},
  {coordinate_system:"EPSG:8138", label: "EPSG:8138 / NAD83(HARN) / WISCRS Pepin and Pierce (ftUS)"},
  {coordinate_system:"EPSG:8139", label: "EPSG:8139 / NAD83(HARN) / WISCRS Oneida (m)"},
  {coordinate_system:"EPSG:8140", label: "EPSG:8140 / NAD83(HARN) / WISCRS Oneida (ftUS)"},
  {coordinate_system:"EPSG:8141", label: "EPSG:8141 / NAD83(HARN) / WISCRS Oconto (m)"},
  {coordinate_system:"EPSG:8142", label: "EPSG:8142 / NAD83(HARN) / WISCRS Oconto (ftUS)"},
  {coordinate_system:"EPSG:8143", label: "EPSG:8143 / NAD83(HARN) / WISCRS Monroe (m)"},
  {coordinate_system:"EPSG:8144", label: "EPSG:8144 / NAD83(HARN) / WISCRS Monroe (ftUS)"},
  {coordinate_system:"EPSG:8145", label: "EPSG:8145 / NAD83(HARN) / WISCRS Menominee (m)"},
  {coordinate_system:"EPSG:8146", label: "EPSG:8146 / NAD83(HARN) / WISCRS Menominee (ftUS)"},
  {coordinate_system:"EPSG:8147", label: "EPSG:8147 / NAD83(HARN) / WISCRS Marinette (m)"},
  {coordinate_system:"EPSG:8148", label: "EPSG:8148 / NAD83(HARN) / WISCRS Marinette (ftUS)"},
  {coordinate_system:"EPSG:8149", label: "EPSG:8149 / NAD83(HARN) / WISCRS Marathon (m)"},
  {coordinate_system:"EPSG:8150", label: "EPSG:8150 / NAD83(HARN) / WISCRS Marathon (ftUS)"},
  {coordinate_system:"EPSG:8151", label: "EPSG:8151 / NAD83(HARN) / WISCRS Lincoln (m)"},
  {coordinate_system:"EPSG:8152", label: "EPSG:8152 / NAD83(HARN) / WISCRS Lincoln (ftUS)"},
  {coordinate_system:"EPSG:8153", label: "EPSG:8153 / NAD83(HARN) / WISCRS Langlade (m)"},
  {coordinate_system:"EPSG:8154", label: "EPSG:8154 / NAD83(HARN) / WISCRS Langlade (ftUS)"},
  {coordinate_system:"EPSG:8155", label: "EPSG:8155 / NAD83(HARN) / WISCRS La Crosse (m)"},
  {coordinate_system:"EPSG:8156", label: "EPSG:8156 / NAD83(HARN) / WISCRS La Crosse (ftUS)"},
  {coordinate_system:"EPSG:8157", label: "EPSG:8157 / NAD83(HARN) / WISCRS Kewaunee, Manitowoc and Sheboygan (m)"},
  {coordinate_system:"EPSG:8158", label: "EPSG:8158 / NAD83(HARN) / WISCRS Kewaunee, Manitowoc and Sheboygan (ftUS)"},
  {coordinate_system:"EPSG:8159", label: "EPSG:8159 / NAD83(HARN) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (m)"},
  {coordinate_system:"EPSG:8160", label: "EPSG:8160 / NAD83(HARN) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (ftUS)"},
  {coordinate_system:"EPSG:8161", label: "EPSG:8161 / NAD83(HARN) / WISCRS Jackson (m)"},
  {coordinate_system:"EPSG:8162", label: "EPSG:8162 / NAD83(HARN) / WISCRS Jackson (ftUS)"},
  {coordinate_system:"EPSG:8163", label: "EPSG:8163 / NAD83(HARN) / WISCRS Iron (m)"},
  {coordinate_system:"EPSG:8164", label: "EPSG:8164 / NAD83(HARN) / WISCRS Iron (ftUS)"},
  {coordinate_system:"EPSG:8165", label: "EPSG:8165 / NAD83(HARN) / WISCRS Iowa (m)"},
  {coordinate_system:"EPSG:8166", label: "EPSG:8166 / NAD83(HARN) / WISCRS Iowa (ftUS)"},
  {coordinate_system:"EPSG:8167", label: "EPSG:8167 / NAD83(HARN) / WISCRS Green Lake and Marquette (m)"},
  {coordinate_system:"EPSG:8168", label: "EPSG:8168 / NAD83(HARN) / WISCRS Green Lake and Marquette (ftUS)"},
  {coordinate_system:"EPSG:8169", label: "EPSG:8169 / NAD83(HARN) / WISCRS Green and Lafayette (m)"},
  {coordinate_system:"EPSG:8170", label: "EPSG:8170 / NAD83(HARN) / WISCRS Green and Lafayette (ftUS)"},
  {coordinate_system:"EPSG:8171", label: "EPSG:8171 / NAD83(HARN) / WISCRS Grant (m)"},
  {coordinate_system:"EPSG:8172", label: "EPSG:8172 / NAD83(HARN) / WISCRS Grant (ftUS)"},
  {coordinate_system:"EPSG:8173", label: "EPSG:8173 / NAD83(HARN) / WISCRS Forest (m)"},
  {coordinate_system:"EPSG:8177", label: "EPSG:8177 / NAD83(HARN) / WISCRS Forest (ftUS)"},
  {coordinate_system:"EPSG:8179", label: "EPSG:8179 / NAD83(HARN) / WISCRS Dunn (m)"},
  {coordinate_system:"EPSG:8180", label: "EPSG:8180 / NAD83(HARN) / WISCRS Dunn (ftUS)"},
  {coordinate_system:"EPSG:8181", label: "EPSG:8181 / NAD83(HARN) / WISCRS Douglas (m)"},
  {coordinate_system:"EPSG:8182", label: "EPSG:8182 / NAD83(HARN) / WISCRS Douglas (ftUS)"},
  {coordinate_system:"EPSG:8184", label: "EPSG:8184 / NAD83(HARN) / WISCRS Door (m)"},
  {coordinate_system:"EPSG:8185", label: "EPSG:8185 / NAD83(HARN) / WISCRS Door (ftUS)"},
  {coordinate_system:"EPSG:8187", label: "EPSG:8187 / NAD83(HARN) / WISCRS Dodge and Jefferson (m)"},
  {coordinate_system:"EPSG:8189", label: "EPSG:8189 / NAD83(HARN) / WISCRS Dodge and Jefferson (ftUS)"},
  {coordinate_system:"EPSG:8191", label: "EPSG:8191 / NAD83(HARN) / WISCRS Dane (m)"},
  {coordinate_system:"EPSG:8193", label: "EPSG:8193 / NAD83(HARN) / WISCRS Dane (ftUS)"},
  {coordinate_system:"EPSG:8196", label: "EPSG:8196 / NAD83(HARN) / WISCRS Crawford (m)"},
  {coordinate_system:"EPSG:8197", label: "EPSG:8197 / NAD83(HARN) / WISCRS Crawford (ftUS)"},
  {coordinate_system:"EPSG:8198", label: "EPSG:8198 / NAD83(HARN) / WISCRS Columbia (m)"},
  {coordinate_system:"EPSG:8200", label: "EPSG:8200 / NAD83(HARN) / WISCRS Columbia (ftUS)"},
  {coordinate_system:"EPSG:8201", label: "EPSG:8201 / NAD83(HARN) / WISCRS Clark (m)"},
  {coordinate_system:"EPSG:8202", label: "EPSG:8202 / NAD83(HARN) / WISCRS Clark (ftUS)"},
  {coordinate_system:"EPSG:8203", label: "EPSG:8203 / NAD83(HARN) / WISCRS Chippewa (m)"},
  {coordinate_system:"EPSG:8204", label: "EPSG:8204 / NAD83(HARN) / WISCRS Chippewa (ftUS)"},
  {coordinate_system:"EPSG:8205", label: "EPSG:8205 / NAD83(HARN) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (m)"},
  {coordinate_system:"EPSG:8206", label: "EPSG:8206 / NAD83(HARN) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (ftUS)"},
  {coordinate_system:"EPSG:8207", label: "EPSG:8207 / NAD83(HARN) / WISCRS Burnett (m)"},
  {coordinate_system:"EPSG:8208", label: "EPSG:8208 / NAD83(HARN) / WISCRS Burnett (ftUS)"},
  {coordinate_system:"EPSG:8209", label: "EPSG:8209 / NAD83(HARN) / WISCRS Buffalo (m)"},
  {coordinate_system:"EPSG:8210", label: "EPSG:8210 / NAD83(HARN) / WISCRS Buffalo (ftUS)"},
  {coordinate_system:"EPSG:8212", label: "EPSG:8212 / NAD83(HARN) / WISCRS Brown (m)"},
  {coordinate_system:"EPSG:8213", label: "EPSG:8213 / NAD83(HARN) / WISCRS Brown (ftUS)"},
  {coordinate_system:"EPSG:8214", label: "EPSG:8214 / NAD83(HARN) / WISCRS Bayfield (m)"},
  {coordinate_system:"EPSG:8216", label: "EPSG:8216 / NAD83(HARN) / WISCRS Bayfield (ftUS)"},
  {coordinate_system:"EPSG:8218", label: "EPSG:8218 / NAD83(HARN) / WISCRS Barron (m)"},
  {coordinate_system:"EPSG:8220", label: "EPSG:8220 / NAD83(HARN) / WISCRS Barron (ftUS)"},
  {coordinate_system:"EPSG:8222", label: "EPSG:8222 / NAD83(HARN) / WISCRS Ashland (m)"},
  {coordinate_system:"EPSG:8224", label: "EPSG:8224 / NAD83(HARN) / WISCRS Ashland (ftUS)"},
  {coordinate_system:"EPSG:8225", label: "EPSG:8225 / NAD83(HARN) / WISCRS Adams and Juneau (m)"},
  {coordinate_system:"EPSG:8226", label: "EPSG:8226 / NAD83(HARN) / WISCRS Adams and Juneau (ftUS)"},
  {coordinate_system:"EPSG:8311", label: "EPSG:8311 / NAD83(2011) / Oregon Burns-Harper zone (m)"},
  {coordinate_system:"EPSG:8312", label: "EPSG:8312 / NAD83(2011) / Oregon Burns-Harper zone (ft)"},
  {coordinate_system:"EPSG:8313", label: "EPSG:8313 / NAD83(2011) / Oregon Canyon City-Burns zone (m)"},
  {coordinate_system:"EPSG:8314", label: "EPSG:8314 / NAD83(2011) / Oregon Canyon City-Burns zone (ft)"},
  {coordinate_system:"EPSG:8315", label: "EPSG:8315 / NAD83(2011) / Oregon Coast Range North zone (m)"},
  {coordinate_system:"EPSG:8316", label: "EPSG:8316 / NAD83(2011) / Oregon Coast Range North zone (ft)"},
  {coordinate_system:"EPSG:8317", label: "EPSG:8317 / NAD83(2011) / Oregon Dayville-Prairie City zone (m)"},
  {coordinate_system:"EPSG:8318", label: "EPSG:8318 / NAD83(2011) / Oregon Dayville-Prairie City zone (ft)"},
  {coordinate_system:"EPSG:8319", label: "EPSG:8319 / NAD83(2011) / Oregon Denio-Burns zone (m)"},
  {coordinate_system:"EPSG:8320", label: "EPSG:8320 / NAD83(2011) / Oregon Denio-Burns zone (ft)"},
  {coordinate_system:"EPSG:8321", label: "EPSG:8321 / NAD83(2011) / Oregon Halfway zone (m)"},
  {coordinate_system:"EPSG:8322", label: "EPSG:8322 / NAD83(2011) / Oregon Halfway zone (ft)"},
  {coordinate_system:"EPSG:8323", label: "EPSG:8323 / NAD83(2011) / Oregon Medford-Diamond Lake zone (m)"},
  {coordinate_system:"EPSG:8324", label: "EPSG:8324 / NAD83(2011) / Oregon Medford-Diamond Lake zone (ft)"},
  {coordinate_system:"EPSG:8325", label: "EPSG:8325 / NAD83(2011) / Oregon Mitchell zone (m)"},
  {coordinate_system:"EPSG:8326", label: "EPSG:8326 / NAD83(2011) / Oregon Mitchell zone (ft)"},
  {coordinate_system:"EPSG:8327", label: "EPSG:8327 / NAD83(2011) / Oregon North Central zone (m)"},
  {coordinate_system:"EPSG:8328", label: "EPSG:8328 / NAD83(2011) / Oregon North Central zone (ft)"},
  {coordinate_system:"EPSG:8329", label: "EPSG:8329 / NAD83(2011) / Oregon Ochoco Summit zone (m)"},
  {coordinate_system:"EPSG:8330", label: "EPSG:8330 / NAD83(2011) / Oregon Ochoco Summit zone (ft)"},
  {coordinate_system:"EPSG:8331", label: "EPSG:8331 / NAD83(2011) / Oregon Owyhee zone (m)"},
  {coordinate_system:"EPSG:8332", label: "EPSG:8332 / NAD83(2011) / Oregon Owyhee zone (ft)"},
  {coordinate_system:"EPSG:8333", label: "EPSG:8333 / NAD83(2011) / Oregon Pilot Rock-Ukiah zone (m)"},
  {coordinate_system:"EPSG:8334", label: "EPSG:8334 / NAD83(2011) / Oregon Pilot Rock-Ukiah zone (ft)"},
  {coordinate_system:"EPSG:8335", label: "EPSG:8335 / NAD83(2011) / Oregon Prairie City-Brogan zone (m)"},
  {coordinate_system:"EPSG:8336", label: "EPSG:8336 / NAD83(2011) / Oregon Prairie City-Brogan zone (ft)"},
  {coordinate_system:"EPSG:8337", label: "EPSG:8337 / NAD83(2011) / Oregon Riley-Lakeview zone (m)"},
  {coordinate_system:"EPSG:8338", label: "EPSG:8338 / NAD83(2011) / Oregon Riley-Lakeview zone (ft)"},
  {coordinate_system:"EPSG:8339", label: "EPSG:8339 / NAD83(2011) / Oregon Siskiyou Pass zone (m)"},
  {coordinate_system:"EPSG:8340", label: "EPSG:8340 / NAD83(2011) / Oregon Siskiyou Pass zone (ft)"},
  {coordinate_system:"EPSG:8341", label: "EPSG:8341 / NAD83(2011) / Oregon Ukiah-Fox zone (m)"},
  {coordinate_system:"EPSG:8342", label: "EPSG:8342 / NAD83(2011) / Oregon Ukiah-Fox zone (ft)"},
  {coordinate_system:"EPSG:8343", label: "EPSG:8343 / NAD83(2011) / Oregon Wallowa zone (m)"},
  {coordinate_system:"EPSG:8344", label: "EPSG:8344 / NAD83(2011) / Oregon Wallowa zone (ft)"},
  {coordinate_system:"EPSG:8345", label: "EPSG:8345 / NAD83(2011) / Oregon Warner Highway zone (m)"},
  {coordinate_system:"EPSG:8346", label: "EPSG:8346 / NAD83(2011) / Oregon Warner Highway zone (ft)"},
  {coordinate_system:"EPSG:8347", label: "EPSG:8347 / NAD83(2011) / Oregon Willamette Pass zone (m)"},
  {coordinate_system:"EPSG:8348", label: "EPSG:8348 / NAD83(2011) / Oregon Willamette Pass zone (ft)"},
  {coordinate_system:"EPSG:8352", label: "EPSG:8352 / S-JTSK [JTSK03] / Krovak"},
  {coordinate_system:"EPSG:8353", label: "EPSG:8353 / S-JTSK [JTSK03] / Krovak East North"},
  {coordinate_system:"EPSG:8379", label: "EPSG:8379 / NAD83 / NCRS Las Vegas (m)"},
  {coordinate_system:"EPSG:8380", label: "EPSG:8380 / NAD83 / NCRS Las Vegas (ftUS)"},
  {coordinate_system:"EPSG:8381", label: "EPSG:8381 / NAD83 / NCRS Las Vegas high (m)"},
  {coordinate_system:"EPSG:8382", label: "EPSG:8382 / NAD83 / NCRS Las Vegas high (ftUS)"},
  {coordinate_system:"EPSG:8383", label: "EPSG:8383 / NAD83(2011) / NCRS Las Vegas (m)"},
  {coordinate_system:"EPSG:8384", label: "EPSG:8384 / NAD83(2011) / NCRS Las Vegas (ftUS)"},
  {coordinate_system:"EPSG:8385", label: "EPSG:8385 / NAD83(2011) / NCRS Las Vegas high (m)"},
  {coordinate_system:"EPSG:8387", label: "EPSG:8387 / NAD83(2011) / NCRS Las Vegas high (ftUS)"},
  {coordinate_system:"EPSG:8391", label: "EPSG:8391 / GDA94 / WEIPA94"},
  {coordinate_system:"EPSG:8395", label: "EPSG:8395 / ETRS89 / Gauss-Kruger CM 9E"},
  {coordinate_system:"EPSG:8433", label: "EPSG:8433 / Macao 1920 / Macao Grid"},
  {coordinate_system:"EPSG:8441", label: "EPSG:8441 / Tananarive / Laborde Grid"},
  {coordinate_system:"EPSG:8455", label: "EPSG:8455 / RGTAAF07 / UTM zone 53S"},
  {coordinate_system:"EPSG:8456", label: "EPSG:8456 / RGTAAF07 / UTM zone 54S"},
  {coordinate_system:"EPSG:8518", label: "EPSG:8518 / NAD83(2011) / KS RCS zone 1"},
  {coordinate_system:"EPSG:8519", label: "EPSG:8519 / NAD83(2011) / KS RCS zone 2"},
  {coordinate_system:"EPSG:8520", label: "EPSG:8520 / NAD83(2011) / KS RCS zone 3"},
  {coordinate_system:"EPSG:8521", label: "EPSG:8521 / NAD83(2011) / KS RCS zone 4"},
  {coordinate_system:"EPSG:8522", label: "EPSG:8522 / NAD83(2011) / KS RCS zone 5"},
  {coordinate_system:"EPSG:8523", label: "EPSG:8523 / NAD83(2011) / KS RCS zone 6"},
  {coordinate_system:"EPSG:8524", label: "EPSG:8524 / NAD83(2011) / KS RCS zone 7"},
  {coordinate_system:"EPSG:8525", label: "EPSG:8525 / NAD83(2011) / KS RCS zone 8"},
  {coordinate_system:"EPSG:8526", label: "EPSG:8526 / NAD83(2011) / KS RCS zone 9"},
  {coordinate_system:"EPSG:8527", label: "EPSG:8527 / NAD83(2011) / KS RCS zone 10"},
  {coordinate_system:"EPSG:8528", label: "EPSG:8528 / NAD83(2011) / KS RCS zone 11"},
  {coordinate_system:"EPSG:8529", label: "EPSG:8529 / NAD83(2011) / KS RCS zone 12"},
  {coordinate_system:"EPSG:8531", label: "EPSG:8531 / NAD83(2011) / KS RCS zone 13"},
  {coordinate_system:"EPSG:8533", label: "EPSG:8533 / NAD83(2011) / KS RCS zone 14"},
  {coordinate_system:"EPSG:8534", label: "EPSG:8534 / NAD83(2011) / KS RCS zone 15"},
  {coordinate_system:"EPSG:8535", label: "EPSG:8535 / NAD83(2011) / KS RCS zone 16"},
  {coordinate_system:"EPSG:8536", label: "EPSG:8536 / NAD83(2011) / KS RCS zone 17"},
  {coordinate_system:"EPSG:8538", label: "EPSG:8538 / NAD83(2011) / KS RCS zone 18"},
  {coordinate_system:"EPSG:8539", label: "EPSG:8539 / NAD83(2011) / KS RCS zone 19"},
  {coordinate_system:"EPSG:8540", label: "EPSG:8540 / NAD83(2011) / KS RCS zone 20"},
  {coordinate_system:"EPSG:8677", label: "EPSG:8677 / MGI 1901 / Balkans zone 5"},
  {coordinate_system:"EPSG:8678", label: "EPSG:8678 / MGI 1901 / Balkans zone 6"},
  {coordinate_system:"EPSG:8679", label: "EPSG:8679 / MGI 1901 / Balkans zone 8"},
  {coordinate_system:"EPSG:8682", label: "EPSG:8682 / SRB_ETRS89 / UTM zone 34N"},
  {coordinate_system:"EPSG:8686", label: "EPSG:8686 / MGI 1901 / Slovenia Grid"},
  {coordinate_system:"EPSG:8687", label: "EPSG:8687 / Slovenia 1996 / UTM zone 33N"},
  {coordinate_system:"EPSG:8692", label: "EPSG:8692 / NAD83(MA11) / UTM zone 54N"},
  {coordinate_system:"EPSG:8693", label: "EPSG:8693 / NAD83(MA11) / UTM zone 55N"},
  {coordinate_system:"EPSG:8826", label: "EPSG:8826 / NAD83 / Idaho Transverse Mercator"},
  {coordinate_system:"EPSG:8836", label: "EPSG:8836 / MTRF-2000 / UTM zone 36N"},
  {coordinate_system:"EPSG:8837", label: "EPSG:8837 / MTRF-2000 / UTM zone 37N"},
  {coordinate_system:"EPSG:8838", label: "EPSG:8838 / MTRF-2000 / UTM zone 38N"},
  {coordinate_system:"EPSG:8839", label: "EPSG:8839 / MTRF-2000 / UTM zone 39N"},
  {coordinate_system:"EPSG:8840", label: "EPSG:8840 / MTRF-2000 / UTM zone 40N"},
  {coordinate_system:"EPSG:8857", label: "EPSG:8857 / WGS 84 / Equal Earth Greenwich"},
  {coordinate_system:"EPSG:8858", label: "EPSG:8858 / WGS 84 / Equal Earth Americas"},
  {coordinate_system:"EPSG:8859", label: "EPSG:8859 / WGS 84 / Equal Earth Asia-Pacific"},
  {coordinate_system:"EPSG:8903", label: "EPSG:8903 / RGWF96 / UTM zone 1S"},
  {coordinate_system:"EPSG:8908", label: "EPSG:8908 / CR-SIRGAS / CRTM05"},
  {coordinate_system:"EPSG:8909", label: "EPSG:8909 / CR-SIRGAS / UTM zone 16N"},
  {coordinate_system:"EPSG:8910", label: "EPSG:8910 / CR-SIRGAS / UTM zone 17N"},
  {coordinate_system:"EPSG:8950", label: "EPSG:8950 / SIRGAS-Chile 2010 / UTM zone 18S"},
  {coordinate_system:"EPSG:8951", label: "EPSG:8951 / SIRGAS-Chile 2010 / UTM zone 19S"},
  {coordinate_system:"EPSG:9039", label: "EPSG:9039 / ISN2016 / LAEA Europe"},
  {coordinate_system:"EPSG:9040", label: "EPSG:9040 / ISN2016 / LCC Europe"},
  {coordinate_system:"EPSG:9141", label: "EPSG:9141 / KOSOVAREF01 / Balkans zone 7"},
  {coordinate_system:"EPSG:9149", label: "EPSG:9149 / SIRGAS-Chile 2013 / UTM zone 18S"},
  {coordinate_system:"EPSG:9150", label: "EPSG:9150 / SIRGAS-Chile 2013 / UTM zone 19S"},
  {coordinate_system:"EPSG:9154", label: "EPSG:9154 / SIRGAS-Chile 2016 / UTM zone 18S"},
  {coordinate_system:"EPSG:9155", label: "EPSG:9155 / SIRGAS-Chile 2016 / UTM zone 19S"},
  {coordinate_system:"EPSG:9156", label: "EPSG:9156 / RSAO13 / UTM zone 32S"},
  {coordinate_system:"EPSG:9157", label: "EPSG:9157 / RSAO13 / UTM zone 33S"},
  {coordinate_system:"EPSG:9158", label: "EPSG:9158 / RSAO13 / UTM zone 34S"},
  {coordinate_system:"EPSG:9159", label: "EPSG:9159 / RSAO13 / TM 12 SE"},
  {coordinate_system:"EPSG:9191", label: "EPSG:9191 / WGS 84 / NIWA Albers"},
  {coordinate_system:"EPSG:9205", label: "EPSG:9205 / VN-2000 / TM-3 103-00"},
  {coordinate_system:"EPSG:9206", label: "EPSG:9206 / VN-2000 / TM-3 104-00"},
  {coordinate_system:"EPSG:9207", label: "EPSG:9207 / VN-2000 / TM-3 104-30"},
  {coordinate_system:"EPSG:9208", label: "EPSG:9208 / VN-2000 / TM-3 104-45"},
  {coordinate_system:"EPSG:9209", label: "EPSG:9209 / VN-2000 / TM-3 105-30"},
  {coordinate_system:"EPSG:9210", label: "EPSG:9210 / VN-2000 / TM-3 105-45"},
  {coordinate_system:"EPSG:9211", label: "EPSG:9211 / VN-2000 / TM-3 106-00"},
  {coordinate_system:"EPSG:9212", label: "EPSG:9212 / VN-2000 / TM-3 106-15"},
  {coordinate_system:"EPSG:9213", label: "EPSG:9213 / VN-2000 / TM-3 106-30"},
  {coordinate_system:"EPSG:9214", label: "EPSG:9214 / VN-2000 / TM-3 107-00"},
  {coordinate_system:"EPSG:9215", label: "EPSG:9215 / VN-2000 / TM-3 107-15"},
  {coordinate_system:"EPSG:9216", label: "EPSG:9216 / VN-2000 / TM-3 107-30"},
  {coordinate_system:"EPSG:9217", label: "EPSG:9217 / VN-2000 / TM-3 108-15"},
  {coordinate_system:"EPSG:9218", label: "EPSG:9218 / VN-2000 / TM-3 108-30"},
  {coordinate_system:"EPSG:9221", label: "EPSG:9221 / Hartebeesthoek94 / ZAF BSU Albers 25E"},
  {coordinate_system:"EPSG:9222", label: "EPSG:9222 / Hartebeesthoek94 / ZAF BSU Albers 44E"},
  {coordinate_system:"EPSG:9249", label: "EPSG:9249 / Tapi Aike / Argentina 1"},
  {coordinate_system:"EPSG:9250", label: "EPSG:9250 / Tapi Aike / Argentina 2"},
  {coordinate_system:"EPSG:9252", label: "EPSG:9252 / MMN / Argentina 2"},
  {coordinate_system:"EPSG:9254", label: "EPSG:9254 / MMS / Argentina 2"},
  {coordinate_system:"EPSG:9265", label: "EPSG:9265 / POSGAR 2007 / UTM zone 19S"},
  {coordinate_system:"EPSG:9271", label: "EPSG:9271 / MGI / Austria West"},
  {coordinate_system:"EPSG:9272", label: "EPSG:9272 / MGI / Austria Central"},
  {coordinate_system:"EPSG:9273", label: "EPSG:9273 / MGI / Austria East"},
  {coordinate_system:"EPSG:9284", label: "EPSG:9284 / Pampa del Castillo / Argentina 1"},
  {coordinate_system:"EPSG:9285", label: "EPSG:9285 / Pampa del Castillo / Argentina 3"},
  {coordinate_system:"EPSG:9295", label: "EPSG:9295 / ONGD17 / UTM zone 39N"},
  {coordinate_system:"EPSG:9296", label: "EPSG:9296 / ONGD17 / UTM zone 40N"},
  {coordinate_system:"EPSG:9297", label: "EPSG:9297 / ONGD17 / UTM zone 41N"},
  {coordinate_system:"EPSG:9300", label: "EPSG:9300 / HS2 Survey Grid"},
  {coordinate_system:"EPSG:9311", label: "EPSG:9311 / NAD27 / US National Atlas Equal Area"},
  {coordinate_system:"EPSG:9354", label: "EPSG:9354 / WGS 84 / IBCSO Polar Stereographic"},
  {coordinate_system:"EPSG:9356", label: "EPSG:9356 / KSA-GRF17 / UTM zone 36N"},
  {coordinate_system:"EPSG:9357", label: "EPSG:9357 / KSA-GRF17 / UTM zone 37N"},
  {coordinate_system:"EPSG:9358", label: "EPSG:9358 / KSA-GRF17 / UTM zone 38N"},
  {coordinate_system:"EPSG:9359", label: "EPSG:9359 / KSA-GRF17 / UTM zone 39N"},
  {coordinate_system:"EPSG:9360", label: "EPSG:9360 / KSA-GRF17 / UTM zone 40N"},
  {coordinate_system:"EPSG:9367", label: "EPSG:9367 / TPEN11 Grid"},
  {coordinate_system:"EPSG:9373", label: "EPSG:9373 / MML07 Grid"},
  {coordinate_system:"EPSG:9377", label: "EPSG:9377 / MAGNA-SIRGAS / Origen-Nacional"},
  {coordinate_system:"EPSG:9387", label: "EPSG:9387 / AbInvA96_2020 Grid"},
  {coordinate_system:"EPSG:9391", label: "EPSG:9391 / BGS2005 / UTM zone 35N"},
  {coordinate_system:"EPSG:9404", label: "EPSG:9404 / PN68 / UTM zone 27N"},
  {coordinate_system:"EPSG:9405", label: "EPSG:9405 / PN68 / UTM zone 28N"},
  {coordinate_system:"EPSG:9406", label: "EPSG:9406 / PN84 / UTM zone 27N"},
  {coordinate_system:"EPSG:9407", label: "EPSG:9407 / PN84 / UTM zone 28N"},
  {coordinate_system:"EPSG:9456", label: "EPSG:9456 / GBK19 Grid"},
  {coordinate_system:"EPSG:9473", label: "EPSG:9473 / GDA2020 / Australian Albers"},
  {coordinate_system:"EPSG:9476", label: "EPSG:9476 / SRGI2013 / UTM zone 46N"},
  {coordinate_system:"EPSG:9477", label: "EPSG:9477 / SRGI2013 / UTM zone 47N"},
  {coordinate_system:"EPSG:9478", label: "EPSG:9478 / SRGI2013 / UTM zone 48N"},
  {coordinate_system:"EPSG:9479", label: "EPSG:9479 / SRGI2013 / UTM zone 49N"},
  {coordinate_system:"EPSG:9480", label: "EPSG:9480 / SRGI2013 / UTM zone 50N"},
  {coordinate_system:"EPSG:9481", label: "EPSG:9481 / SRGI2013 / UTM zone 51N"},
  {coordinate_system:"EPSG:9482", label: "EPSG:9482 / SRGI2013 / UTM zone 52N"},
  {coordinate_system:"EPSG:9487", label: "EPSG:9487 / SRGI2013 / UTM zone 47S"},
  {coordinate_system:"EPSG:9488", label: "EPSG:9488 / SRGI2013 / UTM zone 48S"},
  {coordinate_system:"EPSG:9489", label: "EPSG:9489 / SRGI2013 / UTM zone 49S"},
  {coordinate_system:"EPSG:9490", label: "EPSG:9490 / SRGI2013 / UTM zone 50S"},
  {coordinate_system:"EPSG:9491", label: "EPSG:9491 / SRGI2013 / UTM zone 51S"},
  {coordinate_system:"EPSG:9492", label: "EPSG:9492 / SRGI2013 / UTM zone 52S"},
  {coordinate_system:"EPSG:9493", label: "EPSG:9493 / SRGI2013 / UTM zone 53S"},
  {coordinate_system:"EPSG:9494", label: "EPSG:9494 / SRGI2013 / UTM zone 54S"},
  {coordinate_system:"EPSG:9498", label: "EPSG:9498 / POSGAR 2007 / CABA 2019"},
  {coordinate_system:"EPSG:9674", label: "EPSG:9674 / NAD83 / USFS R6 Albers"},
  {coordinate_system:"EPSG:9678", label: "EPSG:9678 / Gulshan 303 / Bangladesh Transverse Mercator"},
  {coordinate_system:"EPSG:9680", label: "EPSG:9680 / WGS 84 / TM 90 NE"},
  {coordinate_system:"EPSG:9697", label: "EPSG:9697 / REDGEOMIN / UTM zone 12S"},
  {coordinate_system:"EPSG:9698", label: "EPSG:9698 / REDGEOMIN / UTM zone 18S"},
  {coordinate_system:"EPSG:9699", label: "EPSG:9699 / REDGEOMIN / UTM zone 19S"},
  {coordinate_system:"EPSG:9709", label: "EPSG:9709 / NAD83(CSRS) / UTM zone 23N"},
  {coordinate_system:"EPSG:9712", label: "EPSG:9712 / NAD83 / UTM zone 24N"},
  {coordinate_system:"EPSG:9713", label: "EPSG:9713 / NAD83(CSRS) / UTM zone 24N"},
  {coordinate_system:"EPSG:9716", label: "EPSG:9716 / IGM95 / UTM zone 34N"},
  {coordinate_system:"EPSG:9741", label: "EPSG:9741 / EOS21 Grid"},
  {coordinate_system:"EPSG:9748", label: "EPSG:9748 / NAD83(2011) / Alabama East (ftUS)"},
  {coordinate_system:"EPSG:9749", label: "EPSG:9749 / NAD83(2011) / Alabama West (ftUS)"},
  {coordinate_system:"EPSG:9761", label: "EPSG:9761 / ECML14_NB Grid"},
  {coordinate_system:"EPSG:9766", label: "EPSG:9766 / EWR2 Grid"},
  {coordinate_system:"EPSG:9793", label: "EPSG:9793 / RGF93 v2 / Lambert-93"},
  {coordinate_system:"EPSG:9794", label: "EPSG:9794 / RGF93 v2b / Lambert-93"},
  {coordinate_system:"EPSG:9821", label: "EPSG:9821 / UCS-2000 / LCS-32 Kyiv region"},
  {coordinate_system:"EPSG:9822", label: "EPSG:9822 / RGF93 v2 / CC42"},
  {coordinate_system:"EPSG:9823", label: "EPSG:9823 / RGF93 v2 / CC43"},
  {coordinate_system:"EPSG:9824", label: "EPSG:9824 / RGF93 v2 / CC44"},
  {coordinate_system:"EPSG:9825", label: "EPSG:9825 / RGF93 v2 / CC45"},
  {coordinate_system:"EPSG:9826", label: "EPSG:9826 / RGF93 v2 / CC46"},
  {coordinate_system:"EPSG:9827", label: "EPSG:9827 / RGF93 v2 / CC47"},
  {coordinate_system:"EPSG:9828", label: "EPSG:9828 / RGF93 v2 / CC48"},
  {coordinate_system:"EPSG:9829", label: "EPSG:9829 / RGF93 v2 / CC49"},
  {coordinate_system:"EPSG:9830", label: "EPSG:9830 / RGF93 v2 / CC50"},
  {coordinate_system:"EPSG:9831", label: "EPSG:9831 / UCS-2000 / LCS-01 Crimea"},
  {coordinate_system:"EPSG:9832", label: "EPSG:9832 / UCS-2000 / LCS-05 Vinnytsia"},
  {coordinate_system:"EPSG:9833", label: "EPSG:9833 / UCS-2000 / LCS-07 Volyn"},
  {coordinate_system:"EPSG:9834", label: "EPSG:9834 / UCS-2000 / LCS-12 Dnipropetrovsk"},
  {coordinate_system:"EPSG:9835", label: "EPSG:9835 / UCS-2000 / LCS-14 Donetsk"},
  {coordinate_system:"EPSG:9836", label: "EPSG:9836 / UCS-2000 / LCS-18 Zhytomyr"},
  {coordinate_system:"EPSG:9837", label: "EPSG:9837 / UCS-2000 / LCS-21 Zakarpattia"},
  {coordinate_system:"EPSG:9838", label: "EPSG:9838 / UCS-2000 / LCS-23 Zaporizhzhia"},
  {coordinate_system:"EPSG:9839", label: "EPSG:9839 / UCS-2000 / LCS-26 Ivano-Frankivsk"},
  {coordinate_system:"EPSG:9840", label: "EPSG:9840 / UCS-2000 / LCS-35 Kirovohrad"},
  {coordinate_system:"EPSG:9841", label: "EPSG:9841 / UCS-2000 / LCS-44 Luhansk"},
  {coordinate_system:"EPSG:9842", label: "EPSG:9842 / RGF93 v2b / CC42"},
  {coordinate_system:"EPSG:9843", label: "EPSG:9843 / RGF93 v2b / CC43"},
  {coordinate_system:"EPSG:9844", label: "EPSG:9844 / RGF93 v2b / CC44"},
  {coordinate_system:"EPSG:9845", label: "EPSG:9845 / RGF93 v2b / CC45"},
  {coordinate_system:"EPSG:9846", label: "EPSG:9846 / RGF93 v2b / CC46"},
  {coordinate_system:"EPSG:9847", label: "EPSG:9847 / RGF93 v2b / CC47"},
  {coordinate_system:"EPSG:9848", label: "EPSG:9848 / RGF93 v2b / CC48"},
  {coordinate_system:"EPSG:9849", label: "EPSG:9849 / RGF93 v2b / CC49"},
  {coordinate_system:"EPSG:9850", label: "EPSG:9850 / RGF93 v2b / CC50"},
  {coordinate_system:"EPSG:9851", label: "EPSG:9851 / UCS-2000 / LCS-46 Lviv"},
  {coordinate_system:"EPSG:9852", label: "EPSG:9852 / UCS-2000 / LCS-48 Mykolaiv"},
  {coordinate_system:"EPSG:9853", label: "EPSG:9853 / UCS-2000 / LCS-51 Odessa"},
  {coordinate_system:"EPSG:9854", label: "EPSG:9854 / UCS-2000 / LCS-53 Poltava"},
  {coordinate_system:"EPSG:9855", label: "EPSG:9855 / UCS-2000 / LCS-56 Rivne"},
  {coordinate_system:"EPSG:9856", label: "EPSG:9856 / UCS-2000 / LCS-59 Sumy"},
  {coordinate_system:"EPSG:9857", label: "EPSG:9857 / UCS-2000 / LCS-61 Ternopil"},
  {coordinate_system:"EPSG:9858", label: "EPSG:9858 / UCS-2000 / LCS-63 Kharkiv"},
  {coordinate_system:"EPSG:9859", label: "EPSG:9859 / UCS-2000 / LCS-65 Kherson"},
  {coordinate_system:"EPSG:9860", label: "EPSG:9860 / UCS-2000 / LCS-68 Khmelnytsky"},
  {coordinate_system:"EPSG:9861", label: "EPSG:9861 / UCS-2000 / LCS-71 Cherkasy"},
  {coordinate_system:"EPSG:9862", label: "EPSG:9862 / UCS-2000 / LCS-73 Chernivtsi"},
  {coordinate_system:"EPSG:9863", label: "EPSG:9863 / UCS-2000 / LCS-74 Chernihiv"},
  {coordinate_system:"EPSG:9864", label: "EPSG:9864 / UCS-2000 / LCS-80 Kyiv city"},
  {coordinate_system:"EPSG:9865", label: "EPSG:9865 / UCS-2000 / LCS-85 Sevastopol"},
  {coordinate_system:"EPSG:9869", label: "EPSG:9869 / MRH21 Grid"},
  {coordinate_system:"EPSG:9874", label: "EPSG:9874 / PNG94 / PNGMG94 zone 57"},
  {coordinate_system:"EPSG:9875", label: "EPSG:9875 / PNG94 / PNGMG94 zone 58"},
  {coordinate_system:"EPSG:9880", label: "EPSG:9880 / MOLDOR11 Grid"},
  {coordinate_system:"EPSG:9895", label: "EPSG:9895 / LUREF / Luxembourg TM (3D)"},
  {coordinate_system:"EPSG:9943", label: "EPSG:9943 / EBBWV14 Grid"},
  {coordinate_system:"EPSG:9945", label: "EPSG:9945 / Macedonia State Coordinate System truncated"},
  {coordinate_system:"EPSG:9947", label: "EPSG:9947 / ISN2004 / LAEA Iceland"},
  {coordinate_system:"EPSG:9967", label: "EPSG:9967 / HULLEE13 Grid"},
  {coordinate_system:"EPSG:9972", label: "EPSG:9972 / SCM22 Grid"},
  {coordinate_system:"EPSG:9977", label: "EPSG:9977 / FNL22 Grid"},
  {coordinate_system:"EPSG:20002", label: "EPSG:20002 / MWC18 Grid"},
  {coordinate_system:"EPSG:20004", label: "EPSG:20004 / Pulkovo 1995 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:20005", label: "EPSG:20005 / Pulkovo 1995 / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:20006", label: "EPSG:20006 / Pulkovo 1995 / Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:20007", label: "EPSG:20007 / Pulkovo 1995 / Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:20008", label: "EPSG:20008 / Pulkovo 1995 / Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:20009", label: "EPSG:20009 / Pulkovo 1995 / Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:20010", label: "EPSG:20010 / Pulkovo 1995 / Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:20011", label: "EPSG:20011 / Pulkovo 1995 / Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:20012", label: "EPSG:20012 / Pulkovo 1995 / Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:20013", label: "EPSG:20013 / Pulkovo 1995 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:20014", label: "EPSG:20014 / Pulkovo 1995 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:20015", label: "EPSG:20015 / Pulkovo 1995 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:20016", label: "EPSG:20016 / Pulkovo 1995 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:20017", label: "EPSG:20017 / Pulkovo 1995 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:20018", label: "EPSG:20018 / Pulkovo 1995 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:20019", label: "EPSG:20019 / Pulkovo 1995 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:20020", label: "EPSG:20020 / Pulkovo 1995 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:20021", label: "EPSG:20021 / Pulkovo 1995 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:20022", label: "EPSG:20022 / Pulkovo 1995 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:20023", label: "EPSG:20023 / Pulkovo 1995 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:20024", label: "EPSG:20024 / Pulkovo 1995 / Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:20025", label: "EPSG:20025 / Pulkovo 1995 / Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:20026", label: "EPSG:20026 / Pulkovo 1995 / Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:20027", label: "EPSG:20027 / Pulkovo 1995 / Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:20028", label: "EPSG:20028 / Pulkovo 1995 / Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:20029", label: "EPSG:20029 / Pulkovo 1995 / Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:20030", label: "EPSG:20030 / Pulkovo 1995 / Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:20031", label: "EPSG:20031 / Pulkovo 1995 / Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:20032", label: "EPSG:20032 / Pulkovo 1995 / Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:20042", label: "EPSG:20042 / SIRGAS-Chile 2021 / UTM zone 12S"},
  {coordinate_system:"EPSG:20048", label: "EPSG:20048 / SIRGAS-Chile 2021 / UTM zone 18S"},
  {coordinate_system:"EPSG:20049", label: "EPSG:20049 / SIRGAS-Chile 2021 / UTM zone 19S"},
  {coordinate_system:"EPSG:20135", label: "EPSG:20135 / Adindan / UTM zone 35N"},
  {coordinate_system:"EPSG:20136", label: "EPSG:20136 / Adindan / UTM zone 36N"},
  {coordinate_system:"EPSG:20137", label: "EPSG:20137 / Adindan / UTM zone 37N"},
  {coordinate_system:"EPSG:20138", label: "EPSG:20138 / Adindan / UTM zone 38N"},
  {coordinate_system:"EPSG:20249", label: "EPSG:20249 / AGD66 / AMG zone 49"},
  {coordinate_system:"EPSG:20250", label: "EPSG:20250 / AGD66 / AMG zone 50"},
  {coordinate_system:"EPSG:20251", label: "EPSG:20251 / AGD66 / AMG zone 51"},
  {coordinate_system:"EPSG:20252", label: "EPSG:20252 / AGD66 / AMG zone 52"},
  {coordinate_system:"EPSG:20253", label: "EPSG:20253 / AGD66 / AMG zone 53"},
  {coordinate_system:"EPSG:20254", label: "EPSG:20254 / AGD66 / AMG zone 54"},
  {coordinate_system:"EPSG:20255", label: "EPSG:20255 / AGD66 / AMG zone 55"},
  {coordinate_system:"EPSG:20256", label: "EPSG:20256 / AGD66 / AMG zone 56"},
  {coordinate_system:"EPSG:20257", label: "EPSG:20257 / AGD66 / AMG zone 57"},
  {coordinate_system:"EPSG:20258", label: "EPSG:20258 / AGD66 / AMG zone 58"},
  {coordinate_system:"EPSG:20349", label: "EPSG:20349 / AGD84 / AMG zone 49"},
  {coordinate_system:"EPSG:20350", label: "EPSG:20350 / AGD84 / AMG zone 50"},
  {coordinate_system:"EPSG:20351", label: "EPSG:20351 / AGD84 / AMG zone 51"},
  {coordinate_system:"EPSG:20352", label: "EPSG:20352 / AGD84 / AMG zone 52"},
  {coordinate_system:"EPSG:20353", label: "EPSG:20353 / AGD84 / AMG zone 53"},
  {coordinate_system:"EPSG:20354", label: "EPSG:20354 / AGD84 / AMG zone 54"},
  {coordinate_system:"EPSG:20355", label: "EPSG:20355 / AGD84 / AMG zone 55"},
  {coordinate_system:"EPSG:20356", label: "EPSG:20356 / AGD84 / AMG zone 56"},
  {coordinate_system:"EPSG:20436", label: "EPSG:20436 / Ain el Abd / UTM zone 36N"},
  {coordinate_system:"EPSG:20437", label: "EPSG:20437 / Ain el Abd / UTM zone 37N"},
  {coordinate_system:"EPSG:20438", label: "EPSG:20438 / Ain el Abd / UTM zone 38N"},
  {coordinate_system:"EPSG:20439", label: "EPSG:20439 / Ain el Abd / UTM zone 39N"},
  {coordinate_system:"EPSG:20440", label: "EPSG:20440 / Ain el Abd / UTM zone 40N"},
  {coordinate_system:"EPSG:20499", label: "EPSG:20499 / Ain el Abd / Bahrain Grid"},
  {coordinate_system:"EPSG:20538", label: "EPSG:20538 / Afgooye / UTM zone 38N"},
  {coordinate_system:"EPSG:20539", label: "EPSG:20539 / Afgooye / UTM zone 39N"},
  {coordinate_system:"EPSG:20790", label: "EPSG:20790 / Lisbon (Lisbon) / Portuguese National Grid"},
  {coordinate_system:"EPSG:20791", label: "EPSG:20791 / Lisbon (Lisbon) / Portuguese Grid"},
  {coordinate_system:"EPSG:20822", label: "EPSG:20822 / Aratu / UTM zone 22S"},
  {coordinate_system:"EPSG:20823", label: "EPSG:20823 / Aratu / UTM zone 23S"},
  {coordinate_system:"EPSG:20824", label: "EPSG:20824 / Aratu / UTM zone 24S"},
  {coordinate_system:"EPSG:20904", label: "EPSG:20904 / GSK-2011 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:20905", label: "EPSG:20905 / GSK-2011 / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:20906", label: "EPSG:20906 / GSK-2011 / Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:20907", label: "EPSG:20907 / GSK-2011 / Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:20908", label: "EPSG:20908 / GSK-2011 / Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:20909", label: "EPSG:20909 / GSK-2011 / Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:20910", label: "EPSG:20910 / GSK-2011 / Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:20911", label: "EPSG:20911 / GSK-2011 / Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:20912", label: "EPSG:20912 / GSK-2011 / Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:20913", label: "EPSG:20913 / GSK-2011 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:20914", label: "EPSG:20914 / GSK-2011 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:20915", label: "EPSG:20915 / GSK-2011 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:20916", label: "EPSG:20916 / GSK-2011 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:20917", label: "EPSG:20917 / GSK-2011 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:20918", label: "EPSG:20918 / GSK-2011 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:20919", label: "EPSG:20919 / GSK-2011 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:20920", label: "EPSG:20920 / GSK-2011 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:20921", label: "EPSG:20921 / GSK-2011 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:20922", label: "EPSG:20922 / GSK-2011 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:20923", label: "EPSG:20923 / GSK-2011 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:20924", label: "EPSG:20924 / GSK-2011 / Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:20925", label: "EPSG:20925 / GSK-2011 / Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:20926", label: "EPSG:20926 / GSK-2011 / Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:20927", label: "EPSG:20927 / GSK-2011 / Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:20928", label: "EPSG:20928 / GSK-2011 / Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:20929", label: "EPSG:20929 / GSK-2011 / Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:20930", label: "EPSG:20930 / GSK-2011 / Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:20931", label: "EPSG:20931 / GSK-2011 / Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:20932", label: "EPSG:20932 / GSK-2011 / Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:20934", label: "EPSG:20934 / Arc 1950 / UTM zone 34S"},
  {coordinate_system:"EPSG:20935", label: "EPSG:20935 / Arc 1950 / UTM zone 35S"},
  {coordinate_system:"EPSG:20936", label: "EPSG:20936 / Arc 1950 / UTM zone 36S"},
  {coordinate_system:"EPSG:21004", label: "EPSG:21004 / GSK-2011 / Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:21005", label: "EPSG:21005 / GSK-2011 / Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:21006", label: "EPSG:21006 / GSK-2011 / Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:21007", label: "EPSG:21007 / GSK-2011 / Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:21008", label: "EPSG:21008 / GSK-2011 / Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:21009", label: "EPSG:21009 / GSK-2011 / Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:21010", label: "EPSG:21010 / GSK-2011 / Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:21011", label: "EPSG:21011 / GSK-2011 / Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:21012", label: "EPSG:21012 / GSK-2011 / Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:21013", label: "EPSG:21013 / GSK-2011 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:21014", label: "EPSG:21014 / GSK-2011 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:21015", label: "EPSG:21015 / GSK-2011 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:21016", label: "EPSG:21016 / GSK-2011 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:21017", label: "EPSG:21017 / GSK-2011 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:21018", label: "EPSG:21018 / GSK-2011 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:21019", label: "EPSG:21019 / GSK-2011 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:21020", label: "EPSG:21020 / GSK-2011 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:21021", label: "EPSG:21021 / GSK-2011 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:21022", label: "EPSG:21022 / GSK-2011 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:21023", label: "EPSG:21023 / GSK-2011 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:21024", label: "EPSG:21024 / GSK-2011 / Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:21025", label: "EPSG:21025 / GSK-2011 / Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:21026", label: "EPSG:21026 / GSK-2011 / Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:21027", label: "EPSG:21027 / GSK-2011 / Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:21028", label: "EPSG:21028 / GSK-2011 / Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:21029", label: "EPSG:21029 / GSK-2011 / Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:21030", label: "EPSG:21030 / GSK-2011 / Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:21031", label: "EPSG:21031 / GSK-2011 / Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:21032", label: "EPSG:21032 / GSK-2011 / Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:21035", label: "EPSG:21035 / Arc 1960 / UTM zone 35S"},
  {coordinate_system:"EPSG:21036", label: "EPSG:21036 / Arc 1960 / UTM zone 36S"},
  {coordinate_system:"EPSG:21037", label: "EPSG:21037 / Arc 1960 / UTM zone 37S"},
  {coordinate_system:"EPSG:21095", label: "EPSG:21095 / Arc 1960 / UTM zone 35N"},
  {coordinate_system:"EPSG:21096", label: "EPSG:21096 / Arc 1960 / UTM zone 36N"},
  {coordinate_system:"EPSG:21097", label: "EPSG:21097 / Arc 1960 / UTM zone 37N"},
  {coordinate_system:"EPSG:21148", label: "EPSG:21148 / Batavia / UTM zone 48S"},
  {coordinate_system:"EPSG:21149", label: "EPSG:21149 / Batavia / UTM zone 49S"},
  {coordinate_system:"EPSG:21150", label: "EPSG:21150 / Batavia / UTM zone 50S"},
  {coordinate_system:"EPSG:21207", label: "EPSG:21207 / GSK-2011 / GSK 3GK zone 7"},
  {coordinate_system:"EPSG:21208", label: "EPSG:21208 / GSK-2011 / GSK 3GK zone 8"},
  {coordinate_system:"EPSG:21209", label: "EPSG:21209 / GSK-2011 / GSK 3GK zone 9"},
  {coordinate_system:"EPSG:21210", label: "EPSG:21210 / GSK-2011 / GSK 3GK zone 10"},
  {coordinate_system:"EPSG:21211", label: "EPSG:21211 / GSK-2011 / GSK 3GK zone 11"},
  {coordinate_system:"EPSG:21212", label: "EPSG:21212 / GSK-2011 / GSK 3GK zone 12"},
  {coordinate_system:"EPSG:21213", label: "EPSG:21213 / GSK-2011 / GSK 3GK zone 13"},
  {coordinate_system:"EPSG:21214", label: "EPSG:21214 / GSK-2011 / GSK 3GK zone 14"},
  {coordinate_system:"EPSG:21215", label: "EPSG:21215 / GSK-2011 / GSK 3GK zone 15"},
  {coordinate_system:"EPSG:21216", label: "EPSG:21216 / GSK-2011 / GSK 3GK zone 16"},
  {coordinate_system:"EPSG:21217", label: "EPSG:21217 / GSK-2011 / GSK 3GK zone 17"},
  {coordinate_system:"EPSG:21218", label: "EPSG:21218 / GSK-2011 / GSK 3GK zone 18"},
  {coordinate_system:"EPSG:21219", label: "EPSG:21219 / GSK-2011 / GSK 3GK zone 19"},
  {coordinate_system:"EPSG:21220", label: "EPSG:21220 / GSK-2011 / GSK 3GK zone 20"},
  {coordinate_system:"EPSG:21221", label: "EPSG:21221 / GSK-2011 / GSK 3GK zone 21"},
  {coordinate_system:"EPSG:21222", label: "EPSG:21222 / GSK-2011 / GSK 3GK zone 22"},
  {coordinate_system:"EPSG:21223", label: "EPSG:21223 / GSK-2011 / GSK 3GK zone 23"},
  {coordinate_system:"EPSG:21224", label: "EPSG:21224 / GSK-2011 / GSK 3GK zone 24"},
  {coordinate_system:"EPSG:21225", label: "EPSG:21225 / GSK-2011 / GSK 3GK zone 25"},
  {coordinate_system:"EPSG:21226", label: "EPSG:21226 / GSK-2011 / GSK 3GK zone 26"},
  {coordinate_system:"EPSG:21227", label: "EPSG:21227 / GSK-2011 / GSK 3GK zone 27"},
  {coordinate_system:"EPSG:21228", label: "EPSG:21228 / GSK-2011 / GSK 3GK zone 28"},
  {coordinate_system:"EPSG:21229", label: "EPSG:21229 / GSK-2011 / GSK 3GK zone 29"},
  {coordinate_system:"EPSG:21230", label: "EPSG:21230 / GSK-2011 / GSK 3GK zone 30"},
  {coordinate_system:"EPSG:21231", label: "EPSG:21231 / GSK-2011 / GSK 3GK zone 31"},
  {coordinate_system:"EPSG:21232", label: "EPSG:21232 / GSK-2011 / GSK 3GK zone 32"},
  {coordinate_system:"EPSG:21233", label: "EPSG:21233 / GSK-2011 / GSK 3GK zone 33"},
  {coordinate_system:"EPSG:21234", label: "EPSG:21234 / GSK-2011 / GSK 3GK zone 34"},
  {coordinate_system:"EPSG:21235", label: "EPSG:21235 / GSK-2011 / GSK 3GK zone 35"},
  {coordinate_system:"EPSG:21236", label: "EPSG:21236 / GSK-2011 / GSK 3GK zone 36"},
  {coordinate_system:"EPSG:21237", label: "EPSG:21237 / GSK-2011 / GSK 3GK zone 37"},
  {coordinate_system:"EPSG:21238", label: "EPSG:21238 / GSK-2011 / GSK 3GK zone 38"},
  {coordinate_system:"EPSG:21239", label: "EPSG:21239 / GSK-2011 / GSK 3GK zone 39"},
  {coordinate_system:"EPSG:21240", label: "EPSG:21240 / GSK-2011 / GSK 3GK zone 40"},
  {coordinate_system:"EPSG:21241", label: "EPSG:21241 / GSK-2011 / GSK 3GK zone 41"},
  {coordinate_system:"EPSG:21242", label: "EPSG:21242 / GSK-2011 / GSK 3GK zone 42"},
  {coordinate_system:"EPSG:21243", label: "EPSG:21243 / GSK-2011 / GSK 3GK zone 43"},
  {coordinate_system:"EPSG:21244", label: "EPSG:21244 / GSK-2011 / GSK 3GK zone 44"},
  {coordinate_system:"EPSG:21245", label: "EPSG:21245 / GSK-2011 / GSK 3GK zone 45"},
  {coordinate_system:"EPSG:21246", label: "EPSG:21246 / GSK-2011 / GSK 3GK zone 46"},
  {coordinate_system:"EPSG:21247", label: "EPSG:21247 / GSK-2011 / GSK 3GK zone 47"},
  {coordinate_system:"EPSG:21248", label: "EPSG:21248 / GSK-2011 / GSK 3GK zone 48"},
  {coordinate_system:"EPSG:21249", label: "EPSG:21249 / GSK-2011 / GSK 3GK zone 49"},
  {coordinate_system:"EPSG:21250", label: "EPSG:21250 / GSK-2011 / GSK 3GK zone 50"},
  {coordinate_system:"EPSG:21251", label: "EPSG:21251 / GSK-2011 / GSK 3GK zone 51"},
  {coordinate_system:"EPSG:21252", label: "EPSG:21252 / GSK-2011 / GSK 3GK zone 52"},
  {coordinate_system:"EPSG:21253", label: "EPSG:21253 / GSK-2011 / GSK 3GK zone 53"},
  {coordinate_system:"EPSG:21254", label: "EPSG:21254 / GSK-2011 / GSK 3GK zone 54"},
  {coordinate_system:"EPSG:21255", label: "EPSG:21255 / GSK-2011 / GSK 3GK zone 55"},
  {coordinate_system:"EPSG:21256", label: "EPSG:21256 / GSK-2011 / GSK 3GK zone 56"},
  {coordinate_system:"EPSG:21257", label: "EPSG:21257 / GSK-2011 / GSK 3GK zone 57"},
  {coordinate_system:"EPSG:21258", label: "EPSG:21258 / GSK-2011 / GSK 3GK zone 58"},
  {coordinate_system:"EPSG:21259", label: "EPSG:21259 / GSK-2011 / GSK 3GK zone 59"},
  {coordinate_system:"EPSG:21260", label: "EPSG:21260 / GSK-2011 / GSK 3GK zone 60"},
  {coordinate_system:"EPSG:21261", label: "EPSG:21261 / GSK-2011 / GSK 3GK zone 61"},
  {coordinate_system:"EPSG:21262", label: "EPSG:21262 / GSK-2011 / GSK 3GK zone 62"},
  {coordinate_system:"EPSG:21263", label: "EPSG:21263 / GSK-2011 / GSK 3GK zone 63"},
  {coordinate_system:"EPSG:21264", label: "EPSG:21264 / GSK-2011 / GSK 3GK zone 64"},
  {coordinate_system:"EPSG:21291", label: "EPSG:21291 / Barbados 1938 / British West Indies Grid"},
  {coordinate_system:"EPSG:21292", label: "EPSG:21292 / Barbados 1938 / Barbados National Grid"},
  {coordinate_system:"EPSG:21307", label: "EPSG:21307 / GSK-2011 / GSK 3GK CM 21E"},
  {coordinate_system:"EPSG:21308", label: "EPSG:21308 / GSK-2011 / GSK 3GK CM 24E"},
  {coordinate_system:"EPSG:21309", label: "EPSG:21309 / GSK-2011 / GSK 3GK CM 27E"},
  {coordinate_system:"EPSG:21310", label: "EPSG:21310 / GSK-2011 / GSK 3GK CM 30E"},
  {coordinate_system:"EPSG:21311", label: "EPSG:21311 / GSK-2011 / GSK 3GK CM 33E"},
  {coordinate_system:"EPSG:21312", label: "EPSG:21312 / GSK-2011 / GSK 3GK CM 36E"},
  {coordinate_system:"EPSG:21313", label: "EPSG:21313 / GSK-2011 / GSK 3GK CM 39E"},
  {coordinate_system:"EPSG:21314", label: "EPSG:21314 / GSK-2011 / GSK 3GK CM 42E"},
  {coordinate_system:"EPSG:21315", label: "EPSG:21315 / GSK-2011 / GSK 3GK CM 45E"},
  {coordinate_system:"EPSG:21316", label: "EPSG:21316 / GSK-2011 / GSK 3GK CM 48E"},
  {coordinate_system:"EPSG:21317", label: "EPSG:21317 / GSK-2011 / GSK 3GK CM 51E"},
  {coordinate_system:"EPSG:21318", label: "EPSG:21318 / GSK-2011 / GSK 3GK CM 54E"},
  {coordinate_system:"EPSG:21319", label: "EPSG:21319 / GSK-2011 / GSK 3GK CM 57E"},
  {coordinate_system:"EPSG:21320", label: "EPSG:21320 / GSK-2011 / GSK 3GK CM 60E"},
  {coordinate_system:"EPSG:21321", label: "EPSG:21321 / GSK-2011 / GSK 3GK CM 63E"},
  {coordinate_system:"EPSG:21322", label: "EPSG:21322 / GSK-2011 / GSK 3GK CM 66E"},
  {coordinate_system:"EPSG:21323", label: "EPSG:21323 / GSK-2011 / GSK 3GK CM 69E"},
  {coordinate_system:"EPSG:21324", label: "EPSG:21324 / GSK-2011 / GSK 3GK CM 72E"},
  {coordinate_system:"EPSG:21325", label: "EPSG:21325 / GSK-2011 / GSK 3GK CM 75E"},
  {coordinate_system:"EPSG:21326", label: "EPSG:21326 / GSK-2011 / GSK 3GK CM 78E"},
  {coordinate_system:"EPSG:21327", label: "EPSG:21327 / GSK-2011 / GSK 3GK CM 81E"},
  {coordinate_system:"EPSG:21328", label: "EPSG:21328 / GSK-2011 / GSK 3GK CM 84E"},
  {coordinate_system:"EPSG:21329", label: "EPSG:21329 / GSK-2011 / GSK 3GK CM 87E"},
  {coordinate_system:"EPSG:21330", label: "EPSG:21330 / GSK-2011 / GSK 3GK CM 90E"},
  {coordinate_system:"EPSG:21331", label: "EPSG:21331 / GSK-2011 / GSK 3GK CM 93E"},
  {coordinate_system:"EPSG:21332", label: "EPSG:21332 / GSK-2011 / GSK 3GK CM 96E"},
  {coordinate_system:"EPSG:21333", label: "EPSG:21333 / GSK-2011 / GSK 3GK CM 99E"},
  {coordinate_system:"EPSG:21334", label: "EPSG:21334 / GSK-2011 / GSK 3GK CM 102E"},
  {coordinate_system:"EPSG:21335", label: "EPSG:21335 / GSK-2011 / GSK 3GK CM 105E"},
  {coordinate_system:"EPSG:21336", label: "EPSG:21336 / GSK-2011 / GSK 3GK CM 108E"},
  {coordinate_system:"EPSG:21337", label: "EPSG:21337 / GSK-2011 / GSK 3GK CM 111E"},
  {coordinate_system:"EPSG:21338", label: "EPSG:21338 / GSK-2011 / GSK 3GK CM 114E"},
  {coordinate_system:"EPSG:21339", label: "EPSG:21339 / GSK-2011 / GSK 3GK CM 117E"},
  {coordinate_system:"EPSG:21340", label: "EPSG:21340 / GSK-2011 / GSK 3GK CM 120E"},
  {coordinate_system:"EPSG:21341", label: "EPSG:21341 / GSK-2011 / GSK 3GK CM 123E"},
  {coordinate_system:"EPSG:21342", label: "EPSG:21342 / GSK-2011 / GSK 3GK CM 126E"},
  {coordinate_system:"EPSG:21343", label: "EPSG:21343 / GSK-2011 / GSK 3GK CM 129E"},
  {coordinate_system:"EPSG:21344", label: "EPSG:21344 / GSK-2011 / GSK 3GK CM 132E"},
  {coordinate_system:"EPSG:21345", label: "EPSG:21345 / GSK-2011 / GSK 3GK CM 135E"},
  {coordinate_system:"EPSG:21346", label: "EPSG:21346 / GSK-2011 / GSK 3GK CM 138E"},
  {coordinate_system:"EPSG:21347", label: "EPSG:21347 / GSK-2011 / GSK 3GK CM 141E"},
  {coordinate_system:"EPSG:21348", label: "EPSG:21348 / GSK-2011 / GSK 3GK CM 144E"},
  {coordinate_system:"EPSG:21349", label: "EPSG:21349 / GSK-2011 / GSK 3GK CM 147E"},
  {coordinate_system:"EPSG:21350", label: "EPSG:21350 / GSK-2011 / GSK 3GK CM 150E"},
  {coordinate_system:"EPSG:21351", label: "EPSG:21351 / GSK-2011 / GSK 3GK CM 153E"},
  {coordinate_system:"EPSG:21352", label: "EPSG:21352 / GSK-2011 / GSK 3GK CM 156E"},
  {coordinate_system:"EPSG:21353", label: "EPSG:21353 / GSK-2011 / GSK 3GK CM 159E"},
  {coordinate_system:"EPSG:21354", label: "EPSG:21354 / GSK-2011 / GSK 3GK CM 162E"},
  {coordinate_system:"EPSG:21355", label: "EPSG:21355 / GSK-2011 / GSK 3GK CM 165E"},
  {coordinate_system:"EPSG:21356", label: "EPSG:21356 / GSK-2011 / GSK 3GK CM 168E"},
  {coordinate_system:"EPSG:21357", label: "EPSG:21357 / GSK-2011 / GSK 3GK CM 171E"},
  {coordinate_system:"EPSG:21358", label: "EPSG:21358 / GSK-2011 / GSK 3GK CM 174E"},
  {coordinate_system:"EPSG:21359", label: "EPSG:21359 / GSK-2011 / GSK 3GK CM 177E"},
  {coordinate_system:"EPSG:21360", label: "EPSG:21360 / GSK-2011 / GSK 3GK CM 180E"},
  {coordinate_system:"EPSG:21361", label: "EPSG:21361 / GSK-2011 / GSK 3GK CM 177W"},
  {coordinate_system:"EPSG:21362", label: "EPSG:21362 / GSK-2011 / GSK 3GK CM 174W"},
  {coordinate_system:"EPSG:21363", label: "EPSG:21363 / GSK-2011 / GSK 3GK CM 171W"},
  {coordinate_system:"EPSG:21364", label: "EPSG:21364 / GSK-2011 / GSK 3GK CM 168W"},
  {coordinate_system:"EPSG:21413", label: "EPSG:21413 / Beijing 1954 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:21414", label: "EPSG:21414 / Beijing 1954 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:21415", label: "EPSG:21415 / Beijing 1954 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:21416", label: "EPSG:21416 / Beijing 1954 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:21417", label: "EPSG:21417 / Beijing 1954 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:21418", label: "EPSG:21418 / Beijing 1954 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:21419", label: "EPSG:21419 / Beijing 1954 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:21420", label: "EPSG:21420 / Beijing 1954 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:21421", label: "EPSG:21421 / Beijing 1954 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:21422", label: "EPSG:21422 / Beijing 1954 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:21423", label: "EPSG:21423 / Beijing 1954 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:21453", label: "EPSG:21453 / Beijing 1954 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:21454", label: "EPSG:21454 / Beijing 1954 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:21455", label: "EPSG:21455 / Beijing 1954 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:21456", label: "EPSG:21456 / Beijing 1954 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:21457", label: "EPSG:21457 / Beijing 1954 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:21458", label: "EPSG:21458 / Beijing 1954 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:21459", label: "EPSG:21459 / Beijing 1954 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:21460", label: "EPSG:21460 / Beijing 1954 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:21461", label: "EPSG:21461 / Beijing 1954 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:21462", label: "EPSG:21462 / Beijing 1954 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:21463", label: "EPSG:21463 / Beijing 1954 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:21500", label: "EPSG:21500 / BD50 (Brussels) / Belge Lambert 50"},
  {coordinate_system:"EPSG:21780", label: "EPSG:21780 / Bern 1898 (Bern) / LV03C"},
  {coordinate_system:"EPSG:21781", label: "EPSG:21781 / CH1903 / LV03"},
  {coordinate_system:"EPSG:21782", label: "EPSG:21782 / CH1903 / LV03C-G"},
  {coordinate_system:"EPSG:21818", label: "EPSG:21818 / Bogota 1975 / UTM zone 18N"},
  {coordinate_system:"EPSG:21896", label: "EPSG:21896 / Bogota 1975 / Colombia West zone"},
  {coordinate_system:"EPSG:21897", label: "EPSG:21897 / Bogota 1975 / Colombia Bogota zone"},
  {coordinate_system:"EPSG:21898", label: "EPSG:21898 / Bogota 1975 / Colombia East Central zone"},
  {coordinate_system:"EPSG:21899", label: "EPSG:21899 / Bogota 1975 / Colombia East zone"},
  {coordinate_system:"EPSG:22032", label: "EPSG:22032 / Camacupa 1948 / UTM zone 32S"},
  {coordinate_system:"EPSG:22033", label: "EPSG:22033 / Camacupa 1948 / UTM zone 33S"},
  {coordinate_system:"EPSG:22091", label: "EPSG:22091 / Camacupa 1948 / TM 11.30 SE"},
  {coordinate_system:"EPSG:22092", label: "EPSG:22092 / Camacupa 1948 / TM 12 SE"},
  {coordinate_system:"EPSG:22171", label: "EPSG:22171 / POSGAR 98 / Argentina 1"},
  {coordinate_system:"EPSG:22172", label: "EPSG:22172 / POSGAR 98 / Argentina 2"},
  {coordinate_system:"EPSG:22173", label: "EPSG:22173 / POSGAR 98 / Argentina 3"},
  {coordinate_system:"EPSG:22174", label: "EPSG:22174 / POSGAR 98 / Argentina 4"},
  {coordinate_system:"EPSG:22175", label: "EPSG:22175 / POSGAR 98 / Argentina 5"},
  {coordinate_system:"EPSG:22176", label: "EPSG:22176 / POSGAR 98 / Argentina 6"},
  {coordinate_system:"EPSG:22177", label: "EPSG:22177 / POSGAR 98 / Argentina 7"},
  {coordinate_system:"EPSG:22181", label: "EPSG:22181 / POSGAR 94 / Argentina 1"},
  {coordinate_system:"EPSG:22182", label: "EPSG:22182 / POSGAR 94 / Argentina 2"},
  {coordinate_system:"EPSG:22183", label: "EPSG:22183 / POSGAR 94 / Argentina 3"},
  {coordinate_system:"EPSG:22184", label: "EPSG:22184 / POSGAR 94 / Argentina 4"},
  {coordinate_system:"EPSG:22185", label: "EPSG:22185 / POSGAR 94 / Argentina 5"},
  {coordinate_system:"EPSG:22186", label: "EPSG:22186 / POSGAR 94 / Argentina 6"},
  {coordinate_system:"EPSG:22187", label: "EPSG:22187 / POSGAR 94 / Argentina 7"},
  {coordinate_system:"EPSG:22191", label: "EPSG:22191 / Campo Inchauspe / Argentina 1"},
  {coordinate_system:"EPSG:22192", label: "EPSG:22192 / Campo Inchauspe / Argentina 2"},
  {coordinate_system:"EPSG:22193", label: "EPSG:22193 / Campo Inchauspe / Argentina 3"},
  {coordinate_system:"EPSG:22194", label: "EPSG:22194 / Campo Inchauspe / Argentina 4"},
  {coordinate_system:"EPSG:22195", label: "EPSG:22195 / Campo Inchauspe / Argentina 5"},
  {coordinate_system:"EPSG:22196", label: "EPSG:22196 / Campo Inchauspe / Argentina 6"},
  {coordinate_system:"EPSG:22197", label: "EPSG:22197 / Campo Inchauspe / Argentina 7"},
  {coordinate_system:"EPSG:22207", label: "EPSG:22207 / NAD83(CSRS)v2 / UTM zone 7N"},
  {coordinate_system:"EPSG:22208", label: "EPSG:22208 / NAD83(CSRS)v2 / UTM zone 8N"},
  {coordinate_system:"EPSG:22209", label: "EPSG:22209 / NAD83(CSRS)v2 / UTM zone 9N"},
  {coordinate_system:"EPSG:22210", label: "EPSG:22210 / NAD83(CSRS)v2 / UTM zone 10N"},
  {coordinate_system:"EPSG:22211", label: "EPSG:22211 / NAD83(CSRS)v2 / UTM zone 11N"},
  {coordinate_system:"EPSG:22212", label: "EPSG:22212 / NAD83(CSRS)v2 / UTM zone 12N"},
  {coordinate_system:"EPSG:22213", label: "EPSG:22213 / NAD83(CSRS)v2 / UTM zone 13N"},
  {coordinate_system:"EPSG:22214", label: "EPSG:22214 / NAD83(CSRS)v2 / UTM zone 14N"},
  {coordinate_system:"EPSG:22215", label: "EPSG:22215 / NAD83(CSRS)v2 / UTM zone 15N"},
  {coordinate_system:"EPSG:22216", label: "EPSG:22216 / NAD83(CSRS)v2 / UTM zone 16N"},
  {coordinate_system:"EPSG:22217", label: "EPSG:22217 / NAD83(CSRS)v2 / UTM zone 17N"},
  {coordinate_system:"EPSG:22218", label: "EPSG:22218 / NAD83(CSRS)v2 / UTM zone 18N"},
  {coordinate_system:"EPSG:22219", label: "EPSG:22219 / NAD83(CSRS)v2 / UTM zone 19N"},
  {coordinate_system:"EPSG:22220", label: "EPSG:22220 / NAD83(CSRS)v2 / UTM zone 20N"},
  {coordinate_system:"EPSG:22221", label: "EPSG:22221 / NAD83(CSRS)v2 / UTM zone 21N"},
  {coordinate_system:"EPSG:22222", label: "EPSG:22222 / NAD83(CSRS)v2 / UTM zone 22N"},
  {coordinate_system:"EPSG:22234", label: "EPSG:22234 / Cape / UTM zone 34S"},
  {coordinate_system:"EPSG:22235", label: "EPSG:22235 / Cape / UTM zone 35S"},
  {coordinate_system:"EPSG:22239", label: "EPSG:22239 / NAD83(CSRS)v2 / PEI Stereographic"},
  {coordinate_system:"EPSG:22240", label: "EPSG:22240 / NAD83(CSRS)v2 / NB Stereographic"},
  {coordinate_system:"EPSG:22243", label: "EPSG:22243 / NAD83(CSRS)v2 / SCoPQ zone 3"},
  {coordinate_system:"EPSG:22244", label: "EPSG:22244 / NAD83(CSRS)v2 / SCoPQ zone 4"},
  {coordinate_system:"EPSG:22245", label: "EPSG:22245 / NAD83(CSRS)v2 / SCoPQ zone 5"},
  {coordinate_system:"EPSG:22246", label: "EPSG:22246 / NAD83(CSRS)v2 / SCoPQ zone 6"},
  {coordinate_system:"EPSG:22247", label: "EPSG:22247 / NAD83(CSRS)v2 / SCoPQ zone 7"},
  {coordinate_system:"EPSG:22248", label: "EPSG:22248 / NAD83(CSRS)v2 / SCoPQ zone 8"},
  {coordinate_system:"EPSG:22249", label: "EPSG:22249 / NAD83(CSRS)v2 / SCoPQ zone 9"},
  {coordinate_system:"EPSG:22250", label: "EPSG:22250 / NAD83(CSRS)v2 / SCoPQ zone 10"},
  {coordinate_system:"EPSG:22262", label: "EPSG:22262 / NAD83(CSRS)v2 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:22263", label: "EPSG:22263 / NAD83(CSRS)v2 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:22264", label: "EPSG:22264 / NAD83(CSRS)v2 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:22265", label: "EPSG:22265 / NAD83(CSRS)v2 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:22275", label: "EPSG:22275 / Cape / Lo15"},
  {coordinate_system:"EPSG:22277", label: "EPSG:22277 / Cape / Lo17"},
  {coordinate_system:"EPSG:22279", label: "EPSG:22279 / Cape / Lo19"},
  {coordinate_system:"EPSG:22281", label: "EPSG:22281 / Cape / Lo21"},
  {coordinate_system:"EPSG:22283", label: "EPSG:22283 / Cape / Lo23"},
  {coordinate_system:"EPSG:22285", label: "EPSG:22285 / Cape / Lo25"},
  {coordinate_system:"EPSG:22287", label: "EPSG:22287 / Cape / Lo27"},
  {coordinate_system:"EPSG:22289", label: "EPSG:22289 / Cape / Lo29"},
  {coordinate_system:"EPSG:22291", label: "EPSG:22291 / Cape / Lo31"},
  {coordinate_system:"EPSG:22293", label: "EPSG:22293 / Cape / Lo33"},
  {coordinate_system:"EPSG:22300", label: "EPSG:22300 / Carthage (Paris) / Tunisia Mining Grid"},
  {coordinate_system:"EPSG:22307", label: "EPSG:22307 / NAD83(CSRS)v3 / UTM zone 7N"},
  {coordinate_system:"EPSG:22308", label: "EPSG:22308 / NAD83(CSRS)v3 / UTM zone 8N"},
  {coordinate_system:"EPSG:22309", label: "EPSG:22309 / NAD83(CSRS)v3 / UTM zone 9N"},
  {coordinate_system:"EPSG:22310", label: "EPSG:22310 / NAD83(CSRS)v3 / UTM zone 10N"},
  {coordinate_system:"EPSG:22311", label: "EPSG:22311 / NAD83(CSRS)v3 / UTM zone 11N"},
  {coordinate_system:"EPSG:22312", label: "EPSG:22312 / NAD83(CSRS)v3 / UTM zone 12N"},
  {coordinate_system:"EPSG:22313", label: "EPSG:22313 / NAD83(CSRS)v3 / UTM zone 13N"},
  {coordinate_system:"EPSG:22314", label: "EPSG:22314 / NAD83(CSRS)v3 / UTM zone 14N"},
  {coordinate_system:"EPSG:22315", label: "EPSG:22315 / NAD83(CSRS)v3 / UTM zone 15N"},
  {coordinate_system:"EPSG:22316", label: "EPSG:22316 / NAD83(CSRS)v3 / UTM zone 16N"},
  {coordinate_system:"EPSG:22317", label: "EPSG:22317 / NAD83(CSRS)v3 / UTM zone 17N"},
  {coordinate_system:"EPSG:22318", label: "EPSG:22318 / NAD83(CSRS)v3 / UTM zone 18N"},
  {coordinate_system:"EPSG:22319", label: "EPSG:22319 / NAD83(CSRS)v3 / UTM zone 19N"},
  {coordinate_system:"EPSG:22320", label: "EPSG:22320 / NAD83(CSRS)v3 / UTM zone 20N"},
  {coordinate_system:"EPSG:22321", label: "EPSG:22321 / NAD83(CSRS)v3 / UTM zone 21N"},
  {coordinate_system:"EPSG:22322", label: "EPSG:22322 / NAD83(CSRS)v3 / UTM zone 22N"},
  {coordinate_system:"EPSG:22332", label: "EPSG:22332 / Carthage / UTM zone 32N"},
  {coordinate_system:"EPSG:22337", label: "EPSG:22337 / NAD83(CSRS)v3 / MTM NS 1997 zone 5"},
  {coordinate_system:"EPSG:22338", label: "EPSG:22338 / NAD83(CSRS)v3 / MTM NS 1997 zone 4"},
  {coordinate_system:"EPSG:22348", label: "EPSG:22348 / NAD83(CSRS)v3 / MTM zone 8"},
  {coordinate_system:"EPSG:22349", label: "EPSG:22349 / NAD83(CSRS)v3 / MTM zone 9"},
  {coordinate_system:"EPSG:22350", label: "EPSG:22350 / NAD83(CSRS)v3 / MTM zone 10"},
  {coordinate_system:"EPSG:22351", label: "EPSG:22351 / NAD83(CSRS)v3 / MTM zone 11"},
  {coordinate_system:"EPSG:22352", label: "EPSG:22352 / NAD83(CSRS)v3 / MTM zone 12"},
  {coordinate_system:"EPSG:22353", label: "EPSG:22353 / NAD83(CSRS)v3 / MTM zone 13"},
  {coordinate_system:"EPSG:22354", label: "EPSG:22354 / NAD83(CSRS)v3 / MTM zone 14"},
  {coordinate_system:"EPSG:22355", label: "EPSG:22355 / NAD83(CSRS)v3 / MTM zone 15"},
  {coordinate_system:"EPSG:22356", label: "EPSG:22356 / NAD83(CSRS)v3 / MTM zone 16"},
  {coordinate_system:"EPSG:22357", label: "EPSG:22357 / NAD83(CSRS)v3 / MTM zone 17"},
  {coordinate_system:"EPSG:22391", label: "EPSG:22391 / Carthage / Nord Tunisie"},
  {coordinate_system:"EPSG:22392", label: "EPSG:22392 / Carthage / Sud Tunisie"},
  {coordinate_system:"EPSG:22407", label: "EPSG:22407 / NAD83(CSRS)v4 / UTM zone 7N"},
  {coordinate_system:"EPSG:22408", label: "EPSG:22408 / NAD83(CSRS)v4 / UTM zone 8N"},
  {coordinate_system:"EPSG:22409", label: "EPSG:22409 / NAD83(CSRS)v4 / UTM zone 9N"},
  {coordinate_system:"EPSG:22410", label: "EPSG:22410 / NAD83(CSRS)v4 / UTM zone 10N"},
  {coordinate_system:"EPSG:22411", label: "EPSG:22411 / NAD83(CSRS)v4 / UTM zone 11N"},
  {coordinate_system:"EPSG:22412", label: "EPSG:22412 / NAD83(CSRS)v4 / UTM zone 12N"},
  {coordinate_system:"EPSG:22413", label: "EPSG:22413 / NAD83(CSRS)v4 / UTM zone 13N"},
  {coordinate_system:"EPSG:22414", label: "EPSG:22414 / NAD83(CSRS)v4 / UTM zone 14N"},
  {coordinate_system:"EPSG:22415", label: "EPSG:22415 / NAD83(CSRS)v4 / UTM zone 15N"},
  {coordinate_system:"EPSG:22416", label: "EPSG:22416 / NAD83(CSRS)v4 / UTM zone 16N"},
  {coordinate_system:"EPSG:22417", label: "EPSG:22417 / NAD83(CSRS)v4 / UTM zone 17N"},
  {coordinate_system:"EPSG:22418", label: "EPSG:22418 / NAD83(CSRS)v4 / UTM zone 18N"},
  {coordinate_system:"EPSG:22419", label: "EPSG:22419 / NAD83(CSRS)v4 / UTM zone 19N"},
  {coordinate_system:"EPSG:22420", label: "EPSG:22420 / NAD83(CSRS)v4 / UTM zone 20N"},
  {coordinate_system:"EPSG:22421", label: "EPSG:22421 / NAD83(CSRS)v4 / UTM zone 21N"},
  {coordinate_system:"EPSG:22422", label: "EPSG:22422 / NAD83(CSRS)v4 / UTM zone 22N"},
  {coordinate_system:"EPSG:22462", label: "EPSG:22462 / NAD83(CSRS)v4 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:22463", label: "EPSG:22463 / NAD83(CSRS)v4 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:22464", label: "EPSG:22464 / NAD83(CSRS)v4 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:22465", label: "EPSG:22465 / NAD83(CSRS)v4 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:22521", label: "EPSG:22521 / Corrego Alegre 1970-72 / UTM zone 21S"},
  {coordinate_system:"EPSG:22522", label: "EPSG:22522 / Corrego Alegre 1970-72 / UTM zone 22S"},
  {coordinate_system:"EPSG:22523", label: "EPSG:22523 / Corrego Alegre 1970-72 / UTM zone 23S"},
  {coordinate_system:"EPSG:22524", label: "EPSG:22524 / Corrego Alegre 1970-72 / UTM zone 24S"},
  {coordinate_system:"EPSG:22525", label: "EPSG:22525 / Corrego Alegre 1970-72 / UTM zone 25S"},
  {coordinate_system:"EPSG:22607", label: "EPSG:22607 / NAD83(CSRS)v6 / UTM zone 7N"},
  {coordinate_system:"EPSG:22608", label: "EPSG:22608 / NAD83(CSRS)v6 / UTM zone 8N"},
  {coordinate_system:"EPSG:22609", label: "EPSG:22609 / NAD83(CSRS)v6 / UTM zone 9N"},
  {coordinate_system:"EPSG:22610", label: "EPSG:22610 / NAD83(CSRS)v6 / UTM zone 10N"},
  {coordinate_system:"EPSG:22611", label: "EPSG:22611 / NAD83(CSRS)v6 / UTM zone 11N"},
  {coordinate_system:"EPSG:22612", label: "EPSG:22612 / NAD83(CSRS)v6 / UTM zone 12N"},
  {coordinate_system:"EPSG:22613", label: "EPSG:22613 / NAD83(CSRS)v6 / UTM zone 13N"},
  {coordinate_system:"EPSG:22614", label: "EPSG:22614 / NAD83(CSRS)v6 / UTM zone 14N"},
  {coordinate_system:"EPSG:22615", label: "EPSG:22615 / NAD83(CSRS)v6 / UTM zone 15N"},
  {coordinate_system:"EPSG:22616", label: "EPSG:22616 / NAD83(CSRS)v6 / UTM zone 16N"},
  {coordinate_system:"EPSG:22617", label: "EPSG:22617 / NAD83(CSRS)v6 / UTM zone 17N"},
  {coordinate_system:"EPSG:22618", label: "EPSG:22618 / NAD83(CSRS)v6 / UTM zone 18N"},
  {coordinate_system:"EPSG:22619", label: "EPSG:22619 / NAD83(CSRS)v6 / UTM zone 19N"},
  {coordinate_system:"EPSG:22620", label: "EPSG:22620 / NAD83(CSRS)v6 / UTM zone 20N"},
  {coordinate_system:"EPSG:22621", label: "EPSG:22621 / NAD83(CSRS)v6 / UTM zone 21N"},
  {coordinate_system:"EPSG:22622", label: "EPSG:22622 / NAD83(CSRS)v6 / UTM zone 22N"},
  {coordinate_system:"EPSG:22639", label: "EPSG:22639 / NAD83(CSRS)v6 / PEI Stereographic"},
  {coordinate_system:"EPSG:22641", label: "EPSG:22641 / NAD83(CSRS)v6 / MTM zone 1"},
  {coordinate_system:"EPSG:22642", label: "EPSG:22642 / NAD83(CSRS)v6 / MTM zone 2"},
  {coordinate_system:"EPSG:22643", label: "EPSG:22643 / NAD83(CSRS)v6 / MTM zone 3"},
  {coordinate_system:"EPSG:22644", label: "EPSG:22644 / NAD83(CSRS)v6 / MTM zone 4"},
  {coordinate_system:"EPSG:22645", label: "EPSG:22645 / NAD83(CSRS)v6 / MTM zone 5"},
  {coordinate_system:"EPSG:22646", label: "EPSG:22646 / NAD83(CSRS)v6 / MTM zone 6"},
  {coordinate_system:"EPSG:22648", label: "EPSG:22648 / NAD83(CSRS)v6 / MTM zone 8"},
  {coordinate_system:"EPSG:22649", label: "EPSG:22649 / NAD83(CSRS)v6 / MTM zone 9"},
  {coordinate_system:"EPSG:22650", label: "EPSG:22650 / NAD83(CSRS)v6 / MTM zone 10"},
  {coordinate_system:"EPSG:22651", label: "EPSG:22651 / NAD83(CSRS)v6 / MTM zone 11"},
  {coordinate_system:"EPSG:22652", label: "EPSG:22652 / NAD83(CSRS)v6 / MTM zone 12"},
  {coordinate_system:"EPSG:22653", label: "EPSG:22653 / NAD83(CSRS)v6 / MTM zone 13"},
  {coordinate_system:"EPSG:22654", label: "EPSG:22654 / NAD83(CSRS)v6 / MTM zone 14"},
  {coordinate_system:"EPSG:22655", label: "EPSG:22655 / NAD83(CSRS)v6 / MTM zone 15"},
  {coordinate_system:"EPSG:22656", label: "EPSG:22656 / NAD83(CSRS)v6 / MTM zone 16"},
  {coordinate_system:"EPSG:22657", label: "EPSG:22657 / NAD83(CSRS)v6 / MTM zone 17"},
  {coordinate_system:"EPSG:22700", label: "EPSG:22700 / Deir ez Zor / Levant Zone"},
  {coordinate_system:"EPSG:22707", label: "EPSG:22707 / NAD83(CSRS)v7 / UTM zone 7N"},
  {coordinate_system:"EPSG:22708", label: "EPSG:22708 / NAD83(CSRS)v7 / UTM zone 8N"},
  {coordinate_system:"EPSG:22709", label: "EPSG:22709 / NAD83(CSRS)v7 / UTM zone 9N"},
  {coordinate_system:"EPSG:22710", label: "EPSG:22710 / NAD83(CSRS)v7 / UTM zone 10N"},
  {coordinate_system:"EPSG:22711", label: "EPSG:22711 / NAD83(CSRS)v7 / UTM zone 11N"},
  {coordinate_system:"EPSG:22712", label: "EPSG:22712 / NAD83(CSRS)v7 / UTM zone 12N"},
  {coordinate_system:"EPSG:22713", label: "EPSG:22713 / NAD83(CSRS)v7 / UTM zone 13N"},
  {coordinate_system:"EPSG:22714", label: "EPSG:22714 / NAD83(CSRS)v7 / UTM zone 14N"},
  {coordinate_system:"EPSG:22715", label: "EPSG:22715 / NAD83(CSRS)v7 / UTM zone 15N"},
  {coordinate_system:"EPSG:22716", label: "EPSG:22716 / NAD83(CSRS)v7 / UTM zone 16N"},
  {coordinate_system:"EPSG:22717", label: "EPSG:22717 / NAD83(CSRS)v7 / UTM zone 17N"},
  {coordinate_system:"EPSG:22718", label: "EPSG:22718 / NAD83(CSRS)v7 / UTM zone 18N"},
  {coordinate_system:"EPSG:22719", label: "EPSG:22719 / NAD83(CSRS)v7 / UTM zone 19N"},
  {coordinate_system:"EPSG:22720", label: "EPSG:22720 / NAD83(CSRS)v7 / UTM zone 20N"},
  {coordinate_system:"EPSG:22721", label: "EPSG:22721 / NAD83(CSRS)v7 / UTM zone 21N"},
  {coordinate_system:"EPSG:22722", label: "EPSG:22722 / NAD83(CSRS)v7 / UTM zone 22N"},
  {coordinate_system:"EPSG:22739", label: "EPSG:22739 / NAD83(CSRS)v7 / PEI Stereographic"},
  {coordinate_system:"EPSG:22762", label: "EPSG:22762 / NAD83(CSRS)v7 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:22763", label: "EPSG:22763 / NAD83(CSRS)v7 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:22764", label: "EPSG:22764 / NAD83(CSRS)v7 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:22765", label: "EPSG:22765 / NAD83(CSRS)v7 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:22770", label: "EPSG:22770 / Deir ez Zor / Syria Lambert"},
  {coordinate_system:"EPSG:22780", label: "EPSG:22780 / Deir ez Zor / Levant Stereographic"},
  {coordinate_system:"EPSG:22991", label: "EPSG:22991 / Egypt 1907 / Blue Belt"},
  {coordinate_system:"EPSG:22992", label: "EPSG:22992 / Egypt 1907 / Red Belt"},
  {coordinate_system:"EPSG:22993", label: "EPSG:22993 / Egypt 1907 / Purple Belt"},
  {coordinate_system:"EPSG:22994", label: "EPSG:22994 / Egypt 1907 / Extended Purple Belt"},
  {coordinate_system:"EPSG:23028", label: "EPSG:23028 / ED50 / UTM zone 28N"},
  {coordinate_system:"EPSG:23029", label: "EPSG:23029 / ED50 / UTM zone 29N"},
  {coordinate_system:"EPSG:23030", label: "EPSG:23030 / ED50 / UTM zone 30N"},
  {coordinate_system:"EPSG:23031", label: "EPSG:23031 / ED50 / UTM zone 31N"},
  {coordinate_system:"EPSG:23032", label: "EPSG:23032 / ED50 / UTM zone 32N"},
  {coordinate_system:"EPSG:23033", label: "EPSG:23033 / ED50 / UTM zone 33N"},
  {coordinate_system:"EPSG:23034", label: "EPSG:23034 / ED50 / UTM zone 34N"},
  {coordinate_system:"EPSG:23035", label: "EPSG:23035 / ED50 / UTM zone 35N"},
  {coordinate_system:"EPSG:23036", label: "EPSG:23036 / ED50 / UTM zone 36N"},
  {coordinate_system:"EPSG:23037", label: "EPSG:23037 / ED50 / UTM zone 37N"},
  {coordinate_system:"EPSG:23038", label: "EPSG:23038 / ED50 / UTM zone 38N"},
  {coordinate_system:"EPSG:23090", label: "EPSG:23090 / ED50 / TM 0 N"},
  {coordinate_system:"EPSG:23095", label: "EPSG:23095 / ED50 / TM 5 NE"},
  {coordinate_system:"EPSG:23239", label: "EPSG:23239 / Fahud / UTM zone 39N"},
  {coordinate_system:"EPSG:23240", label: "EPSG:23240 / Fahud / UTM zone 40N"},
  {coordinate_system:"EPSG:23700", label: "EPSG:23700 / HD72 / EOV"},
  {coordinate_system:"EPSG:23830", label: "EPSG:23830 / DGN95 / Indonesia TM-3 zone 46.2"},
  {coordinate_system:"EPSG:23831", label: "EPSG:23831 / DGN95 / Indonesia TM-3 zone 47.1"},
  {coordinate_system:"EPSG:23832", label: "EPSG:23832 / DGN95 / Indonesia TM-3 zone 47.2"},
  {coordinate_system:"EPSG:23833", label: "EPSG:23833 / DGN95 / Indonesia TM-3 zone 48.1"},
  {coordinate_system:"EPSG:23834", label: "EPSG:23834 / DGN95 / Indonesia TM-3 zone 48.2"},
  {coordinate_system:"EPSG:23835", label: "EPSG:23835 / DGN95 / Indonesia TM-3 zone 49.1"},
  {coordinate_system:"EPSG:23836", label: "EPSG:23836 / DGN95 / Indonesia TM-3 zone 49.2"},
  {coordinate_system:"EPSG:23837", label: "EPSG:23837 / DGN95 / Indonesia TM-3 zone 50.1"},
  {coordinate_system:"EPSG:23838", label: "EPSG:23838 / DGN95 / Indonesia TM-3 zone 50.2"},
  {coordinate_system:"EPSG:23839", label: "EPSG:23839 / DGN95 / Indonesia TM-3 zone 51.1"},
  {coordinate_system:"EPSG:23840", label: "EPSG:23840 / DGN95 / Indonesia TM-3 zone 51.2"},
  {coordinate_system:"EPSG:23841", label: "EPSG:23841 / DGN95 / Indonesia TM-3 zone 52.1"},
  {coordinate_system:"EPSG:23842", label: "EPSG:23842 / DGN95 / Indonesia TM-3 zone 52.2"},
  {coordinate_system:"EPSG:23843", label: "EPSG:23843 / DGN95 / Indonesia TM-3 zone 53.1"},
  {coordinate_system:"EPSG:23844", label: "EPSG:23844 / DGN95 / Indonesia TM-3 zone 53.2"},
  {coordinate_system:"EPSG:23845", label: "EPSG:23845 / DGN95 / Indonesia TM-3 zone 54.1"},
  {coordinate_system:"EPSG:23846", label: "EPSG:23846 / ID74 / UTM zone 46N"},
  {coordinate_system:"EPSG:23847", label: "EPSG:23847 / ID74 / UTM zone 47N"},
  {coordinate_system:"EPSG:23848", label: "EPSG:23848 / ID74 / UTM zone 48N"},
  {coordinate_system:"EPSG:23849", label: "EPSG:23849 / ID74 / UTM zone 49N"},
  {coordinate_system:"EPSG:23850", label: "EPSG:23850 / ID74 / UTM zone 50N"},
  {coordinate_system:"EPSG:23851", label: "EPSG:23851 / ID74 / UTM zone 51N"},
  {coordinate_system:"EPSG:23852", label: "EPSG:23852 / ID74 / UTM zone 52N"},
  {coordinate_system:"EPSG:23866", label: "EPSG:23866 / DGN95 / UTM zone 46N"},
  {coordinate_system:"EPSG:23867", label: "EPSG:23867 / DGN95 / UTM zone 47N"},
  {coordinate_system:"EPSG:23868", label: "EPSG:23868 / DGN95 / UTM zone 48N"},
  {coordinate_system:"EPSG:23869", label: "EPSG:23869 / DGN95 / UTM zone 49N"},
  {coordinate_system:"EPSG:23870", label: "EPSG:23870 / DGN95 / UTM zone 50N"},
  {coordinate_system:"EPSG:23871", label: "EPSG:23871 / DGN95 / UTM zone 51N"},
  {coordinate_system:"EPSG:23872", label: "EPSG:23872 / DGN95 / UTM zone 52N"},
  {coordinate_system:"EPSG:23877", label: "EPSG:23877 / DGN95 / UTM zone 47S"},
  {coordinate_system:"EPSG:23878", label: "EPSG:23878 / DGN95 / UTM zone 48S"},
  {coordinate_system:"EPSG:23879", label: "EPSG:23879 / DGN95 / UTM zone 49S"},
  {coordinate_system:"EPSG:23880", label: "EPSG:23880 / DGN95 / UTM zone 50S"},
  {coordinate_system:"EPSG:23881", label: "EPSG:23881 / DGN95 / UTM zone 51S"},
  {coordinate_system:"EPSG:23882", label: "EPSG:23882 / DGN95 / UTM zone 52S"},
  {coordinate_system:"EPSG:23883", label: "EPSG:23883 / DGN95 / UTM zone 53S"},
  {coordinate_system:"EPSG:23884", label: "EPSG:23884 / DGN95 / UTM zone 54S"},
  {coordinate_system:"EPSG:23887", label: "EPSG:23887 / ID74 / UTM zone 47S"},
  {coordinate_system:"EPSG:23888", label: "EPSG:23888 / ID74 / UTM zone 48S"},
  {coordinate_system:"EPSG:23889", label: "EPSG:23889 / ID74 / UTM zone 49S"},
  {coordinate_system:"EPSG:23890", label: "EPSG:23890 / ID74 / UTM zone 50S"},
  {coordinate_system:"EPSG:23891", label: "EPSG:23891 / ID74 / UTM zone 51S"},
  {coordinate_system:"EPSG:23892", label: "EPSG:23892 / ID74 / UTM zone 52S"},
  {coordinate_system:"EPSG:23893", label: "EPSG:23893 / ID74 / UTM zone 53S"},
  {coordinate_system:"EPSG:23894", label: "EPSG:23894 / ID74 / UTM zone 54S"},
  {coordinate_system:"EPSG:23946", label: "EPSG:23946 / Indian 1954 / UTM zone 46N"},
  {coordinate_system:"EPSG:23947", label: "EPSG:23947 / Indian 1954 / UTM zone 47N"},
  {coordinate_system:"EPSG:23948", label: "EPSG:23948 / Indian 1954 / UTM zone 48N"},
  {coordinate_system:"EPSG:24047", label: "EPSG:24047 / Indian 1975 / UTM zone 47N"},
  {coordinate_system:"EPSG:24048", label: "EPSG:24048 / Indian 1975 / UTM zone 48N"},
  {coordinate_system:"EPSG:24100", label: "EPSG:24100 / Jamaica 1875 / Jamaica (Old Grid)"},
  {coordinate_system:"EPSG:24200", label: "EPSG:24200 / JAD69 / Jamaica National Grid"},
  {coordinate_system:"EPSG:24305", label: "EPSG:24305 / Kalianpur 1937 / UTM zone 45N"},
  {coordinate_system:"EPSG:24306", label: "EPSG:24306 / Kalianpur 1937 / UTM zone 46N"},
  {coordinate_system:"EPSG:24311", label: "EPSG:24311 / Kalianpur 1962 / UTM zone 41N"},
  {coordinate_system:"EPSG:24312", label: "EPSG:24312 / Kalianpur 1962 / UTM zone 42N"},
  {coordinate_system:"EPSG:24313", label: "EPSG:24313 / Kalianpur 1962 / UTM zone 43N"},
  {coordinate_system:"EPSG:24342", label: "EPSG:24342 / Kalianpur 1975 / UTM zone 42N"},
  {coordinate_system:"EPSG:24343", label: "EPSG:24343 / Kalianpur 1975 / UTM zone 43N"},
  {coordinate_system:"EPSG:24344", label: "EPSG:24344 / Kalianpur 1975 / UTM zone 44N"},
  {coordinate_system:"EPSG:24345", label: "EPSG:24345 / Kalianpur 1975 / UTM zone 45N"},
  {coordinate_system:"EPSG:24346", label: "EPSG:24346 / Kalianpur 1975 / UTM zone 46N"},
  {coordinate_system:"EPSG:24347", label: "EPSG:24347 / Kalianpur 1975 / UTM zone 47N"},
  {coordinate_system:"EPSG:24370", label: "EPSG:24370 / Kalianpur 1880 / India zone 0"},
  {coordinate_system:"EPSG:24371", label: "EPSG:24371 / Kalianpur 1880 / India zone I"},
  {coordinate_system:"EPSG:24372", label: "EPSG:24372 / Kalianpur 1880 / India zone IIa"},
  {coordinate_system:"EPSG:24373", label: "EPSG:24373 / Kalianpur 1880 / India zone IIIa"},
  {coordinate_system:"EPSG:24374", label: "EPSG:24374 / Kalianpur 1880 / India zone IVa"},
  {coordinate_system:"EPSG:24375", label: "EPSG:24375 / Kalianpur 1937 / India zone IIb"},
  {coordinate_system:"EPSG:24376", label: "EPSG:24376 / Kalianpur 1962 / India zone I"},
  {coordinate_system:"EPSG:24377", label: "EPSG:24377 / Kalianpur 1962 / India zone IIa"},
  {coordinate_system:"EPSG:24378", label: "EPSG:24378 / Kalianpur 1975 / India zone I"},
  {coordinate_system:"EPSG:24379", label: "EPSG:24379 / Kalianpur 1975 / India zone IIa"},
  {coordinate_system:"EPSG:24380", label: "EPSG:24380 / Kalianpur 1975 / India zone IIb"},
  {coordinate_system:"EPSG:24381", label: "EPSG:24381 / Kalianpur 1975 / India zone IIIa"},
  {coordinate_system:"EPSG:24382", label: "EPSG:24382 / Kalianpur 1880 / India zone IIb"},
  {coordinate_system:"EPSG:24383", label: "EPSG:24383 / Kalianpur 1975 / India zone IVa"},
  {coordinate_system:"EPSG:24500", label: "EPSG:24500 / Kertau 1968 / Singapore Grid"},
  {coordinate_system:"EPSG:24547", label: "EPSG:24547 / Kertau 1968 / UTM zone 47N"},
  {coordinate_system:"EPSG:24548", label: "EPSG:24548 / Kertau 1968 / UTM zone 48N"},
  {coordinate_system:"EPSG:24600", label: "EPSG:24600 / KOC Lambert"},
  {coordinate_system:"EPSG:24718", label: "EPSG:24718 / La Canoa / UTM zone 18N"},
  {coordinate_system:"EPSG:24719", label: "EPSG:24719 / La Canoa / UTM zone 19N"},
  {coordinate_system:"EPSG:24720", label: "EPSG:24720 / La Canoa / UTM zone 20N"},
  {coordinate_system:"EPSG:24817", label: "EPSG:24817 / PSAD56 / UTM zone 17N"},
  {coordinate_system:"EPSG:24818", label: "EPSG:24818 / PSAD56 / UTM zone 18N"},
  {coordinate_system:"EPSG:24819", label: "EPSG:24819 / PSAD56 / UTM zone 19N"},
  {coordinate_system:"EPSG:24820", label: "EPSG:24820 / PSAD56 / UTM zone 20N"},
  {coordinate_system:"EPSG:24821", label: "EPSG:24821 / PSAD56 / UTM zone 21N"},
  {coordinate_system:"EPSG:24877", label: "EPSG:24877 / PSAD56 / UTM zone 17S"},
  {coordinate_system:"EPSG:24878", label: "EPSG:24878 / PSAD56 / UTM zone 18S"},
  {coordinate_system:"EPSG:24879", label: "EPSG:24879 / PSAD56 / UTM zone 19S"},
  {coordinate_system:"EPSG:24880", label: "EPSG:24880 / PSAD56 / UTM zone 20S"},
  {coordinate_system:"EPSG:24881", label: "EPSG:24881 / PSAD56 / UTM zone 21S"},
  {coordinate_system:"EPSG:24882", label: "EPSG:24882 / PSAD56 / UTM zone 22S"},
  {coordinate_system:"EPSG:24891", label: "EPSG:24891 / PSAD56 / Peru west zone"},
  {coordinate_system:"EPSG:24892", label: "EPSG:24892 / PSAD56 / Peru central zone"},
  {coordinate_system:"EPSG:24893", label: "EPSG:24893 / PSAD56 / Peru east zone"},
  {coordinate_system:"EPSG:25000", label: "EPSG:25000 / Leigon / Ghana Metre Grid"},
  {coordinate_system:"EPSG:25231", label: "EPSG:25231 / Lome / UTM zone 31N"},
  {coordinate_system:"EPSG:25391", label: "EPSG:25391 / Luzon 1911 / Philippines zone I"},
  {coordinate_system:"EPSG:25392", label: "EPSG:25392 / Luzon 1911 / Philippines zone II"},
  {coordinate_system:"EPSG:25393", label: "EPSG:25393 / Luzon 1911 / Philippines zone III"},
  {coordinate_system:"EPSG:25394", label: "EPSG:25394 / Luzon 1911 / Philippines zone IV"},
  {coordinate_system:"EPSG:25395", label: "EPSG:25395 / Luzon 1911 / Philippines zone V"},
  {coordinate_system:"EPSG:25828", label: "EPSG:25828 / ETRS89 / UTM zone 28N"},
  {coordinate_system:"EPSG:25829", label: "EPSG:25829 / ETRS89 / UTM zone 29N"},
  {coordinate_system:"EPSG:25830", label: "EPSG:25830 / ETRS89 / UTM zone 30N"},
  {coordinate_system:"EPSG:25831", label: "EPSG:25831 / ETRS89 / UTM zone 31N"},
  {coordinate_system:"EPSG:25832", label: "EPSG:25832 / ETRS89 / UTM zone 32N"},
  {coordinate_system:"EPSG:25833", label: "EPSG:25833 / ETRS89 / UTM zone 33N"},
  {coordinate_system:"EPSG:25834", label: "EPSG:25834 / ETRS89 / UTM zone 34N"},
  {coordinate_system:"EPSG:25835", label: "EPSG:25835 / ETRS89 / UTM zone 35N"},
  {coordinate_system:"EPSG:25836", label: "EPSG:25836 / ETRS89 / UTM zone 36N"},
  {coordinate_system:"EPSG:25837", label: "EPSG:25837 / ETRS89 / UTM zone 37N"},
  {coordinate_system:"EPSG:25884", label: "EPSG:25884 / ETRS89 / TM Baltic93"},
  {coordinate_system:"EPSG:25932", label: "EPSG:25932 / Malongo 1987 / UTM zone 32S"},
  {coordinate_system:"EPSG:26191", label: "EPSG:26191 / Merchich / Nord Maroc"},
  {coordinate_system:"EPSG:26192", label: "EPSG:26192 / Merchich / Sud Maroc"},
  {coordinate_system:"EPSG:26194", label: "EPSG:26194 / Merchich / Sahara Nord"},
  {coordinate_system:"EPSG:26195", label: "EPSG:26195 / Merchich / Sahara Sud"},
  {coordinate_system:"EPSG:26237", label: "EPSG:26237 / Massawa / UTM zone 37N"},
  {coordinate_system:"EPSG:26331", label: "EPSG:26331 / Minna / UTM zone 31N"},
  {coordinate_system:"EPSG:26332", label: "EPSG:26332 / Minna / UTM zone 32N"},
  {coordinate_system:"EPSG:26391", label: "EPSG:26391 / Minna / Nigeria West Belt"},
  {coordinate_system:"EPSG:26392", label: "EPSG:26392 / Minna / Nigeria Mid Belt"},
  {coordinate_system:"EPSG:26393", label: "EPSG:26393 / Minna / Nigeria East Belt"},
  {coordinate_system:"EPSG:26632", label: "EPSG:26632 / M'poraloko / UTM zone 32N"},
  {coordinate_system:"EPSG:26692", label: "EPSG:26692 / M'poraloko / UTM zone 32S"},
  {coordinate_system:"EPSG:26701", label: "EPSG:26701 / NAD27 / UTM zone 1N"},
  {coordinate_system:"EPSG:26702", label: "EPSG:26702 / NAD27 / UTM zone 2N"},
  {coordinate_system:"EPSG:26703", label: "EPSG:26703 / NAD27 / UTM zone 3N"},
  {coordinate_system:"EPSG:26704", label: "EPSG:26704 / NAD27 / UTM zone 4N"},
  {coordinate_system:"EPSG:26705", label: "EPSG:26705 / NAD27 / UTM zone 5N"},
  {coordinate_system:"EPSG:26706", label: "EPSG:26706 / NAD27 / UTM zone 6N"},
  {coordinate_system:"EPSG:26707", label: "EPSG:26707 / NAD27 / UTM zone 7N"},
  {coordinate_system:"EPSG:26708", label: "EPSG:26708 / NAD27 / UTM zone 8N"},
  {coordinate_system:"EPSG:26709", label: "EPSG:26709 / NAD27 / UTM zone 9N"},
  {coordinate_system:"EPSG:26710", label: "EPSG:26710 / NAD27 / UTM zone 10N"},
  {coordinate_system:"EPSG:26711", label: "EPSG:26711 / NAD27 / UTM zone 11N"},
  {coordinate_system:"EPSG:26712", label: "EPSG:26712 / NAD27 / UTM zone 12N"},
  {coordinate_system:"EPSG:26713", label: "EPSG:26713 / NAD27 / UTM zone 13N"},
  {coordinate_system:"EPSG:26714", label: "EPSG:26714 / NAD27 / UTM zone 14N"},
  {coordinate_system:"EPSG:26715", label: "EPSG:26715 / NAD27 / UTM zone 15N"},
  {coordinate_system:"EPSG:26716", label: "EPSG:26716 / NAD27 / UTM zone 16N"},
  {coordinate_system:"EPSG:26717", label: "EPSG:26717 / NAD27 / UTM zone 17N"},
  {coordinate_system:"EPSG:26718", label: "EPSG:26718 / NAD27 / UTM zone 18N"},
  {coordinate_system:"EPSG:26719", label: "EPSG:26719 / NAD27 / UTM zone 19N"},
  {coordinate_system:"EPSG:26720", label: "EPSG:26720 / NAD27 / UTM zone 20N"},
  {coordinate_system:"EPSG:26721", label: "EPSG:26721 / NAD27 / UTM zone 21N"},
  {coordinate_system:"EPSG:26722", label: "EPSG:26722 / NAD27 / UTM zone 22N"},
  {coordinate_system:"EPSG:26729", label: "EPSG:26729 / NAD27 / Alabama East"},
  {coordinate_system:"EPSG:26730", label: "EPSG:26730 / NAD27 / Alabama West"},
  {coordinate_system:"EPSG:26731", label: "EPSG:26731 / NAD27 / Alaska zone 1"},
  {coordinate_system:"EPSG:26732", label: "EPSG:26732 / NAD27 / Alaska zone 2"},
  {coordinate_system:"EPSG:26733", label: "EPSG:26733 / NAD27 / Alaska zone 3"},
  {coordinate_system:"EPSG:26734", label: "EPSG:26734 / NAD27 / Alaska zone 4"},
  {coordinate_system:"EPSG:26735", label: "EPSG:26735 / NAD27 / Alaska zone 5"},
  {coordinate_system:"EPSG:26736", label: "EPSG:26736 / NAD27 / Alaska zone 6"},
  {coordinate_system:"EPSG:26737", label: "EPSG:26737 / NAD27 / Alaska zone 7"},
  {coordinate_system:"EPSG:26738", label: "EPSG:26738 / NAD27 / Alaska zone 8"},
  {coordinate_system:"EPSG:26739", label: "EPSG:26739 / NAD27 / Alaska zone 9"},
  {coordinate_system:"EPSG:26740", label: "EPSG:26740 / NAD27 / Alaska zone 10"},
  {coordinate_system:"EPSG:26741", label: "EPSG:26741 / NAD27 / California zone I"},
  {coordinate_system:"EPSG:26742", label: "EPSG:26742 / NAD27 / California zone II"},
  {coordinate_system:"EPSG:26743", label: "EPSG:26743 / NAD27 / California zone III"},
  {coordinate_system:"EPSG:26744", label: "EPSG:26744 / NAD27 / California zone IV"},
  {coordinate_system:"EPSG:26745", label: "EPSG:26745 / NAD27 / California zone V"},
  {coordinate_system:"EPSG:26746", label: "EPSG:26746 / NAD27 / California zone VI"},
  {coordinate_system:"EPSG:26748", label: "EPSG:26748 / NAD27 / Arizona East"},
  {coordinate_system:"EPSG:26749", label: "EPSG:26749 / NAD27 / Arizona Central"},
  {coordinate_system:"EPSG:26750", label: "EPSG:26750 / NAD27 / Arizona West"},
  {coordinate_system:"EPSG:26751", label: "EPSG:26751 / NAD27 / Arkansas North"},
  {coordinate_system:"EPSG:26752", label: "EPSG:26752 / NAD27 / Arkansas South"},
  {coordinate_system:"EPSG:26753", label: "EPSG:26753 / NAD27 / Colorado North"},
  {coordinate_system:"EPSG:26754", label: "EPSG:26754 / NAD27 / Colorado Central"},
  {coordinate_system:"EPSG:26755", label: "EPSG:26755 / NAD27 / Colorado South"},
  {coordinate_system:"EPSG:26756", label: "EPSG:26756 / NAD27 / Connecticut"},
  {coordinate_system:"EPSG:26757", label: "EPSG:26757 / NAD27 / Delaware"},
  {coordinate_system:"EPSG:26758", label: "EPSG:26758 / NAD27 / Florida East"},
  {coordinate_system:"EPSG:26759", label: "EPSG:26759 / NAD27 / Florida West"},
  {coordinate_system:"EPSG:26760", label: "EPSG:26760 / NAD27 / Florida North"},
  {coordinate_system:"EPSG:26766", label: "EPSG:26766 / NAD27 / Georgia East"},
  {coordinate_system:"EPSG:26767", label: "EPSG:26767 / NAD27 / Georgia West"},
  {coordinate_system:"EPSG:26768", label: "EPSG:26768 / NAD27 / Idaho East"},
  {coordinate_system:"EPSG:26769", label: "EPSG:26769 / NAD27 / Idaho Central"},
  {coordinate_system:"EPSG:26770", label: "EPSG:26770 / NAD27 / Idaho West"},
  {coordinate_system:"EPSG:26771", label: "EPSG:26771 / NAD27 / Illinois East"},
  {coordinate_system:"EPSG:26772", label: "EPSG:26772 / NAD27 / Illinois West"},
  {coordinate_system:"EPSG:26773", label: "EPSG:26773 / NAD27 / Indiana East"},
  {coordinate_system:"EPSG:26774", label: "EPSG:26774 / NAD27 / Indiana West"},
  {coordinate_system:"EPSG:26775", label: "EPSG:26775 / NAD27 / Iowa North"},
  {coordinate_system:"EPSG:26776", label: "EPSG:26776 / NAD27 / Iowa South"},
  {coordinate_system:"EPSG:26777", label: "EPSG:26777 / NAD27 / Kansas North"},
  {coordinate_system:"EPSG:26778", label: "EPSG:26778 / NAD27 / Kansas South"},
  {coordinate_system:"EPSG:26779", label: "EPSG:26779 / NAD27 / Kentucky North"},
  {coordinate_system:"EPSG:26780", label: "EPSG:26780 / NAD27 / Kentucky South"},
  {coordinate_system:"EPSG:26781", label: "EPSG:26781 / NAD27 / Louisiana North"},
  {coordinate_system:"EPSG:26782", label: "EPSG:26782 / NAD27 / Louisiana South"},
  {coordinate_system:"EPSG:26783", label: "EPSG:26783 / NAD27 / Maine East"},
  {coordinate_system:"EPSG:26784", label: "EPSG:26784 / NAD27 / Maine West"},
  {coordinate_system:"EPSG:26785", label: "EPSG:26785 / NAD27 / Maryland"},
  {coordinate_system:"EPSG:26786", label: "EPSG:26786 / NAD27 / Massachusetts Mainland"},
  {coordinate_system:"EPSG:26787", label: "EPSG:26787 / NAD27 / Massachusetts Island"},
  {coordinate_system:"EPSG:26791", label: "EPSG:26791 / NAD27 / Minnesota North"},
  {coordinate_system:"EPSG:26792", label: "EPSG:26792 / NAD27 / Minnesota Central"},
  {coordinate_system:"EPSG:26793", label: "EPSG:26793 / NAD27 / Minnesota South"},
  {coordinate_system:"EPSG:26794", label: "EPSG:26794 / NAD27 / Mississippi East"},
  {coordinate_system:"EPSG:26795", label: "EPSG:26795 / NAD27 / Mississippi West"},
  {coordinate_system:"EPSG:26796", label: "EPSG:26796 / NAD27 / Missouri East"},
  {coordinate_system:"EPSG:26797", label: "EPSG:26797 / NAD27 / Missouri Central"},
  {coordinate_system:"EPSG:26798", label: "EPSG:26798 / NAD27 / Missouri West"},
  {coordinate_system:"EPSG:26799", label: "EPSG:26799 / NAD27 / California zone VII"},
  {coordinate_system:"EPSG:26847", label: "EPSG:26847 / NAD83 / Maine East (ftUS)"},
  {coordinate_system:"EPSG:26848", label: "EPSG:26848 / NAD83 / Maine West (ftUS)"},
  {coordinate_system:"EPSG:26849", label: "EPSG:26849 / NAD83 / Minnesota North (ftUS)"},
  {coordinate_system:"EPSG:26850", label: "EPSG:26850 / NAD83 / Minnesota Central (ftUS)"},
  {coordinate_system:"EPSG:26851", label: "EPSG:26851 / NAD83 / Minnesota South (ftUS)"},
  {coordinate_system:"EPSG:26852", label: "EPSG:26852 / NAD83 / Nebraska (ftUS)"},
  {coordinate_system:"EPSG:26853", label: "EPSG:26853 / NAD83 / West Virginia North (ftUS)"},
  {coordinate_system:"EPSG:26854", label: "EPSG:26854 / NAD83 / West Virginia South (ftUS)"},
  {coordinate_system:"EPSG:26855", label: "EPSG:26855 / NAD83(HARN) / Maine East (ftUS)"},
  {coordinate_system:"EPSG:26856", label: "EPSG:26856 / NAD83(HARN) / Maine West (ftUS)"},
  {coordinate_system:"EPSG:26857", label: "EPSG:26857 / NAD83(HARN) / Minnesota North (ftUS)"},
  {coordinate_system:"EPSG:26858", label: "EPSG:26858 / NAD83(HARN) / Minnesota Central (ftUS)"},
  {coordinate_system:"EPSG:26859", label: "EPSG:26859 / NAD83(HARN) / Minnesota South (ftUS)"},
  {coordinate_system:"EPSG:26860", label: "EPSG:26860 / NAD83(HARN) / Nebraska (ftUS)"},
  {coordinate_system:"EPSG:26861", label: "EPSG:26861 / NAD83(HARN) / West Virginia North (ftUS)"},
  {coordinate_system:"EPSG:26862", label: "EPSG:26862 / NAD83(HARN) / West Virginia South (ftUS)"},
  {coordinate_system:"EPSG:26863", label: "EPSG:26863 / NAD83(NSRS2007) / Maine East (ftUS)"},
  {coordinate_system:"EPSG:26864", label: "EPSG:26864 / NAD83(NSRS2007) / Maine West (ftUS)"},
  {coordinate_system:"EPSG:26865", label: "EPSG:26865 / NAD83(NSRS2007) / Minnesota North (ftUS)"},
  {coordinate_system:"EPSG:26866", label: "EPSG:26866 / NAD83(NSRS2007) / Minnesota Central (ftUS)"},
  {coordinate_system:"EPSG:26867", label: "EPSG:26867 / NAD83(NSRS2007) / Minnesota South (ftUS)"},
  {coordinate_system:"EPSG:26868", label: "EPSG:26868 / NAD83(NSRS2007) / Nebraska (ftUS)"},
  {coordinate_system:"EPSG:26869", label: "EPSG:26869 / NAD83(NSRS2007) / West Virginia North (ftUS)"},
  {coordinate_system:"EPSG:26870", label: "EPSG:26870 / NAD83(NSRS2007) / West Virginia South (ftUS)"},
  {coordinate_system:"EPSG:26891", label: "EPSG:26891 / NAD83(CSRS) / MTM zone 11"},
  {coordinate_system:"EPSG:26892", label: "EPSG:26892 / NAD83(CSRS) / MTM zone 12"},
  {coordinate_system:"EPSG:26893", label: "EPSG:26893 / NAD83(CSRS) / MTM zone 13"},
  {coordinate_system:"EPSG:26894", label: "EPSG:26894 / NAD83(CSRS) / MTM zone 14"},
  {coordinate_system:"EPSG:26895", label: "EPSG:26895 / NAD83(CSRS) / MTM zone 15"},
  {coordinate_system:"EPSG:26896", label: "EPSG:26896 / NAD83(CSRS) / MTM zone 16"},
  {coordinate_system:"EPSG:26897", label: "EPSG:26897 / NAD83(CSRS) / MTM zone 17"},
  {coordinate_system:"EPSG:26898", label: "EPSG:26898 / NAD83(CSRS) / MTM zone 1"},
  {coordinate_system:"EPSG:26899", label: "EPSG:26899 / NAD83(CSRS) / MTM zone 2"},
  {coordinate_system:"EPSG:26901", label: "EPSG:26901 / NAD83 / UTM zone 1N"},
  {coordinate_system:"EPSG:26902", label: "EPSG:26902 / NAD83 / UTM zone 2N"},
  {coordinate_system:"EPSG:26903", label: "EPSG:26903 / NAD83 / UTM zone 3N"},
  {coordinate_system:"EPSG:26904", label: "EPSG:26904 / NAD83 / UTM zone 4N"},
  {coordinate_system:"EPSG:26905", label: "EPSG:26905 / NAD83 / UTM zone 5N"},
  {coordinate_system:"EPSG:26906", label: "EPSG:26906 / NAD83 / UTM zone 6N"},
  {coordinate_system:"EPSG:26907", label: "EPSG:26907 / NAD83 / UTM zone 7N"},
  {coordinate_system:"EPSG:26908", label: "EPSG:26908 / NAD83 / UTM zone 8N"},
  {coordinate_system:"EPSG:26909", label: "EPSG:26909 / NAD83 / UTM zone 9N"},
  {coordinate_system:"EPSG:26910", label: "EPSG:26910 / NAD83 / UTM zone 10N"},
  {coordinate_system:"EPSG:26911", label: "EPSG:26911 / NAD83 / UTM zone 11N"},
  {coordinate_system:"EPSG:26912", label: "EPSG:26912 / NAD83 / UTM zone 12N"},
  {coordinate_system:"EPSG:26913", label: "EPSG:26913 / NAD83 / UTM zone 13N"},
  {coordinate_system:"EPSG:26914", label: "EPSG:26914 / NAD83 / UTM zone 14N"},
  {coordinate_system:"EPSG:26915", label: "EPSG:26915 / NAD83 / UTM zone 15N"},
  {coordinate_system:"EPSG:26916", label: "EPSG:26916 / NAD83 / UTM zone 16N"},
  {coordinate_system:"EPSG:26917", label: "EPSG:26917 / NAD83 / UTM zone 17N"},
  {coordinate_system:"EPSG:26918", label: "EPSG:26918 / NAD83 / UTM zone 18N"},
  {coordinate_system:"EPSG:26919", label: "EPSG:26919 / NAD83 / UTM zone 19N"},
  {coordinate_system:"EPSG:26920", label: "EPSG:26920 / NAD83 / UTM zone 20N"},
  {coordinate_system:"EPSG:26921", label: "EPSG:26921 / NAD83 / UTM zone 21N"},
  {coordinate_system:"EPSG:26922", label: "EPSG:26922 / NAD83 / UTM zone 22N"},
  {coordinate_system:"EPSG:26923", label: "EPSG:26923 / NAD83 / UTM zone 23N"},
  {coordinate_system:"EPSG:26929", label: "EPSG:26929 / NAD83 / Alabama East"},
  {coordinate_system:"EPSG:26930", label: "EPSG:26930 / NAD83 / Alabama West"},
  {coordinate_system:"EPSG:26931", label: "EPSG:26931 / NAD83 / Alaska zone 1"},
  {coordinate_system:"EPSG:26932", label: "EPSG:26932 / NAD83 / Alaska zone 2"},
  {coordinate_system:"EPSG:26933", label: "EPSG:26933 / NAD83 / Alaska zone 3"},
  {coordinate_system:"EPSG:26934", label: "EPSG:26934 / NAD83 / Alaska zone 4"},
  {coordinate_system:"EPSG:26935", label: "EPSG:26935 / NAD83 / Alaska zone 5"},
  {coordinate_system:"EPSG:26936", label: "EPSG:26936 / NAD83 / Alaska zone 6"},
  {coordinate_system:"EPSG:26937", label: "EPSG:26937 / NAD83 / Alaska zone 7"},
  {coordinate_system:"EPSG:26938", label: "EPSG:26938 / NAD83 / Alaska zone 8"},
  {coordinate_system:"EPSG:26939", label: "EPSG:26939 / NAD83 / Alaska zone 9"},
  {coordinate_system:"EPSG:26940", label: "EPSG:26940 / NAD83 / Alaska zone 10"},
  {coordinate_system:"EPSG:26941", label: "EPSG:26941 / NAD83 / California zone 1"},
  {coordinate_system:"EPSG:26942", label: "EPSG:26942 / NAD83 / California zone 2"},
  {coordinate_system:"EPSG:26943", label: "EPSG:26943 / NAD83 / California zone 3"},
  {coordinate_system:"EPSG:26944", label: "EPSG:26944 / NAD83 / California zone 4"},
  {coordinate_system:"EPSG:26945", label: "EPSG:26945 / NAD83 / California zone 5"},
  {coordinate_system:"EPSG:26946", label: "EPSG:26946 / NAD83 / California zone 6"},
  {coordinate_system:"EPSG:26948", label: "EPSG:26948 / NAD83 / Arizona East"},
  {coordinate_system:"EPSG:26949", label: "EPSG:26949 / NAD83 / Arizona Central"},
  {coordinate_system:"EPSG:26950", label: "EPSG:26950 / NAD83 / Arizona West"},
  {coordinate_system:"EPSG:26951", label: "EPSG:26951 / NAD83 / Arkansas North"},
  {coordinate_system:"EPSG:26952", label: "EPSG:26952 / NAD83 / Arkansas South"},
  {coordinate_system:"EPSG:26953", label: "EPSG:26953 / NAD83 / Colorado North"},
  {coordinate_system:"EPSG:26954", label: "EPSG:26954 / NAD83 / Colorado Central"},
  {coordinate_system:"EPSG:26955", label: "EPSG:26955 / NAD83 / Colorado South"},
  {coordinate_system:"EPSG:26956", label: "EPSG:26956 / NAD83 / Connecticut"},
  {coordinate_system:"EPSG:26957", label: "EPSG:26957 / NAD83 / Delaware"},
  {coordinate_system:"EPSG:26958", label: "EPSG:26958 / NAD83 / Florida East"},
  {coordinate_system:"EPSG:26959", label: "EPSG:26959 / NAD83 / Florida West"},
  {coordinate_system:"EPSG:26960", label: "EPSG:26960 / NAD83 / Florida North"},
  {coordinate_system:"EPSG:26961", label: "EPSG:26961 / NAD83 / Hawaii zone 1"},
  {coordinate_system:"EPSG:26962", label: "EPSG:26962 / NAD83 / Hawaii zone 2"},
  {coordinate_system:"EPSG:26963", label: "EPSG:26963 / NAD83 / Hawaii zone 3"},
  {coordinate_system:"EPSG:26964", label: "EPSG:26964 / NAD83 / Hawaii zone 4"},
  {coordinate_system:"EPSG:26965", label: "EPSG:26965 / NAD83 / Hawaii zone 5"},
  {coordinate_system:"EPSG:26966", label: "EPSG:26966 / NAD83 / Georgia East"},
  {coordinate_system:"EPSG:26967", label: "EPSG:26967 / NAD83 / Georgia West"},
  {coordinate_system:"EPSG:26968", label: "EPSG:26968 / NAD83 / Idaho East"},
  {coordinate_system:"EPSG:26969", label: "EPSG:26969 / NAD83 / Idaho Central"},
  {coordinate_system:"EPSG:26970", label: "EPSG:26970 / NAD83 / Idaho West"},
  {coordinate_system:"EPSG:26971", label: "EPSG:26971 / NAD83 / Illinois East"},
  {coordinate_system:"EPSG:26972", label: "EPSG:26972 / NAD83 / Illinois West"},
  {coordinate_system:"EPSG:26973", label: "EPSG:26973 / NAD83 / Indiana East"},
  {coordinate_system:"EPSG:26974", label: "EPSG:26974 / NAD83 / Indiana West"},
  {coordinate_system:"EPSG:26975", label: "EPSG:26975 / NAD83 / Iowa North"},
  {coordinate_system:"EPSG:26976", label: "EPSG:26976 / NAD83 / Iowa South"},
  {coordinate_system:"EPSG:26977", label: "EPSG:26977 / NAD83 / Kansas North"},
  {coordinate_system:"EPSG:26978", label: "EPSG:26978 / NAD83 / Kansas South"},
  {coordinate_system:"EPSG:26980", label: "EPSG:26980 / NAD83 / Kentucky South"},
  {coordinate_system:"EPSG:26981", label: "EPSG:26981 / NAD83 / Louisiana North"},
  {coordinate_system:"EPSG:26982", label: "EPSG:26982 / NAD83 / Louisiana South"},
  {coordinate_system:"EPSG:26983", label: "EPSG:26983 / NAD83 / Maine East"},
  {coordinate_system:"EPSG:26984", label: "EPSG:26984 / NAD83 / Maine West"},
  {coordinate_system:"EPSG:26985", label: "EPSG:26985 / NAD83 / Maryland"},
  {coordinate_system:"EPSG:26986", label: "EPSG:26986 / NAD83 / Massachusetts Mainland"},
  {coordinate_system:"EPSG:26987", label: "EPSG:26987 / NAD83 / Massachusetts Island"},
  {coordinate_system:"EPSG:26988", label: "EPSG:26988 / NAD83 / Michigan North"},
  {coordinate_system:"EPSG:26989", label: "EPSG:26989 / NAD83 / Michigan Central"},
  {coordinate_system:"EPSG:26990", label: "EPSG:26990 / NAD83 / Michigan South"},
  {coordinate_system:"EPSG:26991", label: "EPSG:26991 / NAD83 / Minnesota North"},
  {coordinate_system:"EPSG:26992", label: "EPSG:26992 / NAD83 / Minnesota Central"},
  {coordinate_system:"EPSG:26993", label: "EPSG:26993 / NAD83 / Minnesota South"},
  {coordinate_system:"EPSG:26994", label: "EPSG:26994 / NAD83 / Mississippi East"},
  {coordinate_system:"EPSG:26995", label: "EPSG:26995 / NAD83 / Mississippi West"},
  {coordinate_system:"EPSG:26996", label: "EPSG:26996 / NAD83 / Missouri East"},
  {coordinate_system:"EPSG:26997", label: "EPSG:26997 / NAD83 / Missouri Central"},
  {coordinate_system:"EPSG:26998", label: "EPSG:26998 / NAD83 / Missouri West"},
  {coordinate_system:"EPSG:27039", label: "EPSG:27039 / Nahrwan 1967 / UTM zone 39N"},
  {coordinate_system:"EPSG:27040", label: "EPSG:27040 / Nahrwan 1967 / UTM zone 40N"},
  {coordinate_system:"EPSG:27120", label: "EPSG:27120 / Naparima 1972 / UTM zone 20N"},
  {coordinate_system:"EPSG:27200", label: "EPSG:27200 / NZGD49 / New Zealand Map Grid"},
  {coordinate_system:"EPSG:27205", label: "EPSG:27205 / NZGD49 / Mount Eden Circuit"},
  {coordinate_system:"EPSG:27206", label: "EPSG:27206 / NZGD49 / Bay of Plenty Circuit"},
  {coordinate_system:"EPSG:27207", label: "EPSG:27207 / NZGD49 / Poverty Bay Circuit"},
  {coordinate_system:"EPSG:27208", label: "EPSG:27208 / NZGD49 / Hawkes Bay Circuit"},
  {coordinate_system:"EPSG:27209", label: "EPSG:27209 / NZGD49 / Taranaki Circuit"},
  {coordinate_system:"EPSG:27210", label: "EPSG:27210 / NZGD49 / Tuhirangi Circuit"},
  {coordinate_system:"EPSG:27211", label: "EPSG:27211 / NZGD49 / Wanganui Circuit"},
  {coordinate_system:"EPSG:27212", label: "EPSG:27212 / NZGD49 / Wairarapa Circuit"},
  {coordinate_system:"EPSG:27213", label: "EPSG:27213 / NZGD49 / Wellington Circuit"},
  {coordinate_system:"EPSG:27214", label: "EPSG:27214 / NZGD49 / Collingwood Circuit"},
  {coordinate_system:"EPSG:27215", label: "EPSG:27215 / NZGD49 / Nelson Circuit"},
  {coordinate_system:"EPSG:27216", label: "EPSG:27216 / NZGD49 / Karamea Circuit"},
  {coordinate_system:"EPSG:27217", label: "EPSG:27217 / NZGD49 / Buller Circuit"},
  {coordinate_system:"EPSG:27218", label: "EPSG:27218 / NZGD49 / Grey Circuit"},
  {coordinate_system:"EPSG:27219", label: "EPSG:27219 / NZGD49 / Amuri Circuit"},
  {coordinate_system:"EPSG:27220", label: "EPSG:27220 / NZGD49 / Marlborough Circuit"},
  {coordinate_system:"EPSG:27221", label: "EPSG:27221 / NZGD49 / Hokitika Circuit"},
  {coordinate_system:"EPSG:27222", label: "EPSG:27222 / NZGD49 / Okarito Circuit"},
  {coordinate_system:"EPSG:27223", label: "EPSG:27223 / NZGD49 / Jacksons Bay Circuit"},
  {coordinate_system:"EPSG:27224", label: "EPSG:27224 / NZGD49 / Mount Pleasant Circuit"},
  {coordinate_system:"EPSG:27225", label: "EPSG:27225 / NZGD49 / Gawler Circuit"},
  {coordinate_system:"EPSG:27226", label: "EPSG:27226 / NZGD49 / Timaru Circuit"},
  {coordinate_system:"EPSG:27227", label: "EPSG:27227 / NZGD49 / Lindis Peak Circuit"},
  {coordinate_system:"EPSG:27228", label: "EPSG:27228 / NZGD49 / Mount Nicholas Circuit"},
  {coordinate_system:"EPSG:27229", label: "EPSG:27229 / NZGD49 / Mount York Circuit"},
  {coordinate_system:"EPSG:27230", label: "EPSG:27230 / NZGD49 / Observation Point Circuit"},
  {coordinate_system:"EPSG:27231", label: "EPSG:27231 / NZGD49 / North Taieri Circuit"},
  {coordinate_system:"EPSG:27232", label: "EPSG:27232 / NZGD49 / Bluff Circuit"},
  {coordinate_system:"EPSG:27258", label: "EPSG:27258 / NZGD49 / UTM zone 58S"},
  {coordinate_system:"EPSG:27259", label: "EPSG:27259 / NZGD49 / UTM zone 59S"},
  {coordinate_system:"EPSG:27260", label: "EPSG:27260 / NZGD49 / UTM zone 60S"},
  {coordinate_system:"EPSG:27291", label: "EPSG:27291 / NZGD49 / North Island Grid"},
  {coordinate_system:"EPSG:27292", label: "EPSG:27292 / NZGD49 / South Island Grid"},
  {coordinate_system:"EPSG:27391", label: "EPSG:27391 / NGO 1948 (Oslo) / NGO zone I"},
  {coordinate_system:"EPSG:27392", label: "EPSG:27392 / NGO 1948 (Oslo) / NGO zone II"},
  {coordinate_system:"EPSG:27393", label: "EPSG:27393 / NGO 1948 (Oslo) / NGO zone III"},
  {coordinate_system:"EPSG:27394", label: "EPSG:27394 / NGO 1948 (Oslo) / NGO zone IV"},
  {coordinate_system:"EPSG:27395", label: "EPSG:27395 / NGO 1948 (Oslo) / NGO zone V"},
  {coordinate_system:"EPSG:27396", label: "EPSG:27396 / NGO 1948 (Oslo) / NGO zone VI"},
  {coordinate_system:"EPSG:27397", label: "EPSG:27397 / NGO 1948 (Oslo) / NGO zone VII"},
  {coordinate_system:"EPSG:27398", label: "EPSG:27398 / NGO 1948 (Oslo) / NGO zone VIII"},
  {coordinate_system:"EPSG:27429", label: "EPSG:27429 / Datum 73 / UTM zone 29N"},
  {coordinate_system:"EPSG:27493", label: "EPSG:27493 / Datum 73 / Modified Portuguese Grid"},
  {coordinate_system:"EPSG:27500", label: "EPSG:27500 / ATF (Paris) / Nord de Guerre"},
  {coordinate_system:"EPSG:27561", label: "EPSG:27561 / NTF (Paris) / Lambert Nord France"},
  {coordinate_system:"EPSG:27562", label: "EPSG:27562 / NTF (Paris) / Lambert Centre France"},
  {coordinate_system:"EPSG:27563", label: "EPSG:27563 / NTF (Paris) / Lambert Sud France"},
  {coordinate_system:"EPSG:27564", label: "EPSG:27564 / NTF (Paris) / Lambert Corse"},
  {coordinate_system:"EPSG:27571", label: "EPSG:27571 / NTF (Paris) / Lambert zone I"},
  {coordinate_system:"EPSG:27572", label: "EPSG:27572 / NTF (Paris) / Lambert zone II"},
  {coordinate_system:"EPSG:27573", label: "EPSG:27573 / NTF (Paris) / Lambert zone III"},
  {coordinate_system:"EPSG:27574", label: "EPSG:27574 / NTF (Paris) / Lambert zone IV"},
  {coordinate_system:"EPSG:27700", label: "EPSG:27700 / OSGB36 / British National Grid"},
  {coordinate_system:"EPSG:28191", label: "EPSG:28191 / Palestine 1923 / Palestine Grid"},
  {coordinate_system:"EPSG:28192", label: "EPSG:28192 / Palestine 1923 / Palestine Belt"},
  {coordinate_system:"EPSG:28193", label: "EPSG:28193 / Palestine 1923 / Israeli CS Grid"},
  {coordinate_system:"EPSG:28232", label: "EPSG:28232 / Pointe Noire / UTM zone 32S"},
  {coordinate_system:"EPSG:28348", label: "EPSG:28348 / GDA94 / MGA zone 48"},
  {coordinate_system:"EPSG:28349", label: "EPSG:28349 / GDA94 / MGA zone 49"},
  {coordinate_system:"EPSG:28350", label: "EPSG:28350 / GDA94 / MGA zone 50"},
  {coordinate_system:"EPSG:28351", label: "EPSG:28351 / GDA94 / MGA zone 51"},
  {coordinate_system:"EPSG:28352", label: "EPSG:28352 / GDA94 / MGA zone 52"},
  {coordinate_system:"EPSG:28353", label: "EPSG:28353 / GDA94 / MGA zone 53"},
  {coordinate_system:"EPSG:28354", label: "EPSG:28354 / GDA94 / MGA zone 54"},
  {coordinate_system:"EPSG:28355", label: "EPSG:28355 / GDA94 / MGA zone 55"},
  {coordinate_system:"EPSG:28356", label: "EPSG:28356 / GDA94 / MGA zone 56"},
  {coordinate_system:"EPSG:28357", label: "EPSG:28357 / GDA94 / MGA zone 57"},
  {coordinate_system:"EPSG:28358", label: "EPSG:28358 / GDA94 / MGA zone 58"},
  {coordinate_system:"EPSG:28404", label: "EPSG:28404 / Pulkovo 1942 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:28405", label: "EPSG:28405 / Pulkovo 1942 / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:28406", label: "EPSG:28406 / Pulkovo 1942 / Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:28407", label: "EPSG:28407 / Pulkovo 1942 / Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:28408", label: "EPSG:28408 / Pulkovo 1942 / Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:28409", label: "EPSG:28409 / Pulkovo 1942 / Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:28410", label: "EPSG:28410 / Pulkovo 1942 / Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:28411", label: "EPSG:28411 / Pulkovo 1942 / Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:28412", label: "EPSG:28412 / Pulkovo 1942 / Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:28413", label: "EPSG:28413 / Pulkovo 1942 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:28414", label: "EPSG:28414 / Pulkovo 1942 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:28415", label: "EPSG:28415 / Pulkovo 1942 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:28416", label: "EPSG:28416 / Pulkovo 1942 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:28417", label: "EPSG:28417 / Pulkovo 1942 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:28418", label: "EPSG:28418 / Pulkovo 1942 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:28419", label: "EPSG:28419 / Pulkovo 1942 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:28420", label: "EPSG:28420 / Pulkovo 1942 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:28421", label: "EPSG:28421 / Pulkovo 1942 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:28422", label: "EPSG:28422 / Pulkovo 1942 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:28423", label: "EPSG:28423 / Pulkovo 1942 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:28424", label: "EPSG:28424 / Pulkovo 1942 / Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:28425", label: "EPSG:28425 / Pulkovo 1942 / Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:28426", label: "EPSG:28426 / Pulkovo 1942 / Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:28427", label: "EPSG:28427 / Pulkovo 1942 / Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:28428", label: "EPSG:28428 / Pulkovo 1942 / Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:28429", label: "EPSG:28429 / Pulkovo 1942 / Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:28430", label: "EPSG:28430 / Pulkovo 1942 / Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:28431", label: "EPSG:28431 / Pulkovo 1942 / Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:28432", label: "EPSG:28432 / Pulkovo 1942 / Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:28600", label: "EPSG:28600 / Qatar 1974 / Qatar National Grid"},
  {coordinate_system:"EPSG:28991", label: "EPSG:28991 / Amersfoort / RD Old"},
  {coordinate_system:"EPSG:28992", label: "EPSG:28992 / Amersfoort / RD New"},
  {coordinate_system:"EPSG:29101", label: "EPSG:29101 / SAD69 / Brazil Polyconic"},
  {coordinate_system:"EPSG:29168", label: "EPSG:29168 / SAD69 / UTM zone 18N"},
  {coordinate_system:"EPSG:29169", label: "EPSG:29169 / SAD69 / UTM zone 19N"},
  {coordinate_system:"EPSG:29170", label: "EPSG:29170 / SAD69 / UTM zone 20N"},
  {coordinate_system:"EPSG:29171", label: "EPSG:29171 / SAD69 / UTM zone 21N"},
  {coordinate_system:"EPSG:29172", label: "EPSG:29172 / SAD69 / UTM zone 22N"},
  {coordinate_system:"EPSG:29187", label: "EPSG:29187 / SAD69 / UTM zone 17S"},
  {coordinate_system:"EPSG:29188", label: "EPSG:29188 / SAD69 / UTM zone 18S"},
  {coordinate_system:"EPSG:29189", label: "EPSG:29189 / SAD69 / UTM zone 19S"},
  {coordinate_system:"EPSG:29190", label: "EPSG:29190 / SAD69 / UTM zone 20S"},
  {coordinate_system:"EPSG:29191", label: "EPSG:29191 / SAD69 / UTM zone 21S"},
  {coordinate_system:"EPSG:29192", label: "EPSG:29192 / SAD69 / UTM zone 22S"},
  {coordinate_system:"EPSG:29193", label: "EPSG:29193 / SAD69 / UTM zone 23S"},
  {coordinate_system:"EPSG:29194", label: "EPSG:29194 / SAD69 / UTM zone 24S"},
  {coordinate_system:"EPSG:29195", label: "EPSG:29195 / SAD69 / UTM zone 25S"},
  {coordinate_system:"EPSG:29220", label: "EPSG:29220 / Sapper Hill 1943 / UTM zone 20S"},
  {coordinate_system:"EPSG:29221", label: "EPSG:29221 / Sapper Hill 1943 / UTM zone 21S"},
  {coordinate_system:"EPSG:29333", label: "EPSG:29333 / Schwarzeck / UTM zone 33S"},
  {coordinate_system:"EPSG:29371", label: "EPSG:29371 / Schwarzeck / Lo22/11"},
  {coordinate_system:"EPSG:29373", label: "EPSG:29373 / Schwarzeck / Lo22/13"},
  {coordinate_system:"EPSG:29375", label: "EPSG:29375 / Schwarzeck / Lo22/15"},
  {coordinate_system:"EPSG:29377", label: "EPSG:29377 / Schwarzeck / Lo22/17"},
  {coordinate_system:"EPSG:29379", label: "EPSG:29379 / Schwarzeck / Lo22/19"},
  {coordinate_system:"EPSG:29381", label: "EPSG:29381 / Schwarzeck / Lo22/21"},
  {coordinate_system:"EPSG:29383", label: "EPSG:29383 / Schwarzeck / Lo22/23"},
  {coordinate_system:"EPSG:29385", label: "EPSG:29385 / Schwarzeck / Lo22/25"},
  {coordinate_system:"EPSG:29701", label: "EPSG:29701 / Tananarive (Paris) / Laborde Grid"},
  {coordinate_system:"EPSG:29702", label: "EPSG:29702 / Tananarive (Paris) / Laborde Grid approximation"},
  {coordinate_system:"EPSG:29738", label: "EPSG:29738 / Tananarive / UTM zone 38S"},
  {coordinate_system:"EPSG:29739", label: "EPSG:29739 / Tananarive / UTM zone 39S"},
  {coordinate_system:"EPSG:29849", label: "EPSG:29849 / Timbalai 1948 / UTM zone 49N"},
  {coordinate_system:"EPSG:29850", label: "EPSG:29850 / Timbalai 1948 / UTM zone 50N"},
  {coordinate_system:"EPSG:29871", label: "EPSG:29871 / Timbalai 1948 / RSO Borneo (ch)"},
  {coordinate_system:"EPSG:29872", label: "EPSG:29872 / Timbalai 1948 / RSO Borneo (ftSe)"},
  {coordinate_system:"EPSG:29873", label: "EPSG:29873 / Timbalai 1948 / RSO Borneo (m)"},
  {coordinate_system:"EPSG:29874", label: "EPSG:29874 / Timbalai 1948 / RSO Sarawak LSD (m)"},
  {coordinate_system:"EPSG:29901", label: "EPSG:29901 / OSNI 1952 / Irish National Grid"},
  {coordinate_system:"EPSG:29902", label: "EPSG:29902 / TM65 / Irish Grid"},
  {coordinate_system:"EPSG:29903", label: "EPSG:29903 / TM75 / Irish Grid"},
  {coordinate_system:"EPSG:30161", label: "EPSG:30161 / Tokyo / Japan Plane Rectangular CS I"},
  {coordinate_system:"EPSG:30162", label: "EPSG:30162 / Tokyo / Japan Plane Rectangular CS II"},
  {coordinate_system:"EPSG:30163", label: "EPSG:30163 / Tokyo / Japan Plane Rectangular CS III"},
  {coordinate_system:"EPSG:30164", label: "EPSG:30164 / Tokyo / Japan Plane Rectangular CS IV"},
  {coordinate_system:"EPSG:30165", label: "EPSG:30165 / Tokyo / Japan Plane Rectangular CS V"},
  {coordinate_system:"EPSG:30166", label: "EPSG:30166 / Tokyo / Japan Plane Rectangular CS VI"},
  {coordinate_system:"EPSG:30167", label: "EPSG:30167 / Tokyo / Japan Plane Rectangular CS VII"},
  {coordinate_system:"EPSG:30168", label: "EPSG:30168 / Tokyo / Japan Plane Rectangular CS VIII"},
  {coordinate_system:"EPSG:30169", label: "EPSG:30169 / Tokyo / Japan Plane Rectangular CS IX"},
  {coordinate_system:"EPSG:30170", label: "EPSG:30170 / Tokyo / Japan Plane Rectangular CS X"},
  {coordinate_system:"EPSG:30171", label: "EPSG:30171 / Tokyo / Japan Plane Rectangular CS XI"},
  {coordinate_system:"EPSG:30172", label: "EPSG:30172 / Tokyo / Japan Plane Rectangular CS XII"},
  {coordinate_system:"EPSG:30173", label: "EPSG:30173 / Tokyo / Japan Plane Rectangular CS XIII"},
  {coordinate_system:"EPSG:30174", label: "EPSG:30174 / Tokyo / Japan Plane Rectangular CS XIV"},
  {coordinate_system:"EPSG:30175", label: "EPSG:30175 / Tokyo / Japan Plane Rectangular CS XV"},
  {coordinate_system:"EPSG:30176", label: "EPSG:30176 / Tokyo / Japan Plane Rectangular CS XVI"},
  {coordinate_system:"EPSG:30177", label: "EPSG:30177 / Tokyo / Japan Plane Rectangular CS XVII"},
  {coordinate_system:"EPSG:30178", label: "EPSG:30178 / Tokyo / Japan Plane Rectangular CS XVIII"},
  {coordinate_system:"EPSG:30179", label: "EPSG:30179 / Tokyo / Japan Plane Rectangular CS XIX"},
  {coordinate_system:"EPSG:30200", label: "EPSG:30200 / Trinidad 1903 / Trinidad Grid"},
  {coordinate_system:"EPSG:30339", label: "EPSG:30339 / TC(1948) / UTM zone 39N"},
  {coordinate_system:"EPSG:30340", label: "EPSG:30340 / TC(1948) / UTM zone 40N"},
  {coordinate_system:"EPSG:30491", label: "EPSG:30491 / Voirol 1875 / Nord Algerie (ancienne)"},
  {coordinate_system:"EPSG:30492", label: "EPSG:30492 / Voirol 1875 / Sud Algerie (ancienne)"},
  {coordinate_system:"EPSG:30493", label: "EPSG:30493 / Voirol 1879 / Nord Algerie (ancienne)"},
  {coordinate_system:"EPSG:30494", label: "EPSG:30494 / Voirol 1879 / Sud Algerie (ancienne)"},
  {coordinate_system:"EPSG:30729", label: "EPSG:30729 / Nord Sahara 1959 / UTM zone 29N"},
  {coordinate_system:"EPSG:30730", label: "EPSG:30730 / Nord Sahara 1959 / UTM zone 30N"},
  {coordinate_system:"EPSG:30731", label: "EPSG:30731 / Nord Sahara 1959 / UTM zone 31N"},
  {coordinate_system:"EPSG:30732", label: "EPSG:30732 / Nord Sahara 1959 / UTM zone 32N"},
  {coordinate_system:"EPSG:30791", label: "EPSG:30791 / Nord Sahara 1959 / Nord Algerie"},
  {coordinate_system:"EPSG:30792", label: "EPSG:30792 / Nord Sahara 1959 / Sud Algerie"},
  {coordinate_system:"EPSG:31028", label: "EPSG:31028 / Yoff / UTM zone 28N"},
  {coordinate_system:"EPSG:31121", label: "EPSG:31121 / Zanderij / UTM zone 21N"},
  {coordinate_system:"EPSG:31154", label: "EPSG:31154 / Zanderij / TM 54 NW"},
  {coordinate_system:"EPSG:31170", label: "EPSG:31170 / Zanderij / Suriname Old TM"},
  {coordinate_system:"EPSG:31171", label: "EPSG:31171 / Zanderij / Suriname TM"},
  {coordinate_system:"EPSG:31251", label: "EPSG:31251 / MGI (Ferro) / Austria GK West Zone"},
  {coordinate_system:"EPSG:31252", label: "EPSG:31252 / MGI (Ferro) / Austria GK Central Zone"},
  {coordinate_system:"EPSG:31253", label: "EPSG:31253 / MGI (Ferro) / Austria GK East Zone"},
  {coordinate_system:"EPSG:31254", label: "EPSG:31254 / MGI / Austria GK West"},
  {coordinate_system:"EPSG:31255", label: "EPSG:31255 / MGI / Austria GK Central"},
  {coordinate_system:"EPSG:31256", label: "EPSG:31256 / MGI / Austria GK East"},
  {coordinate_system:"EPSG:31257", label: "EPSG:31257 / MGI / Austria GK M28"},
  {coordinate_system:"EPSG:31258", label: "EPSG:31258 / MGI / Austria GK M31"},
  {coordinate_system:"EPSG:31259", label: "EPSG:31259 / MGI / Austria GK M34"},
  {coordinate_system:"EPSG:31281", label: "EPSG:31281 / MGI (Ferro) / Austria West Zone"},
  {coordinate_system:"EPSG:31282", label: "EPSG:31282 / MGI (Ferro) / Austria Central Zone"},
  {coordinate_system:"EPSG:31283", label: "EPSG:31283 / MGI (Ferro) / Austria East Zone"},
  {coordinate_system:"EPSG:31284", label: "EPSG:31284 / MGI / Austria M28"},
  {coordinate_system:"EPSG:31285", label: "EPSG:31285 / MGI / Austria M31"},
  {coordinate_system:"EPSG:31286", label: "EPSG:31286 / MGI / Austria M34"},
  {coordinate_system:"EPSG:31287", label: "EPSG:31287 / MGI / Austria Lambert"},
  {coordinate_system:"EPSG:31288", label: "EPSG:31288 / MGI (Ferro) / Austria zone M28"},
  {coordinate_system:"EPSG:31289", label: "EPSG:31289 / MGI (Ferro) / Austria zone M31"},
  {coordinate_system:"EPSG:31290", label: "EPSG:31290 / MGI (Ferro) / Austria zone M34"},
  {coordinate_system:"EPSG:31300", label: "EPSG:31300 / BD72 / Belge Lambert 72"},
  {coordinate_system:"EPSG:31370", label: "EPSG:31370 / BD72 / Belgian Lambert 72"},
  {coordinate_system:"EPSG:31466", label: "EPSG:31466 / DHDN / 3-degree Gauss-Kruger zone 2"},
  {coordinate_system:"EPSG:31467", label: "EPSG:31467 / DHDN / 3-degree Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:31468", label: "EPSG:31468 / DHDN / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:31469", label: "EPSG:31469 / DHDN / 3-degree Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:31528", label: "EPSG:31528 / Conakry 1905 / UTM zone 28N"},
  {coordinate_system:"EPSG:31529", label: "EPSG:31529 / Conakry 1905 / UTM zone 29N"},
  {coordinate_system:"EPSG:31600", label: "EPSG:31600 / Dealul Piscului 1930 / Stereo 33"},
  {coordinate_system:"EPSG:31838", label: "EPSG:31838 / NGN / UTM zone 38N"},
  {coordinate_system:"EPSG:31839", label: "EPSG:31839 / NGN / UTM zone 39N"},
  {coordinate_system:"EPSG:31901", label: "EPSG:31901 / KUDAMS / KTM"},
  {coordinate_system:"EPSG:31965", label: "EPSG:31965 / SIRGAS 2000 / UTM zone 11N"},
  {coordinate_system:"EPSG:31966", label: "EPSG:31966 / SIRGAS 2000 / UTM zone 12N"},
  {coordinate_system:"EPSG:31967", label: "EPSG:31967 / SIRGAS 2000 / UTM zone 13N"},
  {coordinate_system:"EPSG:31968", label: "EPSG:31968 / SIRGAS 2000 / UTM zone 14N"},
  {coordinate_system:"EPSG:31969", label: "EPSG:31969 / SIRGAS 2000 / UTM zone 15N"},
  {coordinate_system:"EPSG:31970", label: "EPSG:31970 / SIRGAS 2000 / UTM zone 16N"},
  {coordinate_system:"EPSG:31971", label: "EPSG:31971 / SIRGAS 2000 / UTM zone 17N"},
  {coordinate_system:"EPSG:31972", label: "EPSG:31972 / SIRGAS 2000 / UTM zone 18N"},
  {coordinate_system:"EPSG:31973", label: "EPSG:31973 / SIRGAS 2000 / UTM zone 19N"},
  {coordinate_system:"EPSG:31974", label: "EPSG:31974 / SIRGAS 2000 / UTM zone 20N"},
  {coordinate_system:"EPSG:31975", label: "EPSG:31975 / SIRGAS 2000 / UTM zone 21N"},
  {coordinate_system:"EPSG:31976", label: "EPSG:31976 / SIRGAS 2000 / UTM zone 22N"},
  {coordinate_system:"EPSG:31977", label: "EPSG:31977 / SIRGAS 2000 / UTM zone 17S"},
  {coordinate_system:"EPSG:31978", label: "EPSG:31978 / SIRGAS 2000 / UTM zone 18S"},
  {coordinate_system:"EPSG:31979", label: "EPSG:31979 / SIRGAS 2000 / UTM zone 19S"},
  {coordinate_system:"EPSG:31980", label: "EPSG:31980 / SIRGAS 2000 / UTM zone 20S"},
  {coordinate_system:"EPSG:31981", label: "EPSG:31981 / SIRGAS 2000 / UTM zone 21S"},
  {coordinate_system:"EPSG:31982", label: "EPSG:31982 / SIRGAS 2000 / UTM zone 22S"},
  {coordinate_system:"EPSG:31983", label: "EPSG:31983 / SIRGAS 2000 / UTM zone 23S"},
  {coordinate_system:"EPSG:31984", label: "EPSG:31984 / SIRGAS 2000 / UTM zone 24S"},
  {coordinate_system:"EPSG:31985", label: "EPSG:31985 / SIRGAS 2000 / UTM zone 25S"},
  {coordinate_system:"EPSG:31986", label: "EPSG:31986 / SIRGAS 1995 / UTM zone 17N"},
  {coordinate_system:"EPSG:31987", label: "EPSG:31987 / SIRGAS 1995 / UTM zone 18N"},
  {coordinate_system:"EPSG:31988", label: "EPSG:31988 / SIRGAS 1995 / UTM zone 19N"},
  {coordinate_system:"EPSG:31989", label: "EPSG:31989 / SIRGAS 1995 / UTM zone 20N"},
  {coordinate_system:"EPSG:31990", label: "EPSG:31990 / SIRGAS 1995 / UTM zone 21N"},
  {coordinate_system:"EPSG:31991", label: "EPSG:31991 / SIRGAS 1995 / UTM zone 22N"},
  {coordinate_system:"EPSG:31992", label: "EPSG:31992 / SIRGAS 1995 / UTM zone 17S"},
  {coordinate_system:"EPSG:31993", label: "EPSG:31993 / SIRGAS 1995 / UTM zone 18S"},
  {coordinate_system:"EPSG:31994", label: "EPSG:31994 / SIRGAS 1995 / UTM zone 19S"},
  {coordinate_system:"EPSG:31995", label: "EPSG:31995 / SIRGAS 1995 / UTM zone 20S"},
  {coordinate_system:"EPSG:31996", label: "EPSG:31996 / SIRGAS 1995 / UTM zone 21S"},
  {coordinate_system:"EPSG:31997", label: "EPSG:31997 / SIRGAS 1995 / UTM zone 22S"},
  {coordinate_system:"EPSG:31998", label: "EPSG:31998 / SIRGAS 1995 / UTM zone 23S"},
  {coordinate_system:"EPSG:31999", label: "EPSG:31999 / SIRGAS 1995 / UTM zone 24S"},
  {coordinate_system:"EPSG:32000", label: "EPSG:32000 / SIRGAS 1995 / UTM zone 25S"},
  {coordinate_system:"EPSG:32001", label: "EPSG:32001 / NAD27 / Montana North"},
  {coordinate_system:"EPSG:32002", label: "EPSG:32002 / NAD27 / Montana Central"},
  {coordinate_system:"EPSG:32003", label: "EPSG:32003 / NAD27 / Montana South"},
  {coordinate_system:"EPSG:32005", label: "EPSG:32005 / NAD27 / Nebraska North"},
  {coordinate_system:"EPSG:32006", label: "EPSG:32006 / NAD27 / Nebraska South"},
  {coordinate_system:"EPSG:32007", label: "EPSG:32007 / NAD27 / Nevada East"},
  {coordinate_system:"EPSG:32008", label: "EPSG:32008 / NAD27 / Nevada Central"},
  {coordinate_system:"EPSG:32009", label: "EPSG:32009 / NAD27 / Nevada West"},
  {coordinate_system:"EPSG:32010", label: "EPSG:32010 / NAD27 / New Hampshire"},
  {coordinate_system:"EPSG:32011", label: "EPSG:32011 / NAD27 / New Jersey"},
  {coordinate_system:"EPSG:32012", label: "EPSG:32012 / NAD27 / New Mexico East"},
  {coordinate_system:"EPSG:32013", label: "EPSG:32013 / NAD27 / New Mexico Central"},
  {coordinate_system:"EPSG:32014", label: "EPSG:32014 / NAD27 / New Mexico West"},
  {coordinate_system:"EPSG:32015", label: "EPSG:32015 / NAD27 / New York East"},
  {coordinate_system:"EPSG:32016", label: "EPSG:32016 / NAD27 / New York Central"},
  {coordinate_system:"EPSG:32017", label: "EPSG:32017 / NAD27 / New York West"},
  {coordinate_system:"EPSG:32019", label: "EPSG:32019 / NAD27 / North Carolina"},
  {coordinate_system:"EPSG:32020", label: "EPSG:32020 / NAD27 / North Dakota North"},
  {coordinate_system:"EPSG:32021", label: "EPSG:32021 / NAD27 / North Dakota South"},
  {coordinate_system:"EPSG:32022", label: "EPSG:32022 / NAD27 / Ohio North"},
  {coordinate_system:"EPSG:32023", label: "EPSG:32023 / NAD27 / Ohio South"},
  {coordinate_system:"EPSG:32024", label: "EPSG:32024 / NAD27 / Oklahoma North"},
  {coordinate_system:"EPSG:32025", label: "EPSG:32025 / NAD27 / Oklahoma South"},
  {coordinate_system:"EPSG:32026", label: "EPSG:32026 / NAD27 / Oregon North"},
  {coordinate_system:"EPSG:32027", label: "EPSG:32027 / NAD27 / Oregon South"},
  {coordinate_system:"EPSG:32028", label: "EPSG:32028 / NAD27 / Pennsylvania North"},
  {coordinate_system:"EPSG:32030", label: "EPSG:32030 / NAD27 / Rhode Island"},
  {coordinate_system:"EPSG:32031", label: "EPSG:32031 / NAD27 / South Carolina North"},
  {coordinate_system:"EPSG:32033", label: "EPSG:32033 / NAD27 / South Carolina South"},
  {coordinate_system:"EPSG:32034", label: "EPSG:32034 / NAD27 / South Dakota North"},
  {coordinate_system:"EPSG:32035", label: "EPSG:32035 / NAD27 / South Dakota South"},
  {coordinate_system:"EPSG:32037", label: "EPSG:32037 / NAD27 / Texas North"},
  {coordinate_system:"EPSG:32038", label: "EPSG:32038 / NAD27 / Texas North Central"},
  {coordinate_system:"EPSG:32039", label: "EPSG:32039 / NAD27 / Texas Central"},
  {coordinate_system:"EPSG:32040", label: "EPSG:32040 / NAD27 / Texas South Central"},
  {coordinate_system:"EPSG:32041", label: "EPSG:32041 / NAD27 / Texas South"},
  {coordinate_system:"EPSG:32042", label: "EPSG:32042 / NAD27 / Utah North"},
  {coordinate_system:"EPSG:32043", label: "EPSG:32043 / NAD27 / Utah Central"},
  {coordinate_system:"EPSG:32044", label: "EPSG:32044 / NAD27 / Utah South"},
  {coordinate_system:"EPSG:32045", label: "EPSG:32045 / NAD27 / Vermont"},
  {coordinate_system:"EPSG:32046", label: "EPSG:32046 / NAD27 / Virginia North"},
  {coordinate_system:"EPSG:32047", label: "EPSG:32047 / NAD27 / Virginia South"},
  {coordinate_system:"EPSG:32048", label: "EPSG:32048 / NAD27 / Washington North"},
  {coordinate_system:"EPSG:32049", label: "EPSG:32049 / NAD27 / Washington South"},
  {coordinate_system:"EPSG:32050", label: "EPSG:32050 / NAD27 / West Virginia North"},
  {coordinate_system:"EPSG:32051", label: "EPSG:32051 / NAD27 / West Virginia South"},
  {coordinate_system:"EPSG:32052", label: "EPSG:32052 / NAD27 / Wisconsin North"},
  {coordinate_system:"EPSG:32053", label: "EPSG:32053 / NAD27 / Wisconsin Central"},
  {coordinate_system:"EPSG:32054", label: "EPSG:32054 / NAD27 / Wisconsin South"},
  {coordinate_system:"EPSG:32055", label: "EPSG:32055 / NAD27 / Wyoming East"},
  {coordinate_system:"EPSG:32056", label: "EPSG:32056 / NAD27 / Wyoming East Central"},
  {coordinate_system:"EPSG:32057", label: "EPSG:32057 / NAD27 / Wyoming West Central"},
  {coordinate_system:"EPSG:32058", label: "EPSG:32058 / NAD27 / Wyoming West"},
  {coordinate_system:"EPSG:32064", label: "EPSG:32064 / NAD27 / BLM 14N (ftUS)"},
  {coordinate_system:"EPSG:32065", label: "EPSG:32065 / NAD27 / BLM 15N (ftUS)"},
  {coordinate_system:"EPSG:32066", label: "EPSG:32066 / NAD27 / BLM 16N (ftUS)"},
  {coordinate_system:"EPSG:32067", label: "EPSG:32067 / NAD27 / BLM 17N (ftUS)"},
  {coordinate_system:"EPSG:32081", label: "EPSG:32081 / NAD27 / MTM zone 1"},
  {coordinate_system:"EPSG:32082", label: "EPSG:32082 / NAD27 / MTM zone 2"},
  {coordinate_system:"EPSG:32083", label: "EPSG:32083 / NAD27 / MTM zone 3"},
  {coordinate_system:"EPSG:32084", label: "EPSG:32084 / NAD27 / MTM zone 4"},
  {coordinate_system:"EPSG:32085", label: "EPSG:32085 / NAD27 / MTM zone 5"},
  {coordinate_system:"EPSG:32086", label: "EPSG:32086 / NAD27 / MTM zone 6"},
  {coordinate_system:"EPSG:32098", label: "EPSG:32098 / NAD27 / Quebec Lambert"},
  {coordinate_system:"EPSG:32099", label: "EPSG:32099 / NAD27 / Louisiana Offshore"},
  {coordinate_system:"EPSG:32100", label: "EPSG:32100 / NAD83 / Montana"},
  {coordinate_system:"EPSG:32104", label: "EPSG:32104 / NAD83 / Nebraska"},
  {coordinate_system:"EPSG:32107", label: "EPSG:32107 / NAD83 / Nevada East"},
  {coordinate_system:"EPSG:32108", label: "EPSG:32108 / NAD83 / Nevada Central"},
  {coordinate_system:"EPSG:32109", label: "EPSG:32109 / NAD83 / Nevada West"},
  {coordinate_system:"EPSG:32110", label: "EPSG:32110 / NAD83 / New Hampshire"},
  {coordinate_system:"EPSG:32111", label: "EPSG:32111 / NAD83 / New Jersey"},
  {coordinate_system:"EPSG:32112", label: "EPSG:32112 / NAD83 / New Mexico East"},
  {coordinate_system:"EPSG:32113", label: "EPSG:32113 / NAD83 / New Mexico Central"},
  {coordinate_system:"EPSG:32114", label: "EPSG:32114 / NAD83 / New Mexico West"},
  {coordinate_system:"EPSG:32115", label: "EPSG:32115 / NAD83 / New York East"},
  {coordinate_system:"EPSG:32116", label: "EPSG:32116 / NAD83 / New York Central"},
  {coordinate_system:"EPSG:32117", label: "EPSG:32117 / NAD83 / New York West"},
  {coordinate_system:"EPSG:32118", label: "EPSG:32118 / NAD83 / New York Long Island"},
  {coordinate_system:"EPSG:32119", label: "EPSG:32119 / NAD83 / North Carolina"},
  {coordinate_system:"EPSG:32120", label: "EPSG:32120 / NAD83 / North Dakota North"},
  {coordinate_system:"EPSG:32121", label: "EPSG:32121 / NAD83 / North Dakota South"},
  {coordinate_system:"EPSG:32122", label: "EPSG:32122 / NAD83 / Ohio North"},
  {coordinate_system:"EPSG:32123", label: "EPSG:32123 / NAD83 / Ohio South"},
  {coordinate_system:"EPSG:32124", label: "EPSG:32124 / NAD83 / Oklahoma North"},
  {coordinate_system:"EPSG:32125", label: "EPSG:32125 / NAD83 / Oklahoma South"},
  {coordinate_system:"EPSG:32126", label: "EPSG:32126 / NAD83 / Oregon North"},
  {coordinate_system:"EPSG:32127", label: "EPSG:32127 / NAD83 / Oregon South"},
  {coordinate_system:"EPSG:32128", label: "EPSG:32128 / NAD83 / Pennsylvania North"},
  {coordinate_system:"EPSG:32129", label: "EPSG:32129 / NAD83 / Pennsylvania South"},
  {coordinate_system:"EPSG:32130", label: "EPSG:32130 / NAD83 / Rhode Island"},
  {coordinate_system:"EPSG:32133", label: "EPSG:32133 / NAD83 / South Carolina"},
  {coordinate_system:"EPSG:32134", label: "EPSG:32134 / NAD83 / South Dakota North"},
  {coordinate_system:"EPSG:32135", label: "EPSG:32135 / NAD83 / South Dakota South"},
  {coordinate_system:"EPSG:32136", label: "EPSG:32136 / NAD83 / Tennessee"},
  {coordinate_system:"EPSG:32137", label: "EPSG:32137 / NAD83 / Texas North"},
  {coordinate_system:"EPSG:32138", label: "EPSG:32138 / NAD83 / Texas North Central"},
  {coordinate_system:"EPSG:32139", label: "EPSG:32139 / NAD83 / Texas Central"},
  {coordinate_system:"EPSG:32140", label: "EPSG:32140 / NAD83 / Texas South Central"},
  {coordinate_system:"EPSG:32141", label: "EPSG:32141 / NAD83 / Texas South"},
  {coordinate_system:"EPSG:32142", label: "EPSG:32142 / NAD83 / Utah North"},
  {coordinate_system:"EPSG:32143", label: "EPSG:32143 / NAD83 / Utah Central"},
  {coordinate_system:"EPSG:32144", label: "EPSG:32144 / NAD83 / Utah South"},
  {coordinate_system:"EPSG:32145", label: "EPSG:32145 / NAD83 / Vermont"},
  {coordinate_system:"EPSG:32146", label: "EPSG:32146 / NAD83 / Virginia North"},
  {coordinate_system:"EPSG:32147", label: "EPSG:32147 / NAD83 / Virginia South"},
  {coordinate_system:"EPSG:32148", label: "EPSG:32148 / NAD83 / Washington North"},
  {coordinate_system:"EPSG:32149", label: "EPSG:32149 / NAD83 / Washington South"},
  {coordinate_system:"EPSG:32150", label: "EPSG:32150 / NAD83 / West Virginia North"},
  {coordinate_system:"EPSG:32151", label: "EPSG:32151 / NAD83 / West Virginia South"},
  {coordinate_system:"EPSG:32152", label: "EPSG:32152 / NAD83 / Wisconsin North"},
  {coordinate_system:"EPSG:32153", label: "EPSG:32153 / NAD83 / Wisconsin Central"},
  {coordinate_system:"EPSG:32154", label: "EPSG:32154 / NAD83 / Wisconsin South"},
  {coordinate_system:"EPSG:32155", label: "EPSG:32155 / NAD83 / Wyoming East"},
  {coordinate_system:"EPSG:32156", label: "EPSG:32156 / NAD83 / Wyoming East Central"},
  {coordinate_system:"EPSG:32157", label: "EPSG:32157 / NAD83 / Wyoming West Central"},
  {coordinate_system:"EPSG:32158", label: "EPSG:32158 / NAD83 / Wyoming West"},
  {coordinate_system:"EPSG:32159", label: "EPSG:32159 / NAD83 / Wyoming Lambert"},
  {coordinate_system:"EPSG:32161", label: "EPSG:32161 / NAD83 / Puerto Rico & Virgin Is."},
  {coordinate_system:"EPSG:32164", label: "EPSG:32164 / NAD83 / BLM 14N (ftUS)"},
  {coordinate_system:"EPSG:32165", label: "EPSG:32165 / NAD83 / BLM 15N (ftUS)"},
  {coordinate_system:"EPSG:32166", label: "EPSG:32166 / NAD83 / BLM 16N (ftUS)"},
  {coordinate_system:"EPSG:32167", label: "EPSG:32167 / NAD83 / BLM 17N (ftUS)"},
  {coordinate_system:"EPSG:32181", label: "EPSG:32181 / NAD83 / MTM zone 1"},
  {coordinate_system:"EPSG:32182", label: "EPSG:32182 / NAD83 / MTM zone 2"},
  {coordinate_system:"EPSG:32183", label: "EPSG:32183 / NAD83 / MTM zone 3"},
  {coordinate_system:"EPSG:32184", label: "EPSG:32184 / NAD83 / MTM zone 4"},
  {coordinate_system:"EPSG:32185", label: "EPSG:32185 / NAD83 / MTM zone 5"},
  {coordinate_system:"EPSG:32186", label: "EPSG:32186 / NAD83 / MTM zone 6"},
  {coordinate_system:"EPSG:32187", label: "EPSG:32187 / NAD83 / MTM zone 7"},
  {coordinate_system:"EPSG:32188", label: "EPSG:32188 / NAD83 / MTM zone 8"},
  {coordinate_system:"EPSG:32189", label: "EPSG:32189 / NAD83 / MTM zone 9"},
  {coordinate_system:"EPSG:32190", label: "EPSG:32190 / NAD83 / MTM zone 10"},
  {coordinate_system:"EPSG:32191", label: "EPSG:32191 / NAD83 / MTM zone 11"},
  {coordinate_system:"EPSG:32192", label: "EPSG:32192 / NAD83 / MTM zone 12"},
  {coordinate_system:"EPSG:32193", label: "EPSG:32193 / NAD83 / MTM zone 13"},
  {coordinate_system:"EPSG:32194", label: "EPSG:32194 / NAD83 / MTM zone 14"},
  {coordinate_system:"EPSG:32195", label: "EPSG:32195 / NAD83 / MTM zone 15"},
  {coordinate_system:"EPSG:32196", label: "EPSG:32196 / NAD83 / MTM zone 16"},
  {coordinate_system:"EPSG:32197", label: "EPSG:32197 / NAD83 / MTM zone 17"},
  {coordinate_system:"EPSG:32198", label: "EPSG:32198 / NAD83 / Quebec Lambert"},
  {coordinate_system:"EPSG:32199", label: "EPSG:32199 / NAD83 / Louisiana Offshore"},
  {coordinate_system:"EPSG:32201", label: "EPSG:32201 / WGS 72 / UTM zone 1N"},
  {coordinate_system:"EPSG:32202", label: "EPSG:32202 / WGS 72 / UTM zone 2N"},
  {coordinate_system:"EPSG:32203", label: "EPSG:32203 / WGS 72 / UTM zone 3N"},
  {coordinate_system:"EPSG:32204", label: "EPSG:32204 / WGS 72 / UTM zone 4N"},
  {coordinate_system:"EPSG:32205", label: "EPSG:32205 / WGS 72 / UTM zone 5N"},
  {coordinate_system:"EPSG:32206", label: "EPSG:32206 / WGS 72 / UTM zone 6N"},
  {coordinate_system:"EPSG:32207", label: "EPSG:32207 / WGS 72 / UTM zone 7N"},
  {coordinate_system:"EPSG:32208", label: "EPSG:32208 / WGS 72 / UTM zone 8N"},
  {coordinate_system:"EPSG:32209", label: "EPSG:32209 / WGS 72 / UTM zone 9N"},
  {coordinate_system:"EPSG:32210", label: "EPSG:32210 / WGS 72 / UTM zone 10N"},
  {coordinate_system:"EPSG:32211", label: "EPSG:32211 / WGS 72 / UTM zone 11N"},
  {coordinate_system:"EPSG:32212", label: "EPSG:32212 / WGS 72 / UTM zone 12N"},
  {coordinate_system:"EPSG:32213", label: "EPSG:32213 / WGS 72 / UTM zone 13N"},
  {coordinate_system:"EPSG:32214", label: "EPSG:32214 / WGS 72 / UTM zone 14N"},
  {coordinate_system:"EPSG:32215", label: "EPSG:32215 / WGS 72 / UTM zone 15N"},
  {coordinate_system:"EPSG:32216", label: "EPSG:32216 / WGS 72 / UTM zone 16N"},
  {coordinate_system:"EPSG:32217", label: "EPSG:32217 / WGS 72 / UTM zone 17N"},
  {coordinate_system:"EPSG:32218", label: "EPSG:32218 / WGS 72 / UTM zone 18N"},
  {coordinate_system:"EPSG:32219", label: "EPSG:32219 / WGS 72 / UTM zone 19N"},
  {coordinate_system:"EPSG:32220", label: "EPSG:32220 / WGS 72 / UTM zone 20N"},
  {coordinate_system:"EPSG:32221", label: "EPSG:32221 / WGS 72 / UTM zone 21N"},
  {coordinate_system:"EPSG:32222", label: "EPSG:32222 / WGS 72 / UTM zone 22N"},
  {coordinate_system:"EPSG:32223", label: "EPSG:32223 / WGS 72 / UTM zone 23N"},
  {coordinate_system:"EPSG:32224", label: "EPSG:32224 / WGS 72 / UTM zone 24N"},
  {coordinate_system:"EPSG:32225", label: "EPSG:32225 / WGS 72 / UTM zone 25N"},
  {coordinate_system:"EPSG:32226", label: "EPSG:32226 / WGS 72 / UTM zone 26N"},
  {coordinate_system:"EPSG:32227", label: "EPSG:32227 / WGS 72 / UTM zone 27N"},
  {coordinate_system:"EPSG:32228", label: "EPSG:32228 / WGS 72 / UTM zone 28N"},
  {coordinate_system:"EPSG:32229", label: "EPSG:32229 / WGS 72 / UTM zone 29N"},
  {coordinate_system:"EPSG:32230", label: "EPSG:32230 / WGS 72 / UTM zone 30N"},
  {coordinate_system:"EPSG:32231", label: "EPSG:32231 / WGS 72 / UTM zone 31N"},
  {coordinate_system:"EPSG:32232", label: "EPSG:32232 / WGS 72 / UTM zone 32N"},
  {coordinate_system:"EPSG:32233", label: "EPSG:32233 / WGS 72 / UTM zone 33N"},
  {coordinate_system:"EPSG:32234", label: "EPSG:32234 / WGS 72 / UTM zone 34N"},
  {coordinate_system:"EPSG:32235", label: "EPSG:32235 / WGS 72 / UTM zone 35N"},
  {coordinate_system:"EPSG:32236", label: "EPSG:32236 / WGS 72 / UTM zone 36N"},
  {coordinate_system:"EPSG:32237", label: "EPSG:32237 / WGS 72 / UTM zone 37N"},
  {coordinate_system:"EPSG:32238", label: "EPSG:32238 / WGS 72 / UTM zone 38N"},
  {coordinate_system:"EPSG:32239", label: "EPSG:32239 / WGS 72 / UTM zone 39N"},
  {coordinate_system:"EPSG:32240", label: "EPSG:32240 / WGS 72 / UTM zone 40N"},
  {coordinate_system:"EPSG:32241", label: "EPSG:32241 / WGS 72 / UTM zone 41N"},
  {coordinate_system:"EPSG:32242", label: "EPSG:32242 / WGS 72 / UTM zone 42N"},
  {coordinate_system:"EPSG:32243", label: "EPSG:32243 / WGS 72 / UTM zone 43N"},
  {coordinate_system:"EPSG:32244", label: "EPSG:32244 / WGS 72 / UTM zone 44N"},
  {coordinate_system:"EPSG:32245", label: "EPSG:32245 / WGS 72 / UTM zone 45N"},
  {coordinate_system:"EPSG:32246", label: "EPSG:32246 / WGS 72 / UTM zone 46N"},
  {coordinate_system:"EPSG:32247", label: "EPSG:32247 / WGS 72 / UTM zone 47N"},
  {coordinate_system:"EPSG:32248", label: "EPSG:32248 / WGS 72 / UTM zone 48N"},
  {coordinate_system:"EPSG:32249", label: "EPSG:32249 / WGS 72 / UTM zone 49N"},
  {coordinate_system:"EPSG:32250", label: "EPSG:32250 / WGS 72 / UTM zone 50N"},
  {coordinate_system:"EPSG:32251", label: "EPSG:32251 / WGS 72 / UTM zone 51N"},
  {coordinate_system:"EPSG:32252", label: "EPSG:32252 / WGS 72 / UTM zone 52N"},
  {coordinate_system:"EPSG:32253", label: "EPSG:32253 / WGS 72 / UTM zone 53N"},
  {coordinate_system:"EPSG:32254", label: "EPSG:32254 / WGS 72 / UTM zone 54N"},
  {coordinate_system:"EPSG:32255", label: "EPSG:32255 / WGS 72 / UTM zone 55N"},
  {coordinate_system:"EPSG:32256", label: "EPSG:32256 / WGS 72 / UTM zone 56N"},
  {coordinate_system:"EPSG:32257", label: "EPSG:32257 / WGS 72 / UTM zone 57N"},
  {coordinate_system:"EPSG:32258", label: "EPSG:32258 / WGS 72 / UTM zone 58N"},
  {coordinate_system:"EPSG:32259", label: "EPSG:32259 / WGS 72 / UTM zone 59N"},
  {coordinate_system:"EPSG:32260", label: "EPSG:32260 / WGS 72 / UTM zone 60N"},
  {coordinate_system:"EPSG:32301", label: "EPSG:32301 / WGS 72 / UTM zone 1S"},
  {coordinate_system:"EPSG:32302", label: "EPSG:32302 / WGS 72 / UTM zone 2S"},
  {coordinate_system:"EPSG:32303", label: "EPSG:32303 / WGS 72 / UTM zone 3S"},
  {coordinate_system:"EPSG:32304", label: "EPSG:32304 / WGS 72 / UTM zone 4S"},
  {coordinate_system:"EPSG:32305", label: "EPSG:32305 / WGS 72 / UTM zone 5S"},
  {coordinate_system:"EPSG:32306", label: "EPSG:32306 / WGS 72 / UTM zone 6S"},
  {coordinate_system:"EPSG:32307", label: "EPSG:32307 / WGS 72 / UTM zone 7S"},
  {coordinate_system:"EPSG:32308", label: "EPSG:32308 / WGS 72 / UTM zone 8S"},
  {coordinate_system:"EPSG:32309", label: "EPSG:32309 / WGS 72 / UTM zone 9S"},
  {coordinate_system:"EPSG:32310", label: "EPSG:32310 / WGS 72 / UTM zone 10S"},
  {coordinate_system:"EPSG:32311", label: "EPSG:32311 / WGS 72 / UTM zone 11S"},
  {coordinate_system:"EPSG:32312", label: "EPSG:32312 / WGS 72 / UTM zone 12S"},
  {coordinate_system:"EPSG:32313", label: "EPSG:32313 / WGS 72 / UTM zone 13S"},
  {coordinate_system:"EPSG:32314", label: "EPSG:32314 / WGS 72 / UTM zone 14S"},
  {coordinate_system:"EPSG:32315", label: "EPSG:32315 / WGS 72 / UTM zone 15S"},
  {coordinate_system:"EPSG:32316", label: "EPSG:32316 / WGS 72 / UTM zone 16S"},
  {coordinate_system:"EPSG:32317", label: "EPSG:32317 / WGS 72 / UTM zone 17S"},
  {coordinate_system:"EPSG:32318", label: "EPSG:32318 / WGS 72 / UTM zone 18S"},
  {coordinate_system:"EPSG:32319", label: "EPSG:32319 / WGS 72 / UTM zone 19S"},
  {coordinate_system:"EPSG:32320", label: "EPSG:32320 / WGS 72 / UTM zone 20S"},
  {coordinate_system:"EPSG:32321", label: "EPSG:32321 / WGS 72 / UTM zone 21S"},
  {coordinate_system:"EPSG:32322", label: "EPSG:32322 / WGS 72 / UTM zone 22S"},
  {coordinate_system:"EPSG:32323", label: "EPSG:32323 / WGS 72 / UTM zone 23S"},
  {coordinate_system:"EPSG:32324", label: "EPSG:32324 / WGS 72 / UTM zone 24S"},
  {coordinate_system:"EPSG:32325", label: "EPSG:32325 / WGS 72 / UTM zone 25S"},
  {coordinate_system:"EPSG:32326", label: "EPSG:32326 / WGS 72 / UTM zone 26S"},
  {coordinate_system:"EPSG:32327", label: "EPSG:32327 / WGS 72 / UTM zone 27S"},
  {coordinate_system:"EPSG:32328", label: "EPSG:32328 / WGS 72 / UTM zone 28S"},
  {coordinate_system:"EPSG:32329", label: "EPSG:32329 / WGS 72 / UTM zone 29S"},
  {coordinate_system:"EPSG:32330", label: "EPSG:32330 / WGS 72 / UTM zone 30S"},
  {coordinate_system:"EPSG:32331", label: "EPSG:32331 / WGS 72 / UTM zone 31S"},
  {coordinate_system:"EPSG:32332", label: "EPSG:32332 / WGS 72 / UTM zone 32S"},
  {coordinate_system:"EPSG:32333", label: "EPSG:32333 / WGS 72 / UTM zone 33S"},
  {coordinate_system:"EPSG:32334", label: "EPSG:32334 / WGS 72 / UTM zone 34S"},
  {coordinate_system:"EPSG:32335", label: "EPSG:32335 / WGS 72 / UTM zone 35S"},
  {coordinate_system:"EPSG:32336", label: "EPSG:32336 / WGS 72 / UTM zone 36S"},
  {coordinate_system:"EPSG:32337", label: "EPSG:32337 / WGS 72 / UTM zone 37S"},
  {coordinate_system:"EPSG:32338", label: "EPSG:32338 / WGS 72 / UTM zone 38S"},
  {coordinate_system:"EPSG:32339", label: "EPSG:32339 / WGS 72 / UTM zone 39S"},
  {coordinate_system:"EPSG:32340", label: "EPSG:32340 / WGS 72 / UTM zone 40S"},
  {coordinate_system:"EPSG:32341", label: "EPSG:32341 / WGS 72 / UTM zone 41S"},
  {coordinate_system:"EPSG:32342", label: "EPSG:32342 / WGS 72 / UTM zone 42S"},
  {coordinate_system:"EPSG:32343", label: "EPSG:32343 / WGS 72 / UTM zone 43S"},
  {coordinate_system:"EPSG:32344", label: "EPSG:32344 / WGS 72 / UTM zone 44S"},
  {coordinate_system:"EPSG:32345", label: "EPSG:32345 / WGS 72 / UTM zone 45S"},
  {coordinate_system:"EPSG:32346", label: "EPSG:32346 / WGS 72 / UTM zone 46S"},
  {coordinate_system:"EPSG:32347", label: "EPSG:32347 / WGS 72 / UTM zone 47S"},
  {coordinate_system:"EPSG:32348", label: "EPSG:32348 / WGS 72 / UTM zone 48S"},
  {coordinate_system:"EPSG:32349", label: "EPSG:32349 / WGS 72 / UTM zone 49S"},
  {coordinate_system:"EPSG:32350", label: "EPSG:32350 / WGS 72 / UTM zone 50S"},
  {coordinate_system:"EPSG:32351", label: "EPSG:32351 / WGS 72 / UTM zone 51S"},
  {coordinate_system:"EPSG:32352", label: "EPSG:32352 / WGS 72 / UTM zone 52S"},
  {coordinate_system:"EPSG:32353", label: "EPSG:32353 / WGS 72 / UTM zone 53S"},
  {coordinate_system:"EPSG:32354", label: "EPSG:32354 / WGS 72 / UTM zone 54S"},
  {coordinate_system:"EPSG:32355", label: "EPSG:32355 / WGS 72 / UTM zone 55S"},
  {coordinate_system:"EPSG:32356", label: "EPSG:32356 / WGS 72 / UTM zone 56S"},
  {coordinate_system:"EPSG:32357", label: "EPSG:32357 / WGS 72 / UTM zone 57S"},
  {coordinate_system:"EPSG:32358", label: "EPSG:32358 / WGS 72 / UTM zone 58S"},
  {coordinate_system:"EPSG:32359", label: "EPSG:32359 / WGS 72 / UTM zone 59S"},
  {coordinate_system:"EPSG:32360", label: "EPSG:32360 / WGS 72 / UTM zone 60S"},
  {coordinate_system:"EPSG:32401", label: "EPSG:32401 / WGS 72BE / UTM zone 1N"},
  {coordinate_system:"EPSG:32402", label: "EPSG:32402 / WGS 72BE / UTM zone 2N"},
  {coordinate_system:"EPSG:32403", label: "EPSG:32403 / WGS 72BE / UTM zone 3N"},
  {coordinate_system:"EPSG:32404", label: "EPSG:32404 / WGS 72BE / UTM zone 4N"},
  {coordinate_system:"EPSG:32405", label: "EPSG:32405 / WGS 72BE / UTM zone 5N"},
  {coordinate_system:"EPSG:32406", label: "EPSG:32406 / WGS 72BE / UTM zone 6N"},
  {coordinate_system:"EPSG:32407", label: "EPSG:32407 / WGS 72BE / UTM zone 7N"},
  {coordinate_system:"EPSG:32408", label: "EPSG:32408 / WGS 72BE / UTM zone 8N"},
  {coordinate_system:"EPSG:32409", label: "EPSG:32409 / WGS 72BE / UTM zone 9N"},
  {coordinate_system:"EPSG:32410", label: "EPSG:32410 / WGS 72BE / UTM zone 10N"},
  {coordinate_system:"EPSG:32411", label: "EPSG:32411 / WGS 72BE / UTM zone 11N"},
  {coordinate_system:"EPSG:32412", label: "EPSG:32412 / WGS 72BE / UTM zone 12N"},
  {coordinate_system:"EPSG:32413", label: "EPSG:32413 / WGS 72BE / UTM zone 13N"},
  {coordinate_system:"EPSG:32414", label: "EPSG:32414 / WGS 72BE / UTM zone 14N"},
  {coordinate_system:"EPSG:32415", label: "EPSG:32415 / WGS 72BE / UTM zone 15N"},
  {coordinate_system:"EPSG:32416", label: "EPSG:32416 / WGS 72BE / UTM zone 16N"},
  {coordinate_system:"EPSG:32417", label: "EPSG:32417 / WGS 72BE / UTM zone 17N"},
  {coordinate_system:"EPSG:32418", label: "EPSG:32418 / WGS 72BE / UTM zone 18N"},
  {coordinate_system:"EPSG:32419", label: "EPSG:32419 / WGS 72BE / UTM zone 19N"},
  {coordinate_system:"EPSG:32420", label: "EPSG:32420 / WGS 72BE / UTM zone 20N"},
  {coordinate_system:"EPSG:32421", label: "EPSG:32421 / WGS 72BE / UTM zone 21N"},
  {coordinate_system:"EPSG:32422", label: "EPSG:32422 / WGS 72BE / UTM zone 22N"},
  {coordinate_system:"EPSG:32423", label: "EPSG:32423 / WGS 72BE / UTM zone 23N"},
  {coordinate_system:"EPSG:32424", label: "EPSG:32424 / WGS 72BE / UTM zone 24N"},
  {coordinate_system:"EPSG:32425", label: "EPSG:32425 / WGS 72BE / UTM zone 25N"},
  {coordinate_system:"EPSG:32426", label: "EPSG:32426 / WGS 72BE / UTM zone 26N"},
  {coordinate_system:"EPSG:32427", label: "EPSG:32427 / WGS 72BE / UTM zone 27N"},
  {coordinate_system:"EPSG:32428", label: "EPSG:32428 / WGS 72BE / UTM zone 28N"},
  {coordinate_system:"EPSG:32429", label: "EPSG:32429 / WGS 72BE / UTM zone 29N"},
  {coordinate_system:"EPSG:32430", label: "EPSG:32430 / WGS 72BE / UTM zone 30N"},
  {coordinate_system:"EPSG:32431", label: "EPSG:32431 / WGS 72BE / UTM zone 31N"},
  {coordinate_system:"EPSG:32432", label: "EPSG:32432 / WGS 72BE / UTM zone 32N"},
  {coordinate_system:"EPSG:32433", label: "EPSG:32433 / WGS 72BE / UTM zone 33N"},
  {coordinate_system:"EPSG:32434", label: "EPSG:32434 / WGS 72BE / UTM zone 34N"},
  {coordinate_system:"EPSG:32435", label: "EPSG:32435 / WGS 72BE / UTM zone 35N"},
  {coordinate_system:"EPSG:32436", label: "EPSG:32436 / WGS 72BE / UTM zone 36N"},
  {coordinate_system:"EPSG:32437", label: "EPSG:32437 / WGS 72BE / UTM zone 37N"},
  {coordinate_system:"EPSG:32438", label: "EPSG:32438 / WGS 72BE / UTM zone 38N"},
  {coordinate_system:"EPSG:32439", label: "EPSG:32439 / WGS 72BE / UTM zone 39N"},
  {coordinate_system:"EPSG:32440", label: "EPSG:32440 / WGS 72BE / UTM zone 40N"},
  {coordinate_system:"EPSG:32441", label: "EPSG:32441 / WGS 72BE / UTM zone 41N"},
  {coordinate_system:"EPSG:32442", label: "EPSG:32442 / WGS 72BE / UTM zone 42N"},
  {coordinate_system:"EPSG:32443", label: "EPSG:32443 / WGS 72BE / UTM zone 43N"},
  {coordinate_system:"EPSG:32444", label: "EPSG:32444 / WGS 72BE / UTM zone 44N"},
  {coordinate_system:"EPSG:32445", label: "EPSG:32445 / WGS 72BE / UTM zone 45N"},
  {coordinate_system:"EPSG:32446", label: "EPSG:32446 / WGS 72BE / UTM zone 46N"},
  {coordinate_system:"EPSG:32447", label: "EPSG:32447 / WGS 72BE / UTM zone 47N"},
  {coordinate_system:"EPSG:32448", label: "EPSG:32448 / WGS 72BE / UTM zone 48N"},
  {coordinate_system:"EPSG:32449", label: "EPSG:32449 / WGS 72BE / UTM zone 49N"},
  {coordinate_system:"EPSG:32450", label: "EPSG:32450 / WGS 72BE / UTM zone 50N"},
  {coordinate_system:"EPSG:32451", label: "EPSG:32451 / WGS 72BE / UTM zone 51N"},
  {coordinate_system:"EPSG:32452", label: "EPSG:32452 / WGS 72BE / UTM zone 52N"},
  {coordinate_system:"EPSG:32453", label: "EPSG:32453 / WGS 72BE / UTM zone 53N"},
  {coordinate_system:"EPSG:32454", label: "EPSG:32454 / WGS 72BE / UTM zone 54N"},
  {coordinate_system:"EPSG:32455", label: "EPSG:32455 / WGS 72BE / UTM zone 55N"},
  {coordinate_system:"EPSG:32456", label: "EPSG:32456 / WGS 72BE / UTM zone 56N"},
  {coordinate_system:"EPSG:32457", label: "EPSG:32457 / WGS 72BE / UTM zone 57N"},
  {coordinate_system:"EPSG:32458", label: "EPSG:32458 / WGS 72BE / UTM zone 58N"},
  {coordinate_system:"EPSG:32459", label: "EPSG:32459 / WGS 72BE / UTM zone 59N"},
  {coordinate_system:"EPSG:32460", label: "EPSG:32460 / WGS 72BE / UTM zone 60N"},
  {coordinate_system:"EPSG:32501", label: "EPSG:32501 / WGS 72BE / UTM zone 1S"},
  {coordinate_system:"EPSG:32502", label: "EPSG:32502 / WGS 72BE / UTM zone 2S"},
  {coordinate_system:"EPSG:32503", label: "EPSG:32503 / WGS 72BE / UTM zone 3S"},
  {coordinate_system:"EPSG:32504", label: "EPSG:32504 / WGS 72BE / UTM zone 4S"},
  {coordinate_system:"EPSG:32505", label: "EPSG:32505 / WGS 72BE / UTM zone 5S"},
  {coordinate_system:"EPSG:32506", label: "EPSG:32506 / WGS 72BE / UTM zone 6S"},
  {coordinate_system:"EPSG:32507", label: "EPSG:32507 / WGS 72BE / UTM zone 7S"},
  {coordinate_system:"EPSG:32508", label: "EPSG:32508 / WGS 72BE / UTM zone 8S"},
  {coordinate_system:"EPSG:32509", label: "EPSG:32509 / WGS 72BE / UTM zone 9S"},
  {coordinate_system:"EPSG:32510", label: "EPSG:32510 / WGS 72BE / UTM zone 10S"},
  {coordinate_system:"EPSG:32511", label: "EPSG:32511 / WGS 72BE / UTM zone 11S"},
  {coordinate_system:"EPSG:32512", label: "EPSG:32512 / WGS 72BE / UTM zone 12S"},
  {coordinate_system:"EPSG:32513", label: "EPSG:32513 / WGS 72BE / UTM zone 13S"},
  {coordinate_system:"EPSG:32514", label: "EPSG:32514 / WGS 72BE / UTM zone 14S"},
  {coordinate_system:"EPSG:32515", label: "EPSG:32515 / WGS 72BE / UTM zone 15S"},
  {coordinate_system:"EPSG:32516", label: "EPSG:32516 / WGS 72BE / UTM zone 16S"},
  {coordinate_system:"EPSG:32517", label: "EPSG:32517 / WGS 72BE / UTM zone 17S"},
  {coordinate_system:"EPSG:32518", label: "EPSG:32518 / WGS 72BE / UTM zone 18S"},
  {coordinate_system:"EPSG:32519", label: "EPSG:32519 / WGS 72BE / UTM zone 19S"},
  {coordinate_system:"EPSG:32520", label: "EPSG:32520 / WGS 72BE / UTM zone 20S"},
  {coordinate_system:"EPSG:32521", label: "EPSG:32521 / WGS 72BE / UTM zone 21S"},
  {coordinate_system:"EPSG:32522", label: "EPSG:32522 / WGS 72BE / UTM zone 22S"},
  {coordinate_system:"EPSG:32523", label: "EPSG:32523 / WGS 72BE / UTM zone 23S"},
  {coordinate_system:"EPSG:32524", label: "EPSG:32524 / WGS 72BE / UTM zone 24S"},
  {coordinate_system:"EPSG:32525", label: "EPSG:32525 / WGS 72BE / UTM zone 25S"},
  {coordinate_system:"EPSG:32526", label: "EPSG:32526 / WGS 72BE / UTM zone 26S"},
  {coordinate_system:"EPSG:32527", label: "EPSG:32527 / WGS 72BE / UTM zone 27S"},
  {coordinate_system:"EPSG:32528", label: "EPSG:32528 / WGS 72BE / UTM zone 28S"},
  {coordinate_system:"EPSG:32529", label: "EPSG:32529 / WGS 72BE / UTM zone 29S"},
  {coordinate_system:"EPSG:32530", label: "EPSG:32530 / WGS 72BE / UTM zone 30S"},
  {coordinate_system:"EPSG:32531", label: "EPSG:32531 / WGS 72BE / UTM zone 31S"},
  {coordinate_system:"EPSG:32532", label: "EPSG:32532 / WGS 72BE / UTM zone 32S"},
  {coordinate_system:"EPSG:32533", label: "EPSG:32533 / WGS 72BE / UTM zone 33S"},
  {coordinate_system:"EPSG:32534", label: "EPSG:32534 / WGS 72BE / UTM zone 34S"},
  {coordinate_system:"EPSG:32535", label: "EPSG:32535 / WGS 72BE / UTM zone 35S"},
  {coordinate_system:"EPSG:32536", label: "EPSG:32536 / WGS 72BE / UTM zone 36S"},
  {coordinate_system:"EPSG:32537", label: "EPSG:32537 / WGS 72BE / UTM zone 37S"},
  {coordinate_system:"EPSG:32538", label: "EPSG:32538 / WGS 72BE / UTM zone 38S"},
  {coordinate_system:"EPSG:32539", label: "EPSG:32539 / WGS 72BE / UTM zone 39S"},
  {coordinate_system:"EPSG:32540", label: "EPSG:32540 / WGS 72BE / UTM zone 40S"},
  {coordinate_system:"EPSG:32541", label: "EPSG:32541 / WGS 72BE / UTM zone 41S"},
  {coordinate_system:"EPSG:32542", label: "EPSG:32542 / WGS 72BE / UTM zone 42S"},
  {coordinate_system:"EPSG:32543", label: "EPSG:32543 / WGS 72BE / UTM zone 43S"},
  {coordinate_system:"EPSG:32544", label: "EPSG:32544 / WGS 72BE / UTM zone 44S"},
  {coordinate_system:"EPSG:32545", label: "EPSG:32545 / WGS 72BE / UTM zone 45S"},
  {coordinate_system:"EPSG:32546", label: "EPSG:32546 / WGS 72BE / UTM zone 46S"},
  {coordinate_system:"EPSG:32547", label: "EPSG:32547 / WGS 72BE / UTM zone 47S"},
  {coordinate_system:"EPSG:32548", label: "EPSG:32548 / WGS 72BE / UTM zone 48S"},
  {coordinate_system:"EPSG:32549", label: "EPSG:32549 / WGS 72BE / UTM zone 49S"},
  {coordinate_system:"EPSG:32550", label: "EPSG:32550 / WGS 72BE / UTM zone 50S"},
  {coordinate_system:"EPSG:32551", label: "EPSG:32551 / WGS 72BE / UTM zone 51S"},
  {coordinate_system:"EPSG:32552", label: "EPSG:32552 / WGS 72BE / UTM zone 52S"},
  {coordinate_system:"EPSG:32553", label: "EPSG:32553 / WGS 72BE / UTM zone 53S"},
  {coordinate_system:"EPSG:32554", label: "EPSG:32554 / WGS 72BE / UTM zone 54S"},
  {coordinate_system:"EPSG:32555", label: "EPSG:32555 / WGS 72BE / UTM zone 55S"},
  {coordinate_system:"EPSG:32556", label: "EPSG:32556 / WGS 72BE / UTM zone 56S"},
  {coordinate_system:"EPSG:32557", label: "EPSG:32557 / WGS 72BE / UTM zone 57S"},
  {coordinate_system:"EPSG:32558", label: "EPSG:32558 / WGS 72BE / UTM zone 58S"},
  {coordinate_system:"EPSG:32559", label: "EPSG:32559 / WGS 72BE / UTM zone 59S"},
  {coordinate_system:"EPSG:32560", label: "EPSG:32560 / WGS 72BE / UTM zone 60S"},
  {coordinate_system:"EPSG:32600", label: "EPSG:32600 / WGS 84 / UTM grid system (northern hemisphere)"},
  {coordinate_system:"EPSG:32601", label: "EPSG:32601 / WGS 84 / UTM zone 1N"},
  {coordinate_system:"EPSG:32602", label: "EPSG:32602 / WGS 84 / UTM zone 2N"},
  {coordinate_system:"EPSG:32603", label: "EPSG:32603 / WGS 84 / UTM zone 3N"},
  {coordinate_system:"EPSG:32604", label: "EPSG:32604 / WGS 84 / UTM zone 4N"},
  {coordinate_system:"EPSG:32605", label: "EPSG:32605 / WGS 84 / UTM zone 5N"},
  {coordinate_system:"EPSG:32606", label: "EPSG:32606 / WGS 84 / UTM zone 6N"},
  {coordinate_system:"EPSG:32607", label: "EPSG:32607 / WGS 84 / UTM zone 7N"},
  {coordinate_system:"EPSG:32608", label: "EPSG:32608 / WGS 84 / UTM zone 8N"},
  {coordinate_system:"EPSG:32609", label: "EPSG:32609 / WGS 84 / UTM zone 9N"},
  {coordinate_system:"EPSG:32610", label: "EPSG:32610 / WGS 84 / UTM zone 10N"},
  {coordinate_system:"EPSG:32611", label: "EPSG:32611 / WGS 84 / UTM zone 11N"},
  {coordinate_system:"EPSG:32612", label: "EPSG:32612 / WGS 84 / UTM zone 12N"},
  {coordinate_system:"EPSG:32613", label: "EPSG:32613 / WGS 84 / UTM zone 13N"},
  {coordinate_system:"EPSG:32614", label: "EPSG:32614 / WGS 84 / UTM zone 14N"},
  {coordinate_system:"EPSG:32615", label: "EPSG:32615 / WGS 84 / UTM zone 15N"},
  {coordinate_system:"EPSG:32616", label: "EPSG:32616 / WGS 84 / UTM zone 16N"},
  {coordinate_system:"EPSG:32617", label: "EPSG:32617 / WGS 84 / UTM zone 17N"},
  {coordinate_system:"EPSG:32618", label: "EPSG:32618 / WGS 84 / UTM zone 18N"},
  {coordinate_system:"EPSG:32619", label: "EPSG:32619 / WGS 84 / UTM zone 19N"},
  {coordinate_system:"EPSG:32620", label: "EPSG:32620 / WGS 84 / UTM zone 20N"},
  {coordinate_system:"EPSG:32621", label: "EPSG:32621 / WGS 84 / UTM zone 21N"},
  {coordinate_system:"EPSG:32622", label: "EPSG:32622 / WGS 84 / UTM zone 22N"},
  {coordinate_system:"EPSG:32623", label: "EPSG:32623 / WGS 84 / UTM zone 23N"},
  {coordinate_system:"EPSG:32624", label: "EPSG:32624 / WGS 84 / UTM zone 24N"},
  {coordinate_system:"EPSG:32625", label: "EPSG:32625 / WGS 84 / UTM zone 25N"},
  {coordinate_system:"EPSG:32626", label: "EPSG:32626 / WGS 84 / UTM zone 26N"},
  {coordinate_system:"EPSG:32627", label: "EPSG:32627 / WGS 84 / UTM zone 27N"},
  {coordinate_system:"EPSG:32628", label: "EPSG:32628 / WGS 84 / UTM zone 28N"},
  {coordinate_system:"EPSG:32629", label: "EPSG:32629 / WGS 84 / UTM zone 29N"},
  {coordinate_system:"EPSG:32630", label: "EPSG:32630 / WGS 84 / UTM zone 30N"},
  {coordinate_system:"EPSG:32631", label: "EPSG:32631 / WGS 84 / UTM zone 31N"},
  {coordinate_system:"EPSG:32632", label: "EPSG:32632 / WGS 84 / UTM zone 32N"},
  {coordinate_system:"EPSG:32633", label: "EPSG:32633 / WGS 84 / UTM zone 33N"},
  {coordinate_system:"EPSG:32634", label: "EPSG:32634 / WGS 84 / UTM zone 34N"},
  {coordinate_system:"EPSG:32635", label: "EPSG:32635 / WGS 84 / UTM zone 35N"},
  {coordinate_system:"EPSG:32636", label: "EPSG:32636 / WGS 84 / UTM zone 36N"},
  {coordinate_system:"EPSG:32637", label: "EPSG:32637 / WGS 84 / UTM zone 37N"},
  {coordinate_system:"EPSG:32638", label: "EPSG:32638 / WGS 84 / UTM zone 38N"},
  {coordinate_system:"EPSG:32639", label: "EPSG:32639 / WGS 84 / UTM zone 39N"},
  {coordinate_system:"EPSG:32640", label: "EPSG:32640 / WGS 84 / UTM zone 40N"},
  {coordinate_system:"EPSG:32641", label: "EPSG:32641 / WGS 84 / UTM zone 41N"},
  {coordinate_system:"EPSG:32642", label: "EPSG:32642 / WGS 84 / UTM zone 42N"},
  {coordinate_system:"EPSG:32643", label: "EPSG:32643 / WGS 84 / UTM zone 43N"},
  {coordinate_system:"EPSG:32644", label: "EPSG:32644 / WGS 84 / UTM zone 44N"},
  {coordinate_system:"EPSG:32645", label: "EPSG:32645 / WGS 84 / UTM zone 45N"},
  {coordinate_system:"EPSG:32646", label: "EPSG:32646 / WGS 84 / UTM zone 46N"},
  {coordinate_system:"EPSG:32647", label: "EPSG:32647 / WGS 84 / UTM zone 47N"},
  {coordinate_system:"EPSG:32648", label: "EPSG:32648 / WGS 84 / UTM zone 48N"},
  {coordinate_system:"EPSG:32649", label: "EPSG:32649 / WGS 84 / UTM zone 49N"},
  {coordinate_system:"EPSG:32650", label: "EPSG:32650 / WGS 84 / UTM zone 50N"},
  {coordinate_system:"EPSG:32651", label: "EPSG:32651 / WGS 84 / UTM zone 51N"},
  {coordinate_system:"EPSG:32652", label: "EPSG:32652 / WGS 84 / UTM zone 52N"},
  {coordinate_system:"EPSG:32653", label: "EPSG:32653 / WGS 84 / UTM zone 53N"},
  {coordinate_system:"EPSG:32654", label: "EPSG:32654 / WGS 84 / UTM zone 54N"},
  {coordinate_system:"EPSG:32655", label: "EPSG:32655 / WGS 84 / UTM zone 55N"},
  {coordinate_system:"EPSG:32656", label: "EPSG:32656 / WGS 84 / UTM zone 56N"},
  {coordinate_system:"EPSG:32657", label: "EPSG:32657 / WGS 84 / UTM zone 57N"},
  {coordinate_system:"EPSG:32658", label: "EPSG:32658 / WGS 84 / UTM zone 58N"},
  {coordinate_system:"EPSG:32659", label: "EPSG:32659 / WGS 84 / UTM zone 59N"},
  {coordinate_system:"EPSG:32660", label: "EPSG:32660 / WGS 84 / UTM zone 60N"},
  {coordinate_system:"EPSG:32661", label: "EPSG:32661 / WGS 84 / UPS North (N,E)"},
  {coordinate_system:"EPSG:32664", label: "EPSG:32664 / WGS 84 / BLM 14N (ftUS)"},
  {coordinate_system:"EPSG:32665", label: "EPSG:32665 / WGS 84 / BLM 15N (ftUS)"},
  {coordinate_system:"EPSG:32666", label: "EPSG:32666 / WGS 84 / BLM 16N (ftUS)"},
  {coordinate_system:"EPSG:32667", label: "EPSG:32667 / WGS 84 / BLM 17N (ftUS)"},
  {coordinate_system:"EPSG:32700", label: "EPSG:32700 / WGS 84 / UTM grid system (southern hemisphere)"},
  {coordinate_system:"EPSG:32701", label: "EPSG:32701 / WGS 84 / UTM zone 1S"},
  {coordinate_system:"EPSG:32702", label: "EPSG:32702 / WGS 84 / UTM zone 2S"},
  {coordinate_system:"EPSG:32703", label: "EPSG:32703 / WGS 84 / UTM zone 3S"},
  {coordinate_system:"EPSG:32704", label: "EPSG:32704 / WGS 84 / UTM zone 4S"},
  {coordinate_system:"EPSG:32705", label: "EPSG:32705 / WGS 84 / UTM zone 5S"},
  {coordinate_system:"EPSG:32706", label: "EPSG:32706 / WGS 84 / UTM zone 6S"},
  {coordinate_system:"EPSG:32707", label: "EPSG:32707 / WGS 84 / UTM zone 7S"},
  {coordinate_system:"EPSG:32708", label: "EPSG:32708 / WGS 84 / UTM zone 8S"},
  {coordinate_system:"EPSG:32709", label: "EPSG:32709 / WGS 84 / UTM zone 9S"},
  {coordinate_system:"EPSG:32710", label: "EPSG:32710 / WGS 84 / UTM zone 10S"},
  {coordinate_system:"EPSG:32711", label: "EPSG:32711 / WGS 84 / UTM zone 11S"},
  {coordinate_system:"EPSG:32712", label: "EPSG:32712 / WGS 84 / UTM zone 12S"},
  {coordinate_system:"EPSG:32713", label: "EPSG:32713 / WGS 84 / UTM zone 13S"},
  {coordinate_system:"EPSG:32714", label: "EPSG:32714 / WGS 84 / UTM zone 14S"},
  {coordinate_system:"EPSG:32715", label: "EPSG:32715 / WGS 84 / UTM zone 15S"},
  {coordinate_system:"EPSG:32716", label: "EPSG:32716 / WGS 84 / UTM zone 16S"},
  {coordinate_system:"EPSG:32717", label: "EPSG:32717 / WGS 84 / UTM zone 17S"},
  {coordinate_system:"EPSG:32718", label: "EPSG:32718 / WGS 84 / UTM zone 18S"},
  {coordinate_system:"EPSG:32719", label: "EPSG:32719 / WGS 84 / UTM zone 19S"},
  {coordinate_system:"EPSG:32720", label: "EPSG:32720 / WGS 84 / UTM zone 20S"},
  {coordinate_system:"EPSG:32721", label: "EPSG:32721 / WGS 84 / UTM zone 21S"},
  {coordinate_system:"EPSG:32722", label: "EPSG:32722 / WGS 84 / UTM zone 22S"},
  {coordinate_system:"EPSG:32723", label: "EPSG:32723 / WGS 84 / UTM zone 23S"},
  {coordinate_system:"EPSG:32724", label: "EPSG:32724 / WGS 84 / UTM zone 24S"},
  {coordinate_system:"EPSG:32725", label: "EPSG:32725 / WGS 84 / UTM zone 25S"},
  {coordinate_system:"EPSG:32726", label: "EPSG:32726 / WGS 84 / UTM zone 26S"},
  {coordinate_system:"EPSG:32727", label: "EPSG:32727 / WGS 84 / UTM zone 27S"},
  {coordinate_system:"EPSG:32728", label: "EPSG:32728 / WGS 84 / UTM zone 28S"},
  {coordinate_system:"EPSG:32729", label: "EPSG:32729 / WGS 84 / UTM zone 29S"},
  {coordinate_system:"EPSG:32730", label: "EPSG:32730 / WGS 84 / UTM zone 30S"},
  {coordinate_system:"EPSG:32731", label: "EPSG:32731 / WGS 84 / UTM zone 31S"},
  {coordinate_system:"EPSG:32732", label: "EPSG:32732 / WGS 84 / UTM zone 32S"},
  {coordinate_system:"EPSG:32733", label: "EPSG:32733 / WGS 84 / UTM zone 33S"},
  {coordinate_system:"EPSG:32734", label: "EPSG:32734 / WGS 84 / UTM zone 34S"},
  {coordinate_system:"EPSG:32735", label: "EPSG:32735 / WGS 84 / UTM zone 35S"},
  {coordinate_system:"EPSG:32736", label: "EPSG:32736 / WGS 84 / UTM zone 36S"},
  {coordinate_system:"EPSG:32737", label: "EPSG:32737 / WGS 84 / UTM zone 37S"},
  {coordinate_system:"EPSG:32738", label: "EPSG:32738 / WGS 84 / UTM zone 38S"},
  {coordinate_system:"EPSG:32739", label: "EPSG:32739 / WGS 84 / UTM zone 39S"},
  {coordinate_system:"EPSG:32740", label: "EPSG:32740 / WGS 84 / UTM zone 40S"},
  {coordinate_system:"EPSG:32741", label: "EPSG:32741 / WGS 84 / UTM zone 41S"},
  {coordinate_system:"EPSG:32742", label: "EPSG:32742 / WGS 84 / UTM zone 42S"},
  {coordinate_system:"EPSG:32743", label: "EPSG:32743 / WGS 84 / UTM zone 43S"},
  {coordinate_system:"EPSG:32744", label: "EPSG:32744 / WGS 84 / UTM zone 44S"},
  {coordinate_system:"EPSG:32745", label: "EPSG:32745 / WGS 84 / UTM zone 45S"},
  {coordinate_system:"EPSG:32746", label: "EPSG:32746 / WGS 84 / UTM zone 46S"},
  {coordinate_system:"EPSG:32747", label: "EPSG:32747 / WGS 84 / UTM zone 47S"},
  {coordinate_system:"EPSG:32748", label: "EPSG:32748 / WGS 84 / UTM zone 48S"},
  {coordinate_system:"EPSG:32749", label: "EPSG:32749 / WGS 84 / UTM zone 49S"},
  {coordinate_system:"EPSG:32750", label: "EPSG:32750 / WGS 84 / UTM zone 50S"},
  {coordinate_system:"EPSG:32751", label: "EPSG:32751 / WGS 84 / UTM zone 51S"},
  {coordinate_system:"EPSG:32752", label: "EPSG:32752 / WGS 84 / UTM zone 52S"},
  {coordinate_system:"EPSG:32753", label: "EPSG:32753 / WGS 84 / UTM zone 53S"},
  {coordinate_system:"EPSG:32754", label: "EPSG:32754 / WGS 84 / UTM zone 54S"},
  {coordinate_system:"EPSG:32755", label: "EPSG:32755 / WGS 84 / UTM zone 55S"},
  {coordinate_system:"EPSG:32756", label: "EPSG:32756 / WGS 84 / UTM zone 56S"},
  {coordinate_system:"EPSG:32757", label: "EPSG:32757 / WGS 84 / UTM zone 57S"},
  {coordinate_system:"EPSG:32758", label: "EPSG:32758 / WGS 84 / UTM zone 58S"},
  {coordinate_system:"EPSG:32759", label: "EPSG:32759 / WGS 84 / UTM zone 59S"},
  {coordinate_system:"EPSG:32760", label: "EPSG:32760 / WGS 84 / UTM zone 60S"},
  {coordinate_system:"EPSG:32761", label: "EPSG:32761 / WGS 84 / UPS South (N,E)"},
  {coordinate_system:"EPSG:32766", label: "EPSG:32766 / WGS 84 / TM 36 SE"},
  {coordinate_system:"CZM:7405", label: "7405 / OSGB36 (with EPSG 1314 Helmert transform) / British National Grid + OSGM15 height"},
  {coordinate_system:"CZM:5513Plus5705", label: "5513Plus5705 / S - JTSK Krakov with Baltic Heights"},
  {coordinate_system:"CZM:5514Plus5705", label: "5514Plus5705 / S - JTSK Krakov Inversed with Baltic Heights"},
  {coordinate_system:"CZM:HS215", label: "HS215 / HS2TN15 + HS2GM15"},
  {coordinate_system:"EPSG:3901", label: "EPSG:3901 / KKJ / Finland Uniform Coordinate System + N60 height"},
  {coordinate_system:"EPSG:3902", label: "EPSG:3902 / ETRS89 / TM35FIN(N,E) + N60 height"},
  {coordinate_system:"EPSG:3903", label: "EPSG:3903 / ETRS89 / TM35FIN(N,E) + N2000 height"},
  {coordinate_system:"EPSG:4097", label: "EPSG:4097 / ETRS89 / DKTM1 + DVR90 height"},
  {coordinate_system:"EPSG:4098", label: "EPSG:4098 / ETRS89 / DKTM2 + DVR90 height"},
  {coordinate_system:"EPSG:4099", label: "EPSG:4099 / ETRS89 / DKTM3 + DVR90 height"},
  {coordinate_system:"EPSG:4100", label: "EPSG:4100 / ETRS89 / DKTM4 + DVR90 height"},
  {coordinate_system:"EPSG:5318", label: "EPSG:5318 / ETRS89 / Faroe TM + FVR09 height"},
  {coordinate_system:"EPSG:5498", label: "EPSG:5498 / NAD83 + NAVD88 height"},
  {coordinate_system:"EPSG:5499", label: "EPSG:5499 / NAD83(HARN) + NAVD88 height"},
  {coordinate_system:"EPSG:5500", label: "EPSG:5500 / NAD83(NSRS2007) + NAVD88 height"},
  {coordinate_system:"EPSG:5554", label: "EPSG:5554 / ETRS89 / UTM zone 31N + DHHN92 height"},
  {coordinate_system:"EPSG:5555", label: "EPSG:5555 / ETRS89 / UTM zone 32N + DHHN92 height"},
  {coordinate_system:"EPSG:5556", label: "EPSG:5556 / ETRS89 / UTM zone 33N + DHHN92 height"},
  {coordinate_system:"EPSG:5598", label: "EPSG:5598 / FEH2010 / Fehmarnbelt TM + FCSVR10 height"},
  {coordinate_system:"EPSG:5628", label: "EPSG:5628 / SWEREF99 + RH2000 height"},
  {coordinate_system:"EPSG:5698", label: "EPSG:5698 / RGF93 v1 / Lambert-93 + NGF-IGN69 height"},
  {coordinate_system:"EPSG:5699", label: "EPSG:5699 / RGF93 v1 / Lambert-93 + NGF-IGN78 height"},
  {coordinate_system:"EPSG:5707", label: "EPSG:5707 / NTF (Paris) / Lambert zone I + NGF-IGN69 height"},
  {coordinate_system:"EPSG:5708", label: "EPSG:5708 / NTF (Paris) / Lambert zone IV + NGF-IGN78 height"},
  {coordinate_system:"EPSG:5845", label: "EPSG:5845 / SWEREF99 TM + RH2000 height"},
  {coordinate_system:"EPSG:5846", label: "EPSG:5846 / SWEREF99 12 00 + RH2000 height"},
  {coordinate_system:"EPSG:5847", label: "EPSG:5847 / SWEREF99 13 30 + RH2000 height"},
  {coordinate_system:"EPSG:5848", label: "EPSG:5848 / SWEREF99 15 00 + RH2000 height"},
  {coordinate_system:"EPSG:5849", label: "EPSG:5849 / SWEREF99 16 30 + RH2000 height"},
  {coordinate_system:"EPSG:5850", label: "EPSG:5850 / SWEREF99 18 00 + RH2000 height"},
  {coordinate_system:"EPSG:5851", label: "EPSG:5851 / SWEREF99 14 15 + RH2000 height"},
  {coordinate_system:"EPSG:5852", label: "EPSG:5852 / SWEREF99 15 45 + RH2000 height"},
  {coordinate_system:"EPSG:5853", label: "EPSG:5853 / SWEREF99 17 15 + RH2000 height"},
  {coordinate_system:"EPSG:5854", label: "EPSG:5854 / SWEREF99 18 45 + RH2000 height"},
  {coordinate_system:"EPSG:5855", label: "EPSG:5855 / SWEREF99 20 15 + RH2000 height"},
  {coordinate_system:"EPSG:5856", label: "EPSG:5856 / SWEREF99 21 45 + RH2000 height"},
  {coordinate_system:"EPSG:5857", label: "EPSG:5857 / SWEREF99 23 15 + RH2000 height"},
  {coordinate_system:"EPSG:5942", label: "EPSG:5942 / ETRS89 + NN2000 height"},
  {coordinate_system:"EPSG:5945", label: "EPSG:5945 / ETRS89 / NTM zone 5 + NN2000 height"},
  {coordinate_system:"EPSG:5946", label: "EPSG:5946 / ETRS89 / NTM zone 6 + NN2000 height"},
  {coordinate_system:"EPSG:5947", label: "EPSG:5947 / ETRS89 / NTM zone 7 + NN2000 height"},
  {coordinate_system:"EPSG:5948", label: "EPSG:5948 / ETRS89 / NTM zone 8 + NN2000 height"},
  {coordinate_system:"EPSG:5949", label: "EPSG:5949 / ETRS89 / NTM zone 9 + NN2000 height"},
  {coordinate_system:"EPSG:5950", label: "EPSG:5950 / ETRS89 / NTM zone 10 + NN2000 height"},
  {coordinate_system:"EPSG:5951", label: "EPSG:5951 / ETRS89 / NTM zone 11 + NN2000 height"},
  {coordinate_system:"EPSG:5952", label: "EPSG:5952 / ETRS89 / NTM zone 12 + NN2000 height"},
  {coordinate_system:"EPSG:5953", label: "EPSG:5953 / ETRS89 / NTM zone 13 + NN2000 height"},
  {coordinate_system:"EPSG:5954", label: "EPSG:5954 / ETRS89 / NTM zone 14 + NN2000 height"},
  {coordinate_system:"EPSG:5955", label: "EPSG:5955 / ETRS89 / NTM zone 15 + NN2000 height"},
  {coordinate_system:"EPSG:5956", label: "EPSG:5956 / ETRS89 / NTM zone 16 + NN2000 height"},
  {coordinate_system:"EPSG:5957", label: "EPSG:5957 / ETRS89 / NTM zone 17 + NN2000 height"},
  {coordinate_system:"EPSG:5958", label: "EPSG:5958 / ETRS89 / NTM zone 18 + NN2000 height"},
  {coordinate_system:"EPSG:5959", label: "EPSG:5959 / ETRS89 / NTM zone 19 + NN2000 height"},
  {coordinate_system:"EPSG:5960", label: "EPSG:5960 / ETRS89 / NTM zone 20 + NN2000 height"},
  {coordinate_system:"EPSG:5961", label: "EPSG:5961 / ETRS89 / NTM zone 21 + NN2000 height"},
  {coordinate_system:"EPSG:5962", label: "EPSG:5962 / ETRS89 / NTM zone 22 + NN2000 height"},
  {coordinate_system:"EPSG:5963", label: "EPSG:5963 / ETRS89 / NTM zone 23 + NN2000 height"},
  {coordinate_system:"EPSG:5964", label: "EPSG:5964 / ETRS89 / NTM zone 24 + NN2000 height"},
  {coordinate_system:"EPSG:5965", label: "EPSG:5965 / ETRS89 / NTM zone 25 + NN2000 height"},
  {coordinate_system:"EPSG:5966", label: "EPSG:5966 / ETRS89 / NTM zone 26 + NN2000 height"},
  {coordinate_system:"EPSG:5967", label: "EPSG:5967 / ETRS89 / NTM zone 27 + NN2000 height"},
  {coordinate_system:"EPSG:5968", label: "EPSG:5968 / ETRS89 / NTM zone 28 + NN2000 height"},
  {coordinate_system:"EPSG:5969", label: "EPSG:5969 / ETRS89 / NTM zone 29 + NN2000 height"},
  {coordinate_system:"EPSG:5970", label: "EPSG:5970 / ETRS89 / NTM zone 30 + NN2000 height"},
  {coordinate_system:"EPSG:5971", label: "EPSG:5971 / ETRS89 / UTM zone 31N + NN2000 height"},
  {coordinate_system:"EPSG:5972", label: "EPSG:5972 / ETRS89 / UTM zone 32N + NN2000 height"},
  {coordinate_system:"EPSG:5973", label: "EPSG:5973 / ETRS89 / UTM zone 33N + NN2000 height"},
  {coordinate_system:"EPSG:5974", label: "EPSG:5974 / ETRS89 / UTM zone 34N + NN2000 height"},
  {coordinate_system:"EPSG:5975", label: "EPSG:5975 / ETRS89 / UTM zone 35N + NN2000 height"},
  {coordinate_system:"EPSG:5976", label: "EPSG:5976 / ETRS89 / UTM zone 36N + NN2000 height"},
  {coordinate_system:"EPSG:6144", label: "EPSG:6144 / ETRS89 + NN54 height"},
  {coordinate_system:"EPSG:6145", label: "EPSG:6145 / ETRS89 / NTM zone 5 + NN54 height"},
  {coordinate_system:"EPSG:6146", label: "EPSG:6146 / ETRS89 / NTM zone 6 + NN54 height"},
  {coordinate_system:"EPSG:6147", label: "EPSG:6147 / ETRS89 / NTM zone 7 + NN54 height"},
  {coordinate_system:"EPSG:6148", label: "EPSG:6148 / ETRS89 / NTM zone 8 + NN54 height"},
  {coordinate_system:"EPSG:6149", label: "EPSG:6149 / ETRS89 / NTM zone 9 + NN54 height"},
  {coordinate_system:"EPSG:6150", label: "EPSG:6150 / ETRS89 / NTM zone 10 + NN54 height"},
  {coordinate_system:"EPSG:6151", label: "EPSG:6151 / ETRS89 / NTM zone 11 + NN54 height"},
  {coordinate_system:"EPSG:6152", label: "EPSG:6152 / ETRS89 / NTM zone 12 + NN54 height"},
  {coordinate_system:"EPSG:6153", label: "EPSG:6153 / ETRS89 / NTM zone 13 + NN54 height"},
  {coordinate_system:"EPSG:6154", label: "EPSG:6154 / ETRS89 / NTM zone 14 + NN54 height"},
  {coordinate_system:"EPSG:6155", label: "EPSG:6155 / ETRS89 / NTM zone 15 + NN54 height"},
  {coordinate_system:"EPSG:6156", label: "EPSG:6156 / ETRS89 / NTM zone 16 + NN54 height"},
  {coordinate_system:"EPSG:6157", label: "EPSG:6157 / ETRS89 / NTM zone 17 + NN54 height"},
  {coordinate_system:"EPSG:6158", label: "EPSG:6158 / ETRS89 / NTM zone 18 + NN54 height"},
  {coordinate_system:"EPSG:6159", label: "EPSG:6159 / ETRS89 / NTM zone 19 + NN54 height"},
  {coordinate_system:"EPSG:6160", label: "EPSG:6160 / ETRS89 / NTM zone 20 + NN54 height"},
  {coordinate_system:"EPSG:6161", label: "EPSG:6161 / ETRS89 / NTM zone 21 + NN54 height"},
  {coordinate_system:"EPSG:6162", label: "EPSG:6162 / ETRS89 / NTM zone 22 + NN54 height"},
  {coordinate_system:"EPSG:6163", label: "EPSG:6163 / ETRS89 / NTM zone 23 + NN54 height"},
  {coordinate_system:"EPSG:6164", label: "EPSG:6164 / ETRS89 / NTM zone 24 + NN54 height"},
  {coordinate_system:"EPSG:6165", label: "EPSG:6165 / ETRS89 / NTM zone 25 + NN54 height"},
  {coordinate_system:"EPSG:6166", label: "EPSG:6166 / ETRS89 / NTM zone 26 + NN54 height"},
  {coordinate_system:"EPSG:6167", label: "EPSG:6167 / ETRS89 / NTM zone 27 + NN54 height"},
  {coordinate_system:"EPSG:6168", label: "EPSG:6168 / ETRS89 / NTM zone 28 + NN54 height"},
  {coordinate_system:"EPSG:6169", label: "EPSG:6169 / ETRS89 / NTM zone 29 + NN54 height"},
  {coordinate_system:"EPSG:6170", label: "EPSG:6170 / ETRS89 / NTM zone 30 + NN54 height"},
  {coordinate_system:"EPSG:6171", label: "EPSG:6171 / ETRS89 / UTM zone 31N + NN54 height"},
  {coordinate_system:"EPSG:6172", label: "EPSG:6172 / ETRS89 / UTM zone 32N + NN54 height"},
  {coordinate_system:"EPSG:6173", label: "EPSG:6173 / ETRS89 / UTM zone 33N + NN54 height"},
  {coordinate_system:"EPSG:6174", label: "EPSG:6174 / ETRS89 / UTM zone 34N + NN54 height"},
  {coordinate_system:"EPSG:6175", label: "EPSG:6175 / ETRS89 / UTM zone 35N + NN54 height"},
  {coordinate_system:"EPSG:6176", label: "EPSG:6176 / ETRS89 / UTM zone 36N + NN54 height"},
  {coordinate_system:"EPSG:6190", label: "EPSG:6190 / BD72 / Belgian Lambert 72 + Ostend height"},
  {coordinate_system:"EPSG:6349", label: "EPSG:6349 / NAD83(2011) + NAVD88 height"},
  {coordinate_system:"EPSG:6649", label: "EPSG:6649 / NAD83(CSRS) + CGVD2013(CGG2013) height"},
  {coordinate_system:"EPSG:6650", label: "EPSG:6650 / NAD83(CSRS) / UTM zone 7N + CGVD2013 height"},
  {coordinate_system:"EPSG:6651", label: "EPSG:6651 / NAD83(CSRS) / UTM zone 8N + CGVD2013 height"},
  {coordinate_system:"EPSG:6652", label: "EPSG:6652 / NAD83(CSRS) / UTM zone 9N + CGVD2013 height"},
  {coordinate_system:"EPSG:6653", label: "EPSG:6653 / NAD83(CSRS) / UTM zone 10N + CGVD2013 height"},
  {coordinate_system:"EPSG:6654", label: "EPSG:6654 / NAD83(CSRS) / UTM zone 11N + CGVD2013 height"},
  {coordinate_system:"EPSG:6655", label: "EPSG:6655 / NAD83(CSRS) / UTM zone 12N + CGVD2013 height"},
  {coordinate_system:"EPSG:6656", label: "EPSG:6656 / NAD83(CSRS) / UTM zone 13N + CGVD2013 height"},
  {coordinate_system:"EPSG:6657", label: "EPSG:6657 / NAD83(CSRS) / UTM zone 14N + CGVD2013 height"},
  {coordinate_system:"EPSG:6658", label: "EPSG:6658 / NAD83(CSRS) / UTM zone 15N + CGVD2013 height"},
  {coordinate_system:"EPSG:6659", label: "EPSG:6659 / NAD83(CSRS) / UTM zone 16N + CGVD2013 height"},
  {coordinate_system:"EPSG:6660", label: "EPSG:6660 / NAD83(CSRS) / UTM zone 17N + CGVD2013 height"},
  {coordinate_system:"EPSG:6661", label: "EPSG:6661 / NAD83(CSRS) / UTM zone 18N + CGVD2013 height"},
  {coordinate_system:"EPSG:6662", label: "EPSG:6662 / NAD83(CSRS) / UTM zone 19N + CGVD2013 height"},
  {coordinate_system:"EPSG:6663", label: "EPSG:6663 / NAD83(CSRS) / UTM zone 20N + CGVD2013 height"},
  {coordinate_system:"EPSG:6664", label: "EPSG:6664 / NAD83(CSRS) / UTM zone 21N + CGVD2013 height"},
  {coordinate_system:"EPSG:6665", label: "EPSG:6665 / NAD83(CSRS) / UTM zone 22N + CGVD2013 height"},
  {coordinate_system:"EPSG:6696", label: "EPSG:6696 / JGD2000 + JGD2000 (vertical) height"},
  {coordinate_system:"EPSG:6697", label: "EPSG:6697 / JGD2011 + JGD2011 (vertical) height"},
  {coordinate_system:"EPSG:6700", label: "EPSG:6700 / Tokyo + JSLD72 height"},
  {coordinate_system:"EPSG:6893", label: "EPSG:6893 / WGS 84 / World Mercator + EGM2008 height"},
  {coordinate_system:"EPSG:6917", label: "EPSG:6917 / SVY21 + SHD height"},
  {coordinate_system:"EPSG:6927", label: "EPSG:6927 / SVY21 / Singapore TM + SHD height"},
  {coordinate_system:"EPSG:7400", label: "EPSG:7400 / NTF (Paris) + NGF IGN69 height"},
  {coordinate_system:"EPSG:7404", label: "EPSG:7404 / RT90 + RH70 height"},
  {coordinate_system:"EPSG:7405", label: "EPSG:7405 / OSGB36 / British National Grid + ODN height"},
  {coordinate_system:"EPSG:7406", label: "EPSG:7406 / NAD27 + NGVD29 height (ftUS)"},
  {coordinate_system:"EPSG:7407", label: "EPSG:7407 / NAD27 / Texas North + NGVD29 height (ftUS)"},
  {coordinate_system:"EPSG:7409", label: "EPSG:7409 / ETRS89 + EVRF2000 height"},
  {coordinate_system:"EPSG:7410", label: "EPSG:7410 / PSHD93"},
  {coordinate_system:"EPSG:7411", label: "EPSG:7411 / NTF (Paris) / Lambert zone II + NGF Lallemand height"},
  {coordinate_system:"EPSG:7414", label: "EPSG:7414 / Tokyo + JSLD69 height"},
  {coordinate_system:"EPSG:7415", label: "EPSG:7415 / Amersfoort / RD New + NAP height"},
  {coordinate_system:"EPSG:7416", label: "EPSG:7416 / ETRS89 / UTM zone 32N + DVR90 height"},
  {coordinate_system:"EPSG:7417", label: "EPSG:7417 / ETRS89 / UTM zone 33N + DVR90 height"},
  {coordinate_system:"EPSG:7418", label: "EPSG:7418 / ETRS89 / Kp2000 Jutland + DVR90 height"},
  {coordinate_system:"EPSG:7419", label: "EPSG:7419 / ETRS89 / Kp2000 Zealand + DVR90 height"},
  {coordinate_system:"EPSG:7420", label: "EPSG:7420 / ETRS89 / Kp2000 Bornholm + DVR90 height"},
  {coordinate_system:"EPSG:7421", label: "EPSG:7421 / NTF (Paris) / Lambert zone II + NGF-IGN69 height"},
  {coordinate_system:"EPSG:7422", label: "EPSG:7422 / NTF (Paris) / Lambert zone III + NGF-IGN69 height"},
  {coordinate_system:"EPSG:7423", label: "EPSG:7423 / ETRS89 + EVRF2007 height"},
  {coordinate_system:"EPSG:7952", label: "EPSG:7952 / EPSG:7952 / OSTN02 + OSGM02 Height"},
  {coordinate_system:"EPSG:27700", label: "EPSG:7953 / EPSG:7953 / OSTN15 + OSGM15 Height"},
  {coordinate_system:"EPSG:7954", label: "EPSG:7954 / Astro DOS 71 / UTM zone 30S + Jamestown 1971 height"},
  {coordinate_system:"EPSG:7955", label: "EPSG:7955 / St. Helena Tritan / UTM zone 30S + Tritan 2011 height"},
  {coordinate_system:"EPSG:7956", label: "EPSG:7956 / SHMG2015 + SHVD2015 height"},
  {coordinate_system:"EPSG:8349", label: "EPSG:8349 / GR96 + GVR2000 height"},
  {coordinate_system:"EPSG:8350", label: "EPSG:8350 / GR96 + GVR2016 height"},
  {coordinate_system:"EPSG:8360", label: "EPSG:8360 / ETRS89 + Baltic 1957 height"},
  {coordinate_system:"EPSG:8370", label: "EPSG:8370 / ETRS89 / Belgian Lambert 2008 + Ostend height"},
  {coordinate_system:"EPSG:8700", label: "EPSG:8700 / NAD83 / Arizona East (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8701", label: "EPSG:8701 / NAD83 / Arizona Central (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8702", label: "EPSG:8702 / NAD83 / Arizona West (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8703", label: "EPSG:8703 / NAD83 / Michigan North (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8704", label: "EPSG:8704 / NAD83 / Michigan Central (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8705", label: "EPSG:8705 / NAD83 / Michigan South (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8706", label: "EPSG:8706 / NAD83 / Montana (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8707", label: "EPSG:8707 / NAD83 / North Dakota North (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8708", label: "EPSG:8708 / NAD83 / North Dakota South (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8709", label: "EPSG:8709 / NAD83 / Oregon North (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8710", label: "EPSG:8710 / NAD83 / Oregon South (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8711", label: "EPSG:8711 / NAD83 / South Carolina (ft) + NAVD88 height (ft)"},
  {coordinate_system:"EPSG:8712", label: "EPSG:8712 / NAD83 / Arkansas North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8713", label: "EPSG:8713 / NAD83 / Arkansas South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8714", label: "EPSG:8714 / NAD83 / California zone 1 (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8715", label: "EPSG:8715 / NAD83 / California zone 2 (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8716", label: "EPSG:8716 / NAD83 / California zone 3 (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8717", label: "EPSG:8717 / NAD83 / California zone 4 (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8718", label: "EPSG:8718 / NAD83 / California zone 5 (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8719", label: "EPSG:8719 / NAD83 / California zone 6 (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8720", label: "EPSG:8720 / NAD83 / Colorado North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8721", label: "EPSG:8721 / NAD83 / Colorado Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8722", label: "EPSG:8722 / NAD83 / Colorado South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8723", label: "EPSG:8723 / NAD83 / Connecticut (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8724", label: "EPSG:8724 / NAD83 / Delaware (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8725", label: "EPSG:8725 / NAD83 / Florida North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8726", label: "EPSG:8726 / NAD83 / Florida East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8727", label: "EPSG:8727 / NAD83 / Florida West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8728", label: "EPSG:8728 / NAD83 / Georgia East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8729", label: "EPSG:8729 / NAD83 / Georgia West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8730", label: "EPSG:8730 / NAD83 / Idaho East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8731", label: "EPSG:8731 / NAD83 / Idaho Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8732", label: "EPSG:8732 / NAD83 / Idaho West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8733", label: "EPSG:8733 / NAD83 / Illinois East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8734", label: "EPSG:8734 / NAD83 / Illinois West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8735", label: "EPSG:8735 / NAD83 / Indiana East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8736", label: "EPSG:8736 / NAD83 / Indiana West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8737", label: "EPSG:8737 / NAD83 / Iowa North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8738", label: "EPSG:8738 / NAD83 / Iowa South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8739", label: "EPSG:8739 / NAD83 / Kansas North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8740", label: "EPSG:8740 / NAD83 / Kansas South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8741", label: "EPSG:8741 / NAD83 / Kentucky North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8742", label: "EPSG:8742 / NAD83 / Kentucky South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8743", label: "EPSG:8743 / NAD83 / Louisiana North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8744", label: "EPSG:8744 / NAD83 / Louisiana South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8745", label: "EPSG:8745 / NAD83 / Maine East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8746", label: "EPSG:8746 / NAD83 / Maine West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8747", label: "EPSG:8747 / NAD83 / Maryland (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8748", label: "EPSG:8748 / NAD83 / Massachusetts Mainland (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8749", label: "EPSG:8749 / NAD83 / Massachusetts Island (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8750", label: "EPSG:8750 / NAD83 / Minnesota North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8751", label: "EPSG:8751 / NAD83 / Minnesota Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8752", label: "EPSG:8752 / NAD83 / Minnesota South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8753", label: "EPSG:8753 / NAD83 / Mississippi East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8754", label: "EPSG:8754 / NAD83 / Mississippi West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8755", label: "EPSG:8755 / NAD83 / Nebraska (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8756", label: "EPSG:8756 / NAD83 / Nevada East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8757", label: "EPSG:8757 / NAD83 / Nevada Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8758", label: "EPSG:8758 / NAD83 / Nevada West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8759", label: "EPSG:8759 / NAD83 / New Hampshire (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8760", label: "EPSG:8760 / NAD83 / New Jersey (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8761", label: "EPSG:8761 / NAD83 / New Mexico East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8762", label: "EPSG:8762 / NAD83 / New Mexico Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8763", label: "EPSG:8763 / NAD83 / New Mexico West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8764", label: "EPSG:8764 / NAD83 / New York East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8765", label: "EPSG:8765 / NAD83 / New York Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8766", label: "EPSG:8766 / NAD83 / New York West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8767", label: "EPSG:8767 / NAD83 / New York Long Island (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8768", label: "EPSG:8768 / NAD83 / North Carolina (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8769", label: "EPSG:8769 / NAD83 / Ohio North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8770", label: "EPSG:8770 / NAD83 / Ohio South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8771", label: "EPSG:8771 / NAD83 / Oklahoma North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8772", label: "EPSG:8772 / NAD83 / Oklahoma South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8773", label: "EPSG:8773 / NAD83 / Pennsylvania North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8774", label: "EPSG:8774 / NAD83 / Pennsylvania South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8775", label: "EPSG:8775 / NAD83 / Rhode Island (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8776", label: "EPSG:8776 / NAD83 / South Dakota North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8777", label: "EPSG:8777 / NAD83 / South Dakota South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8778", label: "EPSG:8778 / NAD83 / Tennessee (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8779", label: "EPSG:8779 / NAD83 / Texas North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8780", label: "EPSG:8780 / NAD83 / Texas North Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8781", label: "EPSG:8781 / NAD83 / Texas Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8782", label: "EPSG:8782 / NAD83 / Texas South Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8783", label: "EPSG:8783 / NAD83 / Texas South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8784", label: "EPSG:8784 / NAD83 / Utah North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8785", label: "EPSG:8785 / NAD83 / Utah Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8786", label: "EPSG:8786 / NAD83 / Utah South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8787", label: "EPSG:8787 / NAD83 / Vermont (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8788", label: "EPSG:8788 / NAD83 / Virginia North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8789", label: "EPSG:8789 / NAD83 / Virginia South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8790", label: "EPSG:8790 / NAD83 / Washington North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8791", label: "EPSG:8791 / NAD83 / Washington South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8792", label: "EPSG:8792 / NAD83 / West Virginia North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8793", label: "EPSG:8793 / NAD83 / West Virginia South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8794", label: "EPSG:8794 / NAD83 / Wisconsin North (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8795", label: "EPSG:8795 / NAD83 / Wisconsin Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8796", label: "EPSG:8796 / NAD83 / Wisconsin South (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8797", label: "EPSG:8797 / NAD83 / Wyoming East (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8798", label: "EPSG:8798 / NAD83 / Wyoming East Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8799", label: "EPSG:8799 / NAD83 / Wyoming West Central (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8800", label: "EPSG:8800 / NAD83 / Wyoming West (ftUS) + NAVD88 height (ftUS)"},
  {coordinate_system:"EPSG:8801", label: "EPSG:8801 / NAD83 / Alabama East + NAVD88 height"},
  {coordinate_system:"EPSG:8802", label: "EPSG:8802 / NAD83 / Alabama West + NAVD88 height"},
  {coordinate_system:"EPSG:8803", label: "EPSG:8803 / NAD83 / Alaska zone 1 + NAVD88 height"},
  {coordinate_system:"EPSG:8804", label: "EPSG:8804 / NAD83 / Alaska zone 2 + NAVD88 height"},
  {coordinate_system:"EPSG:8805", label: "EPSG:8805 / NAD83 / Alaska zone 3 + NAVD88 height"},
  {coordinate_system:"EPSG:8806", label: "EPSG:8806 / NAD83 / Alaska zone 4 + NAVD88 height"},
  {coordinate_system:"EPSG:8807", label: "EPSG:8807 / NAD83 / Alaska zone 5 + NAVD88 height"},
  {coordinate_system:"EPSG:8808", label: "EPSG:8808 / NAD83 / Alaska zone 6 + NAVD88 height"},
  {coordinate_system:"EPSG:8809", label: "EPSG:8809 / NAD83 / Alaska zone 7 + NAVD88 height"},
  {coordinate_system:"EPSG:8810", label: "EPSG:8810 / NAD83 / Alaska zone 8 + NAVD88 height"},
  {coordinate_system:"EPSG:8811", label: "EPSG:8811 / NAD83 / Alaska zone 9 + NAVD88 height"},
  {coordinate_system:"EPSG:8812", label: "EPSG:8812 / NAD83 / Alaska zone 10 + NAVD88 height"},
  {coordinate_system:"EPSG:8813", label: "EPSG:8813 / NAD83 / Missouri East + NAVD88 height"},
  {coordinate_system:"EPSG:8814", label: "EPSG:8814 / NAD83 / Missouri Central + NAVD88 height"},
  {coordinate_system:"EPSG:8815", label: "EPSG:8815 / NAD83 / Missouri West + NAVD88 height"},
  {coordinate_system:"EPSG:8912", label: "EPSG:8912 / CR-SIRGAS / CRTM05 + DACR52 height"},
  {coordinate_system:"EPSG:9286", label: "EPSG:9286 / ETRS89 + NAP height"},
  {coordinate_system:"EPSG:9289", label: "EPSG:9289 / ETRS89 + LAT NL depth"},
  {coordinate_system:"EPSG:9290", label: "EPSG:9290 / ETRS89 + MSL NL depth"},
  {coordinate_system:"EPSG:9306", label: "EPSG:9306 / HS2 Survey Grid + HS2-VRF height"},
  {coordinate_system:"EPSG:9368", label: "EPSG:9368 / TPEN11 Grid + ODN height"},
  {coordinate_system:"EPSG:9374", label: "EPSG:9374 / MML07 Grid + ODN height"},
  {coordinate_system:"EPSG:9388", label: "EPSG:9388 / AbInvA96_2020 Grid + ODN height"},
  {coordinate_system:"EPSG:9422", label: "EPSG:9422 / ETRS89 + EVRF2019 height"},
  {coordinate_system:"EPSG:9423", label: "EPSG:9423 / ETRS89 + EVRF2019 mean-tide height"},
  {coordinate_system:"EPSG:9424", label: "EPSG:9424 / ETRS89 + ODN height"},
  {coordinate_system:"EPSG:9425", label: "EPSG:9425 / ETRS89 + ODN (Offshore) height"},
  {coordinate_system:"EPSG:9426", label: "EPSG:9426 / ETRS89 + ODN Orkney height"},
  {coordinate_system:"EPSG:9427", label: "EPSG:9427 / ETRS89 + Lerwick height"},
  {coordinate_system:"EPSG:9428", label: "EPSG:9428 / ETRS89 + Stornoway height"},
  {coordinate_system:"EPSG:9429", label: "EPSG:9429 / ETRS89 + Douglas height"},
  {coordinate_system:"EPSG:9430", label: "EPSG:9430 / ETRS89 + St. Marys height"},
  {coordinate_system:"EPSG:9449", label: "EPSG:9449 / ETRS89 + Malin Head height"},
  {coordinate_system:"EPSG:9450", label: "EPSG:9450 / ETRS89 + Belfast height"},
  {coordinate_system:"EPSG:9452", label: "EPSG:9452 / ETRS89 + BI height"},
  {coordinate_system:"EPSG:9457", label: "EPSG:9457 / GBK19 Grid + ODN height"},
  {coordinate_system:"EPSG:9462", label: "EPSG:9462 / GDA2020 + AVWS height"},
  {coordinate_system:"EPSG:9463", label: "EPSG:9463 / GDA2020 + AHD height"},
  {coordinate_system:"EPSG:9464", label: "EPSG:9464 / GDA94 + AHD height"},
  {coordinate_system:"EPSG:9500", label: "EPSG:9500 / ETRS89 + EVRF2000 Austria height"},
  {coordinate_system:"EPSG:9501", label: "EPSG:9501 / MGI + EVRF2000 Austria height"},
  {coordinate_system:"EPSG:9502", label: "EPSG:9502 / CIGD11 + CBVD61 height (ft)"},
  {coordinate_system:"EPSG:9503", label: "EPSG:9503 / CIGD11 + GCVD54 height (ft)"},
  {coordinate_system:"EPSG:9504", label: "EPSG:9504 / CIGD11 + LCVD61 height (ft)"},
  {coordinate_system:"EPSG:9505", label: "EPSG:9505 / ETRS89 + Alicante height"},
  {coordinate_system:"EPSG:9506", label: "EPSG:9506 / ETRS89 + Ceuta 2 height"},
  {coordinate_system:"EPSG:9507", label: "EPSG:9507 / ETRS89 + Ibiza height"},
  {coordinate_system:"EPSG:9508", label: "EPSG:9508 / ETRS89 + Mallorca height"},
  {coordinate_system:"EPSG:9509", label: "EPSG:9509 / ETRS89 + Menorca height"},
  {coordinate_system:"EPSG:9510", label: "EPSG:9510 / REGCAN95 + El Hierro height"},
  {coordinate_system:"EPSG:9511", label: "EPSG:9511 / REGCAN95 + Fuerteventura height"},
  {coordinate_system:"EPSG:9512", label: "EPSG:9512 / REGCAN95 + Gran Canaria height"},
  {coordinate_system:"EPSG:9513", label: "EPSG:9513 / REGCAN95 + La Gomera height"},
  {coordinate_system:"EPSG:9514", label: "EPSG:9514 / REGCAN95 + La Palma height"},
  {coordinate_system:"EPSG:9515", label: "EPSG:9515 / REGCAN95 + Lanzarote height"},
  {coordinate_system:"EPSG:9516", label: "EPSG:9516 / REGCAN95 + Tenerife height"},
  {coordinate_system:"EPSG:9517", label: "EPSG:9517 / SHGD2015 + SHVD2015 height"},
  {coordinate_system:"EPSG:9518", label: "EPSG:9518 / WGS 84 + EGM2008 height"},
  {coordinate_system:"EPSG:9519", label: "EPSG:9519 / FEH2010 + FCSVR10 height"},
  {coordinate_system:"EPSG:9520", label: "EPSG:9520 / KSA-GRF17 + KSA-VRF14 height"},
  {coordinate_system:"EPSG:9521", label: "EPSG:9521 / POSGAR 2007 + SRVN16 height"},
  {coordinate_system:"EPSG:9522", label: "EPSG:9522 / NAD83(2011) + PRVD02 height"},
  {coordinate_system:"EPSG:9523", label: "EPSG:9523 / NAD83(2011) + VIVD09 height"},
  {coordinate_system:"EPSG:9524", label: "EPSG:9524 / NAD83(MA11) + GUVD04 height"},
  {coordinate_system:"EPSG:9525", label: "EPSG:9525 / NAD83(MA11) + NMVD03 height"},
  {coordinate_system:"EPSG:9526", label: "EPSG:9526 / NAD83(PA11) + ASVD02 height"},
  {coordinate_system:"EPSG:9527", label: "EPSG:9527 / NZGD2000 + NZVD2009 height"},
  {coordinate_system:"EPSG:9528", label: "EPSG:9528 / NZGD2000 + NZVD2016 height"},
  {coordinate_system:"EPSG:9529", label: "EPSG:9529 / SRGI2013 + INAGeoid2020 v1 height"},
  {coordinate_system:"EPSG:9530", label: "EPSG:9530 / RGFG95 + NGG1977 height"},
  {coordinate_system:"EPSG:9531", label: "EPSG:9531 / RGAF09 + Guadeloupe 1988 height"},
  {coordinate_system:"EPSG:9532", label: "EPSG:9532 / RGAF09 + IGN 1988 LS height"},
  {coordinate_system:"EPSG:9533", label: "EPSG:9533 / RGAF09 + IGN 1988 MG height"},
  {coordinate_system:"EPSG:9534", label: "EPSG:9534 / RGAF09 + IGN 1988 SB height"},
  {coordinate_system:"EPSG:9535", label: "EPSG:9535 / RGAF09 + IGN 1988 SM height"},
  {coordinate_system:"EPSG:9536", label: "EPSG:9536 / RGAF09 + IGN 2008 LD height"},
  {coordinate_system:"EPSG:9537", label: "EPSG:9537 / RGAF09 + Martinique 1987 height"},
  {coordinate_system:"EPSG:9538", label: "EPSG:9538 / RGF93 v2 + NGF-IGN69 height"},
  {coordinate_system:"EPSG:9539", label: "EPSG:9539 / RGF93 v2 + NGF-IGN78 height"},
  {coordinate_system:"EPSG:9540", label: "EPSG:9540 / RGNC91-93 + NGNC08 height"},
  {coordinate_system:"EPSG:9541", label: "EPSG:9541 / RGSPM06 + Danger 1950 height"},
  {coordinate_system:"EPSG:9542", label: "EPSG:9542 / RRAF 1991 + IGN 2008 LD height"},
  {coordinate_system:"EPSG:9543", label: "EPSG:9543 / ITRF2005 + SA LLD height"},
  {coordinate_system:"EPSG:9544", label: "EPSG:9544 / NAD83(CSRS)v6 + CGVD2013a(2010) height"},
  {coordinate_system:"EPSG:9656", label: "EPSG:9656 / ETRF2000-PL + Baltic 1986 height"},
  {coordinate_system:"EPSG:9657", label: "EPSG:9657 / ETRF2000-PL + EVRF2007-PL height"},
  {coordinate_system:"EPSG:9705", label: "EPSG:9705 / WGS 84 + MSL height"},
  {coordinate_system:"EPSG:9707", label: "EPSG:9707 / WGS 84 + EGM96 height"},
  {coordinate_system:"EPSG:9711", label: "EPSG:9711 / NAD83(CSRS) / UTM zone 23N + CGVD2013 height"},
  {coordinate_system:"EPSG:9714", label: "EPSG:9714 / NAD83(CSRS) / UTM zone 24N + CGVD2013 height"},
  {coordinate_system:"EPSG:9715", label: "EPSG:9715 / NAD83(CSRS) / UTM zone 15N + CGVD2013a(2010) height"},
  {coordinate_system:"EPSG:9723", label: "EPSG:9723 / ETRS89 + Genoa 1942 height"},
  {coordinate_system:"EPSG:9724", label: "EPSG:9724 / ETRS89 + Catania 1965 height"},
  {coordinate_system:"EPSG:9725", label: "EPSG:9725 / ETRS89 + Cagliari 1956 height"},
  {coordinate_system:"EPSG:9742", label: "EPSG:9742 / EOS21 Grid + ODN height"},
  {coordinate_system:"EPSG:9762", label: "EPSG:9762 / ECML14_NB Grid + ODN height"},
  {coordinate_system:"EPSG:9767", label: "EPSG:9767 / EWR2 Grid + ODN height"},
  {coordinate_system:"EPSG:9785", label: "EPSG:9785 / RGF93 v2b + NGF-IGN69 height"},
  {coordinate_system:"EPSG:9870", label: "EPSG:9870 / MRH21 Grid + ODN height"},
  {coordinate_system:"EPSG:9881", label: "EPSG:9881 / MOLDOR11 Grid + ODN height"},
  {coordinate_system:"EPSG:9883", label: "EPSG:9883 / ETRS89 + CD Norway depth"},
  {coordinate_system:"EPSG:9897", label: "EPSG:9897 / LUREF / Luxembourg TM + NG95 height"},
  {coordinate_system:"EPSG:9907", label: "EPSG:9907 / ETRS89 + Ostend height"},
  {coordinate_system:"EPSG:9920", label: "EPSG:9920 / OSGB36 / British National Grid + BI height"},
  {coordinate_system:"EPSG:9922", label: "EPSG:9922 / ETRS89 / ITM + BI height"},
  {coordinate_system:"EPSG:9924", label: "EPSG:9924 / ETRS89 + DHHN2016 height"},
  {coordinate_system:"EPSG:9928", label: "EPSG:9928 / DB_REF2003 zone 2"},
  {coordinate_system:"EPSG:9929", label: "EPSG:9929 / DB_REF2003 zone 3"},
  {coordinate_system:"EPSG:9930", label: "EPSG:9930 / DB_REF2003 zone 4"},
  {coordinate_system:"EPSG:9931", label: "EPSG:9931 / DB_REF2003 zone 5"},
  {coordinate_system:"EPSG:9932", label: "EPSG:9932 / DB_REF2016 zone 2"},
  {coordinate_system:"EPSG:9933", label: "EPSG:9933 / DB_REF2016 zone 3"},
  {coordinate_system:"EPSG:9934", label: "EPSG:9934 / DB_REF2016 zone 4"},
  {coordinate_system:"EPSG:9935", label: "EPSG:9935 / DB_REF2016 zone 5"},
  {coordinate_system:"EPSG:9944", label: "EPSG:9944 / EBBWV14 Grid + ODN height"},
  {coordinate_system:"EPSG:9948", label: "EPSG:9948 / ISN93 + ISH2004 height "},
  {coordinate_system:"EPSG:9949", label: "EPSG:9949 / ISN2004 + ISH2004 height"},
  {coordinate_system:"EPSG:9950", label: "EPSG:9950 / ISN2016 + ISH2004 height"},
  {coordinate_system:"EPSG:9951", label: "EPSG:9951 / ISN93 / Lambert 1993 + ISH2004 height"},
  {coordinate_system:"EPSG:9952", label: "EPSG:9952 / ISN2004 / Lambert 2004 + ISH2004 height"},
  {coordinate_system:"EPSG:9953", label: "EPSG:9953 / ISN2016 / Lambert 2016 + ISH2004 height"},
  {coordinate_system:"EPSG:9968", label: "EPSG:9968 / HULLEE13 Grid + ODN height"},
  {coordinate_system:"EPSG:9973", label: "EPSG:9973 / SCM22 Grid + ODN height"},
  {coordinate_system:"EPSG:9978", label: "EPSG:9978 / FNL22 Grid + ODN height"},
  {coordinate_system:"EPSG:20001", label: "EPSG:20001 / ETRS89 + SVD2006 height"},
  {coordinate_system:"EPSG:20003", label: "EPSG:20003 / MWC18 Grid + ODN height"},
  {coordinate_system:"EPSG:20037", label: "EPSG:20037 / NAD83(CSRS)v4 + CGVD2013a(2002) height"},
  {coordinate_system:"EPSG:20038", label: "EPSG:20038 / NAD83(CSRS)v3 + CGVD2013a(1997) height"},
  {coordinate_system:"EPSG:20043", label: "EPSG:20043 / SRGI2013 + INAGeoid2020 v2 height"},
  
  ]
  
  export const VERTICAL_SHIFT_SETTING_LIST = [
    {vertical_shift:"CZM:5705", label: "5705 / Baltic 1977 height"},
    {vertical_shift:"CZM:GCG2016", label: "GCG2016 / GCG2016 geoid model heights"},
    {vertical_shift:"CZM:GEOID03", label: "EPSG:5703 / NAVD88 height - GEOID03"},
    {vertical_shift:"CZM:GEOID06", label: "EPSG:5703 / NAVD88 height - GEOID06"},
    {vertical_shift:"CZM:GEOID09", label: "EPSG:5703 / NAVD88 height - GEOID09"},
    {vertical_shift:"CZM:GEOID12", label: "EPSG:5703 / NAVD88 height - GEOID12"},
    {vertical_shift:"CZM:GEOID12A", label: "EPSG:5703 / NAVD88 height - GEOID12A"},
    {vertical_shift:"CZM:GEOID12B", label: "EPSG:5703 / NAVD88 height - GEOID12B"},
    {vertical_shift:"CZM:GEOID96", label: "EPSG:5703 / NAVD88 height - GEOID96"},
    {vertical_shift:"CZM:GEOID99", label: "EPSG:5703 / NAVD88 height - GEOID99"},
    {vertical_shift:"CZM:LIT20G", label: "LIT20G / LIT20G model"},
    {vertical_shift:"CZM:LV14", label: "LV14 / LV14"},
    {vertical_shift:"CZM:OSGM02", label: "OSGM02 / OSGM02"},
    {vertical_shift:"CZM:OSGM15", label: "OSGM15 / OSGM15"},
    {vertical_shift:"CZM:RAC09", label: "RAC09 / RAC09 geoid model heights"},
    {vertical_shift:"CZM:RAF09", label: "RAF09 / RAF09 geoid model heights"},
    {vertical_shift:"CZM:RAF18", label: "RAF18 / RAF18 geoid model heights"},
    {vertical_shift:"CZM:RAF20", label: "RAF20 / RAF20 geoid model heights"},
    {vertical_shift:"CZM:RAF98", label: "RAF98 / RAF98 geoid model heights"},
    {vertical_shift:"CZM:RIGA20", label: "RIGA20 / RIGA20"},
    {vertical_shift:"CZM:hBG18", label: "hBG18 / hBG18 geoid model heights"},
    {vertical_shift:"EPSG:3855", label: "EPSG:3855 / EGM2008 height"},
    {vertical_shift:"EPSG:3886", label: "EPSG:3886 / Fao 1979 height"},
    {vertical_shift:"EPSG:3900", label: "EPSG:3900 / N2000 height"},
    {vertical_shift:"EPSG:4440", label: "EPSG:4440 / NZVD2009 height"},
    {vertical_shift:"EPSG:4458", label: "EPSG:4458 / Dunedin-Bluff 1960 height"},
    {vertical_shift:"EPSG:5193", label: "EPSG:5193 / Incheon height"},
    {vertical_shift:"EPSG:5195", label: "EPSG:5195 / Trieste height"},
    {vertical_shift:"EPSG:5214", label: "EPSG:5214 / Genoa 1942 height"},
    {vertical_shift:"EPSG:5237", label: "EPSG:5237 / SLVD height"},
    {vertical_shift:"EPSG:5317", label: "EPSG:5317 / FVR09 height"},
    {vertical_shift:"EPSG:5336", label: "EPSG:5336 / Black Sea depth"},
    {vertical_shift:"EPSG:5597", label: "EPSG:5597 / FCSVR10 height"},
    {vertical_shift:"EPSG:5600", label: "EPSG:5600 / NGPF height"},
    {vertical_shift:"EPSG:5601", label: "EPSG:5601 / IGN 1966 height"},
    {vertical_shift:"EPSG:5602", label: "EPSG:5602 / Moorea SAU 1981 height"},
    {vertical_shift:"EPSG:5603", label: "EPSG:5603 / Raiatea SAU 2001 height"},
    {vertical_shift:"EPSG:5604", label: "EPSG:5604 / Maupiti SAU 2001 height"},
    {vertical_shift:"EPSG:5605", label: "EPSG:5605 / Huahine SAU 2001 height"},
    {vertical_shift:"EPSG:5606", label: "EPSG:5606 / Tahaa SAU 2001 height"},
    {vertical_shift:"EPSG:5607", label: "EPSG:5607 / Bora Bora SAU 2001 height"},
    {vertical_shift:"EPSG:5608", label: "EPSG:5608 / IGLD 1955 height"},
    {vertical_shift:"EPSG:5609", label: "EPSG:5609 / IGLD 1985 height"},
    {vertical_shift:"EPSG:5610", label: "EPSG:5610 / HVRS71 height"},
    {vertical_shift:"EPSG:5611", label: "EPSG:5611 / Caspian height"},
    {vertical_shift:"EPSG:5612", label: "EPSG:5612 / Baltic 1977 depth"},
    {vertical_shift:"EPSG:5613", label: "EPSG:5613 / RH2000 height"},
    {vertical_shift:"EPSG:5614", label: "EPSG:5614 / KOC WD depth (ft)"},
    {vertical_shift:"EPSG:5615", label: "EPSG:5615 / RH00 height"},
    {vertical_shift:"EPSG:5616", label: "EPSG:5616 / IGN 1988 LS height"},
    {vertical_shift:"EPSG:5617", label: "EPSG:5617 / IGN 1988 MG height"},
    {vertical_shift:"EPSG:5618", label: "EPSG:5618 / IGN 1992 LD height"},
    {vertical_shift:"EPSG:5619", label: "EPSG:5619 / IGN 1988 SB height"},
    {vertical_shift:"EPSG:5620", label: "EPSG:5620 / IGN 1988 SM height"},
    {vertical_shift:"EPSG:5621", label: "EPSG:5621 / EVRF2007 height"},
    {vertical_shift:"EPSG:5701", label: "EPSG:5701 / ODN height"},
    {vertical_shift:"EPSG:5702", label: "EPSG:5702 / NGVD29 height (ftUS)"},
    {vertical_shift:"EPSG:5703", label: "EPSG:5703 / NAVD88 height"},
    {vertical_shift:"EPSG:5705", label: "EPSG:5705 / Baltic 1977 height"},
    {vertical_shift:"EPSG:5706", label: "EPSG:5706 / Caspian depth"},
    {vertical_shift:"EPSG:5709", label: "EPSG:5709 / NAP height"},
    {vertical_shift:"EPSG:5710", label: "EPSG:5710 / Ostend height"},
    {vertical_shift:"EPSG:5711", label: "EPSG:5711 / AHD height"},
    {vertical_shift:"EPSG:5712", label: "EPSG:5712 / AHD (Tasmania) height"},
    {vertical_shift:"EPSG:5713", label: "EPSG:5713 / CGVD28 height"},
    {vertical_shift:"EPSG:5714", label: "EPSG:5714 / MSL height"},
    {vertical_shift:"EPSG:5715", label: "EPSG:5715 / MSL depth"},
    {vertical_shift:"EPSG:5716", label: "EPSG:5716 / Piraeus height"},
    {vertical_shift:"EPSG:5717", label: "EPSG:5717 / N60 height"},
    {vertical_shift:"EPSG:5718", label: "EPSG:5718 / RH70 height"},
    {vertical_shift:"EPSG:5719", label: "EPSG:5719 / NGF Lallemand height"},
    {vertical_shift:"EPSG:5720", label: "EPSG:5720 / NGF-IGN69 height"},
    {vertical_shift:"EPSG:5721", label: "EPSG:5721 / NGF-IGN78 height"},
    {vertical_shift:"EPSG:5722", label: "EPSG:5722 / Maputo height"},
    {vertical_shift:"EPSG:5723", label: "EPSG:5723 / JSLD69 height"},
    {vertical_shift:"EPSG:5724", label: "EPSG:5724 / PHD93 height"},
    {vertical_shift:"EPSG:5725", label: "EPSG:5725 / Fahud HD height"},
    {vertical_shift:"EPSG:5726", label: "EPSG:5726 / Ha Tien 1960 height"},
    {vertical_shift:"EPSG:5727", label: "EPSG:5727 / Hon Dau 1992 height"},
    {vertical_shift:"EPSG:5728", label: "EPSG:5728 / LN02 height"},
    {vertical_shift:"EPSG:5729", label: "EPSG:5729 / LHN95 height"},
    {vertical_shift:"EPSG:5730", label: "EPSG:5730 / EVRF2000 height"},
    {vertical_shift:"EPSG:5731", label: "EPSG:5731 / Malin Head height"},
    {vertical_shift:"EPSG:5732", label: "EPSG:5732 / Belfast height"},
    {vertical_shift:"EPSG:5733", label: "EPSG:5733 / DNN height"},
    {vertical_shift:"EPSG:5734", label: "EPSG:5734 / AIOC95 depth"},
    {vertical_shift:"EPSG:5735", label: "EPSG:5735 / Black Sea height"},
    {vertical_shift:"EPSG:5736", label: "EPSG:5736 / Yellow Sea 1956 height"},
    {vertical_shift:"EPSG:5737", label: "EPSG:5737 / Yellow Sea 1985 height"},
    {vertical_shift:"EPSG:5738", label: "EPSG:5738 / HKPD height"},
    {vertical_shift:"EPSG:5739", label: "EPSG:5739 / HKCD depth"},
    {vertical_shift:"EPSG:5740", label: "EPSG:5740 / ODN Orkney height"},
    {vertical_shift:"EPSG:5741", label: "EPSG:5741 / Fair Isle height"},
    {vertical_shift:"EPSG:5742", label: "EPSG:5742 / Lerwick height"},
    {vertical_shift:"EPSG:5743", label: "EPSG:5743 / Foula height"},
    {vertical_shift:"EPSG:5744", label: "EPSG:5744 / Sule Skerry height"},
    {vertical_shift:"EPSG:5745", label: "EPSG:5745 / North Rona height"},
    {vertical_shift:"EPSG:5746", label: "EPSG:5746 / Stornoway height"},
    {vertical_shift:"EPSG:5747", label: "EPSG:5747 / St. Kilda height"},
    {vertical_shift:"EPSG:5748", label: "EPSG:5748 / Flannan Isles height"},
    {vertical_shift:"EPSG:5749", label: "EPSG:5749 / St. Marys height"},
    {vertical_shift:"EPSG:5750", label: "EPSG:5750 / Douglas height"},
    {vertical_shift:"EPSG:5751", label: "EPSG:5751 / Fao height"},
    {vertical_shift:"EPSG:5752", label: "EPSG:5752 / Bandar Abbas height"},
    {vertical_shift:"EPSG:5753", label: "EPSG:5753 / NGNC69 height"},
    {vertical_shift:"EPSG:5754", label: "EPSG:5754 / Poolbeg height (ft(Br36))"},
    {vertical_shift:"EPSG:5755", label: "EPSG:5755 / NGG1977 height"},
    {vertical_shift:"EPSG:5756", label: "EPSG:5756 / Martinique 1987 height"},
    {vertical_shift:"EPSG:5757", label: "EPSG:5757 / Guadeloupe 1988 height"},
    {vertical_shift:"EPSG:5758", label: "EPSG:5758 / Reunion 1989 height"},
    {vertical_shift:"EPSG:5759", label: "EPSG:5759 / Auckland 1946 height"},
    {vertical_shift:"EPSG:5760", label: "EPSG:5760 / Bluff 1955 height"},
    {vertical_shift:"EPSG:5761", label: "EPSG:5761 / Dunedin 1958 height"},
    {vertical_shift:"EPSG:5762", label: "EPSG:5762 / Gisborne 1926 height"},
    {vertical_shift:"EPSG:5763", label: "EPSG:5763 / Lyttelton 1937 height"},
    {vertical_shift:"EPSG:5764", label: "EPSG:5764 / Moturiki 1953 height"},
    {vertical_shift:"EPSG:5765", label: "EPSG:5765 / Napier 1962 height"},
    {vertical_shift:"EPSG:5766", label: "EPSG:5766 / Nelson 1955 height"},
    {vertical_shift:"EPSG:5767", label: "EPSG:5767 / One Tree Point 1964 height"},
    {vertical_shift:"EPSG:5768", label: "EPSG:5768 / Tararu 1952 height"},
    {vertical_shift:"EPSG:5769", label: "EPSG:5769 / Taranaki 1970 height"},
    {vertical_shift:"EPSG:5770", label: "EPSG:5770 / Wellington 1953 height"},
    {vertical_shift:"EPSG:5771", label: "EPSG:5771 / Chatham Island 1959 height"},
    {vertical_shift:"EPSG:5772", label: "EPSG:5772 / Stewart Island 1977 height"},
    {vertical_shift:"EPSG:5773", label: "EPSG:5773 / EGM96 height"},
    {vertical_shift:"EPSG:5774", label: "EPSG:5774 / NG95 height"},
    {vertical_shift:"EPSG:5775", label: "EPSG:5775 / Antalya height"},
    {vertical_shift:"EPSG:5776", label: "EPSG:5776 / NN54 height"},
    {vertical_shift:"EPSG:5777", label: "EPSG:5777 / Durres height"},
    {vertical_shift:"EPSG:5778", label: "EPSG:5778 / GHA height"},
    {vertical_shift:"EPSG:5779", label: "EPSG:5779 / SVS2000 height"},
    {vertical_shift:"EPSG:5780", label: "EPSG:5780 / Cascais height"},
    {vertical_shift:"EPSG:5781", label: "EPSG:5781 / Constanta height"},
    {vertical_shift:"EPSG:5782", label: "EPSG:5782 / Alicante height"},
    {vertical_shift:"EPSG:5783", label: "EPSG:5783 / DHHN92 height"},
    {vertical_shift:"EPSG:5784", label: "EPSG:5784 / DHHN85 height"},
    {vertical_shift:"EPSG:5785", label: "EPSG:5785 / SNN76 height"},
    {vertical_shift:"EPSG:5786", label: "EPSG:5786 / Baltic 1982 height"},
    {vertical_shift:"EPSG:5787", label: "EPSG:5787 / EOMA 1980 height"},
    {vertical_shift:"EPSG:5788", label: "EPSG:5788 / Kuwait PWD height"},
    {vertical_shift:"EPSG:5789", label: "EPSG:5789 / KOC WD depth"},
    {vertical_shift:"EPSG:5790", label: "EPSG:5790 / KOC CD height"},
    {vertical_shift:"EPSG:5791", label: "EPSG:5791 / NGC 1948 height"},
    {vertical_shift:"EPSG:5792", label: "EPSG:5792 / Danger 1950 height"},
    {vertical_shift:"EPSG:5793", label: "EPSG:5793 / Mayotte 1950 height"},
    {vertical_shift:"EPSG:5794", label: "EPSG:5794 / Martinique 1955 height"},
    {vertical_shift:"EPSG:5795", label: "EPSG:5795 / Guadeloupe 1951 height"},
    {vertical_shift:"EPSG:5796", label: "EPSG:5796 / Lagos 1955 height"},
    {vertical_shift:"EPSG:5797", label: "EPSG:5797 / AIOC95 height"},
    {vertical_shift:"EPSG:5798", label: "EPSG:5798 / EGM84 height"},
    {vertical_shift:"EPSG:5799", label: "EPSG:5799 / DVR90 height"},
    {vertical_shift:"EPSG:5829", label: "EPSG:5829 / Instantaneous Water Level height"},
    {vertical_shift:"EPSG:5831", label: "EPSG:5831 / Instantaneous Water Level depth"},
    {vertical_shift:"EPSG:5843", label: "EPSG:5843 / Ras Ghumays height"},
    {vertical_shift:"EPSG:5861", label: "EPSG:5861 / LAT depth"},
    {vertical_shift:"EPSG:5862", label: "EPSG:5862 / LLWLT depth"},
    {vertical_shift:"EPSG:5863", label: "EPSG:5863 / ISLW depth"},
    {vertical_shift:"EPSG:5864", label: "EPSG:5864 / MLLWS depth"},
    {vertical_shift:"EPSG:5865", label: "EPSG:5865 / MLWS depth"},
    {vertical_shift:"EPSG:5866", label: "EPSG:5866 / MLLW depth"},
    {vertical_shift:"EPSG:5867", label: "EPSG:5867 / MLW depth"},
    {vertical_shift:"EPSG:5868", label: "EPSG:5868 / MHW height"},
    {vertical_shift:"EPSG:5869", label: "EPSG:5869 / MHHW height"},
    {vertical_shift:"EPSG:5870", label: "EPSG:5870 / MHWS height"},
    {vertical_shift:"EPSG:5871", label: "EPSG:5871 / HHWLT height"},
    {vertical_shift:"EPSG:5872", label: "EPSG:5872 / HAT height"},
    {vertical_shift:"EPSG:5873", label: "EPSG:5873 / Low Water depth"},
    {vertical_shift:"EPSG:5874", label: "EPSG:5874 / High Water height"},
    {vertical_shift:"EPSG:5941", label: "EPSG:5941 / NN2000 height"},
    {vertical_shift:"EPSG:6130", label: "EPSG:6130 / GCVD54 height (ft)"},
    {vertical_shift:"EPSG:6131", label: "EPSG:6131 / LCVD61 height (ft)"},
    {vertical_shift:"EPSG:6132", label: "EPSG:6132 / CBVD61 height (ft)"},
    {vertical_shift:"EPSG:6178", label: "EPSG:6178 / Cais da Pontinha - Funchal height"},
    {vertical_shift:"EPSG:6179", label: "EPSG:6179 / Cais da Vila - Porto Santo height"},
    {vertical_shift:"EPSG:6180", label: "EPSG:6180 / Cais das Velas height"},
    {vertical_shift:"EPSG:6181", label: "EPSG:6181 / Horta height"},
    {vertical_shift:"EPSG:6182", label: "EPSG:6182 / Cais da Madalena height"},
    {vertical_shift:"EPSG:6183", label: "EPSG:6183 / Santa Cruz da Graciosa height"},
    {vertical_shift:"EPSG:6184", label: "EPSG:6184 / Cais da Figueirinha - Angra do Heroismo height"},
    {vertical_shift:"EPSG:6185", label: "EPSG:6185 / Santa Cruz das Flores height"},
    {vertical_shift:"EPSG:6186", label: "EPSG:6186 / Cais da Vila do Porto height"},
    {vertical_shift:"EPSG:6187", label: "EPSG:6187 / Ponta Delgada height"},
    {vertical_shift:"EPSG:6357", label: "EPSG:6357 / NAVD88 depth"},
    {vertical_shift:"EPSG:6358", label: "EPSG:6358 / NAVD88 depth (ftUS)"},
    {vertical_shift:"EPSG:6359", label: "EPSG:6359 / NGVD29 depth (ftUS)"},
    {vertical_shift:"EPSG:6360", label: "EPSG:6360 / NAVD88 height (ftUS)"},
    {vertical_shift:"EPSG:6638", label: "EPSG:6638 / Tutuila 1962 height"},
    {vertical_shift:"EPSG:6639", label: "EPSG:6639 / Guam 1963 height"},
    {vertical_shift:"EPSG:6640", label: "EPSG:6640 / NMVD03 height"},
    {vertical_shift:"EPSG:6641", label: "EPSG:6641 / PRVD02 height"},
    {vertical_shift:"EPSG:6642", label: "EPSG:6642 / VIVD09 height"},
    {vertical_shift:"EPSG:6643", label: "EPSG:6643 / ASVD02 height"},
    {vertical_shift:"EPSG:6644", label: "EPSG:6644 / GUVD04 height"},
    {vertical_shift:"EPSG:6647", label: "EPSG:6647 / CGVD2013(CGG2013) height"},
    {vertical_shift:"EPSG:6693", label: "EPSG:6693 / JSLD72 height"},
    {vertical_shift:"EPSG:6694", label: "EPSG:6694 / JGD2000 (vertical) height"},
    {vertical_shift:"EPSG:6695", label: "EPSG:6695 / JGD2011 (vertical) height"},
    {vertical_shift:"EPSG:6916", label: "EPSG:6916 / SHD height"},
    {vertical_shift:"EPSG:7446", label: "EPSG:7446 / Famagusta 1960 height"},
    {vertical_shift:"EPSG:7447", label: "EPSG:7447 / PNG08 height"},
    {vertical_shift:"EPSG:7651", label: "EPSG:7651 / Kumul 34 height"},
    {vertical_shift:"EPSG:7652", label: "EPSG:7652 / Kiunga height"},
    {vertical_shift:"EPSG:7699", label: "EPSG:7699 / DHHN12 height"},
    {vertical_shift:"EPSG:7700", label: "EPSG:7700 / Latvia 2000 height"},
    {vertical_shift:"EPSG:7707", label: "EPSG:7707 / ODN (Offshore) height"},
    {vertical_shift:"EPSG:7832", label: "EPSG:7832 / POM96 height"},
    {vertical_shift:"EPSG:7837", label: "EPSG:7837 / DHHN2016 height"},
    {vertical_shift:"EPSG:7839", label: "EPSG:7839 / NZVD2016 height"},
    {vertical_shift:"EPSG:7841", label: "EPSG:7841 / POM08 height"},
    {vertical_shift:"EPSG:7888", label: "EPSG:7888 / Jamestown 1971 height"},
    {vertical_shift:"EPSG:7889", label: "EPSG:7889 / St. Helena Tritan 2011 height"},
    {vertical_shift:"EPSG:7890", label: "EPSG:7890 / SHVD2015 height"},
    {vertical_shift:"EPSG:7962", label: "EPSG:7962 / Poolbeg height (m)"},
    {vertical_shift:"EPSG:7968", label: "EPSG:7968 / NGVD29 height (m)"},
    {vertical_shift:"EPSG:7976", label: "EPSG:7976 / HKPD depth"},
    {vertical_shift:"EPSG:7979", label: "EPSG:7979 / KOC WD height"},
    {vertical_shift:"EPSG:8050", label: "EPSG:8050 / MSL height (ft)"},
    {vertical_shift:"EPSG:8051", label: "EPSG:8051 / MSL depth (ft)"},
    {vertical_shift:"EPSG:8052", label: "EPSG:8052 / MSL height (ftUS)"},
    {vertical_shift:"EPSG:8053", label: "EPSG:8053 / MSL depth (ftUS)"},
    {vertical_shift:"EPSG:8089", label: "EPSG:8089 / ISH2004 height"},
    {vertical_shift:"EPSG:8228", label: "EPSG:8228 / NAVD88 height (ft)"},
    {vertical_shift:"EPSG:8266", label: "EPSG:8266 / GVR2000 height"},
    {vertical_shift:"EPSG:8267", label: "EPSG:8267 / GVR2016 height"},
    {vertical_shift:"EPSG:8357", label: "EPSG:8357 / Baltic 1957 height"},
    {vertical_shift:"EPSG:8358", label: "EPSG:8358 / Baltic 1957 depth"},
    {vertical_shift:"EPSG:8378", label: "EPSG:8378 / EPSG example wellbore local vertical CRS"},
    {vertical_shift:"EPSG:8434", label: "EPSG:8434 / Macao height"},
    {vertical_shift:"EPSG:8675", label: "EPSG:8675 / N43 height"},
    {vertical_shift:"EPSG:8690", label: "EPSG:8690 / SVS2010 height"},
    {vertical_shift:"EPSG:8691", label: "EPSG:8691 / SRB_VRS12 height"},
    {vertical_shift:"EPSG:8841", label: "EPSG:8841 / MVGC height"},
    {vertical_shift:"EPSG:8881", label: "EPSG:8881 / Vienna height"},
    {vertical_shift:"EPSG:8897", label: "EPSG:8897 / EPSG example wellbore local vertical CRS (ft)"},
    {vertical_shift:"EPSG:8904", label: "EPSG:8904 / TWVD 2001 height"},
    {vertical_shift:"EPSG:8911", label: "EPSG:8911 / DACR52 height"},
    {vertical_shift:"EPSG:9130", label: "EPSG:9130 / IGN 2008 LD height"},
    {vertical_shift:"EPSG:9245", label: "EPSG:9245 / CGVD2013a(2010) height"},
    {vertical_shift:"EPSG:9255", label: "EPSG:9255 / SRVN16 height"},
    {vertical_shift:"EPSG:9274", label: "EPSG:9274 / EVRF2000 Austria height"},
    {vertical_shift:"EPSG:9279", label: "EPSG:9279 / SA LLD height"},
    {vertical_shift:"EPSG:9287", label: "EPSG:9287 / LAT NL depth"},
    {vertical_shift:"EPSG:9288", label: "EPSG:9288 / MSL NL depth"},
    {vertical_shift:"EPSG:9303", label: "EPSG:9303 / HS2-VRF height"},
    {vertical_shift:"EPSG:9335", label: "EPSG:9335 / KSA-VRF14 height"},
    {vertical_shift:"EPSG:9351", label: "EPSG:9351 / NGNC08 height"},
    {vertical_shift:"EPSG:9389", label: "EPSG:9389 / EVRF2019 height"},
    {vertical_shift:"EPSG:9390", label: "EPSG:9390 / EVRF2019 mean-tide height"},
    {vertical_shift:"EPSG:9392", label: "EPSG:9392 / Mallorca height"},
    {vertical_shift:"EPSG:9393", label: "EPSG:9393 / Menorca height"},
    {vertical_shift:"EPSG:9394", label: "EPSG:9394 / Ibiza height"},
    {vertical_shift:"EPSG:9395", label: "EPSG:9395 / Lanzarote height"},
    {vertical_shift:"EPSG:9396", label: "EPSG:9396 / Fuerteventura height"},
    {vertical_shift:"EPSG:9397", label: "EPSG:9397 / Gran Canaria height"},
    {vertical_shift:"EPSG:9398", label: "EPSG:9398 / Tenerife height"},
    {vertical_shift:"EPSG:9399", label: "EPSG:9399 / La Gomera height"},
    {vertical_shift:"EPSG:9400", label: "EPSG:9400 / La Palma height"},
    {vertical_shift:"EPSG:9401", label: "EPSG:9401 / El Hierro height"},
    {vertical_shift:"EPSG:9402", label: "EPSG:9402 / Ceuta 2 height"},
    {vertical_shift:"EPSG:9451", label: "EPSG:9451 / BI height"},
    {vertical_shift:"EPSG:9458", label: "EPSG:9458 / AVWS height"},
    {vertical_shift:"EPSG:9471", label: "EPSG:9471 / INAGeoid2020 v1 height"},
    {vertical_shift:"EPSG:9650", label: "EPSG:9650 / Baltic 1986 height"},
    {vertical_shift:"EPSG:9651", label: "EPSG:9651 / EVRF2007-PL height"},
    {vertical_shift:"EPSG:9663", label: "EPSG:9663 / EH2000 height"},
    {vertical_shift:"EPSG:9666", label: "EPSG:9666 / LAS07 height"},
    {vertical_shift:"EPSG:9669", label: "EPSG:9669 / BGS2005 height"},
    {vertical_shift:"EPSG:9672", label: "EPSG:9672 / CD Norway depth"},
    {vertical_shift:"EPSG:9675", label: "EPSG:9675 / Pago Pago 2020 height"},
    {vertical_shift:"EPSG:9681", label: "EPSG:9681 / NVD 1992 height"},
    {vertical_shift:"EPSG:9721", label: "EPSG:9721 / Catania 1965 height"},
    {vertical_shift:"EPSG:9722", label: "EPSG:9722 / Cagliari 1956 height"},
    {vertical_shift:"EPSG:9923", label: "EPSG:9923 / GNTRANS height"},
    {vertical_shift:"EPSG:9927", label: "EPSG:9927 / GNTRANS2016 height"},
    {vertical_shift:"EPSG:20000", label: "EPSG:20000 / SVD2006 height"},
    {vertical_shift:"EPSG:20034", label: "EPSG:20034 / CGVD2013a(2002) height"},
    {vertical_shift:"EPSG:20035", label: "EPSG:20035 / CGVD2013a(1997) height"},
    {vertical_shift:"EPSG:20036", label: "EPSG:20036 / INAGeoid2020 v2 height"},
    {vertical_shift:"ESRI:9650", label: "9650 / Baltic_1986_height"},
    {vertical_shift:"ESRI:9651", label: "9651 / EVRF_2007_PL_height"},
    {vertical_shift:"ESRI:9721", label: "9721 / Catania_1965_height"},
    {vertical_shift:"ESRI:9722", label: "9722 / Cagliari_1956_height"},
    {vertical_shift:"ESRI:105603", label: "105603 / REDNAP_height"},
    {vertical_shift:"ESRI:105700", label: "105700 / WGS_1984_Geoid"},
    {vertical_shift:"ESRI:115700", label: "115700 / WGS_1984"},
    {vertical_shift:"ESRI:115701", label: "115701 / ETRS_1989"},
    {vertical_shift:"ESRI:115702", label: "115702 / NAD_1983"},
    {vertical_shift:"ESRI:115703", label: "115703 / Australian_Antarctic_1998"},
    {vertical_shift:"ESRI:115704", label: "115704 / Cadastre_1997"},
    {vertical_shift:"ESRI:115705", label: "115705 / China_Geodetic_vertical_shift_2000"},
    {vertical_shift:"ESRI:115706", label: "115706 / Swiss_TRF_1995"},
    {vertical_shift:"ESRI:115707", label: "115707 / CIGD11"},
    {vertical_shift:"ESRI:115708", label: "115708 / CR05"},
    {vertical_shift:"ESRI:115709", label: "115709 / DB_REF"},
    {vertical_shift:"ESRI:115710", label: "115710 / DGN_1995"},
    {vertical_shift:"ESRI:115711", label: "115711 / DRUKREF_03"},
    {vertical_shift:"ESRI:115712", label: "115712 / Estonia_1997"},
    {vertical_shift:"ESRI:115713", label: "115713 / EUREF_FIN"},
    {vertical_shift:"ESRI:115714", label: "115714 / FEH2010"},
    {vertical_shift:"ESRI:115715", label: "115715 / GDA_1994"},
    {vertical_shift:"ESRI:115716", label: "115716 / GDBD2009"},
    {vertical_shift:"ESRI:115717", label: "115717 / GDM_2000"},
    {vertical_shift:"ESRI:115718", label: "115718 / Greenland_1996"},
    {vertical_shift:"ESRI:115719", label: "115719 / Hartebeesthoek_1994"},
    {vertical_shift:"ESRI:115720", label: "115720 / IGD05"},
    {vertical_shift:"ESRI:115721", label: "115721 / IG05_Intermediate_CRS"},
    {vertical_shift:"ESRI:115722", label: "115722 / IGD05(2012)"},
    {vertical_shift:"ESRI:115723", label: "115723 / IG05(2012)_Intermediate_CRS"},
    {vertical_shift:"ESRI:115724", label: "115724 / IGM_1995"},
    {vertical_shift:"ESRI:115725", label: "115725 / IGRS"},
    {vertical_shift:"ESRI:115726", label: "115726 / IRENET95"},
    {vertical_shift:"ESRI:115727", label: "115727 / ISN_1993"},
    {vertical_shift:"ESRI:115728", label: "115728 / ISN_2004"},
    {vertical_shift:"ESRI:115729", label: "115729 / ITRF_1988"},
    {vertical_shift:"ESRI:115730", label: "115730 / ITRF_1989"},
    {vertical_shift:"ESRI:115731", label: "115731 / ITRF_1990"},
    {vertical_shift:"ESRI:115732", label: "115732 / ITRF_1991"},
    {vertical_shift:"ESRI:115733", label: "115733 / ITRF_1992"},
    {vertical_shift:"ESRI:115734", label: "115734 / ITRF_1993"},
    {vertical_shift:"ESRI:115735", label: "115735 / ITRF_1996"},
    {vertical_shift:"ESRI:115736", label: "115736 / ITRF_1997"},
    {vertical_shift:"ESRI:115737", label: "115737 / ITRF_2000"},
    {vertical_shift:"ESRI:115738", label: "115738 / ITRF_2005"},
    {vertical_shift:"ESRI:115739", label: "115739 / JAD_2001"},
    {vertical_shift:"ESRI:115740", label: "115740 / JGD_2000"},
    {vertical_shift:"ESRI:115741", label: "115741 / JGD_2011"},
    {vertical_shift:"ESRI:115742", label: "115742 / Korea_2000"},
    {vertical_shift:"ESRI:115743", label: "115743 / Lao_1997"},
    {vertical_shift:"ESRI:115744", label: "115744 / LGD2006"},
    {vertical_shift:"ESRI:115745", label: "115745 / LKS_1992"},
    {vertical_shift:"ESRI:115746", label: "115746 / LKS_1994"},
    {vertical_shift:"ESRI:115747", label: "115747 / MACAO_2008"},
    {vertical_shift:"ESRI:115748", label: "115748 / MAGNA"},
    {vertical_shift:"ESRI:115749", label: "115749 / MARCARIO_SOLIS"},
    {vertical_shift:"ESRI:115750", label: "115750 / MARGEN"},
    {vertical_shift:"ESRI:115751", label: "115751 / Mexico_ITRF2008"},
    {vertical_shift:"ESRI:115752", label: "115752 / MOLDREF99"},
    {vertical_shift:"ESRI:115753", label: "115753 / MONREF_1997"},
    {vertical_shift:"ESRI:115754", label: "115754 / Moznet"},
    {vertical_shift:"ESRI:115755", label: "115755 / NAD_1983_2011"},
    {vertical_shift:"ESRI:115756", label: "115756 / NAD_1983_CORS96"},
    {vertical_shift:"ESRI:115757", label: "115757 / North_American_1983_CSRS"},
    {vertical_shift:"ESRI:115758", label: "115758 / North_American_1983_HARN"},
    {vertical_shift:"ESRI:115759", label: "115759 / NAD_1983_MA11"},
    {vertical_shift:"ESRI:115760", label: "115760 / NAD_1983_MARP00"},
    {vertical_shift:"ESRI:115761", label: "115761 / NAD_1983_NSRS2007"},
    {vertical_shift:"ESRI:115762", label: "115762 / NAD_1983_PA11"},
    {vertical_shift:"ESRI:115763", label: "115763 / NAD_1983_PACP00"},
    {vertical_shift:"ESRI:115764", label: "115764 / Nepal_Nagarkot"},
    {vertical_shift:"ESRI:115765", label: "115765 / NZGD_2000"},
    {vertical_shift:"ESRI:115766", label: "115766 / Peru96"},
    {vertical_shift:"ESRI:115767", label: "115767 / PNG94"},
    {vertical_shift:"ESRI:115768", label: "115768 / POSGAR"},
    {vertical_shift:"ESRI:115769", label: "115769 / POSGAR_1994"},
    {vertical_shift:"ESRI:115770", label: "115770 / POSGAR_1998"},
    {vertical_shift:"ESRI:115771", label: "115771 / PRS_1992"},
    {vertical_shift:"ESRI:115772", label: "115772 / PTRA08"},
    {vertical_shift:"ESRI:115773", label: "115773 / PZ_1990"},
    {vertical_shift:"ESRI:115774", label: "115774 / RDN2008"},
    {vertical_shift:"ESRI:115775", label: "115775 / REGCAN95"},
    {vertical_shift:"ESRI:115776", label: "115776 / REGVEN"},
    {vertical_shift:"ESRI:115777", label: "115777 / RGAF09"},
    {vertical_shift:"ESRI:115778", label: "115778 / RGF_1993"},
    {vertical_shift:"ESRI:115779", label: "115779 / RGFG_1995"},
    {vertical_shift:"ESRI:115780", label: "115780 / RGM_2004"},
    {vertical_shift:"ESRI:115781", label: "115781 / RGNC_1991"},
    {vertical_shift:"ESRI:115782", label: "115782 / RGNC_1991-93"},
    {vertical_shift:"ESRI:115783", label: "115783 / RGPF"},
    {vertical_shift:"ESRI:115784", label: "115784 / RGR_1992"},
    {vertical_shift:"ESRI:115785", label: "115785 / RGRDC_2005"},
    {vertical_shift:"ESRI:115786", label: "115786 / RGSPM_2006"},
    {vertical_shift:"ESRI:115787", label: "115787 / RRAF_1991"},
    {vertical_shift:"ESRI:115788", label: "115788 / RSRGD2000"},
    {vertical_shift:"ESRI:115789", label: "115789 / SIRGAS_2000"},
    {vertical_shift:"ESRI:115790", label: "115790 / SIRGAS-Chile"},
    {vertical_shift:"ESRI:115791", label: "115791 / SIRGAS_ES2007.8"},
    {vertical_shift:"ESRI:115792", label: "115792 / SIRGAS-ROU98"},
    {vertical_shift:"ESRI:115793", label: "115793 / SLD99"},
    {vertical_shift:"ESRI:115794", label: "115794 / Slovenia_1996"},
    {vertical_shift:"ESRI:115795", label: "115795 / SREF98"},
    {vertical_shift:"ESRI:115796", label: "115796 / S_JTSK/05"},
    {vertical_shift:"ESRI:115797", label: "115797 / S_JTSK/05_Ferro"},
    {vertical_shift:"ESRI:115798", label: "115798 / SWEREF99"},
    {vertical_shift:"ESRI:115799", label: "115799 / TGD2005"},
    {vertical_shift:"ESRI:115800", label: "115800 / TWD_1997"},
    {vertical_shift:"ESRI:115801", label: "115801 / Ukraine_2000"},
    {vertical_shift:"ESRI:115802", label: "115802 / Yemen_NGN_1996"},
    {vertical_shift:"ESRI:115803", label: "115803 / ITRF_2008"},
    {vertical_shift:"ESRI:115804", label: "115804 / TUREF"},
    {vertical_shift:"ESRI:115805", label: "115805 / GDA2020"},
    {vertical_shift:"ESRI:115806", label: "115806 / BGS2005"},
    {vertical_shift:"ESRI:115807", label: "115807 / Unknown_height_system_(meters)"},
    {vertical_shift:"ESRI:115808", label: "115808 / Unknown_height_system_(US_survey_feet)"},
    {vertical_shift:"ESRI:115809", label: "115809 / Unknown_height_system_(Intl_Feet)"},
    {vertical_shift:"ESRI:115810", label: "115810 / ITRF2014"},
    {vertical_shift:"ESRI:115811", label: "115811 / SHGD2015"},
    {vertical_shift:"ESRI:115812", label: "115812 / St_Helena_Tritan"},
    {vertical_shift:"ESRI:115813", label: "115813 / GSK-2011"},
    {vertical_shift:"ESRI:115814", label: "115814 / PZ-90.11"},
    {vertical_shift:"ESRI:115815", label: "115815 / PZ-90.02"},
    {vertical_shift:"ESRI:115816", label: "115816 / WGS_1984_(Transit)"},
    {vertical_shift:"ESRI:115817", label: "115817 / WGS_1984_(G1762)"},
    {vertical_shift:"ESRI:115818", label: "115818 / WGS_1984_(G1674)"},
    {vertical_shift:"ESRI:115819", label: "115819 / WGS_1984_(G1150)"},
    {vertical_shift:"ESRI:115820", label: "115820 / WGS_1984_(G873)"},
    {vertical_shift:"ESRI:115821", label: "115821 / WGS_1984_(G730)"},
    {vertical_shift:"ESRI:115822", label: "115822 / ETRF90"},
    {vertical_shift:"ESRI:115823", label: "115823 / ETRF91"},
    {vertical_shift:"ESRI:115824", label: "115824 / ETRF92"},
    {vertical_shift:"ESRI:115825", label: "115825 / ETRF93"},
    {vertical_shift:"ESRI:115826", label: "115826 / ETRF94"},
    {vertical_shift:"ESRI:115827", label: "115827 / ETRF96"},
    {vertical_shift:"ESRI:115828", label: "115828 / ETRF97"},
    {vertical_shift:"ESRI:115829", label: "115829 / ETRF2000"},
    {vertical_shift:"ESRI:115830", label: "115830 / NAD83(CSRS96)"},
    {vertical_shift:"ESRI:115831", label: "115831 / NAD83(CSRS)v2"},
    {vertical_shift:"ESRI:115832", label: "115832 / NAD83(CSRS)v3"},
    {vertical_shift:"ESRI:115833", label: "115833 / NAD83(CSRS)v4"},
    {vertical_shift:"ESRI:115834", label: "115834 / NAD83(CSRS)v5"},
    {vertical_shift:"ESRI:115835", label: "115835 / NAD83(CSRS)v6"},
    {vertical_shift:"ESRI:115836", label: "115836 / NAD83(CSRS)v7"},
    {vertical_shift:"ESRI:115837", label: "115837 / IGS14"},
    {vertical_shift:"ESRI:115838", label: "115838 / ISN2016"},
    {vertical_shift:"ESRI:115839", label: "115839 / Hong_Kong_Geodetic_CS"},
    {vertical_shift:"ESRI:115840", label: "115840 / NAD_1983_(FBN)"},
    {vertical_shift:"ESRI:115841", label: "115841 / NAD_1983_(HARN_Corrected)"},
    {vertical_shift:"ESRI:115842", label: "115842 / SRB_ETRS89"},
    {vertical_shift:"ESRI:115843", label: "115843 / MTRF-2000"},
    {vertical_shift:"ESRI:115844", label: "115844 / California_SRS_Epoch_2017.50_(NAD83)"},
    {vertical_shift:"ESRI:115845", label: "115845 / GGD"},
    {vertical_shift:"ESRI:115846", label: "115846 / ONGD14"},
    {vertical_shift:"ESRI:115847", label: "115847 / ONGD17"},
    {vertical_shift:"ESRI:115848", label: "115848 / S-JTSK_[JTSK03]"},
    {vertical_shift:"ESRI:115849", label: "115849 / CR-SIRGAS"},
    {vertical_shift:"ESRI:115850", label: "115850 / RGWF96"},
    {vertical_shift:"ESRI:115851", label: "115851 / SIRGAS-CON_DGF00P01"},
    {vertical_shift:"ESRI:115852", label: "115852 / SIRGAS-CON_DGF01P01"},
    {vertical_shift:"ESRI:115853", label: "115853 / SIRGAS-CON_DGF01P02"},
    {vertical_shift:"ESRI:115854", label: "115854 / SIRGAS-CON_DGF02P01"},
    {vertical_shift:"ESRI:115855", label: "115855 / SIRGAS-CON_DGF04P01"},
    {vertical_shift:"ESRI:115856", label: "115856 / SIRGAS-CON_DGF05P01"},
    {vertical_shift:"ESRI:115857", label: "115857 / SIRGAS-CON_DGF06P01"},
    {vertical_shift:"ESRI:115858", label: "115858 / SIRGAS-CON_DGF07P01"},
    {vertical_shift:"ESRI:115859", label: "115859 / SIRGAS-CON_DGF08P01"},
    {vertical_shift:"ESRI:115860", label: "115860 / SIRGAS-CON_SIR09P01"},
    {vertical_shift:"ESRI:115861", label: "115861 / SIRGAS-CON_SIR10P01"},
    {vertical_shift:"ESRI:115862", label: "115862 / SIRGAS-CON_SIR11P01"},
    {vertical_shift:"ESRI:115863", label: "115863 / SIRGAS-CON_SIR13P01"},
    {vertical_shift:"ESRI:115864", label: "115864 / SIRGAS-CON_SIR14P01"},
    {vertical_shift:"ESRI:115865", label: "115865 / SIRGAS-CON_SIR15P01"},
    {vertical_shift:"ESRI:115866", label: "115866 / SIRGAS-CON_SIR17P01"},
    {vertical_shift:"ESRI:115867", label: "115867 / IGS97"},
    {vertical_shift:"ESRI:115868", label: "115868 / IGS00"},
    {vertical_shift:"ESRI:115869", label: "115869 / IGb00"},
    {vertical_shift:"ESRI:115870", label: "115870 / IGS05"},
    {vertical_shift:"ESRI:115871", label: "115871 / IGb08"},
    {vertical_shift:"ESRI:115872", label: "115872 / KOSOVAREF01"},
    {vertical_shift:"ESRI:115873", label: "115873 / ETRF2005"},
    {vertical_shift:"ESRI:115874", label: "115874 / ETRF2014"},
    {vertical_shift:"ESRI:115875", label: "115875 / CH1903+"},
    {vertical_shift:"ESRI:115876", label: "115876 / ATRF2014"},
    {vertical_shift:"ESRI:115877", label: "115877 / Australian_1984"},
    {vertical_shift:"ESRI:115878", label: "115878 / Australian_1966"},
    {vertical_shift:"ESRI:115879", label: "115879 / ITRF_1994"},
    {vertical_shift:"IGNF:BORA01", label: "BORA01 / BORA_SAU 2001"},
    {vertical_shift:"IGNF:BOURD", label: "BOURD / NGF-BOURDALOUE"},
    {vertical_shift:"IGNF:EVRF2000", label: "EVRF2000 / EVRF2000 (UELN-95/98)(EUROPEAN VERTICAL REFERENCE FRAME)"},
    {vertical_shift:"IGNF:EVRF2007", label: "EVRF2007 / EVRF2007 (EUROPEAN VERTICAL REFERENCE FRAME 2007)"},
    {vertical_shift:"IGNF:FUTUNA1997", label: "FUTUNA1997 / NGWF ILES HORN (FUTUNA ET ALOFI)"},
    {vertical_shift:"IGNF:GLOR77", label: "GLOR77 / SHOM 1977 (ILES GLORIEUSES - CANAL DE MOZAMBIQUE)"},
    {vertical_shift:"IGNF:GUAD2008LD", label: "GUAD2008LD / IGN 2008 LD (GUADELOUPE / LA DESIRADE)"},
    {vertical_shift:"IGNF:GUAD88", label: "GUAD88 / IGN 1988 (GUADELOUPE)"},
    {vertical_shift:"IGNF:GUAD88LS", label: "GUAD88LS / IGN 1988 LS (GUADELOUPE / LES SAINTES)"},
    {vertical_shift:"IGNF:GUAD88MG", label: "GUAD88MG / IGN 1988 MG (GUADELOUPE / MARIE-GALANTE)"},
    {vertical_shift:"IGNF:GUAD88SB", label: "GUAD88SB / IGN 1988 SB (GUADELOUPE / SAINT-BARTHELEMY)"},
    {vertical_shift:"IGNF:GUAD88SM", label: "GUAD88SM / IGN 1988 SM (GUADELOUPE / SAINT-MARTIN)"},
    {vertical_shift:"IGNF:GUAD92LD", label: "GUAD92LD / IGN 1992 LD (GUADELOUPE / LA DESIRADE)"},
    {vertical_shift:"IGNF:GUYA77", label: "GUYA77 / NIVELLEMENT GENERAL DE GUYANE (NGG) 1977"},
    {vertical_shift:"IGNF:HUAH01", label: "HUAH01 / HUAHINE_SAU 2001"},
    {vertical_shift:"IGNF:IGN69", label: "IGN69 / NGF-IGN 1969"},
    {vertical_shift:"IGNF:IGN78C", label: "IGN78C / NGF-IGN 1978"},
    {vertical_shift:"IGNF:KERG62", label: "KERG62 / IGN 1962 (KERGUELEN)"},
    {vertical_shift:"IGNF:LIFOU91", label: "LIFOU91 / NIVELLEMENT GENERAL DE LIFOU (IGN 1991 LF)"},
    {vertical_shift:"IGNF:MARE91", label: "MARE91 / NIVELLEMENT GENERAL DE MARE (IGN 1991 MR)"},
    {vertical_shift:"IGNF:MART87", label: "MART87 / IGN 1987 (MARTINIQUE)"},
    {vertical_shift:"IGNF:MAUPITI01", label: "MAUPITI01 / MAUPITI_SAU 2001"},
    {vertical_shift:"IGNF:MAYO53", label: "MAYO53 / SHOM 1953 (MAYOTTE)"},
    {vertical_shift:"IGNF:MOOREA81", label: "MOOREA81 / MOOREA 1981 (MOOREA_SAU 2001)"},
    {vertical_shift:"IGNF:NCAL69", label: "NCAL69 / NIVELLEMENT GENERAL DE NOUVELLE-CALEDONIE (NGNC)"},
    {vertical_shift:"IGNF:NGC48", label: "NGC48 / NIVELLEMENT GENERAL DE LA CORSE (NGC)"},
    {vertical_shift:"IGNF:NGF84", label: "NGF84 / NGF-LALLEMAND"},
    {vertical_shift:"IGNF:NNLUX", label: "NNLUX / NIVELLEMENT GENERAL DU LUXEMBOURG"},
    {vertical_shift:"IGNF:PETRELS52", label: "PETRELS52 / EPF 1952 (ILE DES PETRELS)"},
    {vertical_shift:"IGNF:PINS78", label: "PINS78 / SHOM 1978 (ILE DES PINS)"},
    {vertical_shift:"IGNF:RAIA01", label: "RAIA01 / RAIATEA_SAU 2001"},
    {vertical_shift:"IGNF:REUN89", label: "REUN89 / IGN 1989 (REUNION)"},
    {vertical_shift:"IGNF:SHOM1984WF", label: "SHOM1984WF / SHOM 1984 (WALLIS ET FUTUNA)"},
    {vertical_shift:"IGNF:STPM50_V", label: "STPM50_V / DANGER 1950 (SAINT-PIERRE-ET-MIQUELON)"},
    {vertical_shift:"IGNF:TAHAA01", label: "TAHAA01 / TAHAA_SAU 2001"},
    {vertical_shift:"IGNF:TAHITI66", label: "TAHITI66 / IGN 1966 (TAHITI)"},
    {vertical_shift:"IGNF:UVEA84", label: "UVEA84 / IGN 1984 (ILE UVEA)"},
    {vertical_shift:"IGNF:WALLIS96", label: "WALLIS96 / NGWF WALLIS (MOP 1996)"},           
  ]
