<template>
  <v-app-bar app color="header" dark dense clipped-left flat>
    <div style="width: 100%; height: 100%; overflow: hidden">
      <v-img
        src="../assets/Pointworks_Header.png"
        height="100%"
        width="210px"
      />
    </div>
    <v-btn icon>
      <v-icon @click="openHelpPDF()">
        icon-Help
      </v-icon>
    </v-btn>
    <template>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn  v-bind="attrs" v-on="on" icon>
            <v-icon color="white"> icon-User </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-title class="text-subtitle-1 mt-2 mb-2">
             {{ this.$store.getters.loginData.user_name }}<br>
             {{ this.$store.getters.loginData.mail }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="gotoAccountmanagement()">
            <v-list-item-icon>
              <v-icon>icon-Setting</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $t("Accountmanagement") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="gotoRefresh()">
            <v-list-item-title>Refresh</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-btn @click="lang('en')">
            en           
          </v-btn>
          <v-btn @click="lang('ja')">
            ja            
          </v-btn>
          <v-btn @click="lang('fr')">
            fr          
          </v-btn>
          <v-btn @click="lang('de')">
            de            
          </v-btn>
          <v-btn @click="lang('cz')">
            cz            
          </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppHeader",
  methods: {
    lang(lang) {
      this.$i18n.locale = lang
    },
    openHelpPDF() {
      window.open("/help/scpointworks_user_manual.pdf")
    },
    gotoAccountmanagement() {
      window.open("https://account-manager.pf.smartconstruction.com/")
    },
    gotoRefresh() {
      window.location.replace("/login");
    }
  }
};
</script>

<style>
.v-toolbar__content {
  padding-left: 0px !important;
}
</style>
