<!-- 外周線 -->
<template>
  <div class="d-flex align-center data-list-title">
    <v-btn
      class="pa-0 mr-1"
      min-width="auto"
      plain
      height="auto"
      :disabled="isDisabledVisibleBtn"
      @click="changeIsVisible()"
    >
      <v-icon v-if="perimeterLine.isVisible">mdi-eye-outline</v-icon>
      <v-icon v-else>mdi-eye-off-outline</v-icon>
    </v-btn>
    <!-- 表示名 -->
    <span class="d-flex align-center name">{{ $t('CQW0020126024') }}</span>

    <!-- アイコン表示エリア -->
    <div class="pl-3 ml-auto d-flex align-center">
      <!-- 処理ステータス -->
      <div 
        v-if="perimeterLine.status && perimeterLine.status !== 10"
      >
        <edit-processing-status
          class="ml-1"
          :status="perimeterLine.status"
          :message="perimeterLine.message"
          :useErrorClearing="false"
        >
        </edit-processing-status>
      </div>
      <!-- メニュー -->
      <v-menu 
        light
        offset-y 
        transition="slide-y-transition"
        v-if="!isDisabledVisibleBtn"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-auto"
            icon
            v-bind="attrs"
            v-on="on"
            small
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="showDialog = true">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("CQW0020300019", 1, {name: operationName}) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- 削除確認ダイアログ -->
    <v-dialog
      hide-overlay
      persistent
      no-click-animation
      v-model="showDialog"
      width="330px"
      height="170px"
    >
      <calculation-data-delete-confirm-dialog
        @ok="deletePerimeterLine"
        @cancel="showDialog = false">
        <!-- ダイアログタイトル -->
        <template v-slot:title>
          <span>{{$t("CQW0020126017", 1, {name: operationName})}} </span>
        </template>
        <!-- ダイアログ本文テキスト -->
        <template v-slot:text>
          <span>{{$t("CQW0020126018", 1, {name: operationName})}}<br />{{$t("CQW0010600004")}}</span>
        </template>
      </calculation-data-delete-confirm-dialog>
    </v-dialog>
  </div>
</template>

<script>
import * as constants from '@/constants/constants'
import * as utils from '@/utils/utils';
import * as cesiumCommon from "@/utils/cesium-common";
import CalculationDataDeleteConfirmDialog from './CalculationDataDeleteConfirmDialog.vue';
import EditProcessingStatus from '@/components/common/EditProcessingStatus.vue';
import { i18n } from '../../../i18n.js';
import { repositoryFactory } from "@/repositories/repository-factory";
const perimeterLineRepository = repositoryFactory.get('perimeterLine');

export default {
  components: {
    CalculationDataDeleteConfirmDialog,
    EditProcessingStatus
  },
  props: {
    perimeterLine: Object,
    allowedToShowPerimeterLine: Boolean
  },
  data: () => ({
    operationName: i18n.tc('CQW0020126024'),
    showDialog: false,
  }),
  computed: {
    /**
     * 可視不可視アイコンの活性制御を行う
     * 以下の場合非活性
     *   外周線データが空の場合
     *   紐づく点群が不可視状態の場合
     */
    isDisabledVisibleBtn() {
      return (!this.perimeterLine.perimeterLineInfo 
              || !Object.keys(this.perimeterLine.perimeterLineInfo).length)
              || !this.allowedToShowPerimeterLine;
    }
  },
  watch: {
    /**
     * 親点群が非表示になったら外周線も非表示にする
     */
    allowedToShowPerimeterLine(newValue) {
      if (!newValue && this.perimeterLine.isVisible) {
        cesiumCommon.hidePerimeterLine(this.perimeterLine);
      }
    },
  },
  methods: {
    changeIsVisible() {
      if (this.perimeterLine.isVisible) {
        this.hidePerimeterLine();
      }
      else {
        this.showPerimeterLine();
      }
    },
    showPerimeterLine() {
      // 他の外周線を非表示にする
      this.$store.commit("hideAllPerimeterLines");
      // 現在の外周線を store に保存する
      this.$store.commit("edit/setPerimeterLineValues", {
        currentPerimeterLine: this.perimeterLine,
      });
      // 外周線を表示する
      cesiumCommon.showPerimeterLine(this.perimeterLine);
    },
    hidePerimeterLine() {
      // 現在のの外周線を store から削除する
      this.$store.commit("edit/setPerimeterLineValues", {
        currentPerimeterLine: null,
      });
      // 外周線を非表示にする
      cesiumCommon.hidePerimeterLine(this.perimeterLine);
    },
    async deletePerimeterLine() {
      let successMessage = i18n.tc("CIW0020300024", 1, {name: i18n.tc("CQW0020126024")})
      let errorMessage = i18n.tc("CEW0020300025", 1, {name: i18n.tc("CQW0020126024")})
      const body = {
        perimeterLine_id: this.perimeterLine.perimeterLineId,
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        // ローディング開始
        this.$store.dispatch("updateIsLoading", true);
        const res = await perimeterLineRepository.deletePerimeterLineByPerimeterLineId(this.perimeterLine.perimeterLineId, body);
        if (res.status === 204) {
          // データリストを最新化
          await this.$store.dispatch("getPointDataList", this.$route.query.siteId)
          utils.showSnackBar('success', successMessage);
          this.showDialog = false;
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        // ローディング終了
        this.$store.dispatch("updateIsLoading", false);
      }
    },
    edit() {
      // 外周線を表示
      if (!this.perimeterLine.isVisible) {
        this.showPerimeterLine();
      }
      // 外周線編集モードに設定
      this.$store.dispatch("updateEditType", constants.editType.perimeterLine);
      // その他の機能を押下できないようにする
      this.$store.commit("setProjectPageAbility", false);
    },
  },
  beforeDestroy() {
    if (this.perimeterLine.isVisible) {
      // データが削除されたらCesium上から削除
      this.changeIsVisible();
    }
  }
}
</script>

<style lang="sass" scoped>
.data-list-title
  font-size: 14px
  margin-top: 4px
  white-space: normal
  word-break: break-all

.name
  line-height: 100%

.data-list-title ::v-deep .v-text-field .v-input__control .v-input__slot
  min-height: auto

::v-deep .v-input__slot .v-input--selection-controls__input
  margin-right: 0
  height: auto

.data-list-title-name .on-hover
  cursor: pointer
  background-color: rgba(245,245,245,0.2)

// チェックボックスホバー時の背景色の変化を無効化
::v-deep div.v-input--selection-controls__ripple
  display: none

</style>